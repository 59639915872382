import React, { useCallback, useRef, useState } from 'react';

import cn from 'classnames';
import { PlayIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import BaseReactPlayer from 'react-player/base';

import { EventName } from '@/models/segment';
import { trackEvent } from '@/segment/segment';

import styles from './VideoPlayer.module.scss';
interface VideoPlayerProps extends ReactPlayerProps {
  url: string;
  thumbnailUrl?: string;
  thumbnailText: string;
  integrationIcon?: React.ReactNode;
  onPlayClick?: () => void;
}

export const VideoPlayer = ({
  url,
  thumbnailUrl,
  thumbnailText,
  integrationIcon,
  onPlayClick,
  ...config
}: VideoPlayerProps) => {
  const { t } = useTranslation();
  const [playing, setPlaying] = useState(false);
  const player = useRef<BaseReactPlayer<ReactPlayerProps>>();

  const startPlaying = useCallback(() => {
    if (onPlayClick) {
      onPlayClick();
    }
    setPlaying(true);
    trackEvent(EventName.ClickPlayVideo, { video_url: url });
  }, [onPlayClick, url]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') {
        startPlaying();
      }
    },
    [startPlaying]
  );

  return (
    <div className={styles.container}>
      <div
        className={cn(styles.thumbnail, { [styles.hidden]: playing })}
        onClick={startPlaying}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        role="button"
      >
        <div className={styles.text}>{thumbnailText}</div>
        {thumbnailUrl && <img src={thumbnailUrl} alt="Video thumbnail" />}
        <div className={styles.videoBtn}>
          <span className={styles.icon}>
            <PlayIcon color="var(--icon-default-white)" />
            <span className={styles.buttonText}>{t('onboarding.watch')}</span>
          </span>
        </div>
        {integrationIcon && (
          <div className={styles.integrationIcon}>{integrationIcon}</div>
        )}
      </div>
      <div
        className={cn(styles.player, {
          [styles.visible]: playing,
          [styles.hasThumbnail]: thumbnailUrl,
        })}
      >
        <ReactPlayer
          {...config}
          url={url}
          onEnded={() => setPlaying(false)}
          ref={player}
          playing={playing}
          width="100%"
          height="100%"
          controls
        />
      </div>
    </div>
  );
};
