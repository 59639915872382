import {
  GavelIcon,
  HandHeartIcon,
  HandshakeIcon,
  LightbulbIcon,
  ListChecksIcon,
  TimerIcon,
} from 'lucide-react';

import { Props } from '@/models/icon';

import { ToneOfVoice } from '../models';

type ToneIconProps = {
  type: ToneOfVoice;
} & Props;

export const ToneIcon = ({
  size = 24,
  color = 'var(--icon-default-black)',
  type,
  ...rest
}: ToneIconProps) => {
  switch (type) {
    case 'creative':
      return <LightbulbIcon size={size} color={color} {...rest} />;
    case 'matter_of_fact':
      return <ListChecksIcon size={size} color={color} {...rest} />;
    case 'neutral':
      return (
        // Custom icon
        <svg
          width={size}
          height={size}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...rest}
        >
          <path
            d="M19 17C19.0111 17 19.0222 16.9999 19.0333 16.9997M19.0333 16.9997C19.5517 16.9911 20.047 16.7814 20.4142 16.4142C20.7893 16.0391 21 15.5304 21 15V5C21 4.46957 20.7893 3.96086 20.4142 3.58579C20.0391 3.21071 19.5304 3 19 3H5C4.46957 3 3.96086 3.21071 3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5V15C3 15.5304 3.21071 16.0391 3.58579 16.4142C3.96086 16.7893 4.46957 17 5 17L10 16.9999L12 21L14 16.9998L19.0333 16.9997ZM15 8H9M16 11.5H8"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'professional':
      return <HandshakeIcon size={size} color={color} {...rest} />;
    case 'empathetic':
      return <HandHeartIcon size={size} color={color} {...rest} />;
    case 'firm':
      return <GavelIcon size={size} color={color} {...rest} />;
    case 'urgent':
      return <TimerIcon size={size} color={color} {...rest} />;
  }
};
