import { useCallback, useEffect, useMemo, useState } from 'react';

import MenuItem from '@mui/material/MenuItem';
import {
  EllipsisIcon,
  RefreshCcwIcon,
  SquarePenIcon,
  Trash2Icon,
} from 'lucide-react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Avatar } from '@/components/atoms/Avatar/Avatar/Avatar';
import PlainFieldset from '@/components/atoms/Fieldset/templates/PlainFieldset';
import IconButton from '@/components/atoms/IconButton/IconButton';
import { CustomMenu } from '@/components/atoms/Menu/Menu';
import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';
import Table from '@/components/atoms/Table/Table';
import {
  MODAL_ACCOUNT_TRANSFER,
  MODAL_DELETE,
  MODAL_ROLE_EDIT,
} from '@/components/organisms/Modals/ModalConductor';
import { useAccount } from '@/hooks/useAccount';
import { useInvitations } from '@/hooks/useInvitations';
import useMembers from '@/hooks/useMembers';
import { useRoles } from '@/hooks/useRoles';
import { useTeams } from '@/hooks/useTeams';
import useUser from '@/hooks/useUser';
import { Member, Invitation, RoleType } from '@/models/member';
import { actions } from '@/models/permissions';
import { EventName } from '@/models/segment';
import { RootState } from '@/models/state';
import { popModal, pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { trackEvent } from '@/segment/segment';

import { getRoleName } from './helper';

import styles from './ManageAccessMembers.module.scss';

export const ManageAccessMembers = () => {
  const { roles } = useRoles();
  const { accountRole } = useAccount();
  const { user } = useUser();
  const { members, deleteMember } = useMembers();
  const { accountInvitations, deleteInvitation } = useInvitations();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [options, setOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const dispatch = useDispatch();
  const { teams } = useTeams();

  const canAssign = useSelector((state: RootState) =>
    getPermissions(state, 'roles', actions.ASSIGN)
  );

  const { t } = useTranslation();

  const filterOptions = useMemo(
    () => [
      { label: t('roles.filters.all'), value: 'all' },
      { label: t('roles.filters.no_role'), value: 'no_role' },
      { label: t('roles.filters.pending'), value: 'pending' },
    ],
    [t]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setSelectedUser(null);
  }, []);

  const handleClick = useCallback((event, i) => {
    setSelectedUser(i);
    setAnchorEl(event.currentTarget);
  }, []);

  const handleFilterChange = useCallback(
    (value: number | string) => {
      const formattedAccountInvitations =
        accountInvitations?.map((inv) => ({
          ...inv,
          name: inv?.email,
        })) ?? [];
      if (value === filterOptions[0]?.value) {
        setOptions([...formattedAccountInvitations, ...members]);
      } else if (value === filterOptions[1].value) {
        const updatedInvitations = formattedAccountInvitations?.filter(
          (r) => r.role_ids && r.role_ids.length === 0
        );
        const formattedMembers = members?.filter(
          (m) =>
            m.role_ids && m.role_ids.length === 0 && m.role !== RoleType.OWNER
        );
        setOptions([...updatedInvitations, ...formattedMembers]);
      } else {
        setOptions([...formattedAccountInvitations]);
      }
    },
    [accountInvitations, filterOptions, members]
  );

  useEffect(() => {
    if (members) {
      const formattedAccountInvitations =
        accountInvitations?.map((inv) => ({
          ...inv,
          name: inv?.email,
        })) ?? [];
      setOptions([...formattedAccountInvitations, ...members]);
    }
  }, [accountInvitations, members]);

  const handleEditRole = useCallback(() => {
    trackEvent(EventName.ClickEditMember);
    setAnchorEl(null);
    dispatch(pushModal(MODAL_ROLE_EDIT, { user: selectedUser }));
  }, [dispatch, selectedUser]);

  const handleTransferAccount = useCallback(() => {
    trackEvent(EventName.ClickTransferAccount);
    setAnchorEl(null);
    dispatch(
      pushModal(MODAL_ACCOUNT_TRANSFER, { user_id: selectedUser?.user_id })
    );
  }, [dispatch, selectedUser]);

  const handleDeleteMember = useCallback(() => {
    if (!selectedUser?.invitation_code) {
      trackEvent(EventName.ClickDeleteMember);

      const deleteProps = {
        subtitle: (
          <Trans
            i18nKey="members.delete_subtitle"
            values={[selectedUser?.name]}
          />
        ),
        title: t('members.delete'),
        confirm: true,
        onDelete: () => {
          deleteMember(
            { user_id: selectedUser?.user_id },
            {
              onSuccess: () => {
                dispatch(popModal());
              },
            }
          );
        },
      };
      dispatch(pushModal(MODAL_DELETE, deleteProps));
    } else {
      trackEvent(EventName.ClickDeleteInvitation);

      const deleteProps = {
        subtitle: (
          <Trans
            i18nKey="invitations.delete_warn"
            values={[selectedUser?.email]}
          />
        ),
        title: t('invitations.revoke'),
        onDelete: () => {
          deleteInvitation(
            { email: selectedUser?.email },
            {
              onSuccess: () => {
                dispatch(popModal());
              },
            }
          );
        },
      };
      dispatch(pushModal(MODAL_DELETE, deleteProps));
    }
    setAnchorEl(null);
  }, [
    deleteInvitation,
    deleteMember,
    dispatch,
    selectedUser?.email,
    selectedUser?.invitation_code,
    selectedUser?.name,
    selectedUser?.user_id,
    t,
  ]);

  const getColumns = useMemo(
    () => [
      {
        Header: t('common.name'),
        accessor: 'name',
        Cell: (props) => {
          const row: Member | Invitation = props.row.original;
          const value: string = props.value;

          if ('invitation_code' in row) {
            const invitationAvatar = row.user_avatar;
            return (
              <div className={styles.listItem}>
                <Avatar src={invitationAvatar} />
                <span className={styles.email}>{value}</span>
                <StatusBadge
                  withIcon={false}
                  label={t('status.pending')}
                  variant="info"
                />
              </div>
            );
          }
          const { user_id, email } = row;
          return (
            <div className={styles.listItem}>
              <Avatar userId={user_id} />
              <div className={styles.infoContainer}>
                <span>{props.value}</span>
                <span className={styles.email}>{email}</span>
              </div>
            </div>
          );
        },
      },
      {
        Header: t('roles.individual'),
        accessor: 'role_ids',
        disableSortBy: true,
        Cell: (props) => {
          return getRoleName(props.row.original, roles);
        },
      },
      {
        Header: t('common.teams'),
        accessor: 'team_ids',
        disableSortBy: true,
        Cell: (props: { value: string[] }) => {
          const teamNames = props.value?.map((team_id: string) => {
            const name = teams?.find((team) => team.team_id === team_id)?.name;
            return name;
          });
          return teamNames?.join(', ') || '-';
        },
      },
      {
        Header: '',
        id: 'menu',
        Cell: (props) => {
          const row: Member | Invitation = props.row.original;

          const id = {
            user_id: row?.user_id,
            invitation_code: 'invitation_code' in row && row?.invitation_code,
            name: 'name' in row && row?.name,
            role_ids: row?.role_ids,
            email: row?.email,
            role: row?.role,
            team_ids: row?.team_ids,
          };
          return (
            <IconButton
              disabled={
                row?.role === 'owner' ||
                row?.user_id === user?.user_id ||
                !canAssign
              }
              onClick={(e) => handleClick(e, id)}
              ariaLabel="Member options"
              ariaHasPopUp
            >
              <EllipsisIcon color="var(--icon-default-gray)" />
            </IconButton>
          );
        },
      },
    ],
    [canAssign, handleClick, roles, t, teams, user?.user_id]
  );

  if (!members) {
    return null;
  }

  const menuText = selectedUser?.invitation_code
    ? t('invitations.revoke')
    : selectedUser?.user_id
      ? t('members.delete')
      : '';

  return (
    <>
      <PlainFieldset fullWidth isLoading={false}>
        <Table
          data={options}
          columns={getColumns}
          sortable
          filterable
          filterOptions={filterOptions}
          onFilterChange={handleFilterChange}
          searchPlaceholder={t('members.search_placeholder')}
        />
      </PlainFieldset>
      <CustomMenu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEditRole}>
          <SquarePenIcon size={16} color="var(--icon-default-gray)" />
          {t('roles.edit_access')}
        </MenuItem>
        {accountRole === RoleType.OWNER && (
          <MenuItem onClick={handleTransferAccount}>
            <RefreshCcwIcon size={16} color="var(--icon-default-gray)" />
            {t('roles.transfer_ownership')}
          </MenuItem>
        )}
        <MenuItem className={styles.danger} onClick={handleDeleteMember}>
          <Trash2Icon size={16} />
          {menuText}
        </MenuItem>
      </CustomMenu>
    </>
  );
};
