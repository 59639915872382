import { memo, ReactNode } from 'react';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import {
  BotIcon,
  CircleAlertIcon,
  FileVolumeIcon,
  MegaphoneIcon,
} from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { Avatar } from '@/components/atoms/Avatar/Avatar/Avatar';
import useBrains from '@/hooks/useBrains';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import {
  ConversationSource,
  Message as MessageType,
  User,
} from '@/models/chat';
import { useBroadcasts } from '@/modules/broadcast/hooks/useBroadcasts';
import { splitArray } from '@/util/util';

import { Attachment } from './Attachment/Attachment';
import Attachments from './Attachment/Attachments';
import { Response } from './Response/Response';

import styles from './Message.module.scss';

/**
 * Renders either an error icon or the provided content based on message failure status
 */
const MessageAvatar = ({
  showAvatar,
  failed,
  children,
}: {
  showAvatar: boolean;
  failed: boolean;
  children: ReactNode;
}): JSX.Element | null => {
  if (!showAvatar) {
    return null;
  }

  if (failed) {
    return <CircleAlertIcon size={24} color="var(--icon-default-error)" />;
  }

  return <>{children}</>;
};

interface Props {
  message: MessageType;
  showAvatar: boolean;
  user: Partial<User>;
  group: boolean;
  visitorStatus: 'online' | 'offline' | 'away' | null;
  lastVisitorActivity: string | null;
  source: ConversationSource;
}

const Message = ({
  message,
  showAvatar,
  user,
  group,
  visitorStatus,
  lastVisitorActivity,
  source,
}: Props) => {
  const { t } = useTranslation();
  const brainId = message.author_type === 'brain' ? message.author_id : null;
  const { brain } = useBrains(brainId);
  const broadcastId =
    message.author_type === 'broadcast' ? message.author_id : null;
  const { broadcast } = useBroadcasts(broadcastId);
  const { ai_agents } = useFeatureFlag();
  // VISITOR
  if (message.author_type === 'visitor') {
    const hasAttachments = message?.body?.attachments?.length > 0;

    return (
      <div className={styles.visitorContainer}>
        {group && (
          <span className={styles.visitorAvatar}>
            <Tooltip
              arrow
              title={t('conversation.last_visitors_activity', {
                0: lastVisitorActivity,
              })}
            >
              <Avatar
                size="medium"
                src={user?.avatar}
                status={visitorStatus}
                hideTooltip
              />
            </Tooltip>
          </span>
        )}
        <div
          className={cn(styles.visitorMessage, {
            [styles.lastOfGroup]: group,
          })}
        >
          {hasAttachments && (
            <Box mb={message?.text ? 1 : 0}>
              {splitArray(message?.body?.attachments, 4).map(
                (chunk, chunkIndex) => (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={chunkIndex}
                    className={cn(styles.attachmentContainer, {
                      [styles.hasText]: !!message?.text,
                    })}
                  >
                    <Attachments>
                      {chunk.map((attachment) => (
                        <Attachment
                          key={attachment?.id ?? attachment.url}
                          attachment={attachment}
                          source={source}
                          isSingleItem={message?.body?.attachments.length === 1}
                          authorType="visitor"
                        />
                      ))}
                    </Attachments>
                  </div>
                )
              )}
            </Box>
          )}
          {message.body?.type === 'audio' && message?.text?.length > 0 && (
            <span className={styles.audioTitle}>
              <FileVolumeIcon size={16} color="var(--icon-default-gray)" />
              <Typography
                variant="label-regular"
                color="var(--text-default-gray-light)"
                className={styles.audio}
              >
                {t('conversation.audio_message')}
              </Typography>
            </span>
          )}
          <Typography variant="body-regular" color="var(--text-default-black)">
            {message.text}
          </Typography>
        </div>
      </div>
    );
  }

  // AGENT with attachments
  if (
    message.author_type === 'agent' &&
    message?.body?.attachments?.length > 0
  ) {
    return (
      <div
        className={cn(styles.container, {
          [styles.withAvatar]: showAvatar,
          [styles.textWithOptions]:
            message.body?.type === 'text' && message?.body?.options,
        })}
      >
        <MessageAvatar showAvatar={showAvatar} failed={!!message.failed_at}>
          <Avatar size="medium" userId={message.author_id} hideStatus />
        </MessageAvatar>
        {message.error_code ? (
          <Tooltip arrow title={t(`error_codes.${message.error_code}`)}>
            <Response
              text={message.text || ''}
              attachments={message.body.attachments}
              source={source}
              authorType={message.author_type}
            />
          </Tooltip>
        ) : (
          <Response
            text={message.text || ''}
            attachments={message.body.attachments}
            source={source}
            authorType={message.author_type}
          />
        )}
      </div>
    );
  }

  // BRAIN
  if (message.author_type === 'brain') {
    return (
      <div
        className={cn(styles.container, {
          [styles.withAvatar]: showAvatar,
          [styles.textWithOptions]:
            message.body.type === 'text' && message?.body?.options,
        })}
      >
        <MessageAvatar showAvatar={showAvatar} failed={!!message.failed_at}>
          <Tooltip
            arrow
            title={(() => {
              const entityType = ai_agents ? 'aiAgent' : 'brain';
              return `${t(`common.${entityType}Cap`)} ${brain?.name}`;
            })()}
          >
            <BotIcon color="var(--icon-default-gray)" />
          </Tooltip>
        </MessageAvatar>
        {message.error_code ? (
          <Tooltip arrow title={t(`error_codes.${message.error_code}`)}>
            <Response {...message.body} hasAvatar={showAvatar} />
          </Tooltip>
        ) : (
          <Response {...message.body} hasAvatar={showAvatar} />
        )}
      </div>
    );
  }

  // BROADCAST
  if (message.author_type === 'broadcast') {
    return (
      <div
        className={cn(styles.container, styles.broadcast, {
          [styles.withAvatar]: showAvatar,
        })}
      >
        <MessageAvatar showAvatar={showAvatar} failed={!!message.failed_at}>
          <Tooltip
            arrow
            title={
              broadcast
                ? `${t('main_navigation.broadcast_one')} ${broadcast?.name}`
                : t('broadcasts.deleted_broadcast')
            }
          >
            <MegaphoneIcon color="var(--icon-default-gray)" />
          </Tooltip>
        </MessageAvatar>
        {message.error_code ? (
          <Tooltip arrow title={t(`error_codes.${message.error_code}`)}>
            <Response
              action_id={message.message_id}
              type="text"
              text={message?.text}
              hasAvatar={showAvatar}
              {...message.body}
            />
          </Tooltip>
        ) : (
          <Response
            action_id={message.message_id}
            type="text"
            text={message?.text}
            hasAvatar={showAvatar}
            {...message.body}
          />
        )}
      </div>
    );
  }

  // TEMPLATES
  if ((message.body?.type as string) === 'template') {
    const options = message?.body?.template?.components
      ?.find((component) => component.type.toLowerCase() === 'buttons')
      ?.buttons?.map((option) => ({
        text: option.text,
        label: option.text,
      }));

    return (
      <div
        className={cn(styles.container, {
          [styles.withAvatar]: showAvatar,
          [styles.textWithOptions]: options,
        })}
      >
        <MessageAvatar showAvatar={showAvatar} failed={!!message.failed_at}>
          <Avatar size="medium" userId={message.author_id} hideStatus />
        </MessageAvatar>
        {message.error_code ? (
          <Tooltip arrow title={t(`error_codes.${message.error_code}`)}>
            <Response
              action_id={message.message_id}
              type="text"
              text={message?.text}
              hasAvatar={showAvatar}
              source={source}
              options={options}
            />
          </Tooltip>
        ) : (
          <Response
            action_id={message.message_id}
            type="text"
            text={message?.text}
            hasAvatar={showAvatar}
            source={source}
            options={options}
          />
        )}
      </div>
    );
  }

  // FALLBACK (includes older agent messages without attachments)
  const hasBody = Boolean(message.body);
  const baseResponse = hasBody ? (
    <Response
      text={message?.text}
      {...message.body}
      hasAvatar={showAvatar}
      source={source}
    />
  ) : (
    <Response
      action_id={message.message_id}
      type="text"
      text={message?.text}
      hasAvatar={showAvatar}
      source={source}
    />
  );

  const wrappedResponse = message.error_code ? (
    <Tooltip arrow title={t(`error_codes.${message.error_code}`)}>
      {baseResponse}
    </Tooltip>
  ) : (
    baseResponse
  );

  return (
    <div
      className={cn(styles.container, {
        [styles.withAvatar]: showAvatar,
        [styles.textWithOptions]:
          message.body?.type === 'text' && message?.body?.options,
      })}
    >
      <MessageAvatar showAvatar={showAvatar} failed={!!message.failed_at}>
        <Avatar size="medium" userId={message.author_id} hideStatus />
      </MessageAvatar>
      {wrappedResponse}
    </div>
  );
};

export default memo(Message);
