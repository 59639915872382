import { useCallback, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { ArcherContainer, ArcherElement } from 'react-archer';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import PlainFieldset from '@/components/atoms/Fieldset/templates/PlainFieldset';
import IfIcon from '@/components/atoms/Icons/IfIcon';
import { BRAIN_ADD } from '@/components/organisms/Modals/ModalConductor';
import { TileCreation } from '@/components/organisms/Tile';
import Tile from '@/components/organisms/Tile/Tile';
import useBrains from '@/hooks/useBrains';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { EventName } from '@/models/segment';
import { pushModal } from '@/redux/modals/actions';
import { selectAccountSlug, selectDeskId } from '@/redux/session/selectors';
import { selectIframeView } from '@/redux/user/selectors';
import { trackEvent } from '@/segment/segment';
import { resolveBrainsPath } from '@/util/util';

import BrainTile from './BrainTile/BrainTile';
import HGSPulse from './HGSPulse';
import IntegrationTile from './IntegrationTile/IntegrationTile';
import MoveoPulse from './MoveoPulse';
import useHomeOverview from './useHomeOverview';

import styles from './HomeOverview.module.scss';

const DEFAULT_ITEMS_LIMIT = 5;

const HomeOverview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const deskId = useSelector(selectDeskId);
  const {
    rulesWithBrains,
    integrations,
    active,
    setActive,
    shouldHighlightIntegration,
    isLoading,
  } = useHomeOverview();
  const isIframeView = useSelector(selectIframeView);
  const { brains } = useBrains();
  const [brainsLimit, setBrainsLImit] = useState(DEFAULT_ITEMS_LIMIT);
  const [integrationsLimit, setIntegrationsLImit] =
    useState(DEFAULT_ITEMS_LIMIT);

  const features = useFeatureFlag();
  const { t } = useTranslation();

  const slug = useSelector(selectAccountSlug);

  const handleMouseOver = useCallback(
    (index: number) => () => {
      setActive(index);
    },
    [setActive]
  );

  const handleMouseOut = useCallback(() => {
    setActive(null);
  }, [setActive]);

  const showAddBrainModal = useCallback(() => {
    if (brains?.length === 0) {
      trackEvent(EventName.ClickCreateBrain);
      dispatch(pushModal(BRAIN_ADD));
      navigate(resolveBrainsPath(`/${slug}/brains`, features.ai_agents));
    } else {
      navigate(`/${slug}/environments/${deskId}/rules`);
    }
  }, [brains?.length, deskId, dispatch, slug, navigate, features.ai_agents]);

  const onCreateChannel = () => {
    navigate(`/${slug}/environments/${deskId}/integrations`);
  };

  const updatedRulesWithBrains = rulesWithBrains?.slice(0, brainsLimit);
  const updatedIntegrations = integrations?.slice(0, integrationsLimit);

  return (
    <PlainFieldset fullWidth isLoading={isLoading}>
      <ArcherContainer>
        <div className={styles.wrapper}>
          <div className={styles.wrapperBrains}>
            <Typography className={styles.title}>
              {t('home.content')}
            </Typography>
            {updatedRulesWithBrains?.map(
              (
                {
                  brain,
                  brain_version,
                  rule_id,
                  collection_id,
                  collection_name,
                },
                index
              ) => {
                return (
                  <ArcherElement
                    id={`brain-${index}`}
                    key={`${brain?.brain_parent_id}-${rule_id}`}
                    relations={[
                      {
                        targetId: `rule-${index}`,
                        targetAnchor: 'left',
                        sourceAnchor: 'right',
                        style: {
                          endMarker: false,
                          strokeWidth: index === active ? 2 : 1,
                          strokeColor:
                            index === active
                              ? 'var(--border-active-blue)'
                              : 'var(--surface-tertiary-blue)',
                        },
                      },
                    ]}
                  >
                    <Box
                      onMouseOver={handleMouseOver(index)}
                      onMouseOut={handleMouseOut}
                    >
                      <BrainTile
                        brain={brain}
                        brain_version={brain_version}
                        collection_id={collection_id}
                        collection_name={collection_name}
                      />
                    </Box>
                  </ArcherElement>
                );
              }
            )}
            {(updatedRulesWithBrains?.length === 0 || brains?.length === 0) && (
              <ArcherElement
                id="brain-empty"
                relations={[
                  {
                    targetId: 'moveo',
                    targetAnchor: 'left',
                    sourceAnchor: 'right',
                    style: {
                      endMarker: false,
                      strokeWidth: 1,
                      strokeColor: 'var(--border-default-gray)',
                      strokeDasharray: '5,5',
                    },
                  },
                ]}
              >
                <span className={styles.createTile}>
                  <TileCreation
                    size="small"
                    onCreateClick={showAddBrainModal}
                    title={
                      brains?.length === 0
                        ? t('brains.add_brain')
                        : t('brains.connect_brain')
                    }
                  />
                </span>
              </ArcherElement>
            )}
          </div>
          <div className={styles.wrapperRules}>
            {updatedRulesWithBrains?.map((rule, index) => (
              <ArcherElement
                id={`rule-${index}`}
                key={rule.rule_id}
                relations={[
                  {
                    targetId: 'moveo',
                    targetAnchor: 'left',
                    sourceAnchor: 'right',
                    style: {
                      endMarker: false,
                      strokeWidth: index === active ? 2 : 1,
                      strokeColor:
                        index === active
                          ? 'var(--border-active-blue)'
                          : 'var(--surface-tertiary-blue)',
                    },
                  },
                ]}
              >
                <div
                  className={cn(styles.ruleTileWrapper, {
                    [styles.hasCollections]:
                      !features?.enable_collections_for_rules,
                  })}
                >
                  <Link
                    to={`/${slug}/environments/${deskId}/rules/${rule.rule_id}`}
                  >
                    <Tile className={styles.tile}>
                      <Box
                        className={styles.ruleTile}
                        onMouseOver={handleMouseOver(index)}
                        onMouseOut={handleMouseOut}
                      >
                        <IfIcon size={36} color="var(--icon-default-blue)" />
                      </Box>
                    </Tile>
                  </Link>
                </div>
              </ArcherElement>
            ))}
          </div>

          <ArcherElement id="moveo">
            <Box>
              {isIframeView ? (
                <HGSPulse />
              ) : (
                <MoveoPulse
                  active={
                    updatedRulesWithBrains?.length > 0 &&
                    integrations?.length > 0
                  }
                />
              )}
            </Box>
          </ArcherElement>

          <div className={styles.wrapperChannels}>
            <Typography className={styles.title}>
              {t('common.channels')}
            </Typography>
            {updatedIntegrations?.map((integration, index) => {
              return (
                <ArcherElement
                  id={`integration-${index}`}
                  key={integration.integration_id}
                  relations={[
                    {
                      targetId: 'moveo',
                      targetAnchor: 'right',
                      sourceAnchor: 'left',
                      style: {
                        endMarker: false,
                        strokeWidth: shouldHighlightIntegration(index) ? 2 : 1,
                        strokeColor: shouldHighlightIntegration(index)
                          ? 'var(--border-active-blue)'
                          : 'var(--surface-tertiary-blue)',
                      },
                    },
                  ]}
                >
                  <Box>
                    <IntegrationTile integration={integration} />
                  </Box>
                </ArcherElement>
              );
            })}

            {integrations?.length === 0 && (
              <ArcherElement
                id="channels-empty"
                relations={[
                  {
                    targetId: 'moveo',
                    targetAnchor: 'right',
                    sourceAnchor: 'left',
                    style: {
                      endMarker: false,
                      strokeWidth: 1,
                      strokeColor: 'var(--border-default-gray)',
                      strokeDasharray: '5,5',
                    },
                  },
                ]}
              >
                <span className={styles.createTile}>
                  <TileCreation
                    size="small"
                    onCreateClick={onCreateChannel}
                    title={t('home.add_channel')}
                  />
                </span>
              </ArcherElement>
            )}
          </div>
        </div>
      </ArcherContainer>
      {rulesWithBrains?.length > DEFAULT_ITEMS_LIMIT && (
        <div className={styles.seeMoreContainer}>
          <Button
            onClick={() =>
              setBrainsLImit((prev) => (prev ? undefined : DEFAULT_ITEMS_LIMIT))
            }
          >
            {t('home.see')} {brainsLimit ? t('home.more') : t('home.less')}
          </Button>
        </div>
      )}
      {integrations?.length > DEFAULT_ITEMS_LIMIT && (
        <div
          className={cn(styles.seeMoreContainer, styles.seeMoreIntegrations)}
        >
          <Button
            onClick={() =>
              setIntegrationsLImit((prev) =>
                prev ? undefined : DEFAULT_ITEMS_LIMIT
              )
            }
          >
            {t('home.see')}{' '}
            {integrationsLimit ? t('home.more') : t('home.less')}
          </Button>
        </div>
      )}
    </PlainFieldset>
  );
};

export default HomeOverview;
