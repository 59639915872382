import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { XIcon } from 'lucide-react';
import { UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import { useClickAway } from 'react-use';

import {
  AccountAnalyticsFilterType,
  LogsFilterType,
} from '@/modules/analytics/models';
import { delay } from '@/util/util';

import FilterDropdown from './FilterDropdown';
import FilterValue from './FilterValue';
import { FilterOptions } from '../../constants';

import styles from './FilterRow.module.scss';

type Props = {
  type: LogsFilterType | AccountAnalyticsFilterType;
  remove: UseFieldArrayRemove;
  index: number;
  filterOptions: FilterOptions;
  openDropdown: boolean;
  setOpenDropdown: (value: boolean) => void;
};

const FilterRow = ({
  type,
  remove,
  index,
  filterOptions,
  openDropdown,
  setOpenDropdown,
}: Props) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const { setValue, getValues } = useFormContext();
  const option = useMemo(
    () => filterOptions.find((opt) => opt.value === type),
    [filterOptions, type]
  );

  useEffect(() => {
    if (openDropdown) {
      setShowDropdown(true);
    }
  }, [openDropdown, type]);

  const dropdownRef = useRef(null);

  const onClickAway = useCallback(async () => {
    await delay(100);
    const val = getValues(`filters.${index}.${type}`);
    if (val?.length === 0 || val === '' || val === null) {
      remove(index);
    }
    setShowDropdown(false);
    setOpenDropdown(null);
  }, [getValues, index, remove, setOpenDropdown, type]);

  useClickAway(dropdownRef, onClickAway);

  return (
    <div className={styles.row_container}>
      <div ref={dropdownRef}>
        <div
          className={styles.row}
          onClick={() => {
            setShowDropdown((prev) => !prev);
          }}
          aria-hidden={true}
        >
          <FilterValue type={type} index={index} option={option} />
          <button className={styles.close} onClick={() => remove(index)}>
            <XIcon size={8} color="var(--icon-default-black)" />
          </button>
        </div>
        {showDropdown && !option.input && (
          <FilterDropdown
            type={type}
            index={index}
            onApply={onClickAway}
            onClear={() => {
              setValue(
                `filters.${index}.${type}`,
                option.input || option.valuePrefix
                  ? ''
                  : option.trueLabel
                    ? null
                    : []
              );
            }}
          />
        )}
      </div>
    </div>
  );
};

export default FilterRow;
