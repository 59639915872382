import { ListOrderedIcon } from 'lucide-react';

import { StyleButton } from './StyleButton';

export const OrderedListButton = () => {
  return (
    <StyleButton
      type="ol_list"
      Icon={() => (
        <ListOrderedIcon size={14} color="var(--icon-default-black)" />
      )}
      size={14}
    />
  );
};
