import { Fragment, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import {
  ChartPieIcon,
  MessageSquareTextIcon,
  Share2Icon,
  UserRoundPlusIcon,
  WandSparklesIcon,
} from 'lucide-react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router';

import { BackgroundWrapper } from '@/components/atoms/BackgroundWrapper/BackgroundWrapper';
import Button from '@/components/atoms/Button/Button/Button';
import { MessageQuestionIcon } from '@/components/atoms/Icons/MessageQuestionIcon';
import { useAccount } from '@/hooks/useAccount';
import useMembers from '@/hooks/useMembers';
import useUser from '@/hooks/useUser';
import { MessagesCircleIcon } from '@/modules/humanChat/icons/MessagesCircleIcon';
import { setAccountSlug } from '@/redux/session/actions';
import { selectAccountSlug } from '@/redux/session/selectors';
import { selectUser } from '@/redux/user/selectors';
import { ADMIN, BUILDER, CHAT_AGENT, CHAT_MANAGER } from '@/util/constants';

import { RoleAction } from '../../components/RoleAction/RoleAction';
import { RoleBadge } from '../../components/RoleBadge/RoleBadge';

import styles from './WelcomePage.module.scss';

// This function is used to remove overlapping roles
// e.g. if a user has both CHAT_AGENT and CHAT_MANAGER roles, we only show CHAT_MANAGER
// This is because CHAT_MANAGER has more permissions than CHAT_AGENT
function getRoles(roles: string[]) {
  const finalRoles = [];
  const hasAdminRole = roles?.some((role) => role.includes(ADMIN));
  const hasBuilderRole = roles?.some((role) => role.includes(BUILDER));
  const hasChatAgentRole = roles?.some((role) => role.includes(CHAT_AGENT));
  const hasChatManagerRole = roles?.some((role) => role.includes(CHAT_MANAGER));

  if (hasAdminRole) {
    finalRoles.push(ADMIN);
    return finalRoles;
  }
  if (hasBuilderRole) {
    finalRoles.push(BUILDER);
  }
  if (hasChatAgentRole && !hasChatManagerRole) {
    finalRoles.push(CHAT_AGENT);
  }
  if (hasChatManagerRole) {
    finalRoles.push(CHAT_MANAGER);
  }

  return finalRoles;
}

type InnerRolesProps = {
  roles: string[];
};

const InnerRoles = ({ roles }: InnerRolesProps) => {
  const { t } = useTranslation();

  return roles.map((role, index: number) => (
    <Fragment key={role}>
      <RoleBadge role={role} />
      {index < roles.length - 1 && <span> {t('rules.and')} </span>}
    </Fragment>
  ));
};

const ICON_PROPS = {
  size: 24,
  color: 'var(--icon-default-black)',
} as const;

// This object defines the actions that a user can take based
//  on their roles and the icons that represent them
const ROLE_ACTIONS = {
  [ADMIN]: [
    {
      translation: 'build_your_virtual_agent',
      icon: <WandSparklesIcon {...ICON_PROPS} />,
    },
    {
      translation: 'manage_your_team',
      icon: <UserRoundPlusIcon {...ICON_PROPS} />,
    },
    { translation: 'monitor_success', icon: <ChartPieIcon {...ICON_PROPS} /> },
  ],
  [BUILDER]: [
    {
      translation: 'build',
      icon: <WandSparklesIcon className="p-1.5" {...ICON_PROPS} />,
    },
    { translation: 'test', icon: <MessageSquareTextIcon {...ICON_PROPS} /> },
    { translation: 'optimize', icon: <ChartPieIcon {...ICON_PROPS} /> },
  ],
  [CHAT_AGENT]: [
    {
      translation: 'create_quick_responses',
      icon: <MessageQuestionIcon {...ICON_PROPS} />,
    },
    {
      translation: 'auto_route_conversations',
      icon: <Share2Icon {...ICON_PROPS} />,
    },
    {
      translation: 'collaborate',
      icon: <MessagesCircleIcon {...ICON_PROPS} />,
    },
  ],
  [CHAT_MANAGER]: [
    {
      translation: 'manage_your_team',
      icon: <UserRoundPlusIcon {...ICON_PROPS} />,
    },
    {
      translation: 'create_quick_responses',
      icon: <MessageQuestionIcon {...ICON_PROPS} />,
    },
    {
      translation: 'monitor_performance',
      icon: <ChartPieIcon {...ICON_PROPS} />,
    },
  ],
} as const;

export const WelcomePage = () => {
  // Custom hooks
  const navigate = useNavigate();
  const { updateUser, user } = useUser();
  const { account } = useAccount();
  const { member } = useMembers();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Redux selectors
  const accountSlug = useSelector(selectAccountSlug);
  const reduxUser = useSelector(selectUser);

  // Local variables
  const role_ids = member?.role_ids || [];
  const roles = getRoles(role_ids);
  let roleActions = [];
  roles.forEach((role) => {
    roleActions = [...roleActions, ...ROLE_ACTIONS[role]];
  });
  const hasMultipleRoles = roles?.length > 1;
  const redirectToHome =
    role_ids?.includes(ADMIN) || role_ids?.includes(BUILDER);
  const redirectUrl = redirectToHome
    ? `/${accountSlug}`
    : `/${accountSlug}/chats`;

  // Callbacks
  const handleClick = () => {
    updateUser({
      ...user,
      // Update the user metadata to indicate that the user has seen the welcome page
      metadata: {
        ...user?.metadata,
        seenWelcomePage: true,
      },
    });

    navigate(redirectUrl, {
      replace: true,
    });
  };

  useEffect(() => {
    // Set the user metadata to indicate that the user has not clicked
    //  the "Let's start" button on first render of the page
    if (user && user?.metadata?.seenWelcomePage === undefined) {
      updateUser({
        ...user,
        metadata: {
          ...user?.metadata,
          seenWelcomePage: false,
        },
      });
    }
  }, [updateUser, user]);

  useEffect(() => {
    const slug = reduxUser?.accounts?.[0].slug;

    // Set the account slug when user refreshes the page
    if (!account?.slug && slug) {
      dispatch(setAccountSlug(slug));
    }
  }, [account?.slug, dispatch, reduxUser.accounts]);

  // Redirect to the home page or human chat if the user has already seen the welcome page
  if (user?.metadata?.seenWelcomePage) {
    <Navigate to={redirectUrl} />;
  }

  return (
    <BackgroundWrapper
      useLeftContainer={false}
      title={
        <Trans
          i18nKey={'member_invitation.welcome_aboard'}
          values={{ 0: user?.name }}
        />
      }
      subtitle={
        <>
          {t('member_invitation.as')} <InnerRoles roles={roles} />{' '}
          {t('member_invitation.you_can_do')}
        </>
      }
    >
      <div className={styles.container}>
        <ul
          className={cn({
            [styles.roleActions]: true,
            [styles['roleActions--tight']]: hasMultipleRoles,
          })}
        >
          {roleActions.map((roleAction) => (
            <RoleAction key={roleAction.translation} {...roleAction} />
          ))}
        </ul>
        <Button className={styles.startButton} onClick={handleClick}>
          {t('member_invitation.lets_start')}
        </Button>
      </div>
      <Typography
        className={styles.accountName}
        variant="body-regular"
        color="var(--text-default-gray)"
      >
        {account?.name} {t('common.account')}
      </Typography>
    </BackgroundWrapper>
  );
};
