import { useCallback } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import {
  CircleStopIcon,
  GripVerticalIcon,
  PlayIcon,
  SquarePenIcon,
  Trash2Icon,
} from 'lucide-react';
import moment from 'moment';
import { useDrag, useDrop } from 'react-dnd';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';
import {
  MODAL_DELETE,
  MODAL_EDIT_MULTIPLE,
} from '@/components/organisms/Modals/ModalConductor';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { useRules } from '@/modules/rules/hooks/useRules';
import { popModal, pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectDeskId } from '@/redux/session/selectors';
import { ruleRules } from '@/util/validator';

import Tile from '../../../components/organisms/Tile/Tile';
import TileMenu from '../../../components/organisms/Tile/TileMenu';

import styles from './TileRule.module.scss';

const TileRule = ({
  slug,
  rule,
  getIndex,
  reorderLocalRule,
  resetLocalRules,
  reorderRule,
  reorderIsLoading,
}) => {
  const { rule_id, name, description, status, triggers, last_used_at } = rule;
  const { t } = useTranslation();
  const deskId = useSelector(selectDeskId);
  const { updateRule, deleteRule } = useRules(deskId, rule_id);
  const dispatch = useDispatch();

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'rules', actions.WRITE)
  );

  const [{ isDragging }, dragRef, dragPreviewRef] = useDrag({
    item: {
      name: `rule-${rule.rule_id}`,
      ruleId: rule.rule_id,
      position: rule.position,
      index: getIndex(),
      newPosition: rule.position,
    },
    type: 'rule',
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    end: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        resetLocalRules();
      }
    },
  });
  const dropNode = useCallback(
    (item) => {
      if (
        item.position !== item.newPosition &&
        item.position !== item.index + 1
      ) {
        reorderRule({ rule_id: item.ruleId, position: item.newPosition });
      }
    },
    [reorderRule]
  );

  const hoverNode = useCallback(
    (item) => {
      if (item.ruleId !== rule.rule_id) {
        reorderLocalRule(item);
        item.newPosition = rule.position;
      }
      item.index = getIndex();
    },
    [getIndex, reorderLocalRule, rule.position, rule.rule_id]
  );

  const [{ isOver }, dropRef] = useDrop({
    accept: 'rule',
    canDrop: (item) => item.position !== item.newPosition,
    drop: dropNode,
    hover: hoverNode,
    collect: (monitor) => ({
      draggedItem: monitor.getItem(),
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const handleActivate = useCallback(() => {
    updateRule({ ...rule, status: 'active' });
  }, [rule, updateRule]);

  const handleDeactivate = useCallback(() => {
    updateRule({ ...rule, status: 'inactive' });
  }, [rule, updateRule]);

  const handleDelete = useCallback(() => {
    const deleteProps = {
      subtitle: <Trans i18nKey="rules.delete_warning" values={[name]} />,
      confirm: true,
      onDelete: () => {
        deleteRule(rule_id, {
          onSuccess: () => {
            dispatch(popModal());
          },
        });
      },
    };
    dispatch(pushModal(MODAL_DELETE, deleteProps));
  }, [deleteRule, dispatch, name, rule_id]);

  const onEditClick = useCallback(() => {
    const renameProps = {
      title: t('rules.condition.edit'),
      onEdit: async (
        data: {
          new_name: string;
          new_description: string;
        },
        onError
      ) => {
        updateRule(
          {
            ...rule,
            name: data.new_name,
            description: data.new_description,
          },
          {
            onSuccess: () => {
              dispatch(popModal());
            },
            onError,
          }
        );
      },
      fields: [
        {
          fieldName: 'new_name',
          fieldValue: name,
          rules: ruleRules.name,
        },
        {
          fieldName: 'new_description',
          fieldValue: description,
          rules: ruleRules.description,
        },
      ],
    };
    dispatch(pushModal(MODAL_EDIT_MULTIPLE, renameProps));
  }, [description, dispatch, name, rule, t, updateRule]);

  const menuItems = [
    {
      name: status === 'active' ? t('rules.deactivate') : t('rules.activate'),
      icon:
        status === 'active' ? (
          <CircleStopIcon size={16} color="var(--icon-default-gray)" />
        ) : (
          <PlayIcon size={16} color="var(--icon-default-gray)" />
        ),
      onClick: status === 'active' ? handleDeactivate : handleActivate,
    },
    {
      name: t('common.rename'),
      icon: <SquarePenIcon size={16} color="var(--icon-default-gray)" />,
      onClick: onEditClick,
    },
    {
      name: t('common.delete'),
      icon: <Trash2Icon size={16} />,
      onClick: handleDelete,
      type: 'delete',
    },
  ];

  return (
    <div className={styles.container}>
      <div
        className={cn(styles.drop_placeholder, {
          [styles.enabled]: isDragging,
          [styles.hover]: isOver,
        })}
      >
        {t('rules.drop_it_here')}
      </div>
      <div
        aria-label={name}
        className={cn({ [styles.isDragging]: isDragging })}
        ref={dragPreviewRef}
      >
        <Tile className={styles.wrapper} ref={dropRef}>
          <div
            className={cn(styles.dnd, {
              [styles.loading]: reorderIsLoading,
            })}
            ref={!reorderIsLoading && canWrite ? dragRef : null}
          >
            <GripVerticalIcon color="var(--icon-default-gray)" size={20} />
          </div>
          <Link
            className={styles.link}
            ref={null}
            to={`/${slug}/environments/${deskId}/rules/${rule_id}`}
          >
            <div className={styles.info}>
              <span className={styles.title}>
                <span className={styles.name}>{name}</span>
                <span>
                  <StatusBadge
                    withIcon={false}
                    variant={status === 'active' ? 'success' : 'neutral'}
                    label={
                      status === 'active'
                        ? t('rules.active')
                        : t('rules.inactive')
                    }
                  />
                </span>
              </span>
              <span className={styles.desc}>{description}</span>
              <span>
                <span className={styles.id}>
                  <span>
                    TRIGGERS:{' '}
                    {triggers.map((x: { type: string }, index: number) => {
                      if (index < 2) {
                        return index === 1 || triggers.length - 1 === index
                          ? `${t(`rules.${x.type}`)}`
                          : `${t(`rules.${x.type}`)}, `;
                      }
                    })}
                    {triggers.length > 2 && (
                      <Typography
                        variant="label-caps-small"
                        className={styles.moreTriggers}
                      >
                        {t('rules.more_triggers', { 0: triggers.length - 2 })}
                      </Typography>
                    )}
                  </span>
                  <Typography
                    variant="label-caps-small"
                    className={styles.lastUsed}
                  >
                    {last_used_at && (
                      <>
                        {t('rules.last_used', {
                          0: moment(last_used_at).fromNow(),
                        })}
                      </>
                    )}
                  </Typography>
                </span>
              </span>
              <div className={styles.menu}>
                {canWrite && <TileMenu menuItems={menuItems} />}
              </div>
            </div>
          </Link>
        </Tile>
      </div>
    </div>
  );
};

export default TileRule;
