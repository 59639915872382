import { forwardRef, useCallback, useState } from 'react';

import InputBase from '@mui/material/InputBase';
import uniqueId from 'lodash/uniqueId';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { CONDITION_ATTRIBUTES_LABEL_TO_VALUE } from '@/components/organisms/Condition/constants';
import { addRuleCondition, addRuleGroup } from '@/redux/condition/actions';

import styles from './ConditionOptions.module.scss';

type Props = {
  attributes: object;
  index: number;
  handleOptionClick: () => void;
  outerIndex: number;
  newConditionGroup?: boolean;
};

const muiStyles = {
  root: {
    borderRadius: 'var(--border-radius-base)',
    border: '1px solid var(--border-default-gray)',
    height: '36px',
    paddingLeft: '8px',
    fontfamily: 'var(--font-primary)',
    fontSize: 'var(--space-14)',
    lineHeight: 'var(--space-16)',
    width: '100%',
  },
};

const NewConditionOptions = forwardRef<HTMLDivElement, Props>(
  (
    {
      attributes,
      index,
      handleOptionClick,
      outerIndex,
      newConditionGroup = false,
    },
    ref
  ) => {
    const options = Object.keys(attributes);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [query, setQuery] = useState('');

    const handleTyping = useCallback((e) => {
      setQuery(e.target.value.toLowerCase().trim());
    }, []);

    const filteredOptions = useCallback(
      (category) => {
        return Object.keys(attributes[category]).filter((option) =>
          t(`rules.${option}`)?.toLowerCase().includes(query)
        );
      },
      [query, t, attributes]
    );

    const handleClick = (category, option) => {
      const data = {
        attribute: CONDITION_ATTRIBUTES_LABEL_TO_VALUE[category][option],
        comparison: null,
        value: null,
        index,
        outerIndex,
      };

      if (newConditionGroup) {
        dispatch(addRuleGroup(data));
      } else {
        dispatch(addRuleCondition(data));
      }
      handleOptionClick();
    };

    return (
      <div className={styles.modal} ref={ref}>
        <div className={styles.input}>
          <InputBase
            onChange={handleTyping}
            sx={muiStyles.root}
            autoFocus
            placeholder={t('common.search_dots')}
          />
        </div>
        {options?.map((category) => {
          return (
            <div key={category}>
              <span className={styles.title}>{t(category)}</span>
              {filteredOptions(category).map((option) => (
                <span
                  key={uniqueId()}
                  className={styles.option}
                  onClick={() => handleClick(category, option)}
                  onKeyDown={() => handleClick(category, option)}
                  role="button"
                  tabIndex={0}
                >
                  {t(`rules.${option}`)}
                </span>
              ))}
            </div>
          );
        })}
      </div>
    );
  }
);

NewConditionOptions.displayName = 'NewConditionOptions';

export default NewConditionOptions;
