import { useMemo, memo, useCallback } from 'react';

import Typography from '@mui/material/Typography';
import { UseMutateFunction } from '@tanstack/react-query';
import cn from 'classnames';
import { TFunction } from 'i18next';
import { MegaphoneIcon, SquarePenIcon, Trash2Icon } from 'lucide-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { integrationIconByType } from '@/components/atoms/Icons/integrationIconByType';
import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';
import {
  MODAL_FORM,
  MODAL_DELETE,
} from '@/components/organisms/Modals/ModalConductor';
import { actions } from '@/models/permissions';
import { EventName } from '@/models/segment';
import { RootState } from '@/models/state';
import { Broadcast, BroadcastStatus } from '@/modules/broadcast/models';
import {
  calculatePercentage,
  isScheduledToBeSentNow,
} from '@/modules/broadcast/utils';
import { popModal, pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountSlug } from '@/redux/session/selectors';
import { trackEvent } from '@/segment/segment';

import Tile from '../Tile';
import TileMenu from '../TileMenu';

import styles from './TileBroadcast.module.scss';

interface Props {
  broadcast: Broadcast;
  updateBroadcast: UseMutateFunction<
    Broadcast,
    unknown,
    Partial<Broadcast>,
    unknown
  >;
  deleteBroadcast: UseMutateFunction<
    Broadcast,
    unknown,
    Partial<Broadcast>,
    unknown
  >;
}

const getStatusVariant = (status: BroadcastStatus) => {
  switch (status) {
    case 'draft':
      return 'neutral';
    case 'ready':
      return 'info';
    case 'sent':
      return 'success';
    default:
      return 'neutral';
  }
};

const getTileDescription = (broadcast: Broadcast, t: TFunction) => {
  if (!broadcast) {
    return '';
  }
  if (broadcast.status === 'sent') {
    const { read_count, delivered_count, replied_count } = broadcast;
    const date = (
      <>
        <span className={styles.label}>{t('broadcasts.date_send')}:</span>
        <span>{moment(broadcast.updated).fromNow()}</span>
      </>
    );
    const viewSpan = (
      <>
        <span className={styles.label}>{t('broadcasts.views')}:</span>
        <span className={styles.span}>
          {calculatePercentage(read_count, delivered_count)}% ({read_count})
        </span>
      </>
    );
    const repliedSpan = (
      <>
        <span className={styles.label}>{t('broadcasts.interactions')}:</span>
        <span className={styles.span}>
          {calculatePercentage(replied_count, delivered_count)}% (
          {replied_count})
        </span>
      </>
    );
    return (
      <>
        {date} | {viewSpan} | {repliedSpan}
      </>
    );
  }
  return (
    <>
      <span className={styles.label}>{t('broadcasts.date_created')}:</span>
      {moment(broadcast.created).fromNow()}
    </>
  );
};
const TileBroadcast = ({
  broadcast,
  deleteBroadcast,
  updateBroadcast,
}: Props) => {
  const { t } = useTranslation();
  const slug = useSelector(selectAccountSlug);

  const { broadcast_id, name } = broadcast ?? {};

  const canDelete = useSelector((state: RootState) =>
    getPermissions(state, 'broadcasts', actions.DELETE)
  );

  const dispatch = useDispatch();

  const handleDelete = useCallback(async () => {
    const deleteProps = {
      confirm: true,
      onDelete: () =>
        deleteBroadcast(
          { broadcast_id },
          {
            onSuccess: () => {
              dispatch(popModal());
            },
          }
        ),
    };
    dispatch(pushModal(MODAL_DELETE, deleteProps));
  }, [broadcast_id, deleteBroadcast, dispatch]);

  const onEditClick = useCallback(() => {
    trackEvent(EventName.ClickEditBroadcast, { broadcast_id });
    const renameProps = {
      title: t('broadcasts.edit_broadcast'),
      primaryButtonText: t('common.edit'),
      onCreate: async (
        data: {
          name: string;
        },
        onError
      ) => {
        updateBroadcast(
          {
            name: data.name,
            broadcast_id,
          },
          {
            onSuccess: () => {
              dispatch(popModal());
            },
            onError,
          }
        );
      },
      fields: [
        {
          fieldName: 'name',
          fieldValue: name,
        },
      ],
    };
    dispatch(pushModal(MODAL_FORM, renameProps));
  }, [broadcast_id, dispatch, name, t, updateBroadcast]);

  let tileMenuItems = useMemo(
    () => [
      {
        name: t('common.edit'),
        icon: <SquarePenIcon size={16} color="var(--icon-default-gray)" />,
        onClick: onEditClick,
      },

      {
        name: t('common.delete'),
        type: 'delete',
        disabled: !canDelete,
        icon: <Trash2Icon size={16} />,
        onClick: handleDelete,
      },
    ],
    [canDelete, handleDelete, onEditClick, t]
  );

  if (broadcast_id) {
    tileMenuItems = [tileMenuItems[tileMenuItems.length - 1]];
  } else {
    tileMenuItems.splice(4);
  }

  const Icon = useMemo(
    () => integrationIconByType[broadcast?.channel as string] || (() => null),
    [broadcast?.channel]
  );
  const status = isScheduledToBeSentNow(broadcast)
    ? 'in_progress'
    : broadcast?.status;

  const broadcastLink =
    broadcast?.sent_count || broadcast?.failed_count
      ? `/${slug}/broadcasts/${broadcast_id}/logs`
      : `/${slug}/broadcasts/${broadcast_id}`;
  return (
    <Link
      aria-label={name}
      className={cn(styles.link, styles.tile, 'tile')}
      to={broadcastLink}
    >
      <Tile className={styles.wrapper}>
        <div className={styles.icon}>
          <MegaphoneIcon />
        </div>
        <div className={styles.info}>
          <div className={styles.header}>
            <Typography variant="subheading-semi-bold" className={styles.name}>
              {name}
            </Typography>
          </div>
          <Typography className={styles.desc}>
            {t('common.channel')}: <Icon size={20} />
          </Typography>
          <Typography variant="label-caps-large" className={styles.footer}>
            {getTileDescription(broadcast, t)}
          </Typography>
        </div>
        <div className={styles.menu}>
          <StatusBadge
            variant={getStatusVariant(status)}
            label={t([
              `broadcasts.status.${status}`,
              'broadcasts.status.unknown',
            ])}
          />
          <TileMenu menuItems={tileMenuItems} />
        </div>
      </Tile>
    </Link>
  );
};

export default memo(TileBroadcast);
