import { useCallback, useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { PlayIcon } from 'lucide-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Banner } from '@/components/atoms/Banner/Banner';
import Button from '@/components/atoms/Button/Button/Button';
import PlainFieldset from '@/components/atoms/Fieldset/templates/PlainFieldset';
import { integrationIconByType } from '@/components/atoms/Icons/integrationIconByType';
import Link from '@/components/atoms/Link/Link';
import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';
import Table from '@/components/atoms/Table/Table';
import { TableInfiniteLoader } from '@/components/organisms/InfiniteLoader/TableInfiniteLoader';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import { useModalTryIt } from '@/hooks/useModalTryIt';
import { IntegrationType } from '@/models/integration';
import { selectAccountSlug } from '@/redux/session/selectors';

import BroadcastLayout from '../components/BroadcastLayout/BroadcastLayout';
import { BroadcastLogsHeader } from '../components/BroadcastLogsHeader';
import { AnalyticsBox } from '../components/BroadcastSidebar/AnalyticsBox/AnalyticsBox';
import { SidebarBox } from '../components/BroadcastSidebar/SidebarBox/SidebarBox';
import SidebarFields from '../components/BroadcastSidebar/SidebarFields/SidebarFields';
import { recipientErrorStatuses } from '../constants';
import { useSubscribers } from '../hooks/useSubscribers';
import { Subscriber } from '../models';
import { selectBroadcast } from '../redux/selectors';

import styles from './BroadcastLogs.module.scss';

interface RowProps {
  row: {
    original: Subscriber;
  };
}
export const BroadcastLogs = () => {
  const broadcast = useSelector(selectBroadcast);
  const [clickedSessions, setClickedSessions] = useState({});
  const [urlParams] = useSearchParams();
  const sessionId = urlParams.get('session_id');
  const navigate = useNavigate();
  const slug = useSelector(selectAccountSlug);
  const [filter, setFilter] = useState('');
  const [filterStatus, setFilterStatus] = useState(null);
  const { handleAccountReplayClick } = useModalTryIt(sessionId);

  const handleVisitSession = useCallback(() => {
    setClickedSessions((prev) => ({
      ...prev,
      [sessionId]: true,
    }));
    handleAccountReplayClick();
  }, [handleAccountReplayClick, sessionId]);

  useEffect(() => {
    if (sessionId) {
      handleVisitSession();
    }
  }, [handleVisitSession, sessionId]);

  const { t } = useTranslation();

  const { subscribers, hasNextPage, fetchNextPage, listStatus } =
    useSubscribers(broadcast?.broadcast_id, filter, filterStatus);

  useEffect(() => {
    if (
      broadcast?.broadcast_id &&
      !broadcast?.sent_count &&
      !broadcast?.failed_count
    ) {
      navigate(`/${slug}/broadcasts/${broadcast?.broadcast_id}`);
    }
  }, [broadcast, navigate, slug]);

  const flatSubscribers = useMemo(
    () => [...(subscribers?.pages ?? [])].flatMap((page) => page.subscribers),
    [subscribers?.pages]
  );

  const getColumns = useMemo(() => {
    const cols: {
      Header: string | JSX.Element;
      Cell?: (data) => JSX.Element;
      accessor?: string;
      id?: string;
    }[] = [
      {
        Header: 'id',
        accessor: 'external_id',
      },
      {
        Header: t('broadcasts.recipients_status.sent'),
        Cell: (props: RowProps) => {
          const { sent_at: value, failed_at, status_code } = props.row.original;

          const link =
            status_code === '131026'
              ? 'https://docs.moveo.ai/docs/get_started/broadcasts-overview#troubleshooting'
              : 'https://developers.facebook.com/docs/whatsapp/cloud-api/support/error-codes/';

          let errorMessage = null;
          if (failed_at && status_code) {
            if (status_code === 'bro_100' || status_code === 'bro_101') {
              errorMessage = t('common.something_wrong');
            } else if (recipientErrorStatuses[status_code]) {
              errorMessage = (
                <>
                  {t(recipientErrorStatuses[status_code])} (#{status_code}){' '}
                  <Link href={link} external>
                    {t('common.learn_more')}
                  </Link>
                </>
              );
            } else {
              errorMessage = (
                <>
                  {t('common.something_wrong')} (#{status_code}){' '}
                  <Link href={link} external>
                    {t('common.learn_more')}
                  </Link>
                </>
              );
            }
            return (
              <Box
                display="flex"
                alignItems="center"
                width="100%"
                height="100%"
              >
                <Tooltip arrow title={errorMessage} enterDelay={300}>
                  <span>
                    <StatusBadge
                      withIcon
                      variant="critical"
                      label={t('status.failed')}
                    />
                  </span>
                </Tooltip>
              </Box>
            );
          }

          return (
            <Box className={styles.center}>
              {value ? moment(value).format('HH:mm') : '-'}
            </Box>
          );
        },
      },
      {
        Header: t('broadcasts.recipients_status.delivered'),
        Cell: (props: RowProps) => {
          const value = props.row.original?.delivered_at;
          return (
            <Box className={styles.center}>
              {value ? moment(value).format('HH:mm') : '-'}
            </Box>
          );
        },
      },
      {
        Header: t('broadcasts.recipients_status.read'),
        Cell: (props: RowProps) => {
          const value = props.row.original?.read_at;
          return (
            <Box className={styles.center}>
              {value ? moment(value).format('HH:mm') : '-'}
            </Box>
          );
        },
      },
      {
        Header: t('broadcasts.recipients_status.replied'),
        Cell: (props: RowProps) => {
          const value = props.row.original?.replied_at;
          return (
            <Box className={styles.center}>
              {value ? moment(value).format('HH:mm') : '-'}
            </Box>
          );
        },
      },
      {
        Header: t('analytics.replay'),
        Cell: (props: RowProps) => {
          const subscriber = props.row.original;
          const hasLog = subscriber?.session_id && subscriber.replied_at;
          const hasBeenClicked = clickedSessions[subscriber?.session_id];

          const getButttonIconColor = () => {
            if (hasLog && !hasBeenClicked) {
              return 'var(--icon-default-white)';
            } else if (hasBeenClicked) {
              return 'var(--icon-default-blue)';
            }
            return 'var(--icon-default-gray)';
          };
          return (
            <Button
              disabled={!hasLog}
              variant={hasBeenClicked ? 'secondary' : 'primary'}
              className={styles.replayButton}
              onClick={() => handleAccountReplayClick(subscriber.session_id)}
            >
              <PlayIcon size={16} color={getButttonIconColor()} />
            </Button>
          );
        },
      },
    ];

    return cols;
  }, [clickedSessions, handleAccountReplayClick, t]);

  const channel: IntegrationType = broadcast.channel;

  const Icon = useMemo(
    () => integrationIconByType[channel] || (() => null),
    [channel]
  );

  const {
    subscribers_count = 0,
    read_count = 0,
    replied_count = 0,
    delivered_count = 0,
    sent_count = 0,
  } = broadcast || {};

  const analyticsData = [
    {
      key: 'sent_rate',
      label: t('broadcasts.recipients_status.sent'),
      percentage:
        subscribers_count === 0
          ? 0
          : Math.ceil((sent_count / subscribers_count) * 100),
      value: `${sent_count}/${subscribers_count}`,
    },
    {
      key: 'delivered_rate',
      label: t('broadcasts.recipients_status.delivered'),
      percentage:
        sent_count === 0 ? 0 : Math.ceil((delivered_count / sent_count) * 100),
      value: `${delivered_count}/${sent_count}`,
    },
    {
      key: 'open_rate',
      label: t('broadcasts.open_rate'),
      percentage:
        delivered_count === 0
          ? 0
          : Math.ceil((read_count / delivered_count) * 100),
      value: `${read_count}/${delivered_count}`,
    },

    {
      key: 'reply_rate',
      label: t('broadcasts.reply_rate'),
      percentage:
        read_count === 0 ? 0 : Math.ceil((replied_count / read_count) * 100),
      value: `${replied_count}/${read_count}`,
    },
  ];

  const filterOptions = useMemo(
    () => [
      { label: t('roles.filters.all'), value: null },
      { label: t('broadcasts.recipients_status.failed'), value: 'failed' },
      {
        label: t('broadcasts.recipients_status.delivered'),
        value: 'delivered',
      },
      {
        label: t('broadcasts.recipients_status.read'),
        value: 'read',
      },
      {
        label: t('broadcasts.recipients_status.replied'),
        value: 'replied',
      },
    ],
    [t]
  );

  return (
    <>
      <BroadcastLogsHeader title={t('broadcasts.logs')} />
      <PageContentWrapper newPlain2>
        <BroadcastLayout>
          <BroadcastLayout.Main>
            <div className={styles.charts}>
              {analyticsData.map(({ key, ...rest }) => (
                <AnalyticsBox key={key} {...rest} />
              ))}
            </div>
            <PlainFieldset fullWidth overflown>
              <TableInfiniteLoader
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
              >
                <div className={styles.tableContainer}>
                  <Table
                    data={flatSubscribers}
                    columns={getColumns}
                    sortable
                    searchable={false}
                    filterable
                    noGutters
                    onAPISearch={setFilter}
                    loading={listStatus === 'pending'}
                    filterOptions={filterOptions}
                    onFilterChange={setFilterStatus}
                  />
                </div>
              </TableInfiniteLoader>
            </PlainFieldset>
          </BroadcastLayout.Main>

          <BroadcastLayout.Sidebar>
            <SidebarBox>
              <SidebarFields
                validSubscribersCount={broadcast.subscribers_count}
                errorsCount={broadcast.failed_count}
              />
              {broadcast?.status === 'sent' && (
                <Box textAlign="left">
                  <Banner
                    relativePosition
                    variant="success"
                    title={t('broadcasts.banner_sent', {
                      0: moment(broadcast.updated)
                        .local()
                        .format('YYYY-MM-DD h:mma'),
                    })}
                  />
                </Box>
              )}
              <div className={styles.channelContainer}>
                <Typography
                  variant="label-caps-large"
                  color="var(--text-default-gray)"
                >
                  {t('broadcasts.channel')}
                </Typography>
                <Icon size={24} />
                <Typography variant="subheading-regular">
                  {t([`channels.${channel}`, 'pages.not_found'])}
                </Typography>
              </div>
            </SidebarBox>
          </BroadcastLayout.Sidebar>
        </BroadcastLayout>
      </PageContentWrapper>
    </>
  );
};
