import { useCallback, useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';
import {
  EllipsisIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  SquarePenIcon,
  Trash2Icon,
  UploadIcon,
} from 'lucide-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Avatar } from '@/components/atoms/Avatar/Avatar/Avatar';
import { CustomMenu } from '@/components/atoms/Menu/Menu';
import { VersionChanges } from '@/components/organisms/Modals/ModalPublishVersion/VersionChanges';
import { useAllRules } from '@/hooks/useAllRules';
import useVersions, { API as versionsApi } from '@/hooks/useVersions';
import { Version } from '@/models/version';
import { selectAllAgents } from '@/redux/presence/selectors';
import { selectBrainId } from '@/redux/session/selectors';
import { isKeyEnter } from '@/util/util';

import { RuleLink } from './RuleLink';
import { useVersionsTable } from './useVersionsTable';

import styles from './Versions.module.scss';
type Props = {
  version: Version;
  index: number;
  versions: Version[];
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'var(--surface-primary-white)',
    maxWidth: '1000px',
    border: '1px solid var(--border-default-gray)',
    borderRadius: 'var(--space-4)',
    color: 'var(--text-default-blue)',
    ...theme.typography['body-regular'],
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: 'var(--border-default-gray)',
  },
}));

export function hasChanges(components) {
  return components.some((component) => {
    if (component.component !== 'users') {
      return (
        component.added.length > 0 ||
        component.removed.length > 0 ||
        component.modified.length > 0
      );
    }
    return false;
  });
}

export const TimeLineBox = ({ version, index, versions }: Props) => {
  const { t } = useTranslation();
  const brainId = useSelector(selectBrainId);
  const members = useSelector(selectAllAgents);
  const { handleRestoreVersion, handleDeleteVersion } = useVersionsTable();
  const { exportVersion } = useVersions(brainId);
  const [seeAll, setSeeAll] = useState(false);
  const [diffs, setDiffs] = useState([]);
  const { rulesWithDeskName } = useAllRules({
    brainId,
    brainVersion: version?.version,
  });
  const calledRef = useRef(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const from = versions[index + 1]?.version;
    const to = versions[index]?.version;

    if (brainId && from && diffs.length === 0 && !calledRef.current) {
      calledRef.current = true;
      versionsApi
        .getDiff(brainId, from, to)
        .then((data) => {
          setDiffs(data);
        })
        .catch((error) => {
          console.error('Error getting versions diff', error);
        });
    }

    return () => {
      calledRef.current = false;
    };
  }, [brainId, diffs.length, index, versions]);

  const ruleCount = rulesWithDeskName?.length;
  const firstRule = rulesWithDeskName[0];
  const restRules = rulesWithDeskName.slice(1) || [];

  const handleSeeAll = useCallback(() => {
    setSeeAll((seeAll) => !seeAll);
  }, []);

  const handleSeeAllKeyDown = useCallback(
    (event) => {
      if (isKeyEnter(event)) {
        handleSeeAll();
      }
    },
    [handleSeeAll]
  );

  const handleMenuClick = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <span className={styles.timeLineHeader}>
        <span className={styles.title}>
          <Typography
            variant="subheading-semi-bold"
            color="var(--text-default-gray)"
          >
            {t('common.version')} {version?.version}
          </Typography>
          {version?.last_used_at && (
            <Typography color="var(--text-default-gray)">
              {t('common.last_used')}: {moment(version?.last_used_at).fromNow()}
            </Typography>
          )}
          {ruleCount > 0 && (
            <HtmlTooltip
              arrow
              title={
                <>
                  {restRules?.map((rule) => (
                    <span key={rule.rule_id} className={styles.linkWrapper}>
                      <RuleLink rule={rule} />
                    </span>
                  ))}
                </>
              }
              disableHoverListener={restRules?.length === 0}
            >
              <Box className={styles.linkBox}>
                <RuleLink rule={firstRule} />
                {ruleCount > 1 && (
                  <Typography color="var(--text-default-gray)">
                    +{ruleCount - 1}
                  </Typography>
                )}
              </Box>
            </HtmlTooltip>
          )}
        </span>
        <span className={styles.menu}>
          <IconButton onClick={handleMenuClick}>
            <EllipsisIcon size={20} />
          </IconButton>
          <CustomMenu
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            keepMounted
          >
            <MenuItem onClick={() => handleRestoreVersion(version?.version)}>
              <SquarePenIcon size={16} color="var(--icon-default-gray)" />
              {t('common.restore')}
            </MenuItem>
            <MenuItem onClick={() => exportVersion(version?.version)}>
              <UploadIcon size={16} color="var(--icon-default-gray)" />
              {t('common.export')}
            </MenuItem>
            <MenuItem
              onClick={() => handleDeleteVersion(version?.version)}
              className={styles.danger}
            >
              <Trash2Icon size={16} />
              {t('common.delete')}
            </MenuItem>
          </CustomMenu>
        </span>
      </span>
      {version.description && (
        <Typography
          color="var(--text-default-gray)"
          className={styles.timeLineDescription}
        >
          {version.description}
        </Typography>
      )}
      <span className={styles.timeLineSubHeader}>
        <span className={styles.createdBy}>
          <Typography color="var(--text-default-gray)">
            {t('common.created_by')}
          </Typography>
          <Tooltip
            arrow
            title={members.find((x) => x.agent_id === version.created_by)?.name}
          >
            <span>
              <Avatar userId={version.created_by} size="small" hideStatus />
            </span>
          </Tooltip>
        </span>
        <Typography color="var(--text-default-gray)">
          {moment(version.created).fromNow()}
        </Typography>
      </span>
      <span className={styles.timeLineMetaData}>
        {hasChanges(diffs) && (
          <span
            className={styles.seeAll}
            role="button"
            onClick={handleSeeAll}
            onKeyDown={handleSeeAllKeyDown}
            tabIndex={0}
          >
            <Typography color="var(--text-default-blue)">
              {t('common.see_all')}{' '}
            </Typography>
            {seeAll ? (
              <ChevronDownIcon size={16} color="var(--icon-default-blue)" />
            ) : (
              <ChevronUpIcon size={16} color="var(--icon-default-blue)" />
            )}
          </span>
        )}
        {seeAll && hasChanges(diffs) && (
          <div className={styles.changes}>
            <VersionChanges
              versionsDiff={diffs}
              loadingChanges={false}
              padding={false}
            />
          </div>
        )}
      </span>
    </>
  );
};
