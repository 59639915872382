/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import { useCallback } from 'react';

import cn from 'classnames';
import { ChevronDownIcon } from 'lucide-react';

import { Avatar } from '../Avatar/Avatar/Avatar';

import styles from './ListItem.module.scss';

export type ListItemProps = {
  text?: string;
  icons?: React.ReactNode[];
  active?: boolean;
  disabled?: boolean;
  mainIcon?: React.ReactNode;
  type?: 'title' | 'firstLevel';
  isCollapsible?: boolean;
  onCollapse?: () => void;
  onItemClick?: () => void;
  color?: string;
  children?: React.ReactNode;
  className?: string;
  userId?: string;
};

const ListItem = ({
  mainIcon,
  text = '',
  icons = undefined,
  active = false,
  disabled = false,
  type = 'firstLevel',
  isCollapsible = false,
  onCollapse = () => {},
  onItemClick = () => {},
  color = 'black',
  children,
  className,
  userId,
}: ListItemProps) => {
  const handleOnItemKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        onItemClick();
      }
    },
    [onItemClick]
  );

  const handleOnCollapseKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        onCollapse && onCollapse();
      }
    },
    [onCollapse]
  );

  if (type === 'title') {
    return (
      <li
        role="button"
        tabIndex={0}
        onKeyDown={handleOnItemKeyDown}
        className={cn(
          styles.container,
          styles[type],
          styles[`icons-${icons?.length}`],
          {
            [styles.isCollapsible]: isCollapsible,
            [styles.active]: active,
          }
        )}
        onClick={onItemClick}
      >
        <div className={styles.infoContainer}>
          {isCollapsible && (
            <ChevronDownIcon size={16} color="var(--icon-default-black)" />
          )}
          {children}
          {text && <p className={styles[color]}>{text}</p>}
        </div>
        {icons && (
          <div className={styles.iconsContainer}>{icons?.map((i) => i)}</div>
        )}
      </li>
    );
  }

  if (type === 'firstLevel') {
    return (
      <li
        role="button"
        tabIndex={-1}
        onKeyDown={handleOnItemKeyDown}
        className={cn(
          className,
          styles.container,
          styles[type],
          styles[`icons-${icons?.length}`],
          {
            [styles.active]: active,
            [styles.disabled]: disabled,
            [styles.hasAvatar]: !!userId,
          }
        )}
        onClick={onItemClick}
      >
        <div
          className={styles.infoContainer}
          onKeyDown={handleOnCollapseKeyDown}
          role="button"
          tabIndex={-1}
          onClick={onCollapse}
        >
          {userId && <Avatar active={active} size="small" userId={userId} />}
          {mainIcon}
          {children}
          {text && <p className={styles[color]}>{text}</p>}
        </div>
        {icons && (
          <div className={styles.iconsContainer}>{icons?.map((i) => i)}</div>
        )}
      </li>
    );
  }

  return null;
};

export default ListItem;
