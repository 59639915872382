import { useCallback, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { CirclePlusIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useClickAway } from 'react-use';

import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';
import { Condition } from '@/models/condition';
import {
  changeConditionOperator,
  changeOuterConditionOperator,
  changeOuterOperatorHover,
  isConditionError,
} from '@/redux/condition/actions';
import {
  selectConditionHover,
  selectCondition,
} from '@/redux/condition/selectors';

import ConditionDropdownButton from './ConditionDropdownButton/ConditionDropdownButton';
import NewConditionOptions from './ConditionsModal/ConditionOptions';
import { isConditionNested } from './util';
import OperatorMenu from '../../../modules/rules/components/Triggers/OperatorMenu';

import styles from './Condition.module.scss';

interface Props {
  singleCondition: Partial<Condition>;
  outerIndex: number;
  attributes: object;
  canWrite: boolean;
}

const ConditionGroup = ({
  singleCondition,
  outerIndex,
  attributes,
  canWrite,
}: Props) => {
  const { t } = useTranslation();

  const conditionRef = useRef();
  const [showConditionOptions, setShowConditionOptions] = useState(false);
  const conditions = singleCondition.conditions;
  const operator = singleCondition.operator;
  const dispatch = useDispatch();
  const _condition = useSelector(selectCondition);
  const isNested = isConditionNested(_condition);
  const [isHover, setIsHover] = useState(false);
  const isOuterHover = useSelector(selectConditionHover);
  const errorStateRef = useRef({
    index: 0,
    outerIndex: 0,
    errorState: false,
  });

  const showAddCondition = isNested
    ? outerIndex + 1 === _condition.conditions?.length
    : true;

  const changeOperator = useCallback(
    (data) => {
      const { operator, outerIndex } = data;
      dispatch(changeConditionOperator({ operator, outerIndex }));
    },
    [dispatch]
  );

  const changeOuterOperator = useCallback(
    (data) => {
      dispatch(changeOuterConditionOperator(data));
    },
    [dispatch]
  );

  useEffect(() => {
    if (
      // avoid dispatching every single time
      errorStateRef.current.errorState !==
      conditions[errorStateRef.current.index]?.isError
    ) {
      dispatch(isConditionError(errorStateRef.current));
    }
  }, [conditions, dispatch]);

  const handleAddContition = useCallback(() => {
    if (!canWrite) {
      return;
    }
    setShowConditionOptions(true);
  }, [canWrite]);

  useClickAway(conditionRef, () => {
    setShowConditionOptions(false);
  });

  const isError = useCallback(
    (
      category: string,
      attribute: string,
      comparison: string,
      value: string,
      isConditionOpen: boolean,
      index: number,
      outerIndex: number
    ) => {
      if (comparison === 'known' || comparison === 'unknown') {
        errorStateRef.current = { index, outerIndex, errorState: false };
        return 'normal';
      }

      if (category === 'channel' || category === 'status') {
        if (attribute && attribute !== 'channel' && attribute !== 'status') {
          errorStateRef.current = { index, outerIndex, errorState: false };
          return 'normal';
        }
        if (isConditionOpen) {
          return 'normal';
        } else {
          errorStateRef.current = { index, outerIndex, errorState: false };
          return 'error';
        }
      }

      if (value) {
        errorStateRef.current = { index, outerIndex, errorState: false };
        return 'normal';
      } else {
        if (isConditionOpen) {
          return 'normal';
        } else {
          errorStateRef.current = { index, outerIndex, errorState: true };
          return 'error';
        }
      }
    },
    []
  );

  return (
    <>
      <div
        className={cn(styles.outerWrapper, {
          [styles.readOnly]: !canWrite,
          [styles.multiConditions]: singleCondition?.conditions?.length > 1,
        })}
      >
        {_condition.conditions?.length > 0 && (
          <span className={styles.badge}>
            <StatusBadge
              label={String(outerIndex + 1)}
              withIcon={false}
              variant="neutral"
            />
          </span>
        )}
        {singleCondition?.conditions?.map((condition, index) => {
          const { attribute, comparison, value, isConditionOpen } = condition;
          const [category, attr, attributeSubcategory] =
            attribute?.split('.') ?? [];
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className={styles.outterWrapper}>
              <div className={styles.innerWrapper}>
                {index !== 0 && (
                  <div className={styles.operator}>
                    <OperatorMenu
                      operator={operator}
                      outerIndex={outerIndex}
                      callbackFn={changeOperator}
                      onMouseEnter={() => setIsHover(true)}
                      onMouseLeave={() => setIsHover(false)}
                      isHover={isHover}
                    />
                  </div>
                )}
                <ConditionDropdownButton
                  attributes={attributes}
                  grouped={conditions.length === index + 1}
                  index={index}
                  conditionCategory={category}
                  conditionAttribute={attr}
                  conditionAttributeSubcategory={attributeSubcategory}
                  conditionComparison={comparison}
                  conditionValue={value}
                  outerIndex={outerIndex}
                  variant={isError(
                    category,
                    attr,
                    comparison,
                    value,
                    isConditionOpen,
                    index,
                    outerIndex
                  )}
                />
              </div>
              {isNested &&
                conditions.length === index + 1 &&
                _condition.conditions.length - 1 !== outerIndex && (
                  <div className={styles.operator}>
                    <OperatorMenu
                      operator={_condition.operator}
                      callbackFn={changeOuterOperator}
                      isHover={isOuterHover}
                      onMouseEnter={() =>
                        dispatch(changeOuterOperatorHover({ isHover: true }))
                      }
                      onMouseLeave={() =>
                        dispatch(changeOuterOperatorHover({ isHover: false }))
                      }
                    />
                  </div>
                )}
            </div>
          );
        })}
      </div>
      {showAddCondition && (
        <div
          ref={conditionRef}
          className={cn(styles.condition, { [styles.readOnly]: !canWrite })}
        >
          <div
            className={styles.interactive}
            role="button"
            onClick={handleAddContition}
            onKeyDown={() => {}}
            tabIndex={0}
          >
            <CirclePlusIcon size={16} color="var(--icon-default-blue)" />

            {t('rules.add_condition')}
          </div>
          {showConditionOptions && (
            <NewConditionOptions
              attributes={attributes}
              handleOptionClick={() => setShowConditionOptions(false)}
              index={0}
              outerIndex={outerIndex}
              newConditionGroup
            />
          )}
        </div>
      )}
    </>
  );
};

export default ConditionGroup;
