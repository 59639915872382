import { useCallback } from 'react';

import cn from 'classnames';
import { XIcon, TriangleAlertIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Accordion from '@/components/atoms/Accordion/Accordion';
import { AccordionFieldsetProps, AccordionType } from '@/models/integration';
import { setExpanded } from '@/redux/integrations/actions';
import { selectAccordion } from '@/redux/integrations/selectors';
import { supportBREmail, supportEmail } from '@/util/constants';
import { delay } from '@/util/util';

import Button from '../../Button/Button/Button';
import StatusChip from '../../StatusChip/StatusChip';

import styles from './ConnectionStatusAccordion.module.scss';

const getReasonText = (t, status: string) => {
  const reasons = {
    'rate-limit': t('integrations.con_status.rate_limit'),
    'bad-request': t('integrations.con_status.bad_req'),
    'account-blocked': t('integrations.con_status.acc_blocked'),
    'not-found': t('integrations.con_status.not_found'),
    'api-version-not-supported': t(
      'integrations.con_status.unsupported_version'
    ),
    'cannot-verify-if-inactive': t('integrations.con_status.verify-inactive'),
  };
  return reasons[status] || t('integrations.con_status.unknown');
};
/**
 *
 * @param conSettingsRef - Used for scrolling to the fields that the user needs to fill to connect the integration
 * @param stateRef - Used for scrolling to the old status fieldset for backwards compatibility
 * @returns
 */
const ConnectionStatusAccordion = ({
  status,
  toggleAccordion,
  conSettingsRef = null,
  stateRef = null,
  registerAccordion,
  type,
}: AccordionFieldsetProps<never> & {
  status: string;
  conSettingsRef?: HTMLDivElement;
  stateRef?: HTMLDivElement;
}) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { expanded } = useSelector(selectAccordion);
  const dispatch = useDispatch();
  const isLoading = !status;
  const isVerified = status === 'verified';
  const isVerifying = status === 'verifying';

  const renderMessage = useCallback(
    (status: string) => {
      const onVisitConSettingsClick = async () => {
        dispatch(setExpanded(AccordionType.CONNECTION_SETTINGS));
        await delay(300); // Wait for expand animation to be completed
        conSettingsRef?.scrollIntoView({
          behavior: 'smooth',
        });
      };
      const onVisitStateAreaClick = async () => {
        dispatch(setExpanded(false));
        await delay(300); // Wait for expand animation to be completed
        stateRef?.scrollIntoView({
          behavior: 'smooth',
        });
      };

      const email = currentLanguage === 'pt-br' ? supportBREmail : supportEmail;
      switch (status) {
        case 'missing-credentials':
          return (
            <>
              <p className={styles.topText}>
                <TriangleAlertIcon
                  size={16}
                  color="var(--icon-default-warning)"
                />
                {t('integrations.con_status.not_connected')}
              </p>
              <p className={styles.indented}>
                {t('integrations.con_status.provide_token')}
              </p>
              <Button
                className={cn(styles.zeroPadding, styles.indented)}
                variant="tertiary"
                size="xs"
                underline
                onClick={onVisitConSettingsClick}
              >
                {t('integrations.con_status.visit_con_settings')}
              </Button>
            </>
          );
        case 'invalid-authentication':
          return (
            <>
              <p className={styles.topText}>
                <XIcon size={16} color="var(--icon-default-error)" />
                {t('integrations.con_status.invalid_token')}
              </p>
              <p className={styles.indented}>
                {t('integrations.con_status.provide_token')}
              </p>
              <Button
                className={cn(styles.zeroPadding, styles.indented)}
                variant="tertiary"
                size="xs"
                underline
                onClick={onVisitConSettingsClick}
              >
                {t('integrations.con_status.visit_con_settings')}
              </Button>
            </>
          );
        case 'account-blocked':
        case 'not-found':
        case 'api-version-not-supported':
        case 'bad-request':
        case 'rate-limit':
        case 'cannot-verify-if-inactive':
          return (
            <>
              <p className={styles.topText}>
                {t('integrations.con_status.reasons_title')}
              </p>
              <p className={styles.indented}>
                <XIcon size={16} color="var(--icon-default-error)" />
                {getReasonText(t, status)}
              </p>
            </>
          );
        case 'general-error':
        case 'forbidden':
        case 'internal-server':
          return (
            <>
              <p className={styles.topText}>
                <TriangleAlertIcon
                  size={16}
                  color="var(--icon-default-warning)"
                />
                {t('integrations.con_status.unsual_error')}
              </p>
              <p className={styles.indented}>
                {t('integrations.con_status.contact_support')}
                <br />
                {t('integrations.con_status.email')}{' '}
                <Button
                  className={styles.zeroPadding}
                  variant="tertiary"
                  size="xs"
                  underline
                  isLink
                  href={`mailto:${email}`}
                >
                  {email}
                </Button>
              </p>
            </>
          );
        case 'unverified': // default state for backwards compatibility
          return (
            <>
              <p className={styles.topText}>
                <XIcon size={16} color="var(--icon-default-error)" />
                {t('integrations.con_status.unsupported_status')}
              </p>
              <p className={styles.indented}>
                {t('integrations.con_status.fix_status_title')}
                <br />
                <p className={cn(styles.topText, styles.steps)}>
                  {t('integrations.con_status.fix_status')}
                </p>
              </p>

              <Button
                className={cn(styles.zeroPadding, styles.indented)}
                variant="tertiary"
                size="xs"
                underline
                onClick={onVisitStateAreaClick}
              >
                {t('integrations.con_status.visit_state')}
              </Button>
            </>
          );
      }
    },
    [conSettingsRef, currentLanguage, dispatch, stateRef, t]
  );

  const noExpandable = isVerified || isVerifying;
  return (
    <>
      <Accordion
        ref={registerAccordion(type)}
        title={
          <div className={styles.accordion_title}>
            {t('integrations.con_status.title')}{' '}
            <StatusChip status={status} isLoading={isLoading} />
          </div>
        }
        subtitle={
          isVerified
            ? t('integrations.con_status.verified_subtitle')
            : isVerifying
              ? t('integrations.con_status.verifying_subtitle')
              : t('integrations.con_status.subtitle')
        }
        isLoading={isLoading}
        expanded={expanded === type && !noExpandable}
        handleChange={toggleAccordion(type)}
        disableFooter
        nonExpandable={noExpandable}
      >
        <div className={styles.description}>{renderMessage(status)}</div>
      </Accordion>
    </>
  );
};

export default ConnectionStatusAccordion;
