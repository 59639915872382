import cn from 'classnames';
import { CirclePlusIcon } from 'lucide-react';

import styles from './AddNew.module.scss';

interface AddNewProps {
  handleClick: () => void;
  text: string;
  large?: boolean;
}

const AddNew = ({ handleClick, text, large }: AddNewProps) => {
  return (
    <div
      onClick={handleClick}
      tabIndex={0}
      onKeyDown={handleClick}
      role="button"
      className={cn(styles.newRule, { [styles.large]: large })}
    >
      <CirclePlusIcon size={16} color="var(--icon-default-blue)" />
      <span className={styles.text}>{text}</span>
    </div>
  );
};

export default AddNew;
