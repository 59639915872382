import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import { MODAL_PLUGIN_CREATE } from '@/components/organisms/Modals/ModalConductor';
import { pushModal } from '@/redux/modals/actions';

/**
 * Empty state component for the Plugins page
 * Displays when no plugins have been created yet
 */
export const Empty = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCreateEvent = useCallback(() => {
    dispatch(pushModal(MODAL_PLUGIN_CREATE));
  }, [dispatch]);

  return (
    <div className="flex flex-col items-center p-4 h-[280px] text-center border border-[var(--border-default-gray)] rounded-lg w-full bg-white">
      <div className="flex justify-center items-center w-24 h-24 rounded-lg mb-2 mt-4">
        <img src="/assets/plugins/empty_plugins.svg" alt="source" />
      </div>

      <p className="text-2xl font-medium mb-1 leading-8 tracking-[-0.6px]">
        {t('plugins.empty_title')}
      </p>

      <p className="text-base leading-6 mb-3 foreground-muted">
        {t('plugins.empty_description')}
      </p>

      <Button variant="tertiary" size="small" onClick={handleCreateEvent}>
        {t('developer_tools.create_event')}
      </Button>
    </div>
  );
};
