import { useCallback, useEffect, useState } from 'react';

import InputBase from '@mui/material/InputBase';
import cn from 'classnames';
import { XIcon } from 'lucide-react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import IconButton from '@/components/atoms/IconButton/IconButton';
import { useMacros } from '@/hooks/useMacros';
import { Macro } from '@/models/macros';
import { popModal } from '@/redux/modals/actions';
import { macros } from '@/util/validator';

import styles from './ModalCannedResponses.module.scss';

const muiStyles = {
  name: {
    borderRadius: 'var(--border-radius-base)',
    border: '1px solid var(--border-default-gray)',
    height: '36px',
    paddingLeft: '8px',
    fontSize: 'var(--space-14)',
    lineHeight: 'var(--space-16)',
    backgroundColor: 'var(--surface-primary-white)',
    width: '60%',
    '&.Mui-focused': {
      border: '1px solid var(--border-default-blue)',
    },
    '&.Mui-error': {
      border: '1px solid var(--border-default-error)',
    },
  },

  text: {
    borderRadius: 'var(--border-radius-base)',
    paddingLeft: '8px',
    fontSize: 'var(--space-14)',
    lineHeight: 'var(--space-16)',
    backgroundColor: 'var(--surface-primary-white)',
    width: '100%',
    maxHeight: '200px',
    overflow: 'auto',
  },
};

interface Props {
  isNewMacro: boolean;
  filteredOptions: Macro[];
  selectedMacro: number;
  setShowCreateNew: (x: boolean) => void;
  setIsNewMacro: (x: boolean) => void;
}

const EditResponse = ({
  isNewMacro,
  filteredOptions,
  selectedMacro,
  setShowCreateNew,
  setIsNewMacro,
}: Props) => {
  const [hasFocus, setHasFocus] = useState(false);
  const { addMacro, addStatus, updateMacro, updateStatus } = useMacros();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      text: '',
    },
  });

  useEffect(() => {
    setValue('name', isNewMacro ? '' : filteredOptions?.[selectedMacro]?.name);
    setValue('text', isNewMacro ? '' : filteredOptions?.[selectedMacro]?.text);
  }, [filteredOptions, isNewMacro, selectedMacro, setValue]);

  const onSubmit = useCallback(
    (data) => {
      isNewMacro
        ? addMacro(data, {
            onSuccess: () => {
              setShowCreateNew(false);
            },
          })
        : updateMacro(
            { macro_id: filteredOptions?.[selectedMacro].macro_id, ...data },
            {
              onSuccess: () => {
                setShowCreateNew(false);
              },
            }
          );
    },
    [
      addMacro,
      filteredOptions,
      isNewMacro,
      selectedMacro,
      setShowCreateNew,
      updateMacro,
    ]
  );

  const onClose = useCallback(() => {
    dispatch(popModal());
  }, [dispatch]);

  const handleAbort = useCallback(() => {
    setShowCreateNew(false);
    setIsNewMacro(true);
  }, [setShowCreateNew, setIsNewMacro]);

  return (
    <div className={styles.editResponse}>
      <div className={styles.closeIcon}>
        <IconButton onClick={onClose} ariaLabel={t('modals.close')}>
          <XIcon size={16} color="var(--icon-default-black)" />
        </IconButton>
      </div>
      <div className={styles.editorTitle}>
        {isNewMacro
          ? t('chatBox.modal.create_response')
          : t('chatBox.modal.edit_response')}
      </div>
      <form autoComplete="off">
        <div className={styles.label}>{t('chatBox.modal.title')}</div>

        <div className={styles.nameInput}>
          <Controller
            render={({ field: { onChange, value } }) => {
              return (
                <InputBase
                  id="name"
                  placeholder={t('common.name_placeholder')}
                  onChange={onChange}
                  error={!!errors.name}
                  value={value}
                  sx={muiStyles.name}
                />
              );
            }}
            control={control}
            name="name"
            rules={macros.name}
          />
        </div>
        <div className={styles.nameError}>{errors?.name?.message}</div>
        <div className={styles.label}>{t('chatBox.modal.content')}</div>

        <div
          className={cn(styles.responseText, {
            [styles.errors]: errors.text,
            [styles.focus]: hasFocus,
          })}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
        >
          <Controller
            render={({ field: { onChange, value } }) => {
              return (
                <InputBase
                  id="text"
                  placeholder={t('chatBox.modal.text_placeholder')}
                  onChange={onChange}
                  error={!!errors.text}
                  value={value}
                  sx={muiStyles.text}
                  multiline
                />
              );
            }}
            control={control}
            name="text"
            rules={macros.text}
          />
          <div className={styles.buttons}>
            <Button variant="secondary" onClick={handleAbort}>
              {t('chatBox.modal.cancel')}
            </Button>
            <Button
              onClick={handleSubmit(onSubmit)}
              isLoading={addStatus === 'pending' || updateStatus === 'pending'}
              disabled={!isValid}
            >
              {t('chatBox.modal.save')}
            </Button>
          </div>
        </div>
        <div className={styles.textError}>{errors?.text?.message}</div>
      </form>
    </div>
  );
};

export default EditResponse;
