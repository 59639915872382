import { useState } from 'react';

import cn from 'classnames';
import { PaperclipIcon } from 'lucide-react';
import { shallowEqual, useSelector } from 'react-redux';

import CarouselOld from '@/components/organisms/Dialogs/CarouselOld/CarouselOld';
import { useMarkdownToHtml } from '@/hooks/useMarkdownToHtml';
import { ResponseMessage } from '@/models/tryIt';

import styles from './styles.module.scss';

type ResponseProps = ResponseMessage & {
  onOptionClick: (text: string) => void;
  onPostback: (text: string) => void;
  texts?: string[];
  text?: string;
};
interface RootState {
  tryIt: { isReplay: boolean };
}
export const Response = (props: ResponseProps) => {
  const { html } = useMarkdownToHtml(props?.text);

  const [optionChosen, setOptionChosen] = useState(false);
  const { isReplay } = useSelector((state: RootState) => {
    return {
      isReplay: state.tryIt.isReplay,
    };
  }, shallowEqual);

  const handleOptionClick = (text: string) => {
    if (!isReplay) {
      props.onOptionClick(text);
      setOptionChosen(true);
    }
  };
  switch (props.type) {
    case 'text':
    case 'disambiguation':
      // for test webhook
      if (props.texts) {
        return (
          <>
            {props.texts.map((text, index) => (
              <p
                // eslint-disable-next-line react/no-array-index-key
                key={`${text}_${index}`}
                className={cn(styles.bubble, styles.brain)}
              >
                {text}
              </p>
            ))}
            {!optionChosen && props.options && (
              <div className={styles.optionsContainer}>
                {props.options.map((option) => (
                  <button
                    key={`option-${option.text}`}
                    className={cn(styles.bubble, styles.option, {
                      [styles.optionDisabled]: isReplay,
                    })}
                    onClick={() => {}}
                    type="button"
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            )}
          </>
        );
      }

      return (
        <>
          <p
            className={cn(styles.bubble, styles.brain)}
            dangerouslySetInnerHTML={{ __html: html }}
          ></p>
          {!optionChosen && props.options && (
            <div className={styles.optionsContainer}>
              {props.options.map((option) => (
                <button
                  key={`option-${option.text}`}
                  className={cn(styles.bubble, styles.option, {
                    [styles.optionDisabled]: isReplay,
                  })}
                  onClick={() => handleOptionClick(option.text)}
                  type="button"
                >
                  {option.label}
                </button>
              ))}
            </div>
          )}
        </>
      );
    case 'url':
      return (
        <p className={cn(styles.bubble, styles.brain)}>
          <a
            href={props.url}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: 'flex',
              alignItems: 'center',
              color: 'var(--text-default-blue)',
            }}
          >
            {props.url}
          </a>
        </p>
      );
    case 'audio':
      return (
        <p className={cn(styles.bubble, styles.brain, styles.isMedia)}>
          <audio src={props.url} controls />
        </p>
      );
    case 'image':
      return (
        <p className={cn(styles.bubble, styles.brain, styles.isMedia)}>
          <img src={props.url} alt={props.name} />
        </p>
      );
    case 'video':
      return (
        <p className={cn(styles.bubble, styles.brain, styles.isMedia)}>
          <video src={props.url} controls />
        </p>
      );
    case 'file':
      return (
        <p className={cn(styles.bubble, styles.brain)}>
          <a
            href={props.url}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: 'flex',
              alignItems: 'center',
              color: 'var(--text-default-blue)',
            }}
          >
            <PaperclipIcon
              className="mr-1"
              size={16}
              color="var(--icon-default-blue)"
            />
            {props.name}
          </a>
        </p>
      );
    case 'carousel':
      return (
        <div className={styles.carouselContainer}>
          <CarouselOld
            onPostback={props.onPostback}
            cards={props.cards}
            isReplay={isReplay}
          />
        </div>
      );
    case 'webview':
    case 'survey':
      return (
        <div className={styles.webviewContainer}>
          <p>{props.name}</p>
          <div className={styles.webviewButtonContainer}>
            <p>{props.label}</p>
          </div>
        </div>
      );
    default:
      return null;
  }
};
