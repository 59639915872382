import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import get from 'lodash/get';
import { BookmarkIcon, CircleDotIcon } from 'lucide-react';
import { useSelector } from 'react-redux';

import useCollections from '@/hooks/useCollections';
import {
  selectCanEdit,
  selectContextTags,
  selectNodesStackParentNodeNameByIndex,
  selectSelectedMessageCollectionId,
  selectSelectedMessageIndex,
  selectTryItContext,
} from '@/modules/TryIt/redux/selectors';

import { BundleSection } from './BundleSection/BundleSection';
import TagsSection from './TagsSection/TagsSection';
import { VariablesSection } from './VariablesSection/VariablesSection';

const ContextSection = () => {
  const canEdit = useSelector(selectCanEdit);
  const contextTags = useSelector(selectContextTags);
  const context = useSelector(selectTryItContext);
  const index = useSelector(selectSelectedMessageIndex);
  const parentNodeName = useSelector(
    selectNodesStackParentNodeNameByIndex(index)
  );
  const collectionId = useSelector(selectSelectedMessageCollectionId);
  const { collection } = useCollections(collectionId);

  const bundle = get(context, 'global.bundle', null);
  const name = parentNodeName || collection?.name;

  return (
    <>
      {name && (
        <Stack
          borderRadius="var(--space-4)"
          flexDirection="row"
          justifyContent="center"
          columnGap="var(--space-4)"
          p="var(--space-4)"
          bgcolor="var(--surface-secondary-gray-light)"
          textAlign="center"
        >
          {parentNodeName ? (
            <CircleDotIcon size={16} color="var(--icon-default-blue)" />
          ) : (
            <BookmarkIcon size={16} color="var(--icon-default-blue)" />
          )}
          <Typography color="var(--text-default-blue)" variant="label-regular">
            {name}
          </Typography>
        </Stack>
      )}

      {/* CONTEXT BUNDLE SECTION */}
      <BundleSection canEdit={canEdit} bundle={bundle} />

      {/* CONTEXT VARIABLES SECTION */}
      <VariablesSection canEdit={canEdit} />

      {/* TAGS SECTION */}
      {<TagsSection canEdit={canEdit} tags={contextTags} />}
    </>
  );
};

export default ContextSection;
