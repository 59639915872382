import { memo } from 'react';

import Typography from '@mui/material/Typography';
import { GripVerticalIcon, XIcon } from 'lucide-react';

import ReorderWrapper from '@/components/atoms/ReorderWrapper/ReorderWrapper';

import styles from './ToolkitGoogleSheet.module.scss';

interface DraggableItemProps {
  id: string;
  index: number;
  item: string;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  dropItem: () => void;
  deleteItem: (id: string) => void;
  getIndex: () => number;
  disableClose?: boolean;
}

const DraggableItem: React.FC<DraggableItemProps> = ({
  id,
  index,
  item,
  moveItem,
  dropItem,
  deleteItem,
  getIndex,
  disableClose = false,
}) => {
  return (
    <li className={styles.innerContainer}>
      <ReorderWrapper
        index={index}
        moveItem={moveItem}
        dropItem={dropItem}
        getIndex={getIndex}
        dragId="tab-item"
      >
        <div className={styles.item}>
          <span>
            <GripVerticalIcon color="var(--icon-default-gray)" size={16} />
          </span>
          <Typography>{item}</Typography>
          {!disableClose && (
            <span
              className={styles.close}
              onMouseUp={() => deleteItem(id)}
              tabIndex={-1}
              role="button"
            >
              <XIcon color="var(--icon-default-gray)" size={16} />
            </span>
          )}
        </div>
      </ReorderWrapper>
    </li>
  );
};

export default memo(DraggableItem);
