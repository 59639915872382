import { memo, useCallback, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { SquareDashedMousePointerIcon } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { Banner } from '@/components/atoms/Banner/Banner';
import Button from '@/components/atoms/Button/Button/Button';
import FileUpload from '@/components/atoms/FileUpload/FileUpload';
import Input from '@/components/atoms/Input/Input';
import Select from '@/components/atoms/Select/Select';
import { Divider } from '@/components/organisms/Divider/Divider';
import { useAccount } from '@/hooks/useAccount';
import useBrains from '@/hooks/useBrains';
import { Brain } from '@/models/brain';
import { EventName } from '@/models/segment';
import { UploadedFile } from '@/models/server';
import { AddBrainIcon } from '@/modules/onboarding/icons/AddBrainIcon';
import Build from '@/modules/onboarding/icons/Build';
import { popModal } from '@/redux/modals/actions';
import { trackEvent } from '@/segment/segment';
import { languageByCountryCode } from '@/util/constants';
import { generateNextName, isKeyEnter } from '@/util/util';
import { errorMessage, brainRules } from '@/util/validator';

import { updateBrainsUrls } from '../../helpers';
import { CommonProps, NewBrainProps } from '../ModalBrainCreationNew';

import styles from '../ModalBrainCreationNew.module.scss';

const RHF_SETVALUE_CONFIG = {
  shouldValidate: true,
  shouldDirty: true,
} as const;

const Start = memo((props: NewBrainProps & CommonProps) => {
  // Custom hooks
  const dispatch = useDispatch();
  const { brains, createBrain } = useBrains();
  const navigate = useNavigate();
  const { slug } = useAccount();

  // Local variables
  const isDisabled = !props.isDirty || !props.isValid;
  const languageOptions = Object.keys(languageByCountryCode).map((i) => ({
    value: i.toLowerCase(),
    label: props.t(`languages.${i.toLowerCase()}`, {
      defaultValue: languageByCountryCode[i],
    }),
  }));
  const message = (
    <Typography display="flex" gap="var(--space-8)" alignItems="center">
      <SquareDashedMousePointerIcon color="var(--icon-default-blue)" />
      {props.t('brains.drag_and_drop')}
    </Typography>
  );

  // Local state
  const [showBanner, setShowBanner] = useState(false);
  const [jsonLanguage, setJsonLanguage] = useState<string>('');
  const [importedBrain, setImportedBrain] = useState<Partial<Brain> | null>(
    null
  );
  const { setValue } = props;

  // Callbacks
  const updateForm = useCallback(
    (data: Partial<Brain>) => {
      const { name, language } = data;

      const uniqueName = generateNextName(brains, name);

      // Validate and mark the fields as dirty
      // to activate submit button
      setValue('name', uniqueName, RHF_SETVALUE_CONFIG);
      setValue('language', language, RHF_SETVALUE_CONFIG);

      setJsonLanguage(language);
    },
    [brains, setValue]
  );

  const onJsonFileUpload = useCallback(
    async ({ data }: UploadedFile) => {
      const updatedBrain = updateBrainsUrls(data);

      updateForm(updatedBrain);

      setImportedBrain(updatedBrain);
    },
    [updateForm]
  );

  const saveImportedBrain = () => {
    const newBrain = props.getValues() as Partial<Brain>;
    // Remove the default language model if the value is 'Default'
    if (newBrain.language_model_id === 'Default') {
      newBrain.language_model_id = undefined;
    }
    const brainParams = { ...importedBrain, ...newBrain };

    createBrain(brainParams, {
      onSuccess: ({ brain_id }) => {
        dispatch(popModal());
        navigate(`/${slug}/brains/${brain_id}`);
        trackEvent(EventName.ClickImportBrain, {
          brain_id,
        });
      },
    });
  };

  const handleContinueClick = () => {
    if (isDisabled) return;

    if (!importedBrain) {
      props.setStep(2);
    } else {
      saveImportedBrain();
    }
  };

  const handleEnterKey = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (isKeyEnter(e)) {
      e.preventDefault();
      handleContinueClick();
    }
  };

  return (
    <>
      <form autoComplete="off" className={styles.form}>
        <span className={styles.title}>{props.t('brains.add_brain')}</span>
        <FileUpload
          message={message}
          onFileUploaded={onJsonFileUpload}
          parseJson
          onFileRemoved={() => {
            setImportedBrain(null);
            props.reset();
          }}
        />

        <Divider text={props.t('rules.or_caps')} />

        <div className={styles.input}>
          <Input
            name="name"
            label={props.t('common.name')}
            register={props.register('name', brainRules.name)}
            errorMessage={errorMessage({
              field: props.errors.name,
              ...brainRules.name,
            })}
            placeholder={props.t('common.name_placeholder')}
            size="medium"
            autoFocus
            onKeyDown={handleEnterKey}
          />
        </div>
        <div className={styles.input}>
          <Select
            name="language"
            label={props.t('field.language')}
            placeholder={props.t('common.select_an_option')}
            register={props.register('language', brainRules.language)}
            errorMessage={errorMessage({
              field: props.errors.language,
              ...brainRules.language,
            })}
            options={languageOptions}
            size="full"
            onChange={(e) => {
              const selectedLanguage = e.target.value;

              if (jsonLanguage !== '' && selectedLanguage !== jsonLanguage) {
                setShowBanner(true);
              } else {
                setShowBanner(false);
              }
            }}
            onKeyDown={handleEnterKey}
          />
        </div>
        {showBanner && (
          <Box mb="var(--space-24)">
            <Banner
              title={t('brains.language_mismatch.title')}
              relativePosition
              variant="warning"
            >
              {t('brains.language_mismatch.message')}
            </Banner>
          </Box>
        )}

        <span className={styles.buttons}>
          <Button
            variant="tertiary"
            onClick={() => dispatch(popModal())}
            size="large"
          >
            {props.t('common.cancel')}
          </Button>
          <Button
            disabled={isDisabled}
            onClick={handleContinueClick}
            size="large"
            type="button"
          >
            {props.t('common.continue')}
          </Button>
        </span>
      </form>
      <div className={styles.right}>
        <Build />
        <AddBrainIcon className={styles.svg} />
      </div>
    </>
  );
});

Start.displayName = 'Start';

export { Start };
