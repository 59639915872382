import { useCallback, useMemo } from 'react';

import Typography from '@mui/material/Typography';
import { ClockIcon, Trash2Icon } from 'lucide-react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { intervalsObjectToHours } from '@/components/pages/BusinessHours/utils';
import { useAccount } from '@/hooks/useAccount';
import useBusinessHours from '@/hooks/useBusinessHours';
import { BusinessHour } from '@/models/businessHours';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectDeskId } from '@/redux/session/selectors';
import { timezoneMaker } from '@/util/util';

import Tile from './Tile';
import TileMenu from './TileMenu';
import { MODAL_DELETE } from '../Modals/ModalConductor';

import styles from './TileBusinessHours.module.scss';

interface Props {
  businessHour: Partial<BusinessHour>;
}

const TileBusinessHours = ({ businessHour }: Props) => {
  const { t } = useTranslation();
  const deskId = useSelector(selectDeskId);
  const { slug } = useAccount();
  const dispatch = useDispatch();

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'business_hours', actions.WRITE)
  );
  const { business_hours_id, name, timezone, intervals } = businessHour;
  const { deleteBusinessHour, isDefaultBusinessHour } =
    useBusinessHours(business_hours_id);

  const hoursHumanFormat = useMemo(
    () => intervalsObjectToHours(intervals),
    [intervals]
  );

  const handleDelete = useCallback(() => {
    const deleteProps = {
      subtitle: (
        <Trans i18nKey="business_hours.delete_warning" values={[name]} />
      ),
      confirm: true,
      onDelete: () => {
        deleteBusinessHour(business_hours_id);
      },
    };
    dispatch(pushModal(MODAL_DELETE, deleteProps));
  }, [business_hours_id, deleteBusinessHour, dispatch, name]);

  const menuItems = [
    {
      name: t('common.delete'),
      icon: <Trash2Icon size={16} />,
      onClick: handleDelete,
      type: 'delete',
    },
  ];

  const timeZone = timezoneMaker([timezone])[0];
  const city = timeZone.value.split('/');
  const cityName = city[city.length - 1];
  const timeZoneHour = timeZone.label
    .replace(/\(/g, '')
    .replace(/\)/g, '')
    .split(' ')[0];

  return (
    <Link
      aria-label={name}
      className={styles.link}
      to={`/${slug}/environments/${deskId}/business_hours/${business_hours_id}`}
    >
      <Tile className={styles.wrapper}>
        <div className={styles.dnd}>
          <ClockIcon />
        </div>
        <div className={styles.info}>
          <Typography variant="subheading-semi-bold" className={styles.name}>
            {hoursHumanFormat.map((x, i) => {
              if (i < 2) {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <span key={i}>
                    <>{x.frequency.label}</> <>{x.from.label} - </>
                    <>{x.to.label}</>
                    {i !== 1 && hoursHumanFormat.length > 1 && <span>, </span>}
                  </span>
                );
              }
            })}
            {hoursHumanFormat.length > 2 && (
              <Typography variant="label-regular" className={styles.desc}>
                {t('business_hours.human_hours_more', {
                  0: hoursHumanFormat.length - 2,
                })}
              </Typography>
            )}
          </Typography>
          <span className={styles.desc}>
            {t('business_hours.time_zone', { 0: cityName, 1: timeZoneHour })}
          </span>
          <div className={styles.menu}>
            {!isDefaultBusinessHour && canWrite && (
              <TileMenu menuItems={menuItems} />
            )}
          </div>
        </div>
      </Tile>
    </Link>
  );
};

export default TileBusinessHours;
