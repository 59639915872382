import { useState, useCallback, useRef } from 'react';

import Chip from '@mui/material/Chip';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import cn from 'classnames';
import {
  BanIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CircleCheckIcon,
} from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { Avatar } from '@/components/atoms/Avatar/Avatar/Avatar';
import { CustomMenu } from '@/components/atoms/Menu/Menu';
import { Brain } from '@/models/brain';
import { Presence } from '@/models/presence';
import { Department } from '@/modules/departments/models';

import styles from './SelectUser.module.scss';

export type AssignedBrain = Partial<Brain & { children?: React.ReactNode }>;

type Dept = {
  type: 'departments';
  options: Department[];
};

type Member = {
  type: 'members';
  options: Array<Presence | AssignedBrain>;
};

const muiStyles = {
  '&': {
    backgroundColor: 'var(--surface-secondary-gray)',
    borderRadius: 'var(--space-20)',
    color: 'var(--text-default-black)',
    height: '24px',
    paddingRight: 'var(--space-12)',

    svg: {
      flexShrink: 0,
    },
    '&:hover': {
      backgroundColor: 'var(--border-default-gray)',
      color: 'var(--text-default-black)',
    },
    '&:focus': {
      backgroundColor: 'var(--surface-secondary-gray)',
    },
  },

  '& .MuiAvatar-root': {
    borderRadius: 'var(--space-20)',
    backgroundColor: 'transparent',
  },
  '& .MuiChip-label': {
    overflow: 'hidden',
    paddingRight: 'var(--space-4)',
    fontSize: '12px',
  },
  '& .MuiChip-deleteIcon': {
    color: 'var(--icon-default-gray)',
    height: '18px',
    width: '18px',

    '&:hover': {
      backgroundColor: 'transparent',
      color: 'var(--icon-default-gray)',
    },
  },
};

export type Props = {
  activeIndex?: number;
  onSelect: (i: number | null) => void;
  disabled?: boolean;
  tabIndex?: number;
  className?: string;
} & (Dept | Member);

const SelectUser = ({
  activeIndex,
  options,
  disabled = false,
  type,
  onSelect,
  tabIndex = 0,
  className = '',
}: Props) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const chipRef = useRef(null);

  const handleClick = useCallback(() => {
    if (chipRef.current) {
      setAnchorEl(chipRef.current);
    }
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSelect = useCallback(
    (i) => {
      handleClose();
      onSelect(i);
    },
    [handleClose, onSelect]
  );

  const getAvatar = useCallback(() => {
    if (activeIndex === null) {
      return null;
    }
    if (type === 'departments' && options[activeIndex]?.icon) {
      return <>{options[activeIndex].icon}</>;
    }
    if (
      type == 'members' &&
      options[activeIndex] &&
      'children' in options[activeIndex]
    ) {
      return (
        <Avatar altText={options[activeIndex]?.name}>
          {(options[activeIndex] as AssignedBrain)?.children}
        </Avatar>
      );
    }
    if (type == 'members') {
      return (
        <Avatar
          size="small"
          userId={(options[activeIndex] as Presence)?.agent_id}
        />
      );
    }
    return null;
  }, [activeIndex, options, type]);

  return (
    <div className={cn(styles.container, className)}>
      <Chip
        id={`${type}-button`}
        aria-controls={open ? `${type}-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        tabIndex={tabIndex}
        ref={chipRef}
        disabled={disabled}
        sx={muiStyles}
        size="small"
        avatar={getAvatar()}
        label={
          activeIndex !== null
            ? options?.[activeIndex]?.name
            : t('conversation.assign')
        }
        clickable
        onClick={handleClick}
        onDelete={handleClick}
        deleteIcon={
          open ? <ChevronUpIcon size={18} /> : <ChevronDownIcon size={18} />
        }
      />

      <CustomMenu
        id={`${type}-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `${type}-button`,
        }}
        keepMounted
        TransitionComponent={Grow}
      >
        {activeIndex !== null && type === 'members' ? (
          <MenuItem onClick={() => handleSelect(null)}>
            <BanIcon color="var(--icon-default-gray)" size={16} />
            {t('conversation.unassign')}
          </MenuItem>
        ) : null}

        {options?.map(({ name, ...rest }, index) => {
          if ('brain_id' in rest) {
            return null;
          }

          const CHARACTER_LIMIT = 30;
          const displayName =
            name?.length < CHARACTER_LIMIT
              ? name
              : `${name?.slice(0, CHARACTER_LIMIT)}...`;

          return (
            <MenuItem
              key={rest.id || rest.agent_id || rest.department_id}
              onClick={() => handleSelect(index)}
            >
              <Tooltip arrow title={name?.length > CHARACTER_LIMIT ? name : ''}>
                <>
                  {type === 'departments' ? (
                    <span>{rest?.icon}</span>
                  ) : (
                    <Avatar size="small" userId={rest?.agent_id} />
                  )}

                  <span>{displayName}</span>

                  {activeIndex === index && (
                    <CircleCheckIcon
                      size={16}
                      color="var(--icon-default-blue)"
                    />
                  )}
                </>
              </Tooltip>
            </MenuItem>
          );
        })}
      </CustomMenu>
    </div>
  );
};

export default SelectUser;
