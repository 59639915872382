import { useEffect, useState } from 'react';

import { SearchIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useAsyncDebounce } from 'react-table';

import Input from '../Input/Input';

interface Props {
  onChange: (value: string) => void;
  searchPlaceholder?: string;
  defaultValue?: string;
}

export const TableAPIFilter = ({
  onChange,
  searchPlaceholder,
  defaultValue,
}: Props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = useAsyncDebounce((val) => {
    onChange(val || undefined);
  }, 500);

  const onInputChange = (e) => {
    setValue(e.target.value);
    handleChange(e.target.value);
  };

  return (
    <Input
      iconLeft={<SearchIcon size={16} color="var(--icon-default-gray)" />}
      size="medium"
      value={value || ''}
      onChange={onInputChange}
      placeholder={searchPlaceholder ?? t('common.search_dots')}
    />
  );
};
