import React from 'react';

import Typography from '@mui/material/Typography';
import { TagIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { formatCents } from '@/modules/billing/util/billing';

import styles from './TotalPayment.module.scss';

export const TotalPayment = ({ upcomingInvoice, balance }) => {
  const {
    tax,
    subtotal_excluding_tax,
    total_discount_amounts,
    discount,
    total,
    amount_due,
    default_tax_rates,
  } = upcomingInvoice ?? {};
  const { coupon } = discount ?? {};

  const { t } = useTranslation();

  const couponText = coupon?.amount_off
    ? t(`billing.invoice.price`, {
        0: formatCents(coupon.amount_off),
      })
    : `${coupon?.percent_off}%`;

  const couponDiscount = total_discount_amounts?.[0]?.amount ?? 0;
  const { percentage, display_name, jurisdiction } = default_tax_rates[0] ?? {};
  const taxMessage = default_tax_rates[0]?.percentage
    ? `${display_name} ${jurisdiction} ${percentage}%`
    : 0;

  const getBalance = () => {
    if (-balance > total && amount_due === 0) {
      return -total;
    }
    return balance;
  };
  return (
    <div className={styles.container}>
      {subtotal_excluding_tax !== amount_due && (
        <>
          <Typography variant="body-semi-bold" component="p">
            {t('billing.subtotal')}
          </Typography>
          <Typography variant="body-semi-bold" component="p">
            {t(`billing.invoice.price`, {
              0: formatCents(subtotal_excluding_tax),
            })}
          </Typography>
        </>
      )}
      {coupon && (
        <>
          <Typography component="p" className={styles.couponContainer}>
            <TagIcon color="var(--text-default-gray)" size={16} /> {coupon.name}{' '}
            {t(`billing.plan_table.${coupon.object}`)}&nbsp;
            <Typography color="var(--text-default-gray)">
              ({couponText} {t('billing.off')})
            </Typography>
          </Typography>
          <Typography color="var(--text-default-gray)" component="p">
            -{' '}
            {t(`billing.invoice.price`, {
              0: formatCents(couponDiscount),
            })}
          </Typography>
        </>
      )}
      {tax ? (
        <>
          <Typography component="p" color="var(--text-default-gray)">
            {taxMessage}
          </Typography>
          <Typography component="p" color="var(--text-default-gray)">
            {t(`billing.invoice.price`, {
              0: formatCents(tax),
            })}
          </Typography>
        </>
      ) : null}
      {balance ? (
        <>
          <Typography component="p" color="var(--text-default-gray)">
            {t('billing.balance')}
          </Typography>
          <Typography component="p" color="var(--text-default-gray)">
            {t(`billing.invoice.price`, {
              0: formatCents(getBalance()),
            })}
          </Typography>
        </>
      ) : null}
      <Typography variant="body-semi-bold" component="p">
        {t('billing.table.total')}
      </Typography>
      <Typography variant="body-semi-bold" component="p">
        {t(`billing.invoice.price`, {
          0: formatCents(amount_due),
        })}
      </Typography>
    </div>
  );
};
