import { memo } from 'react';

import Typography from '@mui/material/Typography';
import { LightbulbIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/atoms/Button/Button/Button';

import styles from './SubHeaderRecommended.module.scss';

interface Props {
  onDisMissClick: () => void;
  onAcceptClick: () => void;
  title: string;
}
function SubHeaderRecommended({ title, onDisMissClick, onAcceptClick }: Props) {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.information}>
        <Typography variant="subheading-semi-bold">{title}</Typography>
        <LightbulbIcon size={16} color="var(--icon-default-gray)" />
      </div>
      <div className={styles.buttonContainer}>
        <Button onClick={onDisMissClick} type="submit" variant="secondary">
          {t('common.reject')}
        </Button>

        <Button onClick={onAcceptClick} type="submit">
          {t('common.accept_and_edit')}
        </Button>
      </div>
    </div>
  );
}

export default memo(SubHeaderRecommended);
