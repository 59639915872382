import { useCallback, useMemo, ReactElement } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { PinIcon } from 'lucide-react';

import { HoverMenu } from '@/components/atoms/HoverMenu/HoverMenu';
import IconButton from '@/components/atoms/IconButton/IconButton';
import { PinFilledIcon } from '@/components/atoms/Icons/PinFilledIcon';
import { SearchList } from '@/components/atoms/SearchList/SearchList';

import { MenuItem } from '../../models';
import { IconComponent, trimText } from '../../utils';

import styles from './NavList.module.scss';

interface MenuProps {
  items: MenuItem[] | { label: string; fn: () => void }[];
  pinnedItems?: MenuItem[];
  children?: ReactElement;
  variant?: 'action_menu' | 'views_list';
}

export const MAX_NAME_LENGTH = 20;

export const Menu = ({ items, pinnedItems, children }: MenuProps) => {
  const itemsSorted = useMemo(() => {
    return items?.sort((a, b) => {
      if (a.pinned && !b.pinned) {
        return -1;
      }
      if (!a.pinned && b.pinned) {
        return 1;
      }
      return 0;
    });
  }, [items]);

  const itemTemplate = useCallback(
    (item: MenuItem) => {
      const isItemPinned =
        pinnedItems?.some((i) => i.id === item.id) ||
        item.pinned ||
        item.disabled;
      return (
        <div className={cn(styles.menuTile)}>
          <button
            onClick={() => {
              item.onClick();
            }}
            className={styles.info}
          >
            <IconComponent icon={item.icon} />
            <Typography>{trimText(item.text, MAX_NAME_LENGTH)}</Typography>
          </button>
          <div className={styles.pin}>
            <IconButton
              ariaLabel="pin"
              onClick={() => !item.pinned && item.onPinClick(item.id)}
              disabled={item.pinned}
            >
              {isItemPinned ? (
                <PinFilledIcon color={'var(--icon-default-blue)'} />
              ) : (
                <PinIcon size={16} color="var(--icon-default-gray)" />
              )}
            </IconButton>
          </div>
        </div>
      );
    },
    [pinnedItems]
  );

  // If there are no items, render children skiping the tooltip

  if (!items) {
    return children;
  }

  return (
    <HoverMenu
      leaveDelay={100}
      placement={'right-start'}
      title={
        <div className={styles.searchContainer}>
          <SearchList
            items={itemsSorted as MenuItem[]}
            itemTemplate={itemTemplate}
            accessor="text"
          />
        </div>
      }
    >
      {children}
    </HoverMenu>
  );
};
