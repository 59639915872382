import { useCallback, useState } from 'react';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { LogOutIcon, SettingsIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Avatar } from '@/components/atoms/Avatar/Avatar/Avatar';
import { Badge } from '@/components/atoms/Badge/Badge';
import { CustomMenu } from '@/components/atoms/Menu/Menu';
import Switch from '@/components/atoms/Switch/Switch';
import { useInvitations } from '@/hooks/useInvitations';
import { usePresence } from '@/hooks/usePresence';
import useUser from '@/hooks/useUser';
import { PresenceStatus } from '@/models/presence';
import { EventName } from '@/models/segment';
import { RootState } from '@/models/state';
import { StyledBadge } from '@/modules/humanChat/components/LeftSidebar/Drawer/DrawerItem/components';
import { selectAgentById } from '@/redux/presence/selectors';
import { trackEvent } from '@/segment/segment';

import styles from './UserMenu.module.scss';

export const UserMenu = () => {
  // Custom hooks
  const { t } = useTranslation();
  const { hasInvitations } = useInvitations();
  const { user } = useUser();
  const navigate = useNavigate();
  const { updateAgent } = usePresence();

  // Redux
  const agent = useSelector((state: RootState) =>
    selectAgentById(state, user?.user_id)
  );

  // Local state
  const [anchorEl, setAnchorEl] = useState(null);

  // Local variables
  const open = Boolean(anchorEl);

  // Handlers
  const handleClick = useCallback((event) => {
    trackEvent(EventName.OpenUserMenu);
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleLogout = useCallback(() => {
    navigate('/logout');
  }, [navigate]);

  const handleProfileNavigation = useCallback(() => {
    handleClose();
    navigate('/profile/information');
  }, [handleClose, navigate]);

  const handleToggle = () => {
    if (!agent) {
      return;
    }

    const updatedStatus =
      agent.status === PresenceStatus.ONLINE
        ? PresenceStatus.AWAY
        : PresenceStatus.ONLINE;

    updateAgent({
      agent_id: agent?.agent_id,
      status: updatedStatus,
    });
  };

  const showMenu = !!user;

  return (
    <>
      <Button
        id="profile-button"
        aria-controls={open ? 'profile-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={styles.profileButton}
        disableRipple
      >
        <Badge
          color="error"
          variant="dot"
          overlap="circular"
          invisible={!hasInvitations}
        >
          <Avatar
            altText={user?.name || ''}
            userId={user?.user_id}
            hideStatus
          />
        </Badge>
      </Button>

      {showMenu && (
        <CustomMenu
          id="profile-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          disableRestoreFocus
          MenuListProps={{
            'aria-labelledby': 'profile-button',
          }}
        >
          <ListSubheader className={styles.menu__header}>
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot"
              status={agent?.status}
            >
              <Avatar altText={agent?.name || ''} userId={agent?.agent_id} />
            </StyledBadge>
            <div>
              <Typography
                color="var(--text-default-black)"
                component="div"
                variant="body-semi-bold"
              >
                {user.name}
              </Typography>
              <Typography
                component="div"
                variant="label-regular"
                color="var(--text-default-gray)"
              >
                {user.email}
              </Typography>
            </div>
          </ListSubheader>

          <Divider />

          <MenuItem
            onClick={handleToggle}
            disableGutters
            className={styles.menu__toggle}
          >
            <Switch
              reverse
              name="disambiguation"
              label={t('profile.set_as_away')}
              checked={agent?.status === 'away'}
              size="medium"
            />
          </MenuItem>

          <Divider />

          <MenuItem onClick={handleProfileNavigation} disableGutters>
            <SettingsIcon size={16} color="var(--icon-default-gray)" />
            <Badge
              variant="dot"
              color="error"
              invisible={!hasInvitations}
              overlap="rectangular"
            >
              {t('invitations.profile')}
            </Badge>
          </MenuItem>

          <MenuItem
            onClick={handleLogout}
            disableGutters
            className={styles.danger}
          >
            <LogOutIcon size={16} color="var(--icon-default-gray)" />
            {t('invitations.logout')}
          </MenuItem>
        </CustomMenu>
      )}
    </>
  );
};
