import {
  useCallback,
  useDeferredValue,
  useEffect,
  useRef,
  useState,
} from 'react';

import orderBy from 'lodash/orderBy';
import {
  CirclePlusIcon,
  CopyIcon,
  MessageCircleIcon,
  StarIcon,
  Trash2Icon,
  UploadIcon,
} from 'lucide-react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTitle } from 'react-use';

import Button from '@/components/atoms/Button/Button/Button';
import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import IconButton from '@/components/atoms/IconButton/IconButton';
import { StarFilledIcon } from '@/components/atoms/Icons/StarFilledIcon';
import { RichHeader } from '@/components/organisms/Headers/RichHeader/RichHeader';
import {
  BRAIN_DELETE,
  MODAL_AI_AGENT_CREATE,
  MODAL_TRYIT,
} from '@/components/organisms/Modals/ModalConductor';
import { TileBrainSkeleton, TileCreation } from '@/components/organisms/Tile';
import TileMenu from '@/components/organisms/Tile/TileMenu';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import PermissionsWrapper from '@/components/templates/PermissionsWrapper';
import useBrains from '@/hooks/useBrains';
import { usePreferences } from '@/hooks/usePreferences';
import { AIAgent, Brain } from '@/models/brain';
import { actions } from '@/models/permissions';
import { EventName, PageName } from '@/models/segment';
import { RootState } from '@/models/state';
import { useBilling } from '@/modules/billing/hooks/useBilling';
import { pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountSlug } from '@/redux/session/selectors';
import { pageView, trackEvent } from '@/segment/segment';
import { AI_AGENTS_DOCS_URL } from '@/util/constants';
import { preventClickThrough } from '@/util/util';

import { TileAIAgentBody } from './components/TileAIAgent/TileAIAgentBody';
import { TileAIAgentFooter } from './components/TileAIAgent/TileAIAgentFooter';
import { TileAIAgentHeader } from './components/TileAIAgent/TileAIAgentHeader';
import { TileAIAgentLayout } from './components/TileAIAgent/TileAIAgentLayout';
import { TileList } from './components/TileList/TileList';
import { AIAgentIcon } from './icons/AIAgentIcon';
import { toggleTryIt } from '../TryIt/redux/actions';

import styles from './AIAgents.module.scss';

export default function AIAgents() {
  // Redux selectors
  const slug = useSelector(selectAccountSlug);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  // Local state
  const [sort, setSort] = useState('last_trained');
  const [filter, setFilter] = useState<string>();
  const [tooltipMessage, setTooltipMessage] = useState('');
  const intervalId = useRef(null);

  // Custom hooks
  const { t } = useTranslation();
  const { billingState } = useBilling();
  const navigate = useNavigate();
  useTitle(t('pages.ai_agents', { 0: slug }));
  const { updateAccountUserPreferences, accountUserPrefs } = usePreferences();
  const dispatch = useDispatch();
  const defferedFilter = useDeferredValue(filter);
  const {
    brains: aiAgents,
    maxBrains: maxAIAgents,
    maxBrainsReached: maxAIAgentsReached,
    createStatus,
    exportBrain,
  } = useBrains();

  // Local variables
  const disabled = maxAIAgentsReached || !canWrite;
  const canCreateAIAgent = canWrite && !maxAIAgentsReached;

  useEffect(() => {
    pageView(PageName.AI_AGENTS);
  }, []);

  // Memoized variables
  const getTooltipTitle = () => {
    if (!canWrite) {
      return t('ai_agents.no_permission');
    } else if (disabled) {
      return t('limits.reached', {
        0: t('common.ai_agents'),
        1: billingState?.plan_name,
      });
    }

    return '';
  };

  // Handlers
  const tileMenuItems = useCallback(
    (id: string, name: string) => [
      {
        name: t('common.test'),
        icon: <MessageCircleIcon color="var(--icon-default-gray)" size={16} />,
        onClick: () => {
          trackEvent(EventName.ClickTryIt, { brain_id: id });
          dispatch(toggleTryIt());
          dispatch(pushModal(MODAL_TRYIT, { brainId: id }));
          navigate(`/${slug}/ai-agents/${id}`);
        },
      },
      {
        name: t('common.export'),
        icon: <UploadIcon size={16} color="var(--icon-default-gray)" />,
        onClick: () => {
          trackEvent(EventName.ClickExportBrain, { brain_id: id });
          exportBrain(id);
        },
      },
      {
        name: t('brains.copy_id'),
        icon: <CopyIcon size={16} color="var(--icon-default-gray)" />,
        keepOpen: true,
        tooltipMessage,
        onClick: () => {
          trackEvent(EventName.ClickCopyBrainId, { brain_id: id });
          const text = id;
          navigator.clipboard.writeText(text);
          setTooltipMessage(t('common.copied'));
          intervalId.current = setTimeout(() => {
            setTooltipMessage('');
          }, 1200);
        },
      },
      {
        name: t('common.delete'),
        type: 'delete',
        icon: <Trash2Icon size={16} />,
        onClick: () => {
          const warnProps = {
            brainName: name,
            brainId: id,
          };
          dispatch(pushModal(BRAIN_DELETE, warnProps));
        },
      },
    ],
    [dispatch, exportBrain, navigate, slug, t, tooltipMessage]
  );

  const showAddAIAgentModal = useCallback(() => {
    trackEvent(EventName.ClickCreateAIAgent);
    dispatch(pushModal(MODAL_AI_AGENT_CREATE));
  }, [dispatch]);

  const handleFavorite = useCallback(
    (event: React.MouseEvent<HTMLElement>, aiAgentId: string) => {
      preventClickThrough(event);

      const favoriteAIAgentIds: string[] =
        (accountUserPrefs?.favorite_brain_ids as string[]) || [];
      const index = favoriteAIAgentIds.indexOf(aiAgentId);

      if (index > -1) {
        favoriteAIAgentIds.splice(index, 1);
      } else {
        favoriteAIAgentIds.push(aiAgentId);
      }

      updateAccountUserPreferences({
        ...accountUserPrefs,
        favorite_brain_ids: favoriteAIAgentIds,
      });
    },
    [accountUserPrefs, updateAccountUserPreferences]
  );

  function renderSkeletons(n = 3) {
    return (
      Array(n)
        .fill(0)
        // eslint-disable-next-line react/no-array-index-key
        .map((_, i) => <TileBrainSkeleton key={i} />)
    );
  }

  const splitAIAgentsByFavorite = (
    sortedAIAgents: AIAgent[],
    favoriteAIAgentIds: string[]
  ) => {
    const favorites = [];
    const nonFavorites = [];

    for (let i = 0; i < sortedAIAgents.length; i++) {
      if (
        favoriteAIAgentIds.find(
          (agentId) => agentId === sortedAIAgents[i].brain_id
        )
      ) {
        favorites.push(sortedAIAgents[i]);
      } else {
        nonFavorites.push(sortedAIAgents[i]);
      }
    }

    return [favorites, nonFavorites];
  };

  const renderTiles = useCallback(() => {
    let sortedAIAgents: Brain[];
    sortedAIAgents = orderBy(aiAgents, [sort], ['desc']) as Brain[];

    if (defferedFilter) {
      sortedAIAgents = sortedAIAgents.filter((aiAgent) =>
        aiAgent.name.toLowerCase().includes(defferedFilter)
      );
    }

    const favoriteAiAgentIds: string[] =
      accountUserPrefs?.favorite_brain_ids as string[];

    if (favoriteAiAgentIds) {
      const [favorites, nonFavorites] = splitAIAgentsByFavorite(
        sortedAIAgents,
        favoriteAiAgentIds
      );

      return [
        ...favorites.map((aiAgent) => (
          <TileAIAgentLayout
            id={aiAgent.brain_id}
            isOnboarding={aiAgent.metadata?.onboarding_status === 'unavailable'}
            icon={<AIAgentIcon type={aiAgent.brain_type} />}
            key={aiAgent.brain_id}
          >
            <TileAIAgentHeader
              title={aiAgent.name}
              actions={
                <div className={styles.actions}>
                  {handleFavorite && (
                    <IconButton
                      ariaLabel="favorite"
                      onClick={(e) => handleFavorite(e, aiAgent.brain_id)}
                    >
                      <StarFilledIcon
                        size={20}
                        color={'var(--icon-default-blue)'}
                      />
                    </IconButton>
                  )}
                  <TileMenu
                    menuItems={tileMenuItems(aiAgent.brain_id, aiAgent.name)}
                  />
                </div>
              }
            />
            <TileAIAgentBody id={aiAgent.brain_id} type={aiAgent.brain_type} />
            <TileAIAgentFooter
              lastTrained={aiAgent.last_trained}
              language={aiAgent.language}
            />
          </TileAIAgentLayout>
        )),
        ...nonFavorites.map((aiAgent) => (
          <TileAIAgentLayout
            id={aiAgent.brain_id}
            isOnboarding={aiAgent.metadata?.onboarding_status === 'unavailable'}
            icon={<AIAgentIcon type={aiAgent.brain_type} />}
            key={aiAgent.brain_id}
          >
            <TileAIAgentHeader
              title={aiAgent.name}
              actions={
                <div className={styles.actions}>
                  {handleFavorite && (
                    <IconButton
                      ariaLabel="favorite"
                      onClick={(e) => handleFavorite(e, aiAgent.brain_id)}
                    >
                      <StarIcon size={20} color={'var(--icon-default-blue)'} />
                    </IconButton>
                  )}
                  <TileMenu
                    menuItems={tileMenuItems(aiAgent.brain_id, aiAgent.name)}
                  />
                </div>
              }
            />
            <TileAIAgentBody id={aiAgent.brain_id} type={aiAgent.brain_type} />

            <TileAIAgentFooter
              lastTrained={aiAgent.last_trained}
              language={aiAgent.language}
            />
          </TileAIAgentLayout>
        )),
      ];
    }

    return sortedAIAgents.map((aiAgent) => (
      <TileAIAgentLayout
        id={aiAgent.brain_id}
        isOnboarding={aiAgent.metadata?.onboarding_status === 'unavailable'}
        icon={<AIAgentIcon type={aiAgent.brain_type} />}
        key={aiAgent.brain_id}
      >
        <TileAIAgentHeader
          title={aiAgent.name}
          actions={
            <div className={styles.actions}>
              {handleFavorite && (
                <IconButton
                  ariaLabel="favorite"
                  onClick={(e) => handleFavorite(e, aiAgent.brain_id)}
                >
                  <StarIcon size={20} color={'var(--icon-default-blue)'} />
                </IconButton>
              )}
              <TileMenu
                menuItems={tileMenuItems(aiAgent.brain_id, aiAgent.name)}
              />
            </div>
          }
        />
        <TileAIAgentBody id={aiAgent.brain_id} type={aiAgent.brain_type} />

        <TileAIAgentFooter
          lastTrained={aiAgent.last_trained}
          language={aiAgent.language}
        />
      </TileAIAgentLayout>
    ));
  }, [
    accountUserPrefs?.favorite_brain_ids,
    aiAgents,
    defferedFilter,
    handleFavorite,
    sort,
    tileMenuItems,
  ]);

  const handleButtonClick = useCallback(() => {
    trackEvent(EventName.ClickCreateBrain);
    dispatch(pushModal(MODAL_AI_AGENT_CREATE));
  }, [dispatch]);

  const handleChange = useCallback((value: string) => {
    if (value === 'last_created') {
      setSort('created');
    } else {
      setSort(value);
    }
  }, []);

  return (
    <PermissionsWrapper module="brains">
      <RichHeader
        handleChange={handleChange}
        sortingOptions={['last_trained', 'last_created']}
        setFilter={setFilter}
        disabled={disabled}
        onButtonClick={handleButtonClick}
        tooltip={getTooltipTitle()}
        name="main_navigation.ai_agents"
        customButton={
          <Button onClick={handleButtonClick} disabled={disabled}>
            <CirclePlusIcon
              color={
                disabled
                  ? 'var(--icon-disabled-gray)'
                  : 'var(--icon-default-white)'
              }
              size={16}
            />
            {t('ai_agents.add_ai_agent')}
          </Button>
        }
      />

      <PageContentWrapper newPlain readOnly={!canWrite}>
        <ContextualHelp
          title={t('ai_agents.contextual_title')}
          name="about_ai_agents"
          links={[
            {
              label: t('ai_agents.contextual_link'),
              url: AI_AGENTS_DOCS_URL,
            },
          ]}
        >
          {t('ai_agents.contextual_message')}
        </ContextualHelp>

        <TileList>
          {canWrite && aiAgents && (
            <TileCreation
              title={t('ai_agents.add_ai_agent')}
              limitReached={maxAIAgentsReached}
              className={styles.tileCreation}
              subtitle={
                canCreateAIAgent ? (
                  <>
                    <Trans
                      i18nKey="limits.create_resource"
                      values={{
                        0: aiAgents?.length,
                        1: maxAIAgents,
                        2: t('common.ai_agents'),
                      }}
                    />
                  </>
                ) : (
                  t('limits.reached', {
                    0: t('common.ai_agents'),
                    1: billingState?.plan_name,
                  })
                )
              }
              onCreateClick={showAddAIAgentModal}
            />
          )}
          {!aiAgents && renderSkeletons(3)}
          {aiAgents && renderTiles()}
          {createStatus === 'pending' && renderSkeletons(1)}
        </TileList>
      </PageContentWrapper>
    </PermissionsWrapper>
  );
}
