import { useCallback } from 'react';

import Box from '@mui/material/Box/Box';
import { TypographyVariant } from '@mui/material/styles';
import { BoxIcon, CirclePlusIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import Button from '@/components/atoms/Button/Button/Button';
import { EditableText } from '@/components/atoms/EditableText/EditableText';
import Tab from '@/components/atoms/Tab/Tab';
import Tabs from '@/components/atoms/Tabs/Tabs';
import Header from '@/components/organisms/Header/Header';
import {
  BUNDLE_CREATE,
  MODAL_DEPARTMENT_CREATE,
} from '@/components/organisms/Modals/ModalConductor';
import { useAccount } from '@/hooks/useAccount';
import useDesks from '@/hooks/useDesks';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { popModal, pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectDeskId } from '@/redux/session/selectors';
import { getRestrictedNames } from '@/util/util';
import { LENGTH_XS, getEditableTextValidationSchema } from '@/util/validator';

const DeskHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { slug } = useAccount();
  const deskId = useSelector(selectDeskId);
  const navigate = useNavigate();

  const { desks, updateDesk, getStatus } = useDesks(deskId);
  const { pathname } = useLocation();
  const pageArray = pathname.split('/');
  const page = pageArray[pageArray.length - 1];

  const canWriteDesks = useSelector((state: RootState) =>
    getPermissions(state, 'desks', actions.WRITE)
  );

  const canWriteBundles = useSelector((state: RootState) =>
    getPermissions(state, 'bundles', actions.WRITE)
  );

  const canWriteDepartments = useSelector((state: RootState) =>
    getPermissions(state, 'departments', actions.WRITE)
  );

  const canWriteBusinessHours = useSelector((state: RootState) =>
    getPermissions(state, 'business_hours', actions.WRITE)
  );

  const header = [];

  header.push({
    text: t('common.context_bundles'),
    link: `/${slug}/environments/${deskId}/context_bundles`,
  });

  header.push({
    text: t('common.business_hours'),
    link: `/${slug}/environments/${deskId}/business_hours`,
  });

  header.push({
    text: t('common.departments'),
    link: `/${slug}/environments/${deskId}/departments`,
  });

  header.push({
    text: t('common.settings'),
    link: `/${slug}/environments/${deskId}/settings`,
  });

  const renderButton = useCallback(() => {
    if (page === 'context_bundles') {
      return (
        <Button
          onClick={() => dispatch(pushModal(BUNDLE_CREATE))}
          disabled={!canWriteBundles}
        >
          <CirclePlusIcon
            color={
              canWriteBundles
                ? 'var(--icon-default-white)'
                : 'var(--icon-disabled-gray)'
            }
            size={16}
          />
          {t('bundles.add_context_bundle')}
        </Button>
      );
    }
    if (page === 'departments') {
      return (
        <Button
          onClick={() => dispatch(pushModal(MODAL_DEPARTMENT_CREATE))}
          disabled={!canWriteDepartments}
        >
          <CirclePlusIcon
            color={
              canWriteDepartments
                ? 'var(--icon-default-white)'
                : 'var(--icon-disabled-gray)'
            }
            size={16}
          />
          {t('departments.add_department')}
        </Button>
      );
    }
    if (page === 'business_hours') {
      return (
        <Button
          onClick={() =>
            navigate(`/${slug}/environments/${deskId}/business_hours/draft`)
          }
          disabled={!canWriteBusinessHours}
        >
          <CirclePlusIcon
            color={
              canWriteBusinessHours
                ? 'var(--icon-default-white)'
                : 'var(--icon-disabled-gray)'
            }
            size={16}
          />
          {t('business_hours.add')}
        </Button>
      );
    }
  }, [
    canWriteBundles,
    canWriteBusinessHours,
    canWriteDepartments,
    deskId,
    dispatch,
    navigate,
    page,
    slug,
    t,
  ]);

  const selectedDesk = desks?.find((desk) => desk.desk_id === deskId)?.name;

  const handleSubmit = (text: string) => {
    updateDesk(
      { name: text, desk_id: deskId },
      {
        onSuccess: () => {
          dispatch(popModal());
        },
      }
    );
  };

  const initialTab = header.find((item) => item.link === pathname);

  // Filters out the current desk name from the list of restricted values
  const restrictedValues = getRestrictedNames(desks, selectedDesk);

  const validationSchema = getEditableTextValidationSchema(
    LENGTH_XS,
    restrictedValues,
    t('environment.environment_name')
  );

  const editableTextProps = {
    defaultValue: selectedDesk ?? t('common.environments'),
    onSubmit: handleSubmit,
    variant: 'h2-semi-bold' as TypographyVariant,
    canEdit: canWriteDesks,
    validationSchema,
  };

  return (
    <Header>
      <Box mt={1.5}>
        <Header.Body noGutters>
          <Header.Title
            icon={<BoxIcon size={24} />}
            title={
              <EditableText<typeof validationSchema> {...editableTextProps} />
            }
            isLoading={getStatus === 'pending'}
          />
        </Header.Body>
        <Header.Navigation>
          <Tabs
            size="small"
            value={initialTab?.link ?? false}
            aria-label={`${selectedDesk} environment`}
          >
            {header.map((item) => (
              <Tab
                key={item.link}
                label={item.text}
                value={item.link}
                to={item.link}
                component={NavLink}
                icon={item?.icon}
                disabled={!deskId}
              />
            ))}
          </Tabs>
          {page !== 'settings' && (
            <Header.Actions>{renderButton()}</Header.Actions>
          )}
        </Header.Navigation>
      </Box>
    </Header>
  );
};

export default DeskHeader;
