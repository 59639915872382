import { useCallback, useEffect, useMemo } from 'react';

import { CircleMinusIcon, CirclePlusIcon } from 'lucide-react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Accordion from '@/components/atoms/Accordion/Accordion';
import CodeBlock from '@/components/atoms/CodeBlock/CodeBlock';
import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import CopyButton from '@/components/atoms/CopyButton/CopyButton';
import IconButton from '@/components/atoms/IconButton/IconButton';
import Input from '@/components/atoms/Input/Input';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import { useIntegrations } from '@/hooks/useIntegrations';
import usePrompt from '@/hooks/usePrompt';
import {
  AccordionFieldsetProps,
  WebWidgetIntegration,
} from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { setDirty, setExpanded } from '@/redux/integrations/actions';
import { selectAccordion } from '@/redux/integrations/selectors';
import { getPermissions } from '@/redux/permissions/selectors';
import { CHANNELS_WS_URL, getDocsUrl } from '@/util/constants';
import { getWebClientSDK, isDevOrLocal } from '@/util/util';
import { urlPattern } from '@/util/validator';

import styles from './ConnectAndSecure.module.scss';

type Form = {
  domains: { name: string }[];
};

const ConnectAndSecure = ({
  type,
  integration,
  toggleAccordion,
  registerAccordion,
}: AccordionFieldsetProps<Form, WebWidgetIntegration>) => {
  const { t } = useTranslation();
  const { expanded } = useSelector(selectAccordion);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );
  const dispatch = useDispatch();
  const { updateIntegration, updateStatus } =
    useIntegrations<WebWidgetIntegration>(
      integration?.desk_id,
      integration?.integration_id
    );

  const getDefaultValues = useCallback((domains: string[]) => {
    if (!domains) {
      return [{ name: '' }];
    }
    return domains.reduce(
      function (accumulator, domain) {
        const currentObject = { name: domain };
        accumulator.push(currentObject);
        return accumulator;
      },
      domains.length > 0 ? [] : [{ name: '' }]
    );
  }, []);

  const formMethods = useForm<Form>({
    mode: 'onSubmit',
  });

  const {
    register,
    reset,
    formState: { errors, isDirty },
    control,
    handleSubmit,
    trigger,
  } = formMethods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'domains',
  });

  const values = useWatch({
    control,
    name: 'domains',
  });

  useEffect(() => {
    reset({ domains: getDefaultValues(integration?.config?.domains) });
  }, [getDefaultValues, integration?.config?.domains, reset]);

  const codeSnippet = useMemo(() => {
    const hostSnippet = isDevOrLocal() ? `, host: "${CHANNELS_WS_URL}"` : '';
    return `<script src="${getWebClientSDK()}"></script>
      <script>MoveoAI.init({ integrationId: "${integration?.integration_id}"${hostSnippet}}).then((desk) => console.log("Moveo Connected")).catch((error) => console.error(error));
      </script>`;
  }, [integration]);

  const handleCreate = useCallback(() => {
    if (canWrite) {
      append({ name: '' });
    }
  }, [append, canWrite]);

  const handleDelete = useCallback(
    (index) => {
      if (canWrite) {
        remove(index);
      }
    },
    [canWrite, remove]
  );

  const handleUpdateIntegration = useCallback(() => {
    const domains = formMethods
      .getValues()
      .domains.map((domain) => domain.name.trim())
      .filter((x) => x !== '');
    const uniqDomains = [...new Set(domains)];
    updateIntegration(
      {
        ...integration,
        config: {
          ...integration?.config,
          domains: uniqDomains.length > 0 ? uniqDomains : undefined,
        },
      },
      {
        onSuccess: () => {
          if (expanded === type) {
            dispatch(setExpanded(false));
          }
        },
      }
    );
  }, [dispatch, expanded, formMethods, integration, type, updateIntegration]);

  usePrompt(
    isDirty && canWrite,
    undefined,
    undefined,
    handleSubmit(handleUpdateIntegration)
  );

  useEffect(() => {
    dispatch(setDirty(isDirty));
  }, [dispatch, isDirty]);

  useEffect(() => {
    if (expanded !== type && isDirty) {
      reset();
    }
  }, [expanded, isDirty, reset, type]);

  return (
    <>
      <Accordion
        title={t('integrations.web_connect.title')}
        subtitle={t('integrations.web_connect.subtitle')}
        footerText={t('integrations.web_connect.footer')}
        onSubmit={handleSubmit(handleUpdateIntegration)}
        disabled={!isDirty}
        isLoading={updateStatus === 'pending'}
        expanded={expanded === type}
        handleChange={toggleAccordion(type)}
        readOnly={!canWrite}
        ref={registerAccordion(
          type,
          handleSubmit(handleUpdateIntegration),
          trigger
        )}
      >
        <TitleSubtitle
          title={t('integrations.web_connect.title2')}
          subtitle={t('integrations.web_connect.2')}
        />

        <div className={styles.codeSnippet}>
          <div className={styles.codeBlockBontainer}>
            <CodeBlock>{codeSnippet}</CodeBlock>
            {codeSnippet && (
              <CopyButton
                className={styles.copyCodeSnippet}
                data={codeSnippet}
              />
            )}
          </div>
        </div>

        <ContextualHelp
          title={t('integrations.web_connect.help_title')}
          name="integration"
          links={[
            {
              label: t('integrations.web_connect.help_link'),
              url: getDocsUrl('/docs/integrations/web'),
            },
          ]}
        >
          {t('integrations.web_connect.help')}
        </ContextualHelp>

        <TitleSubtitle
          title={t('integrations.web_connect.domains_title')}
          subtitle={t('integrations.web_connect.domains_subtitle')}
        />

        <div>
          {fields.map((domain, index) => (
            <div className={styles.input} key={domain.id}>
              <Input
                key={domain.id}
                register={register(`domains.${index}.name`, {
                  pattern: {
                    value: urlPattern,
                    message: t('integrations.web_connect.invalid_url'),
                  },
                })}
                errorMessage={
                  (errors?.domains && errors?.domains[index]?.name?.message) ||
                  null
                }
                placeholder="https://"
                size="large"
                trimValue
                readOnly={!canWrite}
              />
              <IconButton
                ariaLabel={t('common.create')}
                onClick={handleCreate}
                disabled={values[index]?.name === ''}
              >
                <CirclePlusIcon size={16} color="var(--icon-default-gray)" />
              </IconButton>
              {fields.length > 1 && (
                <IconButton
                  ariaLabel={t('common.delete')}
                  onClick={() => handleDelete(index)}
                >
                  <CircleMinusIcon size={16} color="var(--icon-default-gray)" />
                </IconButton>
              )}
            </div>
          ))}
        </div>
      </Accordion>
    </>
  );
};

export default ConnectAndSecure;
