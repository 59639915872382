import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import uniqBy from 'lodash/uniqBy';
import { SquareDashedMousePointerIcon } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Banner } from '@/components/atoms/Banner/Banner';
import Button from '@/components/atoms/Button/Button/Button';
import FileUpload from '@/components/atoms/FileUpload/FileUpload';
import Input from '@/components/atoms/Input/Input';
import Select from '@/components/atoms/Select/Select';
import useBrains from '@/hooks/useBrains';
import { Brain, BrainLanguage, BrainType } from '@/models/brain';
import { UploadedFile } from '@/models/server';
import { getAIAgentType } from '@/modules/aiAgents/helper';
import { AIAgentIcon } from '@/modules/aiAgents/icons/AIAgentIcon';
import useLanguageModels from '@/modules/developerTools/hooks/useLanguageModels';
import { providerNameMapper } from '@/modules/developerTools/pages/languageModels/helper';
import { ProviderName } from '@/modules/developerTools/types';
import { addTemporalToast } from '@/modules/notifications/redux/actions';
import { popModal } from '@/redux/modals/actions';
import { selectAccountSlug } from '@/redux/session/selectors';
import { languageByCountryCode } from '@/util/constants';
import { capitalizeFirstLetter, generateNextName } from '@/util/util';
import { brainRules, errorMessage } from '@/util/validator';

import { updateBrainsUrls } from '../helpers';
import { Form } from './ModalAIAgentCreate';

type FormWithDefaults = Form & {
  provider: string;
  language_model_id: string;
};

import styles from './ModalAIAgentCreate.module.scss';

const RHF_SETVALUE_CONFIG = {
  shouldValidate: true,
  shouldDirty: true,
} as const;

const ACCEPT_TYPES = { 'application/json': ['.json'] } as const;

type ImportAIAgentFormProps = {
  setStep: Dispatch<SetStateAction<number>>;
};

export const ImportAIAgentForm = ({ setStep }: ImportAIAgentFormProps) => {
  // Redux selectors
  const slug = useSelector(selectAccountSlug);

  // Custom hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enabledModels, enabledModelsWithMoveo } = useLanguageModels();
  const { brains, createBrain } = useBrains();
  const navigate = useNavigate();

  // Local state
  const [fileUploadError, setFileUploadError] = useState<string | null>('');
  const [showBanner, setShowBanner] = useState(false);
  const [jsonLanguage, setJsonLanguage] = useState<BrainLanguage>(null);
  const [importedBrain, setImportedBrain] = useState<Partial<Brain> | null>(
    null
  );

  // RFH
  const {
    setValue,
    watch,
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<FormWithDefaults>({
    defaultValues: {
      provider: 'moveo',
      language_model_id: 'Default',
    },
  });

  // Local variables
  const hasSelectedProvider = watch('provider') !== '';
  const isDisabled = !isDirty || !isValid || !importedBrain;
  const llms = useMemo(
    () => (enabledModels.length > 0 ? enabledModelsWithMoveo : []),
    [enabledModels.length, enabledModelsWithMoveo]
  );
  const uniqueProviders = uniqBy(llms, 'provider');
  const languageOptions = Object.keys(languageByCountryCode).map((i) => ({
    value: i.toLowerCase(),
    label: t(`languages.${i.toLowerCase()}`, {
      defaultValue: languageByCountryCode[i],
    }),
  }));
  const message = useMemo(
    () => (
      <Typography display="flex" gap="var(--space-8)" alignItems="center">
        <SquareDashedMousePointerIcon color="var(--icon-default-blue)" />
        {t('brains.drag_and_drop_ai_agent')}
      </Typography>
    ),
    [t]
  );

  // Callbacks
  const updateForm = useCallback(
    (name: string, language: BrainLanguage) => {
      const uniqueName = generateNextName(brains, name);

      // Validate and mark the fields as dirty
      // to activate submit button
      setValue('name', uniqueName, RHF_SETVALUE_CONFIG);
      setValue('language', language, RHF_SETVALUE_CONFIG);

      setJsonLanguage(language);
    },
    [brains, setValue]
  );

  const validateForm = useCallback(
    (data: Partial<Brain>) => {
      const { name, language } = data;

      // Display error when JSON is not valid
      if (!name || !language) {
        setFileUploadError(t('errors.invalid_json'));
        dispatch(addTemporalToast('error', t('errors.invalid_json')));
        return false;
      }

      updateForm(name, language);

      return true;
    },
    [dispatch, t, updateForm]
  );

  const onJsonFileUpload = useCallback(
    async ({ data }: UploadedFile) => {
      const updatedBrain = updateBrainsUrls(data);

      if (validateForm(updatedBrain)) {
        setImportedBrain(updatedBrain);
      }
    },
    [validateForm]
  );

  const removeFile = useCallback(() => {
    setImportedBrain(null);
    setFileUploadError(null);
  }, []);

  const onSubmit = async (data: Form) => {
    if (data.language_model_id === '' || data.language_model_id === 'Default') {
      data.language_model_id = null;
    }

    createBrain(
      {
        ...importedBrain,
        ...data,
        brain_type: importedBrain.brain_type as BrainType,
        name: data.name,
        language: data.language,
      },
      {
        onSuccess: (brain) => {
          dispatch(popModal());
          navigate(`/${slug}/ai-agents/${brain.brain_id}`);
        },
      }
    );
  };

  const handleBackClick = () => {
    if (importedBrain) {
      removeFile();
    }
    setStep((prev) => prev - 1);
  };
  return (
    <form
      autoComplete="off"
      className={styles.importForm}
      id="import-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={styles.fileUploadContainer}>
        <FileUpload
          message={message}
          onFileUploaded={onJsonFileUpload}
          parseJson
          accept={ACCEPT_TYPES}
          onFileRemoved={removeFile}
          forceError={fileUploadError}
        />
      </div>

      <Typography
        component="p"
        color="var(--text-default-blue)"
        variant="label-regular"
        className={cn(styles.type, {
          [styles.empty]: !importedBrain,
        })}
      >
        <AIAgentIcon size={12} type={importedBrain?.brain_type} />
        {getAIAgentType(importedBrain?.brain_type)}
      </Typography>

      <Input
        name="name"
        label={t('common.name')}
        register={register('name', brainRules.name)}
        error={!!errors.name}
        errorMessage={capitalizeFirstLetter(errors.name?.message)}
        placeholder={t('common.name_placeholder')}
        size="medium"
      />

      <Select
        name="language"
        label={t('field.language')}
        placeholder={t('common.select_an_option')}
        defaultValue={importedBrain?.language ?? ''}
        register={register('language', brainRules.language)}
        errorMessage={capitalizeFirstLetter(errors.language?.message)}
        options={languageOptions}
        size="full"
        onChange={(e) => {
          const selectedLanguage = e.target.value;

          if (jsonLanguage !== null && selectedLanguage !== jsonLanguage) {
            setShowBanner(true);
          } else {
            setShowBanner(false);
          }
        }}
      />

      {enabledModels?.length > 0 && (
        <div className={styles.providers}>
          <Select
            name="provider"
            label={t('field.provider')}
            placeholder={t('common.select_an_option')}
            register={register('provider', brainRules.provider)}
            errorMessage={errorMessage({
              field: errors.provider,
              ...brainRules.provider,
            })}
            options={uniqueProviders?.map((model) => ({
              label: providerNameMapper(model.provider as ProviderName),
              value: model.provider,
            }))}
            onChange={(e) => {
              const selected = e.target.value;
              const firstModelOption = uniqueProviders.find(
                (m) => m.provider === selected
              );
              setValue(
                'language_model_id',
                firstModelOption?.language_model_id
              );
            }}
          />

          <Select
            name="language_model_id"
            disabled={!hasSelectedProvider}
            label={t('field.model')}
            placeholder={t('common.select_an_option')}
            register={register('language_model_id', brainRules.model)}
            errorMessage={errorMessage({
              field: errors.language,
              ...brainRules.model,
            })}
            options={llms
              ?.filter((model) => model.provider === watch('provider'))
              ?.map((model) => ({
                label: model.model,
                value: model.language_model_id as string,
              }))}
          />
        </div>
      )}

      {showBanner && (
        <Banner
          title={t('brains.language_mismatch.title')}
          relativePosition
          variant="warning"
        >
          {t('brains.language_mismatch.message')}
        </Banner>
      )}

      <footer className={styles.modal__footer}>
        <div className={styles.modal__buttons}>
          <Button
            onClick={handleBackClick}
            variant="tertiary"
            type="button"
            size="medium"
          >
            {t('common.back')}
          </Button>

          <Button
            variant="tertiary"
            onClick={() => dispatch(popModal())}
            size="large"
          >
            {t('common.cancel')}
          </Button>
          <Button disabled={isDisabled} size="large" type="submit">
            {t('common.create')}
          </Button>
        </div>
      </footer>
    </form>
  );
};
