import { ReactNode, useCallback, useState } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import cn from 'classnames';
import sort from 'lodash/sortBy';
import { ChevronDownIcon } from 'lucide-react';

import { ConditionOptionsModal } from '@/components/organisms/Condition/ConditionDropdownButton/ConditionOptionsModal';
import { OptionBase } from '@/models/common';
import { Option } from '@/modules/rules/model';

import styles from './DropdownList.module.scss';

type data = OptionBase<{ index?: number }>;

type DropdownOption = Option & {
  disabled?: boolean;
};
interface DropdownListProps {
  children: string | ReactNode;
  disabled?: boolean;
  fullWidth?: boolean;
  index?: number;
  lowerCase?: boolean;
  noGutter?: boolean;
  optionCallbackFn?: () => void;
  optionClick?: (data: data, index?: number) => void;
  options?: DropdownOption[];
  searchBar?: boolean;
  selected?: string;
  size?: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'auto';
  variant?: 'normal' | 'active' | 'error';
  height?: 'medium' | 'large' | 'xlarge';
  renderValue?: boolean;
  translationPrefix?: string;
  optionsFullWidth?: boolean;
  alignment?: 'right' | 'left';
  doubleBorder?: boolean;
  isEmpty?: boolean;
  sortBy?: string;
}

const DropdownList = ({
  children,
  variant = 'normal',
  disabled = false,
  optionCallbackFn,
  options = [],
  optionClick,
  size = 'medium',
  height,
  searchBar,
  selected,
  noGutter = false,
  lowerCase = false,
  index,
  renderValue = false,
  translationPrefix = '',
  fullWidth,
  optionsFullWidth,
  alignment = 'left',
  doubleBorder = false,
  isEmpty = false,
  sortBy = 'label',
}: DropdownListProps) => {
  const [showModal, setShowModal] = useState(false);

  const sortedOptions = sortBy ? sort(options, [sortBy]) : options;

  const handleClick = useCallback(() => {
    if (!disabled) {
      try {
        if (optionCallbackFn) {
          optionCallbackFn();
        }
        setShowModal((prev) => !prev);
      } catch (e) {
        /* empty */
      }
    }
  }, [disabled, optionCallbackFn]);

  const handleOptionClick = useCallback(
    (data) => {
      optionClick(data, index);
      setShowModal((prev) => !prev);
    },
    [index, optionClick]
  );

  const handleClickAway = useCallback(() => setShowModal(false), []);

  return (
    <div className={styles.wrapper}>
      <button
        onClick={handleClick}
        className={cn(styles.button, styles[size], styles[variant], {
          [styles.disable]: disabled,
          [styles[size]]: size,
          [styles.heightLarge]: height === 'large',
          [styles.heightXlarge]: height === 'xlarge',
          [styles.heightMedium]: height === 'medium',
          [styles.noGutter]: noGutter,
          [styles.lowerCase]: lowerCase,
          [styles.fullWidth]: fullWidth,
          [styles.doubleBorder]: doubleBorder,
        })}
        type="button"
      >
        <span
          className={cn(styles.children, {
            [styles.large]: size === 'large',
            [styles.valueSelected]: !isEmpty,
          })}
        >
          {children}
        </span>
        <span
          className={cn(styles.openButton, {
            [styles.disable]: disabled,
            [styles.rotate]: showModal,
            [styles.heightLarge]: height === 'large',
            [styles.heightXlarge]: height === 'xlarge',
            [styles.heightMedium]: height === 'medium',
          })}
        >
          <ChevronDownIcon size={16} color={'var(--icon-default-black)'} />
        </span>
      </button>
      {showModal && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <ConditionOptionsModal
            options={sortedOptions}
            index={0}
            handleOptionClick={handleOptionClick}
            searchBar={searchBar}
            selected={selected}
            height={height}
            lowerCase={lowerCase}
            renderValue={renderValue}
            translationPrefix={translationPrefix}
            fullWidth={optionsFullWidth}
            alignment={alignment}
          />
        </ClickAwayListener>
      )}
    </div>
  );
};

export default DropdownList;
