import React from 'react';

import Typography from '@mui/material/Typography';
import { PencilLineIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/atoms/Button/Button/Button';

import styles from '../../LogSections/DetailsSection/DetailsSection.module.scss';

interface Props {
  onClick: () => void;
  amended: boolean;
}
export const DocumentAmend = ({ onClick, amended }: Props) => {
  const { t } = useTranslation();

  if (amended) {
    return (
      <div className={styles.amended}>
        <Typography variant="label-regular" color="var(--text-default-success)">
          {t('try_it.amended')}
        </Typography>
        <PencilLineIcon size={16} color="var(--icon-default-success)" />
      </div>
    );
  }
  return (
    <Button variant="tertiary" size="small" onClick={onClick} noGutters>
      <Typography variant="label-semi-bold">
        {t('try_it.mark_incorrect')}
      </Typography>
    </Button>
  );
};
