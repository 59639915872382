import { useEffect } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { clearCondition } from '@/redux/condition/actions';
import { selectCondition } from '@/redux/condition/selectors';

import ConditionGroup from './ConditionGroup';
import { isConditionNested } from './util';

import styles from './Condition.module.scss';

interface ConditionProps {
  attributes: object;
  canWrite: boolean;
  error?: string;
}

const Condition = ({ attributes, error, canWrite }: ConditionProps) => {
  const _condition = useSelector(selectCondition);
  const isNested = isConditionNested(_condition);
  const hasError = Boolean(error);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(clearCondition());
  }, [dispatch]);

  const ErrorMessage = () => {
    if (hasError) {
      return (
        <Typography variant="label-regular" color="var(--text-default-error)">
          {error}
        </Typography>
      );
    }
    return null;
  };

  if (!isNested) {
    return (
      <>
        <div
          className={cn(styles.conditionWrapper, {
            [styles.empty]: !_condition?.conditions?.length,
            [styles.error]: hasError,
          })}
        >
          <div className={styles.rule}>
            <ConditionGroup
              canWrite={canWrite}
              singleCondition={_condition}
              outerIndex={0}
              attributes={attributes}
            />
          </div>
        </div>
        <ErrorMessage />
      </>
    );
  }

  if (isNested) {
    return (
      <>
        <div
          className={cn(styles.conditionWrapper, {
            [styles.empty]: !_condition?.conditions.length,
            [styles.error]: hasError,
          })}
        >
          {_condition.conditions.map((condition, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className={styles.conditionInnerWrapper} key={index}>
              <div className={styles.rule}>
                <ConditionGroup
                  canWrite={canWrite}
                  singleCondition={condition}
                  outerIndex={index}
                  attributes={attributes}
                />
              </div>
            </div>
          ))}
        </div>
        <ErrorMessage />
      </>
    );
  }
};

export default Condition;
