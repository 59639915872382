import { PhoneIcon } from 'lucide-react';

import styles from './PhoneTile.module.scss';

const PhoneTile = ({ name, phoneNumber, onSelect, disabled }) => {
  return (
    <button className={styles.tile} onClick={onSelect} disabled={disabled}>
      <PhoneIcon color={'var(--icon-default-gray)'} size={16} />
      <div className={styles.textWrapper}>
        <p className={styles.name}>{name}</p>
        <p className={styles.number}>+{phoneNumber}</p>
      </div>
    </button>
  );
};

export default PhoneTile;
