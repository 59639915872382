import { useCallback, useMemo, useState } from 'react';

import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { EllipsisIcon, SquarePenIcon, Trash2Icon } from 'lucide-react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Avatar } from '@/components/atoms/Avatar/Avatar/Avatar';
import PlainFieldset from '@/components/atoms/Fieldset/templates/PlainFieldset';
import IconButton from '@/components/atoms/IconButton/IconButton';
import { CustomMenu } from '@/components/atoms/Menu/Menu';
import Table from '@/components/atoms/Table/Table';
import {
  MODAL_DELETE,
  MODAL_PLUGIN_CREATE,
} from '@/components/organisms/Modals/ModalConductor';
import { Switch } from '@/components/ui/switch';
import useMembers from '@/hooks/useMembers';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { addErrorTemporalToast } from '@/modules/notifications/redux/actions';
import { popModal, pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { sortColumns } from '@/util/util';

import { Empty } from './Empty';
import { PluginsSkeleton } from './PluginsSkeleton';
import usePlugins from '../../hooks/usePlugins';

import style from './Plugins.module.scss';

export const Plugins = () => {
  const { members } = useMembers();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedPlugin, setSelectedPlugin] = useState(null);
  const { plugins, deletePlugin, listStatus, updatePlugin } = usePlugins(
    selectedPlugin ? selectedPlugin.plugin_id : null
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'plugins', actions.WRITE)
  );

  const handleMenuClick = useCallback((event, plugin) => {
    setSelectedPlugin(plugin);
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(async () => {
    setAnchorEl(null);
    setTimeout(() => {
      setSelectedPlugin(null);
    }, 200);
  }, []);

  const handleDelete = useCallback(() => {
    if (!selectedPlugin) return;
    const { plugin_id, name } = selectedPlugin;
    const deleteProps = {
      subtitle: <Trans i18nKey="plugins.delete_warning" values={[name]} />,
      confirm: false,
      onDelete: () => {
        deletePlugin(plugin_id, {
          onError: (error) => {
            dispatch(addErrorTemporalToast(error));
          },
        });

        dispatch(popModal());
      },
      secondaryButtonText: t('common.cancel'),
    };
    dispatch(pushModal(MODAL_DELETE, deleteProps));
    handleMenuClose();
  }, [deletePlugin, dispatch, handleMenuClose, selectedPlugin, t]);

  const handleToggleActive = useCallback(
    (plugin, active) => {
      updatePlugin({ id: plugin.plugin_id, data: { active } });
    },
    [updatePlugin]
  );

  const handleUpdate = useCallback(() => {
    if (!selectedPlugin) return;

    const { plugin_id, name, url, events, token, verify_ssl } = selectedPlugin;

    const updateProps = {
      initialValues: {
        eventName: name,
        eventUrl: url,
        events: events,
        eventSecret: token,
        verifySsl: verify_ssl,
      },
      pluginId: plugin_id,
      isEditing: true,
    };

    dispatch(pushModal(MODAL_PLUGIN_CREATE, updateProps));
    handleMenuClose();
  }, [dispatch, handleMenuClose, selectedPlugin]);

  const columns = useMemo(
    () => [
      {
        Header: t('common.name'),
        accessor: 'name',
        Cell: (props) => (
          <Typography variant="body-regular">{props.value}</Typography>
        ),
      },
      {
        Header: t('plugins.url'),
        accessor: 'url',
        width: 200,
        Cell: (props) => (
          <div className={style.urlColumn}>
            <Tooltip title={props.value} arrow placement="top">
              <Typography variant="body-regular">{props.value}</Typography>
            </Tooltip>
          </div>
        ),
      },
      {
        Header: t('plugins.event'),
        accessor: 'events',
        Cell: (props) => (
          <div className="grid grid-cols-1 gap-2">
            {props.value.map((event) => (
              <Chip key={event} label={event} size="small" />
            ))}
          </div>
        ),
      },
      {
        Header: t('common.created_by'),
        accessor: 'created_by',
        Cell: (props) => {
          const member = members.find(
            (member) => member.user_id === props.value
          );
          return (
            <div className="flex items-center gap-2">
              <Avatar userId={props.value} hideStatus />
              <Typography variant="body-regular">
                {member?.name || '-'}
              </Typography>
            </div>
          );
        },
      },
      {
        Header: t('common.created'),
        accessor: 'created',
        sortType: sortColumns,
        Cell: (props) => (
          <Typography variant="body-regular">
            {moment(props.value).format('DD-MM-YYYY')}
          </Typography>
        ),
      },
      {
        Header: t('common.enabled'),
        accessor: 'active',
        Cell: (props) => {
          const plugin = props.row.original;
          return (
            <div className="flex items-center justify-center mr-10">
              <Tooltip
                title={props.value ? t('common.disable') : t('common.enable')}
                arrow
                placement="top"
              >
                <div>
                  <Switch
                    checked={props.value}
                    onCheckedChange={(checked) =>
                      handleToggleActive(plugin, checked)
                    }
                    disabled={!canWrite}
                  />
                </div>
              </Tooltip>
            </div>
          );
        },
      },
      {
        Header: '',
        id: 'menu',
        Cell: (props) => {
          const plugin = props.row.original;
          return (
            <IconButton
              onClick={(e) => handleMenuClick(e, plugin)}
              ariaLabel={`Plugin options for ${plugin.name}`}
              ariaHasPopUp
              disabled={!canWrite}
            >
              <EllipsisIcon color="var(--icon-default-gray)" />
            </IconButton>
          );
        },
      },
    ],
    [canWrite, handleMenuClick, handleToggleActive, members, t]
  );

  if (listStatus === 'pending') {
    return <PluginsSkeleton />;
  }

  if (plugins.length === 0) {
    return <Empty />;
  }

  return (
    <div className={style.tabPanel__content}>
      <PlainFieldset
        fullWidth
        overflown
        isLoading={listStatus !== 'success'}
        addPB
      >
        <Table
          title={t('plugins.endpoints')}
          data={plugins}
          columns={columns}
          sortBy="created_at"
          sortable
          variant="dark"
          headerHeight="medium"
          noGutters
          fullWidth={false}
          searchable={false}
        />
        <CustomMenu
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleUpdate}>
            <SquarePenIcon size={16} color="var(--icon-default-gray)" />
            {t('common.update')}
          </MenuItem>
          <MenuItem onClick={handleDelete} className={style.danger}>
            <Trash2Icon size={16} />
            {t('common.delete')}
          </MenuItem>
        </CustomMenu>
      </PlainFieldset>
    </div>
  );
};
