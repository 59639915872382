import { useEffect, useRef, useState } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { CircleCheckIcon } from 'lucide-react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ImportIntercom from './ImportIntercom/ImportIntercom';
import ImportUrls from './ImportUrls/ImportUrls';
import ConfigureZendesk from './ImportZendesk/ConfigureZendesk';
import ConnectZendesk from './ImportZendesk/ConnectZendesk';
import SelectDataSourceType, {
  datasourceOptions,
} from './SelectDataSourceType';
import { steps, useModalDatasource } from './useModalDatasource';
import Modal from '../Modal';

import styles from './ModalDatasourceAdd.module.scss';

interface Props {
  isImportWebsite?: boolean;
  isImportZendesk?: boolean;
  zendeskSubdomain?: string;
  isImportIntercom?: boolean;
  onDatasourceCreatedSuccess?: () => void;
  onDatasourceCreatedError?: () => void;
  onClose?: () => void;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  initialSubdomain?: string;
  isUpdate?: boolean;
}
const ModalDatasourceAdd = ({
  isImportWebsite,
  isImportZendesk,
  zendeskSubdomain,
  isImportIntercom,
  onDatasourceCreatedSuccess,
  onDatasourceCreatedError,
  onClose,
  primaryButtonText,
  secondaryButtonText,
  initialSubdomain,
  isUpdate = false,
}: Props) => {
  const [enablePrimaryButton, setEnablePrimaryButton] = useState(true);
  const submitCountRef = useRef(0);

  const {
    onPrimaryClick,
    onSecondaryClick,
    isLoading,
    formMethods,
    pageStep,
    selected,
    setSelected,
    zendeskLanguages,
    externalKbState: externalKb,
    resetExternalKbState,
  } = useModalDatasource({
    isImportWebsite,
    isImportZendesk,
    zendeskSubdomain,
    isImportIntercom,
    onDatasourceCreatedSuccess,
    onDatasourceCreatedError,
    onClose,
    initialSubdomain,
    submitCountRef,
  });

  const { t } = useTranslation();
  const getPrimaryButtonText = () => {
    if (
      (pageStep === 1 && datasourceOptions(t)[selected].id === 'web') ||
      isImportWebsite
    ) {
      return isUpdate ? t('common.update') : t('common.import');
    }
    if (
      (pageStep === 1 && datasourceOptions(t)[selected].id === 'intercom') ||
      isImportIntercom
    ) {
      return t('common.continue');
    }

    if (pageStep === 2 && datasourceOptions(t)[selected].id === 'zendesk') {
      return zendeskLanguages.length === 0
        ? t('common.close')
        : t('common.done');
    }

    return t('common.next');
  };

  const getSecondaryButtonText = () => {
    if (pageStep > 0) {
      return t('common.back');
    }
    return t('common.cancel');
  };

  useEffect(() => {
    if (pageStep === 0) {
      setEnablePrimaryButton(true);
    }
  }, [pageStep]);

  const resetSubmitCount = () => {
    submitCountRef.current = 0;
  };

  const resetForm = () => {
    resetExternalKbState();
    resetSubmitCount();
  };

  return (
    <Modal
      size="medium"
      title={t('collections.add_datasource')}
      onPrimarySubmit={onPrimaryClick}
      onSecondarySubmit={onSecondaryClick}
      primaryButtonText={primaryButtonText || getPrimaryButtonText()}
      secondaryButtonText={secondaryButtonText || getSecondaryButtonText()}
      className={styles.modal}
      isSubmitting={isLoading}
      autoFocus
      primaryButtonDisable={!enablePrimaryButton}
      preventSecondaryClose={pageStep > 0}
    >
      <div className={styles.content}>
        {!isImportWebsite && (
          <div className={styles.stepsContainer}>
            {datasourceOptions(t)[selected].id !== 'doc' &&
              steps(t).map((step, index) => {
                if (
                  (datasourceOptions(t)[selected].id === 'intercom' ||
                    datasourceOptions(t)[selected].id === 'web') &&
                  index === 2
                ) {
                  return null;
                }

                return (
                  <div
                    key={step.id}
                    className={cn(styles.stepContainer, {
                      [styles.isSelected]: pageStep >= index,
                    })}
                  >
                    {index !== 0 && <div className={styles.line} />}
                    {pageStep > index ? (
                      <span className={styles.circle}>
                        <CircleCheckIcon
                          color="var(--icon-default-blue)"
                          size={20}
                        />
                      </span>
                    ) : (
                      <Typography
                        variant="label-semi-bold"
                        className={styles.circle}
                      >
                        {index + 1}
                      </Typography>
                    )}
                    <Typography variant="label-regular">
                      {step.label}
                    </Typography>
                  </div>
                );
              })}
          </div>
        )}
        <FormProvider {...formMethods}>
          {pageStep === 0 && (
            <SelectDataSourceType
              setSelected={setSelected}
              selected={selected}
            />
          )}

          {pageStep == 1 && datasourceOptions(t)[selected].id === 'web' && (
            <>
              <ImportUrls
                externalKb={externalKb}
                setEnablePrimaryButton={setEnablePrimaryButton}
                resetForm={resetForm}
                submitCountRef={submitCountRef}
              />
            </>
          )}

          {pageStep == 1 &&
            datasourceOptions(t)[selected].id === 'intercom' && (
              <ImportIntercom />
            )}

          {pageStep == 1 && datasourceOptions(t)[selected].id === 'zendesk' && (
            <ConfigureZendesk setEnablePrimaryButton={setEnablePrimaryButton} />
          )}

          {pageStep == 2 && datasourceOptions(t)[selected].id === 'zendesk' && (
            <ConnectZendesk
              languages={zendeskLanguages}
              setEnablePrimaryButton={setEnablePrimaryButton}
            />
          )}
        </FormProvider>
      </div>
    </Modal>
  );
};

export default ModalDatasourceAdd;
