import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ChevronDownIcon } from 'lucide-react';

import Button from '@/components/atoms/Button/Button/Button';

export const DynamicLabelButton = ({
  icon,
  currentLabel,
  type,
  handleMenuClick,
}: Props) => (
  <Button
    onClick={(e) => handleMenuClick(e, type)}
    variant="tertiary"
    size="small"
  >
    <Box
      sx={{
        display: 'flex',
        gap: 'var(--space-4)',
        svg: {
          marginRight: '0 !important',
        },
        alignItems: 'center',
      }}
    >
      {icon}
      <Typography variant="body-semi-bold">{currentLabel}</Typography>
      <ChevronDownIcon size={16} color="var(--icon-default-blue)" />
    </Box>
  </Button>
);

type Props = {
  icon: React.ReactNode;
  currentLabel: string;
  type: 'sort' | 'status';
  handleMenuClick: (
    e: React.MouseEvent<HTMLButtonElement>,
    type: 'sort' | 'status'
  ) => void;
};
