import { CircleCheckBigIcon, CircleXIcon } from 'lucide-react';

const Checkmark = ({ checked = true, size = 16, color = '' }) => {
  const styleColor =
    color ||
    (checked ? 'var(--icon-default-success)' : 'var(--icon-default-error)');
  const style = { color: styleColor, width: size, height: size };

  return checked ? (
    <CircleCheckBigIcon style={style} />
  ) : (
    <CircleXIcon style={style} />
  );
};

export default Checkmark;
