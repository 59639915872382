import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TFunction } from 'i18next';
import { FileVolumeIcon } from 'lucide-react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Attachment as AttachmentType } from '@/models/chat';
import { Entity, MessageReminder } from '@/models/tryIt';
import { useReminderOptions } from '@/modules/aiAgents/components/Setup/useReminderOptions';
import { Attachment } from '@/modules/humanChat/components/Conversation/MessageArea/Attachment/Attachment';
import Attachments from '@/modules/humanChat/components/Conversation/MessageArea/Attachment/Attachments';
import { selectSessionSource } from '@/modules/TryIt/redux/selectors';
import { splitArray } from '@/util/util';

import { highlightEntities } from './helper';

import styles from './PromptMessage.module.scss';
interface PromptMessageProps {
  text: string;
  entities: Entity[];
  onGoToEntity: (entity: string) => void;
  t: TFunction;
  isAudio: boolean;
  attachments: AttachmentType[];
  reminder?: MessageReminder;
}

export const PromptMessage = ({
  text,
  entities,
  onGoToEntity,
  t,
  isAudio,
  attachments,
  reminder,
}: PromptMessageProps) => {
  const { getTimerLabel } = useReminderOptions();
  const source = useSelector(selectSessionSource);
  // Reminder banner
  if (!text && reminder) {
    return (
      <Typography
        color="var(--text-default-gray)"
        bgcolor="var(--surface-secondary-gray)"
        padding="var(--space-8)"
        textAlign="center"
        borderRadius="var(--space-4)"
        mb="var(--space-24)"
        component="p"
      >
        <Trans
          i18nKey={'conversation.after_duration_of_inactivity'}
          values={{
            0: getTimerLabel(reminder.minutes),
          }}
        />
      </Typography>
    );
  }

  return (
    <div className={styles.prompt}>
      {attachments && (
        <Box mb={text ? 1 : 0}>
          {splitArray(attachments, 4).map((chunk, chunkIndex) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={chunkIndex}
            >
              <Attachments>
                {chunk.map((attachment) => (
                  <Attachment
                    key={attachment?.id ?? attachment.url}
                    attachment={attachment}
                    source={source}
                    isSingleItem={attachments.length === 1}
                    bg="dark"
                    authorType="visitor"
                  />
                ))}
              </Attachments>
            </div>
          ))}
        </Box>
      )}
      {isAudio && (
        <span className={styles.audioTitle}>
          <FileVolumeIcon size={16} color="var(--text-default-white)" />
          <Typography
            variant="label-regular"
            color="var(--text-default-white)"
            className={styles.audio}
          >
            {t('conversation.audio_message')}
          </Typography>
        </span>
      )}

      <Typography variant="body-regular" component="p">
        {highlightEntities(text, entities, onGoToEntity)}
      </Typography>
    </div>
  );
};
