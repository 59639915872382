import { forwardRef, useCallback, useState } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip from '@mui/material/Tooltip';
import cn from 'classnames';
import { CirclePlusIcon, XIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { integrationIconByType } from '@/components/atoms/Icons/integrationIconByType';
import {
  CHANNEL_VALUE_TO_LABEL,
  CONDITION_ATTRIBUTES_VALUE_TO_LABEL,
  RULES_ATTRIBUTES_VALUE_TO_LABEL,
  STATUS_VALUE_TO_LABEL,
} from '@/components/organisms/Condition/constants';
import useBusinessHours from '@/hooks/useBusinessHours';
import { useIntegrations } from '@/hooks/useIntegrations';
import useMembers from '@/hooks/useMembers';
import { RootState } from '@/models/state';
import {
  deleteRuleCondition,
  isConditionOpen,
} from '@/redux/condition/actions';
import { selectIsConditionOpen } from '@/redux/condition/selectors';
import { selectDeskId } from '@/redux/session/selectors';
import { COUNTRIES_VALUE_TO_LABEL } from '@/util/countries';
import { languageCodes } from '@/util/languageCodes';
import { isKeyEnter, preventClickThrough, timezoneMaker } from '@/util/util';

import ConditionConfigurationModal from '../ConditionConfigurationModal/ConditionConfigurationModal';
import NewConditionOptions from '../ConditionsModal/ConditionOptions';

import styles from './ConditionDropdownButton.module.scss';

export type ButtonProps = {
  attributes: object;
  conditionAttribute: string;
  conditionValue: string;
  conditionComparison: string;
  variant?: 'normal' | 'active' | 'error';
  disabled?: boolean;
  grouped?: boolean;
  labelText?: string;
  index?: number;
  outerIndex?: number;
  isCondition?: boolean;
  conditionCategory: string;
  conditionAttributeSubcategory: string;
  serachBar?: boolean;
};

const CondtitionDropdownButton: React.FC<ButtonProps> = forwardRef(
  (
    {
      conditionCategory,
      conditionAttribute,
      conditionValue,
      conditionComparison,
      conditionAttributeSubcategory,
      variant = 'normal',
      disabled = false,
      grouped = false,
      labelText,
      index,
      outerIndex,
      attributes,
    }: ButtonProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const { t } = useTranslation();
    const isOpen = useSelector((state: RootState) =>
      selectIsConditionOpen(state, { outerIndex, index })
    );
    const [showConditionOptionsModal, setShowConditionOptionsModal] =
      useState(false);
    const { members } = useMembers();
    const deskId = useSelector(selectDeskId);
    const { integrations } = useIntegrations(deskId);
    const { businessHours } = useBusinessHours();
    const dispatch = useDispatch();

    const handleClose = useCallback(
      (e) => {
        preventClickThrough(e);
        dispatch(deleteRuleCondition({ index, outerIndex }));
      },
      [dispatch, index, outerIndex]
    );

    const handleButtonClick = useCallback(
      (e) => {
        preventClickThrough(e);
        dispatch(isConditionOpen({ index, outerIndex, revertBoolean: true }));
      },
      [dispatch, index, outerIndex]
    );

    const handleConfigurationApply = useCallback(() => {
      dispatch(isConditionOpen({ index, outerIndex, revertBoolean: false }));
    }, [dispatch, index, outerIndex]);

    const handleAddClick = useCallback(() => {
      setShowConditionOptionsModal(true);
    }, []);

    const handleKeyStroke = useCallback(
      (e) => {
        if (isKeyEnter(e)) {
          handleAddClick();
        }
      },
      [handleAddClick]
    );

    const handleOptionModalClick = () => {
      setShowConditionOptionsModal(false);
    };

    const noTextTransform = [
      'email',
      'text',
      'display_name',
      'global',
      'tag',
      'location',
      'platform',
      'browser',
      'business_hours',
    ];

    const conditionValueToShow = (
      conditionValue: string,
      attribute: string,
      attributeSubCategory: string,
      conditionCategory: string
    ) => {
      if (attributeSubCategory === 'country') {
        return COUNTRIES_VALUE_TO_LABEL[conditionValue];
      }

      if (attribute === 'language') {
        return languageCodes[conditionValue];
      }

      if (attribute === 'timezone') {
        return timezoneMaker([`${conditionValue}`])[0]?.label;
      }

      if (attribute === 'agent_id') {
        return (
          <span className={cn(styles.valueText)}>
            {members?.find((x) => x.user_id === conditionValue)?.name}
          </span>
        );
      }

      if (attribute === 'integration_id') {
        const name = integrations?.find(
          (x) => x.integration_id === conditionValue
        )?.name;
        const type = integrations?.find(
          (x) => x.integration_id === conditionValue
        )?.type;
        const Icon = integrationIconByType[type];
        return type ? (
          <span className={styles.buttonInner}>
            <span className={styles.paddingRight}>
              <Icon size={16} />
            </span>
            {name}
          </span>
        ) : null;
      }

      if (conditionCategory === 'business_hours') {
        return businessHours?.business_hours.find(
          (x) => x.business_hours_id === conditionValue
        )?.name;
      }

      if (noTextTransform.includes(conditionAttribute)) {
        return <span className={styles.noTextTransform}>{conditionValue}</span>;
      }
      return conditionValue;
    };

    const buttonText = () => {
      if (
        conditionAttribute === 'location' ||
        conditionAttribute === 'global'
      ) {
        return (
          <>
            <span className={styles.bold}>
              {t(`rules.${conditionAttributeSubcategory}`)}
            </span>{' '}
            <span className={styles.noTextTransform}>
              {conditionComparison ? t(`rules.${conditionComparison}`) : ''}
            </span>{' '}
            {conditionValueToShow(
              conditionValue,
              conditionAttribute,
              conditionAttributeSubcategory,
              conditionCategory
            )}
          </>
        );
      }

      if (conditionCategory === 'channel') {
        return (
          <>
            <span className={styles.bold}>
              {t(`rules.${conditionCategory}`)}
            </span>{' '}
            <span className={styles.noTextTransform}>
              {conditionComparison ? t(`rules.${conditionComparison}`) : ''}
            </span>{' '}
            {CHANNEL_VALUE_TO_LABEL[conditionAttribute]}
          </>
        );
      }
      if (conditionCategory === 'status') {
        return (
          <>
            <span className={styles.bold}>
              {t(`rules.${conditionCategory}`)}
            </span>{' '}
            <span className={styles.noTextTransform}>
              {conditionComparison ? t(`rules.${conditionComparison}`) : ''}
            </span>{' '}
            {STATUS_VALUE_TO_LABEL[conditionAttribute]
              ? t(
                  `rules.${STATUS_VALUE_TO_LABEL[conditionAttribute]}`
                ).toLowerCase()
              : undefined}
          </>
        );
      }

      if (conditionCategory === 'business_hours') {
        return (
          <>
            <span className={styles.bold}>
              {t(
                `rules.${CONDITION_ATTRIBUTES_VALUE_TO_LABEL[conditionCategory]}`
              )}
            </span>{' '}
            <span className={styles.noTextTransform}>
              {conditionComparison ? t(`rules.${conditionComparison}`) : ''}
            </span>{' '}
            {conditionValueToShow(
              conditionValue,
              conditionAttribute,
              conditionAttributeSubcategory,
              conditionCategory
            )}
          </>
        );
      }

      const value = conditionValueToShow(
        conditionValue,
        conditionAttribute,
        conditionAttributeSubcategory,
        conditionCategory
      );

      const shouldShowTooltip = value?.length
        ? value?.length < 30
        : value?.props?.children?.length < 30;
      return (
        <span className={styles.buttonInner}>
          <span className={styles.bold}>
            {t(`rules.${RULES_ATTRIBUTES_VALUE_TO_LABEL[conditionAttribute]}`)}
          </span>
          <span className={styles.paddingRight}>
            {conditionAttributeSubcategory}
          </span>
          <span className={cn(styles.paddingRight, styles.noTextTransform)}>
            {conditionComparison ? t(`rules.${conditionComparison}`) : ''}
          </span>
          <Tooltip arrow title={shouldShowTooltip ? value : ''}>
            <>{value}</>
          </Tooltip>
        </span>
      );
    };

    return (
      <>
        <div
          className={cn(styles.wrapper, {
            [styles.grouped]: !grouped,
          })}
          ref={ref}
        >
          <button
            className={cn(styles.button, {
              [styles.error]: variant === 'error',
              [styles.active]: variant === 'active',
              [styles.disable]: disabled,
              [styles.grouped]: grouped,
            })}
            onClick={handleButtonClick}
            aria-label={labelText}
          >
            {buttonText()}
            <span
              className={cn(styles.closeButton, { [styles.disable]: disabled })}
              role="button"
              onClick={handleClose}
              onKeyDown={handleClose}
              tabIndex={0}
            >
              <XIcon
                size={14}
                color={
                  variant === 'error'
                    ? 'var(--icon-default-error)'
                    : 'var(--icon-default-blue)'
                }
              />
            </span>
          </button>

          {grouped && (
            <span
              className={cn(styles.create, {
                [styles.disable]: variant === 'error',
              })}
              role="button"
              onClick={handleAddClick}
              onKeyDown={handleKeyStroke}
              tabIndex={0}
            >
              <CirclePlusIcon
                size={16}
                color={
                  variant === 'normal' || variant === 'active'
                    ? 'var(--icon-default-blue)'
                    : 'var(--icon-disabled-gray)'
                }
              />
            </span>
          )}

          {isOpen && (
            <ClickAwayListener onClickAway={handleConfigurationApply}>
              <ConditionConfigurationModal
                index={index}
                conditionCategory={conditionCategory}
                conditionAttribute={conditionAttribute}
                conditionComparison={conditionComparison}
                conditionAttributeSubcategory={conditionAttributeSubcategory}
                conditionValue={conditionValue}
                outerIndex={outerIndex}
                handleApply={handleConfigurationApply}
              />
            </ClickAwayListener>
          )}

          {showConditionOptionsModal && (
            <ClickAwayListener
              onClickAway={() => setShowConditionOptionsModal(false)}
            >
              <NewConditionOptions
                handleOptionClick={handleOptionModalClick}
                attributes={attributes}
                index={index}
                outerIndex={outerIndex}
              />
            </ClickAwayListener>
          )}
        </div>
      </>
    );
  }
);

CondtitionDropdownButton.displayName = 'CondtitionDropdownButton';
export default CondtitionDropdownButton;
