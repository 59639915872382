import { SyntheticEvent, useCallback } from 'react';

import cn from 'classnames';
import { AlertTriangleIcon } from 'lucide-react';
import { Trans, useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import ActionList from '@/components/organisms/Dialogs/Action/ActionList';
import ActionPlaceholder from '@/components/organisms/Dialogs/Action/Placeholder/Placeholder';
import ActionHeader from '@/components/organisms/Dialogs/ActionHeader/ActionHeader';
import Box from '@/components/organisms/Dialogs/Box/Box';
import Condition from '@/components/organisms/Dialogs/Node/Conditions/Condition';
import ConditionIntersection from '@/components/organisms/Dialogs/Node/Conditions/ConditionIntersection/ConditionIntersection';
import useConditionsCollapsed from '@/components/organisms/Dialogs/Node/Conditions/useConditionsCollapsed';
import { MODAL_DELETE } from '@/components/organisms/Modals/ModalConductor';
import { RootState } from '@/models/state';
import { selectHasError } from '@/redux/dialogAlerts/selectors';
import { isFinalAction } from '@/redux/dialogs/helper';
import { selectConditionsByNodeId } from '@/redux/dialogs/selectors';
import { popModal, pushModal } from '@/redux/modals/actions';
import { removeCondition, selectCondition } from '@/redux/nodes/actions';
import { preventClickThrough } from '@/util/util';

import styles from './ConditionList.module.scss';

interface Props {
  nodeId: string;
}

function ConditionList({ nodeId }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { conditions, selectedNodeId, selectedType, selectedConditionIndex } =
    useSelector((state: RootState) => {
      const conditions = selectConditionsByNodeId(state, nodeId);
      const selectedNodeId = state.nodes.selectedNodeId;
      const selectedType = state.nodes.selectedType;
      const selectedConditionIndex = state.nodes.selectedConditionIndex;
      return {
        conditions,
        selectedNodeId,
        selectedType,
        selectedConditionIndex,
      };
    }, shallowEqual);

  const hasDialogError = useSelector(selectHasError(nodeId));

  const {
    collapsed,
    setCollapsed,
    activeConditionIndex,
    setActiveConditionIndex,
  } = useConditionsCollapsed(nodeId, conditions);

  const isConditionSelected =
    nodeId === selectedNodeId && selectedType === 'condition';

  const conditionSize = conditions ? conditions.length : 0;
  let showLastPlaceholder = false;

  // If there are conditions, check if the last action is a final action.
  if (conditions) {
    const lastActionType =
      conditions[activeConditionIndex]?.actions[
        conditions[activeConditionIndex]?.actions?.length - 1
      ]?.type;

    showLastPlaceholder = !isFinalAction(lastActionType);
  }

  const calcPosition = useCallback(
    (val) => {
      let position = '';

      // Special case where conditionSize is 1 and val is 0.
      if (conditionSize === 1 && val === 0) {
        position = 'single';
      } else {
        // Calculate the center position based on conditionSize.
        // This is only necessary when conditionSize is not 1.
        const center = Math.floor((conditionSize - 1) / 2);

        // If val is equal to center, check whether conditionSize is even or odd.
        // If it's even, position is 'left'. If it's odd, position is 'center'.
        if (val === center) {
          position = conditionSize % 2 === 0 ? 'left' : 'center';
        } else {
          // If val is not equal to center, position is 'right' if val is greater than center,
          // and 'left' if val is less than center.
          position = val > center ? 'right' : 'left';
        }
      }
      return position;
    },
    [conditionSize]
  );

  // Deletes the condition when the delete button is clicked from the condition header
  const handleDelete = useCallback(
    (index, name) => {
      const deleteProps = {
        subtitle: <Trans i18nKey="dialog.delete_condition" values={[name]} />,
        confirm: false,
        onDelete: () => {
          // If a condition is being deleted, set the active condition to the first condition
          setActiveConditionIndex(0);
          dispatch(removeCondition({ nodeId, index }));
          dispatch(popModal());
        },
        secondaryButtonText: t('common.cancel'),
      };
      dispatch(pushModal(MODAL_DELETE, deleteProps));
    },
    [dispatch, nodeId, setActiveConditionIndex, t]
  );

  if (conditionSize === 0) {
    return null;
  }

  const handleClick = (e: SyntheticEvent, index: number) => {
    preventClickThrough(e);
    dispatch(selectCondition({ nodeId, index }));
  };

  return (
    <>
      <ConditionIntersection
        nodeId={nodeId}
        isCollapsed={collapsed}
        setCollapsed={setCollapsed}
      />
      <div
        className={cn(styles.condition__list, {
          [styles['condition__list--collapsed']]: collapsed,
        })}
      >
        {conditions &&
          conditions.map((c, index) => (
            <Condition
              condition={c}
              index={index}
              key={c.condition_id}
              id={c.condition_id}
              name={c.name || String.fromCharCode(65 + index)}
              match={c.match}
              nodeId={nodeId}
              position={calcPosition(index)}
              firstActionType={c.actions[0]?.type}
              isCollapsed={collapsed}
              setActiveConditionIndex={setActiveConditionIndex}
              conditionSize={conditionSize}
            />
          ))}
      </div>
      {collapsed && activeConditionIndex !== null && (
        <>
          <div className={styles.collapsedCondition}>
            {hasDialogError && (
              <AlertTriangleIcon size={16} color="var(--icon-default-error)" />
            )}

            {conditions.map(({ condition_id, name }, index) => (
              <Box
                key={condition_id}
                className={cn({
                  [styles.collapsedCondition__hide]:
                    index !== activeConditionIndex,
                })}
                role="button"
                onClick={(e) => handleClick(e, index)}
              >
                <ActionHeader
                  onDelete={() => handleDelete(index, name)}
                  type={'condition'}
                  objectToCopy={conditions[selectedConditionIndex]}
                  isSelected={isConditionSelected}
                  hasDialogError={hasDialogError}
                >
                  {conditions[activeConditionIndex]?.name}
                </ActionHeader>
              </Box>
            ))}
          </div>
          <ActionList nodeId={nodeId} conditionIndex={activeConditionIndex} />
          {showLastPlaceholder && (
            <ActionPlaceholder
              show={conditions[activeConditionIndex]?.actions.length === 0}
              nodeId={nodeId}
              conditionIndex={activeConditionIndex}
              isLast
            />
          )}
        </>
      )}
    </>
  );
}

export default ConditionList;
