import { useCallback, useEffect, useMemo, useState } from 'react';

import InputBase from '@mui/material/InputBase';
import { CirclePlusIcon, CircleMinusIcon } from 'lucide-react';
import moment from 'moment';
import { Controller, useForm, useFieldArray, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import DropdownList from '@/components/atoms/DropdownList/DropdownList';
import IconButton from '@/components/atoms/IconButton/IconButton';
import TabPanel from '@/components/atoms/TabPanel/TabPanel';
import {
  extractIntervalsFromHours,
  intervalsObjectToHours,
  frequencyOptions,
  transformHolidaysToOrchestrator,
  getDateFromDayOfYear,
} from '@/components/pages/BusinessHours/utils';
import Layout from '@/components/templates/Layout/Layout';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import { useAccount } from '@/hooks/useAccount';
import useBusinessHours from '@/hooks/useBusinessHours';
import usePrompt from '@/hooks/usePrompt';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectDeskId } from '@/redux/session/selectors';
import { timezoneMaker } from '@/util/util';
import { businessHoursRules } from '@/util/validator';

import BusinessHourHeader from './BusinessHourHeader';
import Holidays from './Holidays';

import styles from './BusinessHour.module.scss';

type Dates = { startDate: string; endDate: string };

export type BusinessHoursForm = {
  businessHourName: string;
  holidays: { holidayName: string; dates: Dates }[];
};
const FIRST_OF_AUGUST = 213;
const FIFTEENTH_OF_AUGUST = 228;

const BusinessHour = () => {
  const navigate = useNavigate();
  const { businessHourId } = useParams();
  const deskId = useSelector(selectDeskId);
  const { slug } = useAccount();
  const { t } = useTranslation();

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'business_hours', actions.WRITE)
  );

  const [tabIndex, setTabIndex] = useState(0);
  const [updated, setUpdated] = useState(false);
  const {
    singleBusinessHour,
    updateBusinessHour,
    addBusinessHour,
    addStatus,
    updateStatus,
    isDefaultBusinessHour,
    hours,
    setHours,
    handleFrequencyClick,
    handleFromClick,
    handleToClick,
    handleTimezoneClick,
    setTimezone,
    timezone,
    handleRemoveClick,
    handleAddClick,
    timezoneOptions,
    startTimeOptions,
    endTimeOptions,
    initialHours,
    setInitalHours,
    initialTimezone,
    setInitialTimezone,
  } = useBusinessHours(businessHourId !== 'draft' ? businessHourId : undefined);

  const muiStyles = useMemo(
    () => ({
      root: {
        borderRadius: 'var(--border-radius-base)',
        border: '1px solid var(--border-default-gray)',
        height: '40px',
        width: tabIndex === 0 ? '495px' : '300px',
        paddingLeft: '16px',
        fontSize: 'var(--space-14)',
        lineHeight: 'var(--space-16)',
        backgroundColor: 'var(--surface-primary-white)',
        '&.Mui-focused': {
          border: '1px solid var(--border-default-blue)',
        },
        '&.Mui-error': {
          border: '1px solid red',
        },
      },
    }),
    [tabIndex]
  );

  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate'>(
    'startDate'
  );

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitting, isValid },
    setValue,
    getValues,
  } = useForm<BusinessHoursForm>({
    mode: 'onChange',
    defaultValues: {
      businessHourName: '',
      holidays: [],
    },
  });
  const dirty =
    isDirty ||
    JSON.stringify(hours) !== JSON.stringify(initialHours) ||
    JSON.stringify(timezone) !== JSON.stringify(initialTimezone);

  const [showDatePicker, setShowDatePicker] = useState([]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'holidays',
  });

  useEffect(() => {
    if (singleBusinessHour) {
      setHours(intervalsObjectToHours(singleBusinessHour.intervals));
      setInitalHours(intervalsObjectToHours(singleBusinessHour.intervals));
      setTimezone(timezoneMaker([singleBusinessHour.timezone])[0]);
      setInitialTimezone(timezoneMaker([singleBusinessHour.timezone])[0]);
    }
  }, [
    setHours,
    setInitalHours,
    setInitialTimezone,
    setTimezone,
    singleBusinessHour,
  ]);

  useEffect(() => {
    if (singleBusinessHour) {
      setValue('businessHourName', singleBusinessHour?.name);
    }
  }, [setValue, singleBusinessHour]);

  useEffect(() => {
    setValue(
      'holidays',
      singleBusinessHour?.holidays.map((holiday) => {
        return {
          holidayName: holiday.name,
          dates: {
            startDate: `${getDateFromDayOfYear(holiday.start)}`,
            endDate: `${getDateFromDayOfYear(holiday.end)}`,
          },
        };
      })
    );
    setShowDatePicker(
      Array.from({ length: singleBusinessHour?.holidays.length ?? 0 }, () => {
        return { isOpen: false };
      })
    );
  }, [setValue, singleBusinessHour?.holidays]);

  const values = useWatch({
    control,
    name: 'holidays',
  });
  const onSubmit = useCallback(() => {
    setUpdated(true);
    const { businessHourName, holidays } = getValues();
    if (businessHourId && businessHourId !== 'draft') {
      updateBusinessHour(
        {
          ...singleBusinessHour,
          timezone: timezone?.value,
          name: businessHourName.trim(),
          intervals: extractIntervalsFromHours(hours),
          holidays: transformHolidaysToOrchestrator({ holidays }),
        },
        {
          onSuccess: () => {
            navigate(`/${slug}/environments/${deskId}/business_hours`);
          },
        }
      );
    } else {
      addBusinessHour(
        {
          timezone: timezone?.value,
          name: businessHourName.trim(),
          intervals: extractIntervalsFromHours(hours),
          holidays: transformHolidaysToOrchestrator({ holidays }),
        },
        {
          onSuccess: () => {
            navigate(`/${slug}/environments/${deskId}/business_hours`);
          },
        }
      );
    }
  }, [
    getValues,
    businessHourId,
    updateBusinessHour,
    singleBusinessHour,
    timezone?.value,
    hours,
    navigate,
    slug,
    deskId,
    addBusinessHour,
  ]);

  const handleCalendarSave = useCallback((event, index) => {
    event.preventDefault();
    setShowDatePicker((prev) => {
      const newArray = [...prev];
      newArray[index] = { isOpen: false };
      return newArray;
    });
  }, []);

  const handleCreate = useCallback(() => {
    setShowDatePicker((prev) => {
      const newArray = [...prev];
      newArray.push({ isOpen: false });
      return newArray;
    });
    append({
      holidayName: t('business_hours.summer_holidays'),
      dates: {
        startDate: `${getDateFromDayOfYear(FIRST_OF_AUGUST)}`,
        endDate: `${getDateFromDayOfYear(FIFTEENTH_OF_AUGUST)}`,
      },
    });
  }, [append, t]);

  const handleDelete = useCallback(
    (index) => {
      setShowDatePicker((prev) => {
        const newArray = [...prev];
        newArray.splice(index, 1);
        return newArray;
      });
      remove(index);
    },
    [remove]
  );

  usePrompt(dirty && !updated, t('prompts.leave_warning'), undefined, onSubmit);

  const buttonText = useCallback(
    (index: number) => {
      if (values) {
        return moment(values[index]?.dates?.endDate).diff(
          values[index]?.dates.startDate,
          'days'
        ) === 0 ? (
          <>{moment(values[index]?.dates.startDate).format('MMM DD')}</>
        ) : (
          <>
            {moment(values[index]?.dates.startDate).format('MMM DD')}
            {values[index].dates.endDate !== null ? (
              <span>
                {' - '}
                {moment(values[index]?.dates.endDate).format('MMM DD')}
              </span>
            ) : null}
          </>
        );
      }
      <>
        {moment(getDateFromDayOfYear(FIRST_OF_AUGUST)).format('MMM DD')} -{' '}
        {moment(getDateFromDayOfYear(FIFTEENTH_OF_AUGUST)).format('MMM DD')}
      </>;
    },
    [values]
  );

  const render = useCallback(
    (tab: number) => {
      return (
        <>
          <TabPanel value={tab} index={0}>
            <div className={styles.content}>
              {isDefaultBusinessHour && (
                <span className={styles.message}>
                  {t('business_hours.message.first')}
                  <br /> {t('business_hours.message.second')}
                </span>
              )}
              {!isDefaultBusinessHour && (
                <>
                  <span className={styles.title}>name</span>
                  <form autoComplete="off">
                    <Controller
                      render={({ field: { onChange, value, ref } }) => {
                        return (
                          <InputBase
                            id="businessHourName"
                            placeholder={t('common.name_placeholder')}
                            fullWidth
                            sx={muiStyles.root}
                            error={!!errors.businessHourName}
                            onChange={onChange}
                            value={value}
                            inputRef={ref}
                            disabled={!canWrite}
                          />
                        );
                      }}
                      control={control}
                      name="businessHourName"
                      rules={businessHoursRules.businessHourName}
                    />
                  </form>
                  <div className={styles.error}>
                    {errors?.businessHourName?.message}
                  </div>
                </>
              )}
              <span className={styles.title}>
                {t('business_hours.timezone')}
              </span>
              <DropdownList
                optionClick={handleTimezoneClick}
                options={timezoneOptions()}
                size="xxlarge"
                searchBar
                selected={timezone.label}
                height="large"
                noGutter
                disabled={!canWrite}
              >
                {timezone.label}
              </DropdownList>

              <span className={styles.title}>
                {t('business_hours.schedule')}
              </span>

              {hours.map((hour, index) => {
                return (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${hour.frequency.label}_${hour.from.label}_${hour.to.label}_${index}`}
                  >
                    <div className={styles.time}>
                      <div className={styles.dropdown}>
                        <DropdownList
                          optionClick={handleFrequencyClick}
                          options={frequencyOptions(t)}
                          selected={hour.frequency.value}
                          renderValue
                          translationPrefix="business_hours.frequency."
                          height="large"
                          noGutter
                          size="large"
                          index={index}
                          disabled={!canWrite}
                        >
                          {t(
                            `business_hours.frequency.${hour.frequency.value}`
                          )}
                        </DropdownList>
                      </div>

                      <div className={styles.dropdown}>
                        <DropdownList
                          optionClick={handleFromClick}
                          options={startTimeOptions()}
                          selected={hour.from.label}
                          height="large"
                          noGutter
                          size="small"
                          index={index}
                          lowerCase
                          disabled={!canWrite}
                        >
                          {hour.from.label}
                        </DropdownList>
                      </div>

                      <span className={styles.to}>to</span>

                      <div className={styles.dropdown}>
                        <DropdownList
                          optionClick={handleToClick}
                          options={endTimeOptions(index)}
                          selected={hour.to.label}
                          height="large"
                          noGutter
                          size="small"
                          index={index}
                          lowerCase
                          disabled={!canWrite}
                        >
                          {hour.to.label}
                        </DropdownList>
                      </div>

                      <IconButton
                        ariaLabel={t('common.create')}
                        onClick={canWrite && handleAddClick}
                      >
                        <CirclePlusIcon
                          size={16}
                          color="var(--icon-default-gray)"
                        />
                      </IconButton>

                      {hours.length > 1 && (
                        <IconButton
                          ariaLabel={t('common.delete')}
                          onClick={() => handleRemoveClick(index)}
                        >
                          <CircleMinusIcon
                            size={16}
                            color="var(--icon-default-gray)"
                          />
                        </IconButton>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Holidays
              fields={fields}
              classes={muiStyles}
              handleCreate={handleCreate}
              handleDelete={handleDelete}
              control={control}
              errors={errors}
              setShowDatePicker={setShowDatePicker}
              showDatePicker={showDatePicker}
              buttonText={buttonText}
              focusedInput={focusedInput}
              setFocusedInput={setFocusedInput}
              handleCalendarSave={handleCalendarSave}
            />
          </TabPanel>
        </>
      );
    },
    [
      isDefaultBusinessHour,
      t,
      control,
      errors,
      handleTimezoneClick,
      timezoneOptions,
      timezone.label,
      canWrite,
      hours,
      fields,
      muiStyles,
      handleCreate,
      handleDelete,
      showDatePicker,
      buttonText,
      focusedInput,
      handleCalendarSave,
      handleFrequencyClick,
      handleFromClick,
      startTimeOptions,
      handleToClick,
      endTimeOptions,
      handleAddClick,
      handleRemoveClick,
    ]
  );
  return (
    <Layout>
      <BusinessHourHeader
        isLoading={
          addStatus === 'pending' || updateStatus === 'pending' || isSubmitting
        }
        handleSubmit={handleSubmit(onSubmit)}
        selectedTab={tabIndex}
        setTab={setTabIndex}
        disabled={!isValid || !dirty}
      />

      <PageContentWrapper readOnly={!canWrite} newPlain>
        {render(tabIndex)}
      </PageContentWrapper>
    </Layout>
  );
};

export default BusinessHour;
