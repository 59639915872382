import { PropsWithChildren, useEffect, useMemo, useRef } from 'react';

import Tooltip from '@mui/material/Tooltip/Tooltip';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import {
  BookmarkIcon,
  BookOpenTextIcon,
  CircleDotIcon,
  FileTextIcon,
  GlobeIcon,
  LibraryBigIcon,
  MegaphoneIcon,
  SplitIcon,
  ThumbsDownIcon,
  ThumbsUpIcon,
} from 'lucide-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import {
  NodeType,
  ReactionType,
  TryItCollection,
  UserMessage,
} from '@/models/tryIt';
import {
  ResponseTypes,
  useMessageNode,
} from '@/modules/TryIt/hooks/useMessageNode';
import { useResponseTypeFlags } from '@/modules/TryIt/hooks/useResponseTypeFlags';
import { iconByType, TYPES } from '@/redux/dialogs/helper';
import { isKeyEnter } from '@/util/util';

import styles from './MessageNode.module.scss';

type MessageNodeProps = {
  active: boolean;
  handleNodeClick: () => void;
  id?: string;
  brainId: string;
  collection?: TryItCollection;
  isComplete?: boolean;
  index: number;
  author_type?: string;
  resetLayout: () => void;
  messages: NodeType['messages'];
  time?: string;
  steps?: NodeType['steps'];
  broadcastId?: string;
  reaction: ReactionType;
  request_id: string;
};

const MAX_STEP_ICONS_SHOWN = 5;

const iconType = {
  brain: <CircleDotIcon size={12} />,
  collection: <BookmarkIcon size={12} />,
  disambiguation: <SplitIcon color="var)(--icon-default-blue)" size={12} />,
  unknown: <CircleDotIcon size={12} />,
  broadcast: <MegaphoneIcon size={12} />,
  knowledge: <LibraryBigIcon size={12} />,
} as const;

export const datasourceIconByType = {
  website: <GlobeIcon size={16} />,
  zendesk_kb: <BookOpenTextIcon size={16} />,
  intercom_kb: <BookOpenTextIcon size={16} />,
  files: <FileTextIcon size={16} />,
} as const;

const MessageNode = ({
  active = false,
  handleNodeClick,
  id,
  brainId,
  children,
  collection,
  isComplete,
  index,
  author_type,
  resetLayout,
  messages,
  time,
  steps,
  broadcastId,
  ...rest
}: PropsWithChildren<MessageNodeProps>) => {
  const node = cloneDeep({
    ...rest,
    active,
    id,
    collection,
    isComplete,
    author_type,
    messages,
    time,
    steps,
  });

  const {
    isBroadcastResponse,
    isAgentResponse,
    isBrainResponse,
    isUnknownResponse,
    isCollectionResponse,
    isDisambiguationResponse,
    isErrorResponse,
    isInstructionsResponse,
  } = useResponseTypeFlags({
    index,
    node,
  });

  const entitiesMatched = (messages?.[0] as UserMessage)?.entities?.length > 0;
  const responseTypeFlags = useMemo(
    () => ({
      isBroadcastResponse,
      isAgentResponse,
      isBrainResponse,
      isUnknownResponse,
      isCollectionResponse,
      isDisambiguationResponse,
      isErrorResponse,
      isInstructionsResponse,
    }),
    [
      isBroadcastResponse,
      isAgentResponse,
      isBrainResponse,
      isUnknownResponse,
      isCollectionResponse,
      isDisambiguationResponse,
      isErrorResponse,
      isInstructionsResponse,
    ]
  );
  const { title, icons, handleGoToNode } = useMessageNode(
    id,
    brainId,
    collection,
    index,
    author_type,
    steps,
    entitiesMatched,
    responseTypeFlags,
    broadcastId
  );
  const myRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  // This useEffect hook is used to observe changes in the size of the MessageNode component.
  // When the size (height in our case) of the element changes, the resetLayout function is called.
  useEffect(() => {
    const currentRef = myRef.current;
    const observer = new ResizeObserver(() => {
      resetLayout();
    });

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [resetLayout]);

  const isThumbsUp = rest.reaction === '1';
  const isThumbsDown = rest.reaction === '-1';

  return (
    <div
      id={rest.request_id}
      ref={myRef}
      className={cn({
        [styles.wrapper]: true,
        [styles.active]: active,
      })}
      onClick={handleNodeClick}
      onKeyDown={(e) => {
        if (isKeyEnter(e)) handleNodeClick();
      }}
      role="button"
      tabIndex={0}
    >
      <div className={styles.heading}>
        <div
          className={cn(styles.title, {
            [styles.isKnowledge]: title?.type === ResponseTypes.KNOWLEDGE,
          })}
          onClick={handleGoToNode}
          onKeyDown={(e) => {
            if (isKeyEnter(e)) handleGoToNode();
          }}
          tabIndex={0}
          role="link"
        >
          {iconType[title?.type]}
          <Typography
            className={styles.title__text}
            variant="label-regular"
            color="inherit"
          >
            {title.name}
          </Typography>
        </div>

        <div className={styles.stepIcons}>
          {icons?.length > 0 && (
            <>
              {/* Brain icons */}
              {isBrainResponse && (
                <>
                  {icons?.slice(0, MAX_STEP_ICONS_SHOWN).map((icon, index) => (
                    <Tooltip
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${JSON.stringify(icon)}-${index}`}
                      arrow
                      title={
                        icon === 'entity'
                          ? t('common.entity')
                          : t(`actions.types.${icon}`)
                      }
                    >
                      <span>
                        {iconByType({
                          id: icon,
                          size: 16,
                          type: TYPES.ACTION,
                          color: 'var(--icon-default-gray)',
                          stepsMode: true,
                        })}
                      </span>
                    </Tooltip>
                  ))}

                  {icons?.length > MAX_STEP_ICONS_SHOWN && (
                    <Tooltip
                      arrow
                      title={t('try_it.extra_steps', {
                        0: icons.length - MAX_STEP_ICONS_SHOWN,
                        count: icons.length - MAX_STEP_ICONS_SHOWN,
                      })}
                    >
                      <Typography variant="label-regular" color="inherit">
                        +{icons.length - MAX_STEP_ICONS_SHOWN}
                      </Typography>
                    </Tooltip>
                  )}
                </>
              )}

              {/* Collection icons */}
              {isCollectionResponse && (
                <>
                  {icons.map((icon) => (
                    <Tooltip
                      key={icon}
                      arrow
                      title={t(`try_it.datasource_icons.${icon}`)}
                    >
                      <span>{datasourceIconByType[icon]}</span>
                    </Tooltip>
                  ))}
                </>
              )}
            </>
          )}
        </div>
      </div>
      {children}

      {isThumbsUp && (
        <ThumbsUpIcon
          color="var(--icon-default-success)"
          className={styles.thumbIcon}
        />
      )}
      {isThumbsDown && (
        <ThumbsDownIcon
          color="var(--icon-default-error)"
          className={styles.thumbIcon}
        />
      )}

      {time && (
        <Typography
          variant="label-regular"
          color="var(--text-default-gray)"
          component="p"
          className={cn(styles.timestamp, styles[author_type])}
        >
          <span className={styles.timestamp__content}>
            <span>{moment.utc(time).local().format('MM/DD/YYYY')}</span>
            <span>
              {', '}
              {moment.utc(time).local().format('HH:mm')}
            </span>
          </span>
        </Typography>
      )}
    </div>
  );
};

export default MessageNode;
