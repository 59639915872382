import { useCallback, useEffect, useState } from 'react';

import cn from 'classnames';
import { CirclePlusIcon } from 'lucide-react';
import { FieldErrors, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Accordion from '@/components/atoms/Accordion/Accordion';
import { Banner } from '@/components/atoms/Banner/Banner';
import Button from '@/components/atoms/Button/Button/Button';
import TestNumber from '@/components/atoms/TestNumber/TestNumber';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { useIntegrations } from '@/hooks/useIntegrations';
import usePrompt from '@/hooks/usePrompt';
import useWhatsappTestingSettings from '@/hooks/useWhatsappTestingSettings';
import { ApiError } from '@/models/api';
import {
  AccordionFieldsetProps,
  TestNumber as TestNumberType,
  WhatsAppIntegration,
} from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { addErrorTemporalToast } from '@/modules/notifications/redux/actions';
import { setDirty, setExpanded } from '@/redux/integrations/actions';
import { selectAccordion } from '@/redux/integrations/selectors';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountSlug } from '@/redux/session/selectors';
import { resolveBrainsPath } from '@/util/util';
import { phoneRegExpInline } from '@/util/validator';

import styles from './WhatsappTesting.module.scss';

type Form = {
  test_numbers: TestNumberType[];
};

const WhatsappTesting = ({
  integration,
  toggleAccordion,
  type,
  registerAccordion,
}: AccordionFieldsetProps<Form, WhatsAppIntegration>) => {
  const { t } = useTranslation();
  const slug = useSelector(selectAccountSlug);
  const { expanded } = useSelector(selectAccordion);
  const [isBannerDismissed, setIsBannerDismissed] = useState(false);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );
  const dispatch = useDispatch();
  const { ai_agents } = useFeatureFlag();
  const { updateIntegration, updateStatus } =
    useIntegrations<WhatsAppIntegration>(
      integration?.desk_id,
      integration?.integration_id
    );

  const { ruleToBrain, updateConnectedRules, deleteUnconnectedRules } =
    useWhatsappTestingSettings(
      integration?.desk_id,
      integration?.integration_id
    );

  const formMethods = useForm<Form>({
    mode: 'onSubmit',
  });

  const {
    register,
    control,
    reset,
    formState: { errors, isDirty, isValid },
    handleSubmit,
    trigger,
    getValues,
    setError,
  } = formMethods;

  const { fields, prepend, remove } = useFieldArray({
    name: 'test_numbers',
    control,
  });

  useEffect(() => {
    reset({
      test_numbers: integration?.config?.test_numbers || [],
    });
  }, [integration?.config, reset]);

  const handleUpdateIntegration = useCallback(
    (values: Form) => {
      updateIntegration(
        {
          ...integration,
          config: {
            ...integration.config,
            ...values,
          },
          mutationOptions: { hideErrors: true },
        },
        {
          onSuccess: () => {
            updateConnectedRules(values.test_numbers);
            deleteUnconnectedRules(integration, values.test_numbers);

            if (expanded === type) {
              dispatch(setExpanded(false));
            }
          },
          onError: (error) => {
            const err = error as unknown as ApiError;
            if (err.statusCode === 409) {
              const matches = phoneRegExpInline.exec(err.message);
              if (matches?.length > 0) {
                const errorNumber = matches[0];
                const test_number = getValues('test_numbers');
                const errIndex = test_number?.findIndex(
                  (tn) => tn.phone_number === errorNumber
                );
                setError(
                  `test_numbers.${errIndex}.phone_number`,
                  {
                    type: 'custom',
                    message: t(
                      'integrations.whatsapp.testing.num_conflict_error'
                    ),
                  },
                  { shouldFocus: true }
                );
                return;
              }
              dispatch(addErrorTemporalToast(error));
            }
            dispatch(addErrorTemporalToast(error));
          },
        }
      );
    },
    [
      deleteUnconnectedRules,
      dispatch,
      expanded,
      getValues,
      integration,
      setError,
      t,
      type,
      updateConnectedRules,
      updateIntegration,
    ]
  );

  usePrompt(
    isDirty,
    undefined,
    undefined,
    handleSubmit(handleUpdateIntegration)
  );

  useEffect(() => {
    dispatch(setDirty(isDirty));
  }, [dispatch, isDirty]);

  // for reseting changes
  useEffect(() => {
    if (expanded !== type && isDirty) {
      reset();
    }
  }, [expanded, isDirty, reset, type]);

  return (
    <>
      <Accordion
        ref={registerAccordion(
          type,
          handleSubmit(handleUpdateIntegration),
          trigger
        )}
        title={t('integrations.whatsapp.testing.title')}
        subtitle={t('integrations.whatsapp.testing.subtitle')}
        onSubmit={handleSubmit(handleUpdateIntegration)}
        disabled={!isDirty || !isValid}
        isLoading={updateStatus === 'pending'}
        expanded={expanded === type}
        readOnly={!canWrite}
        handleChange={toggleAccordion(type)}
      >
        <div
          className={cn(styles.banner, {
            [styles.dismissed]: isBannerDismissed,
          })}
        >
          <Banner
            padding="regular"
            relativePosition
            dismissable
            onDismiss={() => setIsBannerDismissed(true)}
          >
            {t('integrations.whatsapp.testing.banner')}
          </Banner>
        </div>
        <TitleSubtitle
          title={t('integrations.whatsapp.testing.text1')}
          subtitle={t('integrations.whatsapp.testing.text2')}
        />
        <Button
          size="small"
          variant="tertiary"
          disabled={
            getValues('test_numbers')?.length >= 4 || !canWrite || !isValid
          }
          onClick={() => {
            prepend({ label: '', phone_number: '' });
          }}
        >
          <CirclePlusIcon
            size={16}
            color={
              getValues('test_numbers')?.length < 4 && canWrite && isValid
                ? 'var(--icon-default-blue)'
                : undefined
            }
          />
          {t('integrations.whatsapp.testing.add_num')}
        </Button>
        {fields.map((field, index) => {
          const brainInfo = field.rule_id
            ? ruleToBrain[field.rule_id]
            : undefined;
          return (
            <TestNumber
              key={field.id}
              readOnly={!canWrite}
              register={register}
              control={control}
              prefix={`test_numbers.${index}.`}
              errors={errors.test_numbers?.[index] as FieldErrors<Form>}
              onDelete={() => remove(index)}
              brainName={brainInfo?.name}
              brainLink={resolveBrainsPath(
                `/${slug}/brains/${brainInfo?.id}`,
                ai_agents
              )}
            />
          );
        })}
      </Accordion>
    </>
  );
};

export default WhatsappTesting;
