import { ReactNode, useRef, useState } from 'react';

import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { ArrowDownIcon, ArrowUpIcon, CloudDownloadIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useClickAway } from 'react-use';

import Checkbox from '@/components/atoms/Checkbox/Checkbox/Checkbox';
import HelpTooltip from '@/components/atoms/HelpTooltip/HelpTooltip';
import IconButton from '@/components/atoms/IconButton/IconButton';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import { getGrowthArrowColor } from '@/util/analytics';
import { delay } from '@/util/util';

import ExportDropdown from '../../AnalyticsFieldset/AnalyticsTitle/ExportDropdown';

import styles from './ScoreCard.module.scss';

interface Props {
  title: string;
  tooltip?: string;
  counts?: number | string;
  exactCounts?: number;
  growth?: number;
  active: boolean;
  onClick: () => void;
  isPreviousLoading?: boolean;
  data?: unknown;
}

const ScoreCard = ({
  title,
  tooltip,
  counts,
  exactCounts,
  growth,
  active,
  onClick,
  isPreviousLoading,
}: Props) => {
  const arrowColor = getGrowthArrowColor(growth);

  return (
    <div
      className={cn(styles.scorecard, {
        [styles.active]: !!active,
      })}
      key={title}
      aria-label={title}
      tabIndex={0}
      role="button"
      onClick={onClick}
      onKeyUp={onClick}
    >
      <Typography
        component="div"
        variant="label-semi-bold"
        className={styles.label}
      >
        {title} {tooltip && <HelpTooltip tooltip={tooltip} />}
      </Typography>
      {counts ? (
        <Typography component="p" variant="subheading-semi-bold">
          <Tooltip arrow title={exactCounts !== counts ? exactCounts : ''}>
            <span> {counts}</span>
          </Tooltip>
        </Typography>
      ) : (
        <Skeleton animation="pulse" width={50} />
      )}
      {isPreviousLoading ? (
        <span>
          <Skeleton height={24} width={80} />
        </span>
      ) : (
        typeof growth === 'number' && (
          <Typography
            component="p"
            variant="label-regular"
            className={cn(styles.percentage, {
              [styles.success]: growth > 0,
              [styles.error]: growth < 0,
            })}
          >
            {growth < 0 ? (
              <ArrowDownIcon size={10} color={arrowColor} />
            ) : (
              <ArrowUpIcon size={10} color={arrowColor} />
            )}
            {growth}%
          </Typography>
        )
      )}
    </div>
  );
};
export default ScoreCard;

export const ScoreCards = ({
  children,
  onPreviousClick,
  showPrevious,
  data,
}: {
  children?: ReactNode;
  onPreviousClick: () => void;
  showPrevious: boolean;
  data?: unknown;
}) => {
  const { t } = useTranslation();
  const exportRef = useRef<HTMLDivElement>();
  const [showExport, setShowExport] = useState(false);

  useClickAway(exportRef, async () => {
    await delay(100);
    setShowExport(false);
  });

  return (
    <div className={styles.scorecards}>
      <div className={styles.exportContainer} ref={exportRef}>
        {
          <IconButton
            onClick={() => setShowExport((prev) => !prev)}
            tooltip={t('common.export')}
            disabled={!data}
            ariaLabel={t('common.export')}
          >
            <CloudDownloadIcon color="var(--icon-default-blue)" size={20} />
          </IconButton>
        }
        {showExport && (
          <ExportDropdown
            className={styles.dropdown}
            setShow={setShowExport}
            data={data}
          />
        )}
      </div>
      {onPreviousClick && (
        <div className={styles.checkbox}>
          <Checkbox
            label={<TitleSubtitle subtitle={t('analytics.compare_prev')} />}
            onChange={onPreviousClick}
            checked={showPrevious}
            sameLevel
          />
        </div>
      )}
      {children}
    </div>
  );
};
