import { SmileIcon } from 'lucide-react';

import { StyleButton } from './StyleButton';

export const EmojiButton = ({ onClick }) => {
  return (
    <StyleButton
      type="emoji"
      Icon={() => <SmileIcon size={18} color="var(--icon-default-black)" />}
      onClick={onClick}
    />
  );
};
