import { memo, useCallback } from 'react';

import Typography from '@mui/material/Typography';
import isEmpty from 'lodash/isEmpty';
import { SquarePenIcon, Trash2Icon, WebhookIcon } from 'lucide-react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { Banner } from '@/components/atoms/Banner/Banner';
import InlineStatus from '@/components/atoms/InlineStatus/InlineStatus';
import {
  MODAL_DELETE,
  MODAL_WARN,
} from '@/components/organisms/Modals/ModalConductor';
import { useAccount } from '@/hooks/useAccount';
import useDialogs from '@/hooks/useDialogs';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import useWebhooks from '@/hooks/useWebhooks';
import { actions } from '@/models/permissions';
import { EventName } from '@/models/segment';
import { RootState } from '@/models/state';
import { Webhook as WebhookType } from '@/models/webhook';
import { popModal, pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { trackEvent } from '@/segment/segment';
import { renderCroppedURL, resolveBrainsPath } from '@/util/util';

import TileMenu from './TileMenu';

import styles from './TileWebhook.module.scss';

interface Props {
  brainId: string;
  webhook: WebhookType;
  hasActions?: boolean;
}
const TileWebhook = ({ brainId, webhook, hasActions = false }: Props) => {
  const navigate = useNavigate();
  const { webhook_id, name, url, updated } = webhook;
  const { t } = useTranslation();
  const { deleteWebhook } = useWebhooks(brainId);
  const { findConnectedDialogsForWebhooks } = useDialogs(brainId);
  const { slug } = useAccount();
  const { ai_agents } = useFeatureFlag();

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  const dispatch = useDispatch();

  const handleDialogNameClick = useCallback(
    (dialog_id: string) => {
      dispatch(popModal());
      navigate(
        resolveBrainsPath(
          `/${slug}/brains/${brainId}/dialogs/${dialog_id}`,
          ai_agents
        )
      );
    },
    [ai_agents, brainId, dispatch, navigate, slug]
  );

  const menuItems = [
    {
      name: t('common.edit'),
      icon: <SquarePenIcon size={16} />,
      onClick: () => {
        trackEvent(EventName.ClickEditWebhook, { webhook_id });
        navigate(
          resolveBrainsPath(
            `/${slug}/brains/${brainId}/webhooks/${webhook_id}`,
            ai_agents
          )
        );
      },
    },
    {
      name: t('common.delete'),
      type: 'delete',
      icon: <Trash2Icon size={16} />,
      onClick: () => {
        trackEvent(EventName.ClickDeleteWebhook, { webhook_id });
        const usedDialogs = findConnectedDialogsForWebhooks(webhook_id);

        if (!isEmpty(usedDialogs)) {
          const warning = (
            <Banner
              relativePosition
              variant="critical"
              references={usedDialogs}
              onRefClick={handleDialogNameClick}
              referenceProp="url"
            >
              <Trans i18nKey="webhook.delete_warning" values={[name]} />
            </Banner>
          );
          const warnProps = {
            title: t('common.warning'),
            children: warning,
            primaryButtonText: t('common.close'),
          };

          dispatch(pushModal(MODAL_WARN, warnProps));
        } else {
          const deleteProps = {
            title: t('webhook.delete_webhook'),
            subtitle: (
              <Trans
                i18nKey="webhook.delete_warning_with_name"
                values={[name]}
              />
            ),
            onDelete: () => {
              deleteWebhook(webhook_id);
            },
          };
          dispatch(pushModal(MODAL_DELETE, deleteProps));
        }
      },
    },
  ];

  const renderTimestamp = useCallback(() => {
    if (!updated) {
      return null;
    }

    return (
      <span>{`${t('common.last_updated')}: ${moment(updated).fromNow()}`}</span>
    );
  }, [t, updated]);

  return (
    <div className="tile tile__preview">
      <Link
        aria-label={name}
        to={resolveBrainsPath(
          `/${slug}/brains/${brainId}/webhooks/${webhook_id}`,
          ai_agents
        )}
      >
        <div className={styles.wrapper}>
          <div className={styles.icon}>
            <WebhookIcon color="var(--icon-default-gray)" size={20} />
          </div>
          <div className={styles.information}>
            <div className={styles.menu}>
              {canWrite && <TileMenu menuItems={menuItems} />}
            </div>
            <Typography
              variant="subheading-semi-bold"
              color="var(--text-default-gray)"
              component="p"
              mb="var(--space-4)"
            >
              {name}
            </Typography>

            <p className="tile__item-name">
              <Typography variant="body-regular">
                {t('webhook.url')}{' '}
                <span className="tile__item-value">
                  {renderCroppedURL(url)}
                </span>
              </Typography>
            </p>

            <span className={styles.footer}>
              <Typography
                className={styles.last_updated}
                variant="label-caps-large"
              >
                {renderTimestamp()}
              </Typography>
              <span className={styles.details}>
                {hasActions && (
                  <InlineStatus
                    color="var(--icon-default-warning)"
                    value="2"
                    text={t('brains.actions_required')}
                  />
                )}
              </span>
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default memo(TileWebhook);
