import { useState, useCallback, useEffect, useRef, useMemo } from 'react';

import { ChevronDownIcon, PlayIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useClickAway } from 'react-use';

import Button from '@/components/atoms/Button/Button/Button';
import CheckBoxTable from '@/components/atoms/CheckBoxTable/CheckBoxTable';
import { StarFilledIcon } from '@/components/atoms/Icons/StarFilledIcon';
import Link from '@/components/atoms/Link/Link';
import { Rating } from '@/components/atoms/Rating';
import Table from '@/components/atoms/Table/Table';
import { useAccount } from '@/hooks/useAccount';
import useBrains from '@/hooks/useBrains';
import useDesks from '@/hooks/useDesks';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { useModalTryIt } from '@/hooks/useModalTryIt';
import { RootState } from '@/models/state';
import AnalyticsFieldset from '@/modules/analytics/components/fieldsets/AnalyticsFieldset/AnalyticsFieldset';
import { addLastSeen } from '@/modules/analytics/redux/actions';
import { convertTimestampToDate } from '@/util/analytics';
import { resolveBrainsPath, sortColumns } from '@/util/util';

import useCustomerRemarks from '../../../hooks/useCustomerRemarks';
import DropdownTemplate from '../../filters/DropdownTemplate';

import styles from './CustomerRemarks.module.scss';

type Item = {
  text: string;
  checked?: boolean;
  icon?: React.ReactNode;
  value?: string;
  hidden?: boolean;
};

export const getRatingOptions = (formatedRatings: number[]) => [
  {
    text: '1',
    icon: <Rating stars={1} />,
    checked: formatedRatings?.includes(1),
  },
  {
    text: '2',
    icon: <Rating stars={2} />,
    checked: formatedRatings?.includes(2),
  },
  {
    text: '3',
    icon: <Rating stars={3} />,
    checked: formatedRatings?.includes(3),
  },
  {
    text: '4',
    icon: <Rating stars={4} />,
    checked: formatedRatings?.includes(4),
  },
  {
    text: '5',
    icon: <Rating stars={5} />,
    checked: formatedRatings?.includes(5),
  },
];

const CustomerRemarks = () => {
  const { t } = useTranslation();
  const { brains } = useBrains();
  const { desks } = useDesks();
  const dispatch = useDispatch();
  const lastSeen = useSelector((state: RootState) => state.analytics.lastSeen);

  const { slug } = useAccount();

  const [urlParams] = useSearchParams();
  const sessionId = urlParams.get('session_id');
  const [showItems, setShowItems] = useState(false);
  const [formatedRatings, setFormatedRatings] = useState<number[]>();
  const checkedItemsRef = useRef<Item[]>();
  const { handleAccountReplayClick } = useModalTryIt(sessionId, true);
  const ratingsRef = useRef();
  const { ai_agents } = useFeatureFlag();

  const title = t('analytics.customer_remarks');

  useClickAway(ratingsRef, () => {
    if (checkedItemsRef.current) {
      const items = checkedItemsRef.current.reduce(
        (acc, item) => (item.checked ? [...acc, Number(item.text)] : acc),
        []
      );
      setFormatedRatings(items);
    }

    setShowItems(false);
  });

  useEffect(() => {
    if (sessionId) {
      handleAccountReplayClick();
    }
  }, [handleAccountReplayClick, sessionId]);

  const toggleItems = useCallback(() => {
    setShowItems(true);
  }, []);

  const handleItemsChange = useCallback((items) => {
    checkedItemsRef.current = items;
  }, []);

  const handleReplayClick = useCallback(
    (value) => {
      handleAccountReplayClick(value);
      if (!(lastSeen[lastSeen.length - 1] === value)) {
        dispatch(addLastSeen(value));
      }
    },
    [dispatch, handleAccountReplayClick, lastSeen]
  );

  const columns = useMemo(() => {
    return [
      {
        Header: t('analytics.customer'),
        accessor: 'name',
      },
      {
        Header: t(`analytics.${ai_agents ? 'agent_ai_agent' : 'agent_brain'}`),
        accessor: 'agent_name',
        sortType: sortColumns,
        Cell: ({ value }) => {
          const brain = brains?.find((brain) => brain.brain_id === value);
          if (brain) {
            return (
              <Link
                href={resolveBrainsPath(
                  `/${slug}/brains/${brain.brain_id}`,
                  ai_agents
                )}
                external
              >
                {brain.name}
              </Link>
            );
          } else {
            return value;
          }
        },
      },
      {
        Header: t('analytics.rating'),
        accessor: 'rating',
        Cell: ({ value }) => (
          <span className={styles.rating}>
            {`${parseFloat(value.toFixed(1))}`}
            <span>
              <StarFilledIcon size={16} color="var(--icon-default-gray)" />
            </span>
          </span>
        ),
      },
      {
        Header: t('common.environment'),
        accessor: 'desk_id',

        Cell: ({ value }) => {
          const desk = desks?.find((desk) => desk.desk_id === value);
          if (desk) {
            return (
              <Link href={`/${slug}/environments/${desk.desk_id}`} external>
                {desk.name}
              </Link>
            );
          } else {
            return t('analytics.deleted_env');
          }
        },
      },
      {
        Header: 'feedback',
        accessor: 'feedback',
      },
      {
        Header: t('common.date'),
        accessor: 'time',
        Cell: ({ value }) => {
          return <span key={value}>{convertTimestampToDate(value)}</span>;
        },
      },
      {
        Header: t('common.conversation'),
        accessor: 'session_id',
        disableSortBy: true,
        Cell: ({ value }) => {
          const isRecentlySeen = lastSeen.includes(value);

          return (
            <div className={styles.replayButton}>
              <Button
                onClick={() => handleReplayClick(value)}
                variant={isRecentlySeen ? 'secondary' : 'primary'}
              >
                <PlayIcon
                  size={16}
                  color={
                    isRecentlySeen
                      ? 'var(--icon-default-blue)'
                      : 'var(--icon-default-white)'
                  }
                />
              </Button>
            </div>
          );
        },
      },
    ];
  }, [ai_agents, brains, desks, handleReplayClick, lastSeen, slug, t]);

  const { data, isLoading, error, refetch } =
    useCustomerRemarks(formatedRatings);

  const getRatingMessage = useCallback(() => {
    if (
      !formatedRatings ||
      formatedRatings.length === 0 ||
      formatedRatings.length === 5
    ) {
      return t('analytics.all_ratings');
    }
    if (formatedRatings?.length === 1) {
      return `${formatedRatings[0]} ${t('analytics.stars')}`;
    }
    return t('common.custom');
  }, [formatedRatings, t]);

  return (
    <AnalyticsFieldset
      fullWidth
      title={title}
      hasFooter={false}
      isLoading={isLoading}
      isEmpty={!isLoading && !error && !data}
      error={error}
      onReload={refetch}
      data={data}
    >
      <div className={styles.checkbox}>
        <Button variant="secondary" size="small" onClick={toggleItems}>
          {getRatingMessage()}

          <ChevronDownIcon size={16} color={'var(--icon-default-gray)'} />
        </Button>
        {showItems && (
          <div className={styles.dropdown} ref={ratingsRef}>
            <DropdownTemplate size="full" zeroPadding>
              <CheckBoxTable
                title={t('analytics.ratings')}
                items={getRatingOptions(formatedRatings)}
                onlyIcon
                disableCollapse
                onItemsChecked={handleItemsChange}
              />
            </DropdownTemplate>
          </div>
        )}
      </div>

      {data && (
        <div className={styles.container}>
          <Table
            data={data}
            columns={columns}
            sortable
            filterable={data.length > 10}
            sortBy="time"
          />
        </div>
      )}
    </AnalyticsFieldset>
  );
};

export default CustomerRemarks;
