import { ReactNode, useMemo, useState } from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { SearchIcon } from 'lucide-react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useTranslation } from 'react-i18next';

import Input from '../Input/Input';

import styles from './SearchList.module.scss';

type SearchListProps<T> = {
  items: T[];
  itemTemplate?: (props: T) => ReactNode;
  accessor?: keyof T;
};

export const SearchList = <T,>({
  items,
  itemTemplate,
  accessor,
}: SearchListProps<T>) => {
  const { t } = useTranslation();
  const [valueSearch, setValueSearch] = useState('');

  const handleOnChange = (e) => {
    const { value } = e.target;
    setValueSearch(value);
  };
  const filteredItems = useMemo(
    () =>
      items.filter((item) => {
        const value = item[accessor] as unknown as string;
        return value?.toLowerCase().includes(valueSearch.toLowerCase());
      }),
    [items, accessor, valueSearch]
  );
  return (
    <div className={styles.container}>
      <Input
        iconLeft={<SearchIcon size={16} color="var(--icon-default-gray)" />}
        placeholder={t('common.search')}
        onChange={handleOnChange}
        spellCheck={false}
      />
      <Scrollbars autoHide>
        <List>
          {filteredItems.map((item, index) => (
            <ListItem
              key={String(index) + String(item[accessor])}
              disablePadding
            >
              {itemTemplate?.(item)}
            </ListItem>
          ))}
        </List>
      </Scrollbars>
    </div>
  );
};
