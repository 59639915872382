import { TriangleAlertIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import styles from './Webhook.module.scss';

const Warning = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.warning}>
      <TriangleAlertIcon color="var(--icon-default-gray)" size={64} />
      <span>{t('webhook.test.warning')}</span>
    </div>
  );
};

export default Warning;
