import { CircleUserRoundIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/atoms/Button/Button/Button';
import fs from '@/components/atoms/Fieldset';
import { Invitation, RespondInvitation } from '@/models/member';
import { systemRoles } from '@/util/constants';

interface InvitationFieldsetProps {
  invitation: Invitation;
  respondInvitation: (invitationData: RespondInvitation) => void;
}

const InvitationFieldset = ({
  invitation,
  respondInvitation,
}: InvitationFieldsetProps) => {
  const { t } = useTranslation();
  const {
    role_ids,
    account_id,
    account_name,
    account_avatar,
    invitation_code,
  } = invitation;

  const hasCustomRole = role_ids.some((id) => !systemRoles.includes(id));

  const roles = role_ids.map((r) => t(`roles.${r}.name`)).join(', ');

  return (
    <fs.Fieldset
      key={account_name}
      title={t('invitations.you_are_invited', { 0: account_name })}
      subtitle={
        role_ids.length > 0 &&
        !hasCustomRole &&
        t('invitations.invitation_subtitle', { 0: roles })
      }
    >
      <div className="fieldset__avatar">
        {account_avatar ? (
          <img src={account_avatar} alt="avatar" referrerPolicy="no-referrer" />
        ) : (
          <CircleUserRoundIcon color="var(--icon-default-gray)" size={48} />
        )}
      </div>
      <fs.Footer>
        <fs.FooterStatus>
          <Button
            variant="tertiary"
            onClick={() =>
              respondInvitation({
                invitation_code,
                account_id,
                action: 'cancel',
              })
            }
          >
            {t('common.dismiss')}
          </Button>
        </fs.FooterStatus>
        <fs.FooterAction>
          <Button
            onClick={() =>
              respondInvitation({
                invitation_code,
                account_id,
                action: 'accept',
              })
            }
          >
            {t('common.accept')}
          </Button>
        </fs.FooterAction>
      </fs.Footer>
    </fs.Fieldset>
  );
};

export default InvitationFieldset;
