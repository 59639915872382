import { memo, useCallback, useState } from 'react';

import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { AlertTriangleIcon } from 'lucide-react';
import { useDrag } from 'react-dnd';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import { Banner } from '@/components/atoms/Banner/Banner';
import ActionPlaceholder from '@/components/organisms/Dialogs/Action/Placeholder/Placeholder';
import ConditionPlaceholder from '@/components/organisms/Dialogs/ConditionPlaceholder/ConditionPlaceholder';
import DialogTooltip from '@/components/organisms/Dialogs/DialogTooltip/DialogTooltip';
import BranchList from '@/components/organisms/Dialogs/Node/BranchList/BranchList';
import NodePlaceholder from '@/components/organisms/Dialogs/Node/Placeholder/Placeholder';
import RequisitePlaceholder from '@/components/organisms/Dialogs/Node/RequisitePlaceholder';
import {
  MODAL_DELETE,
  MODAL_WARN,
} from '@/components/organisms/Modals/ModalConductor';
import useBrains from '@/hooks/useBrains';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import useLocalStorage from '@/hooks/useLocalStorage';
import useNodesConnected from '@/hooks/useNodesConnected';
import useToolkitControls from '@/hooks/useToolkitControls';
import { selectHasError } from '@/redux/dialogAlerts/selectors';
import {
  TYPES,
  isFinalAction,
  languageSupportsEnhance,
} from '@/redux/dialogs/helper';
import { nodeSelector } from '@/redux/dialogs/selectors';
import { popModal, pushModal } from '@/redux/modals/actions';
import { removeNode, selectNode, convertNode } from '@/redux/nodes/actions';
import {
  selectIsIdInFlow,
  selectIsNodeConditionRequisiteSelected,
} from '@/redux/nodes/selectors';
import {
  resolveBrainsPath,
  isKeyEnter,
  preventClickThrough,
} from '@/util/util';

import ConditionList from './Conditions/ConditionList/ConditionList';
import Intent from './Intent';
import RequisiteList from './RequisiteList';
import ToolkitControls from './ToolkitControls/ToolkitControls';
import ActionList from '../Action/ActionList';
import Box from '../Box/Box';
import NodeHeader from '../NodeHeader/NodeHeader';

import styles from './Node.module.scss';

type Props = {
  nodeId: string;
  showBranch?: boolean;
  internal?: boolean;
};

const Node = memo(({ nodeId, showBranch = false, internal = false }: Props) => {
  const { t } = useTranslation();
  const { slug, brainId } = useParams();
  const navigate = useNavigate();
  const { findUsedNodes } = useNodesConnected();
  const dispatch = useDispatch();
  const hasDialogError = useSelector(selectHasError(nodeId));
  const location = useLocation();
  const isDialogPreview = location.pathname.endsWith('/setup');

  const { brain } = useBrains(brainId);
  const [hideAIToooltip, setHideAIToooltip] = useLocalStorage(
    'enhance_response',
    false
  );
  const { ai_agents, enhance_response } = useFeatureFlag();

  const {
    onZoomInClick,
    onZoomOutClick,
    onZoomResetClick,
    zoomPercentage,
    container,
  } = useToolkitControls();

  const {
    name,
    type,
    parentId,
    showPlaceholder,
    isSelected,
    intent,
    node,
    lastActionType,
    isRootNodeUnknown,
    hasConditions,
  } = useSelector((state) => nodeSelector(state, nodeId), isEqual);

  const isActionInFlow = useSelector(selectIsIdInFlow(nodeId));
  const isAnythingSelected = useSelector(
    selectIsNodeConditionRequisiteSelected
  );

  // Checks if node is the first node , id the user has hidden the tooltip, and if the language supports the enhancement
  const [showAITooltip, setShowAITooltip] = useState(
    !hideAIToooltip &&
      !parentId &&
      languageSupportsEnhance(brain?.language) &&
      ai_agents &&
      enhance_response
  );

  // If the node has no conditions, it is the last node in the flow
  const isLast = !hasConditions;

  const [{ isDragging }, dragRef] = useDrag({
    item: { nodeId, move: true, parentId },
    type: TYPES.NODE,
    canDrag: () => Boolean(parentId) && !node.isReminderNode,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      preventClickThrough(e);
      dispatch(selectNode({ nodeId }));
    },
    [dispatch, nodeId]
  );
  const handleDialogNameClick = useCallback(
    (dialog_id: string) => {
      dispatch(popModal());
      navigate(
        resolveBrainsPath(
          `/${slug}/brains/${brainId}/dialogs/${dialog_id}`,
          ai_agents
        )
      );
    },
    [ai_agents, brainId, dispatch, navigate, slug]
  );

  const onDelete = useCallback(() => {
    const usedNodes = findUsedNodes(nodeId);

    if (usedNodes && !isEmpty(usedNodes)) {
      const warning = (
        <Banner
          relativePosition
          variant="critical"
          references={usedNodes}
          onRefClick={handleDialogNameClick}
        >
          <Trans i18nKey="dialog.delete_node" values={[name]} />
        </Banner>
      );
      const warnProps = {
        title: t('common.warning'),
        children: warning,
        primaryButtonText: t('common.close'),
      };

      dispatch(pushModal(MODAL_WARN, warnProps));
    } else {
      const deleteProps = {
        title: t('dialog.delete_node_title'),
        subtitle: <Trans i18nKey="dialog.delete_node_warn" values={[name]} />,
        onDelete: () => {
          dispatch(removeNode({ nodeId }));
          dispatch(popModal());
        },
      };

      dispatch(pushModal(MODAL_DELETE, deleteProps));
    }
  }, [dispatch, findUsedNodes, handleDialogNameClick, name, nodeId, t]);

  const onKeyUp = useCallback(
    (e) => {
      if (isRootNodeUnknown) {
        return;
      }
      preventClickThrough(e);
      if (isKeyEnter(e)) {
        dispatch(selectNode({ nodeId }));
      } else if (e.key === 'Delete') {
        onDelete();
      }
    },
    [dispatch, isRootNodeUnknown, nodeId, onDelete]
  );

  const handleIntentNavigate = useCallback(() => {
    navigate(
      resolveBrainsPath(
        `/${slug}/brains/${brainId}/intents/${intent}`,
        ai_agents
      )
    );
  }, [ai_agents, brainId, intent, navigate, slug]);

  const handleTriggerConvert = useCallback(() => {
    dispatch(convertNode({ nodeId, node }));
  }, [dispatch, node, nodeId]);

  // This will prevent the reminder nodes to have all the interactivity
  const clickableProps = !node.isReminderNode
    ? { role: 'button', tabIndex: 0, onKeyUp, onClick }
    : {};
  const handleAITooltipDismiss = () => {
    setShowAITooltip(false);
    setHideAIToooltip(true);
  };

  return (
    <>
      <section
        id={nodeId}
        className={cn(styles.node, {
          [styles['node--preview']]: isDialogPreview,
          [styles['node--reminder']]: node.isReminderNode,
        })}
        ref={container}
        // TODO: Remove this in React 19
        // Reference: https://mayank.co/notes/inert-in-react/
        {...{ inert: isDialogPreview ? 'true' : undefined }}
      >
        {!parentId && !isRootNodeUnknown && <NodePlaceholder />}
        <DialogTooltip
          onNavigate={type === 'intent' ? handleIntentNavigate : null}
          onTriggerConvert={
            type === 'intent' || (type === 'event' && !node?.isReminderNode)
              ? handleTriggerConvert
              : null
          }
          type={type}
          show={!showAITooltip}
        >
          <div
            ref={dragRef}
            className={cn({
              [styles['node-box__container']]: true,
              [styles['node-box__container--is-dragging']]: isDragging,
              [styles['node-box__container__arrow']]: parentId,
              [styles['node-box__container--reminder']]: node.isReminderNode,
            })}
          >
            {hasDialogError && (
              <div className={cn(styles.icon)}>
                <AlertTriangleIcon
                  size={16}
                  color="var(--icon-default-error)"
                />
              </div>
            )}

            <Box
              className={cn({
                [styles.selected]: isSelected,
                [styles.opacity]: !isActionInFlow && !isAnythingSelected,
              })}
              data-testid="dialog-node"
              {...clickableProps}
            >
              <NodeHeader
                nodeId={nodeId}
                type={type}
                isRootNodeUnknown={isRootNodeUnknown}
                node={node}
                isSelected={isSelected}
                showAITooltip={showAITooltip}
                onAITooltipDismiss={handleAITooltipDismiss}
              />
              {type === 'intent' && (
                <div className={styles.node__content}>
                  <Intent nodeId={nodeId} />
                </div>
              )}
            </Box>
            {showBranch && !node.isReminderNode && (
              <NodePlaceholder parentId={parentId} branch />
            )}
          </div>
        </DialogTooltip>
        <RequisitePlaceholder requisiteIndex={0} nodeId={nodeId} />
        <RequisiteList nodeId={nodeId} />
        <ActionList nodeId={nodeId} />
        {!isFinalAction(lastActionType) && (
          <>
            <ActionPlaceholder
              nodeId={nodeId}
              show={showPlaceholder}
              isLast={isLast}
            />
            <ConditionPlaceholder parentId={nodeId} />
            <ConditionList nodeId={nodeId} />
          </>
        )}
        <BranchList nodeId={nodeId} />
      </section>

      {!internal && (
        <ToolkitControls
          onZoomInClick={onZoomInClick}
          onZoomOutClick={onZoomOutClick}
          onZoomResetClick={onZoomResetClick}
          zoomPercentage={zoomPercentage}
        />
      )}
    </>
  );
});

Node.displayName = 'Node';

export default Node;
