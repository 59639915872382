import { PropsWithChildren, memo } from 'react';

import Typography from '@mui/material/Typography/Typography';
import { BotIcon } from 'lucide-react';

import { NameAvatar } from './NameAvatar';
import { Time } from './Time';

import styles from './Events.module.scss';

type Props = {
  userId?: string;
  time: string;
  name?: string;
};

export const EventWrapper = memo(
  ({ userId, time, name, children }: PropsWithChildren<Props>) => {
    return (
      <div className={styles.event}>
        {userId === 'brain' ? (
          <>
            <BotIcon size={16} color="var(--icon-default-gray)" />
            <Typography
              variant="label-semi-bold"
              color="var(--text-default-gray)"
            >
              {name}
            </Typography>
          </>
        ) : (
          <NameAvatar id={userId} name={name} />
        )}

        {children}
        <Time time={time} />
      </div>
    );
  }
);

EventWrapper.displayName = 'EventWrapper';
