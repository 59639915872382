import { useMemo, useCallback, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/system';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { CirclePlusIcon } from 'lucide-react';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Autocomplete from '@/components/atoms/AutocompleteNew/AutocompleteNew2';
import Button from '@/components/atoms/Button/Button/Button';
import Select from '@/components/atoms/Select/Select';
import useDialogs from '@/hooks/useDialogs';
import { TextAction } from '@/models/action';
import { Requisite, RequisiteOptionType } from '@/models/node';
import { RootState } from '@/models/state';
import { updateDialogAlerts } from '@/redux/dialogAlerts/actions';
import { generateOption, randId } from '@/redux/dialogs/helper';
import {
  selectRequisitesByNodeId,
  selectSelectedNode,
  selectSelectedRequisiteIndex,
} from '@/redux/dialogs/selectors';
import {
  addNewRequisiteOption,
  reorderRequisiteOption,
  updateRequisite,
} from '@/redux/nodes/actions';
import { selectBrainId } from '@/redux/session/selectors';
import { MAX_TEXT_ACTION_OPTIONS } from '@/util/constants';
import {
  removeDollarSign,
  addDollarSign,
  noop,
  capitalizeFirstLetter,
  findObjectByValue,
} from '@/util/util';
import { toolkitQuestionSchema } from '@/util/validator';

import QuestionAskOnly from './QuestionAskOnly';
import QuestionCheckAndAsk from './QuestionCheckAndAsk';
import RequisiteOption from './RequisiteOption/RequisiteOption';
import ToolkitWrapper from '../../ToolkitWrapper';

const QuestionType = {
  ASK_ONLY: 'ask',
  CHECK_AND_ASK: 'check_ask',
} as const;

type Form = {
  questionType: string;
  save_as: {
    value: string;
    type: string;
    label: string;
  };
};

const ToolkitActionQuestion = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedNode = useSelector(selectSelectedNode);
  const nodeId = selectedNode?.node_id;
  const requisiteIndex = useSelector(selectSelectedRequisiteIndex);
  const requisite: Requisite = useSelector(
    (state: RootState) =>
      selectRequisitesByNodeId(state, nodeId)[requisiteIndex]
  );
  // Format options for select
  const questionTypes = useMemo(
    () => [
      { value: QuestionType.ASK_ONLY, label: t('dialog.question.ask_only') },
      {
        value: QuestionType.CHECK_AND_ASK,
        label: t('dialog.question.check_and_ask'),
      },
    ],
    [t]
  );

  const brainId = useSelector(selectBrainId);
  const { getContextVariables } = useDialogs(brainId);
  const options = useMemo(
    () =>
      getContextVariables()
        // Filter out the tags variable
        .filter((variable) => variable?.value !== '$tags'),
    [getContextVariables]
  );

  const defaultSaveAs = findObjectByValue(
    options,
    addDollarSign(requisite?.save_as)
  );

  const {
    watch,
    trigger,
    register,
    control,
    formState: { errors },
    setValue,
  } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      questionType: requisite?.check_for
        ? QuestionType.CHECK_AND_ASK
        : QuestionType.ASK_ONLY,
      save_as: defaultSaveAs,
    },
    resolver: yupResolver(toolkitQuestionSchema) as Resolver<Form>,
  });

  const saveAsErrorMessage = capitalizeFirstLetter(
    errors?.save_as?.message || errors?.save_as?.value.message
  );

  const handleRequisiteUpdate = useCallback(
    (updatedRequisite) => {
      dispatch(
        updateRequisite({
          nodeId,
          index: requisiteIndex,
          requisite: updatedRequisite,
        })
      );
    },
    [dispatch, nodeId, requisiteIndex]
  );

  const updateRedux = useCallback(
    (key: string, value: string) => {
      dispatch(
        updateRequisite({
          nodeId,
          index: requisiteIndex,
          requisite: {
            ...requisite,
            [key]: value,
          },
        })
      );
    },
    [dispatch, nodeId, requisite, requisiteIndex]
  );

  const handleDragRequisiteOption = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      dispatch(
        reorderRequisiteOption({
          dragIndex,
          hoverIndex,
          requisiteId: requisite?.requisite_id,
        })
      );
    },
    [dispatch, requisite?.requisite_id]
  );

  const addRequisiteOption = useCallback(() => {
    dispatch(
      addNewRequisiteOption({
        requisiteId: requisite?.requisite_id,
        option: generateOption(),
      })
    );
  }, [dispatch, requisite?.requisite_id]);

  const requisiteOptions: RequisiteOptionType[] = get(
    requisite,
    'actions[0].options',
    []
  );

  const isAddQuickOptionDisabled =
    requisiteOptions.length === MAX_TEXT_ACTION_OPTIONS || !requisite?.actions;

  const updateErrors = useCallback(
    (key: string, value: string) => {
      dispatch(
        updateDialogAlerts({
          dialogAlerts: {
            alertType: 'error',
            id: requisite?.requisite_id,
            title: t('actions.types.question'),
            body: value,
            type: 'question',
            alertField: key,
            nodeId,
            index: requisiteIndex,
          },
        })
      );
    },
    [dispatch, nodeId, requisite?.requisite_id, requisiteIndex, t]
  );

  const watchQuestionType = watch('questionType');

  // Dialog errors
  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    updateErrors('save_as', saveAsErrorMessage);
  }, [saveAsErrorMessage, updateErrors]);

  const handleAskOnlyChange = (requisite: Requisite) => {
    // Use the existing texts if they exist, otherwise generate a random new one
    const texts = get(requisite, 'actions[0].texts', [
      t('actions.generate_text', { 0: randId() }),
    ]);
    return {
      ...requisite,
      check_for: undefined,
      required: undefined,
      validate: undefined,
      reprompt: undefined,
      actions: [
        {
          type: 'text',
          texts: texts,
          options: requisiteOptions.length !== 0 ? requisiteOptions : undefined,
        } as TextAction,
      ],
    };
  };

  const handleCheckAndAskChange = (requisite: Requisite) => {
    return {
      ...requisite,
      required: true,
      validate: 'integer',
      check_for: 'integer',
      actions: [
        {
          type: 'text',
          texts: [
            requisite?.actions?.[0].texts?.[0] ||
              t('actions.generate_text', { 0: randId() }),
          ],
          options: requisiteOptions.length !== 0 ? requisiteOptions : undefined,
        } as TextAction,
      ],
    };
  };

  return (
    <ToolkitWrapper type="question">
      <Box mb="var(--space-24)">
        <Select
          options={questionTypes}
          size="small-full"
          name="questionType"
          register={register('questionType')}
          onChange={(e) => {
            let updatedRequisite = cloneDeep(requisite);
            if (e.target.value === QuestionType.ASK_ONLY) {
              updatedRequisite = handleAskOnlyChange(updatedRequisite);
              updateErrors('Question', undefined);
              updateErrors('Reprompt', undefined);
            } else {
              updatedRequisite = handleCheckAndAskChange(updatedRequisite);
              updateErrors(t('dialog.question.ask'), undefined);
            }
            handleRequisiteUpdate(updatedRequisite);
          }}
        />
      </Box>

      {watchQuestionType === QuestionType.ASK_ONLY ? (
        <QuestionAskOnly
          requisite={requisite}
          options={options}
          updateErrors={updateErrors}
          nodeId={nodeId}
          requisiteIndex={requisiteIndex}
        />
      ) : (
        <QuestionCheckAndAsk
          requisite={requisite}
          onRequisiteUpdate={handleRequisiteUpdate}
          options={options}
          updateErrors={updateErrors}
        />
      )}

      <Box width="100%" mb="var(--space-24)" textAlign="right">
        <Button
          disabled={isAddQuickOptionDisabled}
          size="small"
          variant="tertiary"
          onClick={addRequisiteOption}
          data-testid="add-quick-option"
        >
          <CirclePlusIcon
            color={
              isAddQuickOptionDisabled ? 'var(--icon-default-gray)' : undefined
            }
            size={16}
          />
          {t('dialog.question.add_quick_option')}
        </Button>
      </Box>

      {requisiteOptions.map((option, index) => (
        <RequisiteOption
          key={option.option_id}
          option={option}
          expanded={false}
          index={index}
          moveItem={handleDragRequisiteOption}
          dropItem={noop}
          requisiteId={requisite?.requisite_id}
          updateErrors={updateErrors}
        />
      ))}

      <Box mt="var(--space-24)">
        <Autocomplete
          freeSolo
          enableNewEntry
          control={control}
          name="save_as"
          options={options}
          label={t('dialog.question.variable_label')}
          placeholder={t('dialog.set_variables.variable_name.placeholder')}
          size="xs"
          onChange={(_, option) => {
            if (option?.value) {
              setValue('save_as', {
                ...option,
                value: addDollarSign(option?.value),
                label: addDollarSign(option?.value),
              });
              trigger();
            }
            updateRedux('save_as', removeDollarSign(option?.value ?? ''));
          }}
          hasError={!!errors?.save_as}
          errorMessage={saveAsErrorMessage}
          groupByProp="type"
          tooltip={t('dialog.save_as_tooltip')}
        />
      </Box>
    </ToolkitWrapper>
  );
};

export default ToolkitActionQuestion;
