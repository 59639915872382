import { useState } from 'react';

import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
import { BookmarkIcon, LibraryBigIcon, ZapIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { sendCorrection } from '@/api/client';
import { useAccount } from '@/hooks/useAccount';
import useBrains from '@/hooks/useBrains';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { Collection } from '@/models/collections';
import {
  AmendPayload,
  CollectionMessage,
  ExternalUrl,
  Fragment,
  NodeType,
  UserMessage,
} from '@/models/tryIt';
import { LogMessage, LogSessionContentRow } from '@/modules/analytics/models';
import { addErrorTemporalToast } from '@/modules/notifications/redux/actions';
import { useDetailsSection } from '@/modules/TryIt/hooks/useDetailsSection';
import { setNodeAmend } from '@/modules/TryIt/redux/actions';
import {
  selectCurrentNode,
  selectSelectedMessageIndex,
  selectSessionNodes,
  selectTryItSessionId,
} from '@/modules/TryIt/redux/selectors';
import {
  isMessageFromCollection,
  isMessageFromKnowledge,
} from '@/modules/TryIt/utils/helper';
import { popModal } from '@/redux/modals/actions';
import { selectBrainId } from '@/redux/session/selectors';
import { delay } from '@/util/util';

import { DocumentsMatched } from '../../../Messages/DocumentsMatched/DocumentsMatched';
import { PossibleMatchedIntents } from '../../../Messages/PossibleMatchedIntents/PossibleMatchedIntents';
import { StandaloneWrapper } from '../StandaloneWrapper';

import styles from '../DetailsSection.module.scss';

type CollectionDetailsSectionProps = {
  content: LogSessionContentRow;
};

export type CollectionDetailsSectionContentProps = {
  nodes: NodeType[] | Partial<NodeType>[];
  index: number;
  messages: LogMessage[];
  collection: Collection;
  uniqueDocuments: Fragment[];
  slug: string;
  closeModal: () => void;
  session_id?: string;
  activeBrainId: string;
  external_urls?: ExternalUrl[];
};

export const CollectionDetailsSectionContent = ({
  nodes,
  index,
  messages,
  collection,
  uniqueDocuments,
  slug,
  session_id,
  activeBrainId,
  external_urls,
}: CollectionDetailsSectionContentProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tryItSessionId = useSelector(selectTryItSessionId);
  const { ai_agents } = useFeatureFlag();

  const node = nodes[index];
  const isInstructions = isMessageFromKnowledge(node.collection);
  const isCollection = isMessageFromCollection(node.collection);

  const intent =
    (node?.messages?.[0] as UserMessage)?.intents?.[0] || node?.intents?.[0];
  const showAddTrainingPhrase = intent?.confidence !== 1;

  const [isLoading, setIsLoading] = useState(false);
  const userMessage = (node?.messages[0] as CollectionMessage)?.text;
  const planner_intents = node?.planner_intents;
  const isPlannerIntentOfftopic =
    planner_intents?.includes('offtopics') && planner_intents?.length === 1;

  const plannerIntent = planner_intents?.[0];

  const { brain } = useBrains(activeBrainId);
  const possibleMatchedIntents =
    node?.intents || (messages?.[index]?.message as UserMessage)?.intents;

  const amendPayload: AmendPayload = {
    request_id: node.request_id || node?.nodes_stack?.[0]?.request_id,
    task: 'standalone',
    session_id: session_id || tryItSessionId,
    language: brain?.language,
  };

  const isStandaloneAmended = !!node?.corrections?.find(
    (c) => c.task === 'standalone'
  );

  const isIntentAmended = !!node?.corrections?.find(
    (c) =>
      c.task === 'planner' &&
      possibleMatchedIntents?.some((i) => i.intent === c.correction)
  );

  const isDocumentSelectionAmended = !!node?.corrections?.find(
    (c) => c.task === 'planner' && c.correction === 'null'
  );

  const handleDocumentIncorrectClick = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const payload = {
      ...amendPayload,
      task: 'planner' as const,
      correction: 'null',
    };
    try {
      const result = await sendCorrection(payload);
      setIsLoading(false);
      if (result.status == 'ok') {
        await delay(300);
        dispatch(setNodeAmend(payload));
      }
    } catch (e) {
      setIsLoading(false);
      dispatch(addErrorTemporalToast(e));
    }
  };

  return (
    <Stack data-testid="newDebugLogs">
      <StandaloneWrapper
        userMessage={userMessage}
        amendPayload={amendPayload}
        isAmended={isStandaloneAmended}
      />
      <div className={styles.step}>
        <PossibleMatchedIntents
          key={amendPayload.request_id}
          brainId={activeBrainId}
          isPlannerIntentOfftopic={isPlannerIntentOfftopic}
          plannerIntent={plannerIntent}
          intentName={intent?.intent}
          possibleMatchedIntents={possibleMatchedIntents}
          showAddTrainingPhrase={showAddTrainingPhrase}
          userMessage={userMessage}
          amendPayload={amendPayload}
          isAmended={isIntentAmended}
        />
      </div>
      {isInstructions && isCollection && ai_agents && (
        <div className={styles.step}>
          <div className={styles.title}>
            <LibraryBigIcon size={16} color="var(--icon-default-gray)" />

            <Typography
              variant="body-regular"
              color="var(--text-default-gray)"
              component="div"
            >
              {t('try_it.details.multiple_sources')}
            </Typography>
          </div>
          <div className={styles.details}></div>
        </div>
      )}

      {isInstructions && (
        <div className={styles.step}>
          <div className={styles.title}>
            <ZapIcon size={16} color="var(--icon-default-gray)" />

            <Typography
              variant="body-regular"
              color="var(--text-default-gray)"
              component="div"
            >
              {ai_agents
                ? t('try_it.details.agents_knowledge_found')
                : t('try_it.details.instructions_found')}
            </Typography>
          </div>
          <div className={styles.details}></div>
        </div>
      )}
      {isCollection && (
        <div className={styles.step}>
          <div className={styles.title}>
            <BookmarkIcon color="var(--icon-default-gray)" size={16} />

            <Typography
              variant="body-regular"
              color="var(--text-default-gray)"
              component="div"
            >
              {t('collections.collection')}{' '}
              <NavLink
                target="_blank"
                rel="noopener noreferrer"
                to={`/${slug}/collections/${collection?.collection_id}`}
                className={styles.link}
              >
                {collection?.name}
              </NavLink>{' '}
              {t('try_it.triggered')}
            </Typography>
          </div>
          <div className={styles.details}></div>
        </div>
      )}
      <div className={styles.step}>
        <DocumentsMatched
          nodes={nodes}
          messages={messages}
          index={index}
          uniqueDocuments={uniqueDocuments}
          external_urls={external_urls}
          onClick={handleDocumentIncorrectClick}
          amended={isDocumentSelectionAmended}
          isAmending={isLoading}
        />
      </div>
    </Stack>
  );
};

export const CollectionDetailsSection = ({
  content,
}: CollectionDetailsSectionProps) => {
  const dispatch = useDispatch();
  const index = useSelector(selectSelectedMessageIndex);
  const nodes = useSelector(selectSessionNodes);
  const currentNode = useSelector(selectCurrentNode);
  const messages = content?.messages.filter(
    (msg) =>
      msg.event === 'message:brain_send' ||
      msg.event === 'message:broadcast_send'
  );
  const { slug } = useAccount();
  const { collection, uniqueDocuments, external_urls } = useDetailsSection(
    currentNode?.collection,
    messages?.[index]
  );

  const brainId = useSelector(selectBrainId);
  const closeModal = () => {
    dispatch(popModal());
  };
  const { session_id, brain_id: logBrainId } = content || {};
  const activeBrainId = brainId || logBrainId;

  return (
    <CollectionDetailsSectionContent
      {...{
        nodes,
        index,
        messages,
        collection,
        uniqueDocuments,
        slug,
        closeModal,
        session_id,
        activeBrainId,
        external_urls,
      }}
    />
  );
};
