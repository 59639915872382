import { AlertColor } from '@mui/lab';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { XIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import Button from '@/components/atoms/Button/Button/Button';
import IconButton from '@/components/atoms/IconButton/IconButton';
import ProgressBar from '@/components/atoms/ProgressBar/ProgressBar';

import NewVersionNotification from './NewVersionNotification';
import { PROGRESS_BAR_DURATION } from '../utils';

import styles from './Notification.module.scss';

export interface NotificationProps {
  message: React.ReactNode | string;
  type: AlertColor | 'plain' | 'update';
  onClose?: () => void;
  autoRemove?: boolean;
  action?: {
    label: string;
    url: string;
  };
  avatar?: string;
  duration?: number;
  animate?: boolean;
}

const Notification = ({
  message,
  type = 'info',
  onClose,
  autoRemove = false,
  action,
  avatar,
  duration,
  animate = false,
}: NotificationProps) => {
  const navigate = useNavigate();
  const isLinkExternal = action && action?.url?.includes('https://');
  const { t } = useTranslation();
  const isPlain = type === 'plain';
  const props = {
    icon: avatar ? (
      <Avatar sx={{ width: 16, height: 16 }} src={avatar} />
    ) : undefined,
  };
  const handleClick = () => {
    if (isLinkExternal) {
      window.open(action.url, '_blank');
    } else if (action) {
      navigate(action.url);
    }
  };

  return type !== 'update' ? (
    <Alert
      onClose={onClose}
      severity={!isPlain ? type : undefined}
      className={cn(styles.alert, {
        [styles.fadeIn]: animate,
        [styles.isPlain]: isPlain,
      })}
      action={
        onClose ? (
          <IconButton onClick={onClose} ariaLabel={t('common.close')}>
            <XIcon size={12} color="var(--icon-default-black)" />
          </IconButton>
        ) : null
      }
      {...props}
    >
      <div className={cn(styles.body, styles[type])}>
        <Typography variant="body-regular">{message}</Typography>
        {action && (
          <Button onClick={handleClick} size="xs" variant="tertiary" noGutters>
            {action.label}
          </Button>
        )}
      </div>
      {autoRemove && (
        <div className={styles.progress_container}>
          <ProgressBar
            autoFill
            duration={duration || PROGRESS_BAR_DURATION}
            variant={isPlain ? 'info' : type}
          />
        </div>
      )}
    </Alert>
  ) : (
    <NewVersionNotification />
  );
};

export default Notification;
