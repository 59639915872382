import React, { useState, useEffect, useRef } from 'react';

import { TextareaAutosize as MuiTextareaAutosize } from '@mui/base/TextareaAutosize';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { ChevronDownIcon } from 'lucide-react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import CopyButton from '@/components/atoms/CopyButton/CopyButton';
import TextareaAutosize from '@/components/atoms/TextareaAutosize/TextareaAutosize';
import useBrains from '@/hooks/useBrains';
import { selectBrainId } from '@/redux/session/selectors';
import { noop } from '@/util/util';

import { defaultStartingText } from './util';

import styles from './ModalAutoBrain.module.scss';

const MAX_PROMPT = 1200;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AutoBrainLandingPage = ({ control }: any) => {
  const { t } = useTranslation();

  const prefixRef = useRef(null);
  const brainId = useSelector(selectBrainId);
  const { brain } = useBrains(brainId);
  const [indendation, setIndendation] = useState(0);
  const MAX_INPUT = MAX_PROMPT - defaultStartingText(brain?.language)?.length;
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleChange = (value: string) => {
    if (value.length > 500 && expanded) {
      setExpanded(false);
    }
  };

  const muiStyles = {
    root: {
      border: 0,
      background: 'transparent',
      boxShadow: 'none',
      '&:before': {
        opacity: 0,
      },
      '& .MuiAccordionDetails-root': {
        padding: 0,
      },
    },
    summary: {
      padding: 0,
      justifyContent: 'start',
      flexGrow: 'initial',
      '& .MuiAccordionSummary-content': {
        flexGrow: 'initial',
        color: 'var(--text-default-blue)',
      },
    },
  };

  const example1 = t('auto_brain.prompt1', { lng: brain?.language });
  const example2 = t('auto_brain.prompt2', { lng: brain?.language });

  useEffect(() => {
    setIndendation(prefixRef.current?.clientWidth);
  }, []);

  return (
    <div className={styles.formContainer}>
      <div>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleAccordionChange('panel1')}
          sx={muiStyles.root}
        >
          <AccordionSummary
            sx={muiStyles.summary}
            expandIcon={
              <ChevronDownIcon size={20} color="var(--icon-default-blue)" />
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography>
              {expanded === 'panel1' ? t('common.hide') : t('common.view')}{' '}
              {t('auto_brain.simple_example')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className={styles.exampleContainer}>
              <TextareaAutosize
                readOnly
                defaultValue={example1}
                onKeyUp={noop}
                onBlur={noop}
                onKeyDown={noop}
              />
              <CopyButton
                data={example1.slice(
                  defaultStartingText(brain?.language)?.length
                )}
              />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleAccordionChange('panel2')}
          sx={{ ...muiStyles.root, mt: 1 }}
        >
          <AccordionSummary
            sx={muiStyles.summary}
            expandIcon={
              <ChevronDownIcon size={20} color="var(--icon-default-blue)" />
            }
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography>
              {expanded === 'panel2' ? t('common.hide') : t('common.view')}{' '}
              {t('auto_brain.advanced_example')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className={styles.exampleContainer}>
              <TextareaAutosize
                readOnly
                defaultValue={example2}
                onKeyUp={noop}
                onBlur={noop}
                onKeyDown={noop}
              />
              <CopyButton
                data={example2.slice(
                  defaultStartingText(brain?.language)?.length
                )}
              />
            </div>
          </AccordionDetails>
        </Accordion>
        <Typography className={styles.label}>
          {t('auto_brain.tooltip')}
        </Typography>
      </div>

      <form autoComplete="off">
        <Typography className={styles.requiredText} ref={prefixRef}>
          {defaultStartingText(brain?.language)}
        </Typography>

        <Controller
          render={({ field: { value, onChange } }) => {
            return (
              <>
                <MuiTextareaAutosize
                  onChange={(data) => {
                    handleChange(value);
                    onChange(data);
                  }}
                  value={value}
                  minRows={4}
                  maxLength={MAX_INPUT}
                  style={{
                    textIndent: `${indendation + 3}px`,
                  }}
                />
                <Typography variant="label-regular">
                  {value.length + defaultStartingText(brain?.language)?.length}/
                  {MAX_PROMPT} {t('common.characters')}
                </Typography>
              </>
            );
          }}
          control={control}
          name="prompt"
        />
        <Typography variant="label-regular" className={styles.helper}>
          *{t('auto_brain.minimum_prompt')}
        </Typography>
      </form>
    </div>
  );
};

export default AutoBrainLandingPage;
