import { useState } from 'react';

import * as DialogPrimitive from '@radix-ui/react-dialog';
import cn from 'classnames';
import { XIcon } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { DropdownSelect } from '@/components/atoms/DropdownSelect';
import { Button } from '@/components/ui/button';
import {
  DialogDescription,
  DialogHeader,
  DialogPortal,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  FormSwitch,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useEventOptions } from '@/modules/developerTools/hooks/useEventOptions';
import usePlugins from '@/modules/developerTools/hooks/usePlugins';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { popModal } from '@/redux/modals/actions';
import {
  urlPattern,
  LENGTH_XXXL,
  LENGTH_XS,
  LENGTH_1024,
} from '@/util/validator';

interface FormValues {
  eventName: string;
  events: string[];
  eventUrl: string;
  eventSecret: string;
  verifySsl: boolean;
  active: boolean;
}

export type CreateSampleDialogFormValues = FormValues;

const getValidationRules = (
  t: (key: string, options?: Record<string, unknown>) => string
) => ({
  eventName: {
    required: t('validation.required'),
    maxLength: {
      value: LENGTH_XS,
      message: t('validation.less_than', { 0: LENGTH_XS }),
    },
  },
  events: {
    required: t('validation.required'),
  },
  eventUrl: {
    required: t('validation.required'),
    maxLength: {
      value: LENGTH_XXXL,
      message: t('validation.less_than', { 0: LENGTH_XXXL }),
    },
    pattern: {
      value: urlPattern,
      message: t('errors.invalid_url'),
    },
  },
  eventSecret: {
    required: t('validation.required'),
    maxLength: {
      value: LENGTH_1024,
      message: t('validation.less_than', { 0: LENGTH_1024 }),
    },
  },
});

interface ModalPluginCreateProps {
  initialValues?: FormValues;
  pluginId?: string;
  isEditing?: boolean;
}

export const ModalPluginCreate = ({
  initialValues,
  pluginId,
  isEditing = false,
}: ModalPluginCreateProps): JSX.Element => {
  const dispatch = useDispatch();
  const [isTesting, setIsTesting] = useState(false);
  const { t } = useTranslation();
  const { createPlugin, updatePlugin } = usePlugins(pluginId);
  const EVENT_OPTIONS = useEventOptions();

  const handleSubmit = (values: FormValues): void => {
    const pluginData = {
      name: values.eventName,
      events: values.events,
      url: values.eventUrl,
      token: values.eventSecret,
      verify_ssl: values.verifySsl,
      active: values.active,
    };

    if (isEditing && pluginId) {
      updatePlugin(
        {
          id: pluginId,
          data: pluginData,
        },
        {
          onSettled: () => {
            dispatch(popModal());
          },
        }
      );
    } else {
      createPlugin(pluginData, {
        onSettled: () => {
          dispatch(popModal());
        },
      });
    }
  };

  const validationRules = getValidationRules(t);

  const form = useForm<FormValues>({
    defaultValues: {
      eventName: initialValues?.eventName || '',
      eventUrl: initialValues?.eventUrl || '',
      events: initialValues?.events || [],
      eventSecret: initialValues?.eventSecret || '',
      verifySsl: initialValues?.verifySsl ?? true,
      active: initialValues?.active ?? true,
    },
  });

  const closeDialog = (): void => {
    dispatch(popModal());
  };

  const testUrl = async (): Promise<void> => {
    const url = form.getValues('eventUrl');
    const secret = form.getValues('eventSecret');

    // Validate URL and secret before testing
    if (!url || !urlPattern.test(url)) {
      dispatch(addErrorTemporalToast(t('errors.invalid_url')));
      return;
    }

    if (!secret) {
      dispatch(addErrorTemporalToast(t('plugins.secret_required')));
      return;
    }

    try {
      setIsTesting(true);
      // Use our server endpoint to proxy the request and avoid CORS issues
      const response = await fetch('/www/plugin-test', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          url,
          secret,
        }),
      });

      // Safely parse the JSON response
      let result;
      try {
        const text = await response.text();
        result = text ? JSON.parse(text) : {};
      } catch (parseError) {
        // Handle JSON parsing errors
        dispatch(
          addErrorTemporalToast(
            `${t('plugins.test_failed')}: Invalid response format`
          )
        );
        return;
      }

      if (response.ok && result.success) {
        dispatch(addTemporalToast('success', t('plugins.test_successful')));
        setIsTesting(false);
      } else {
        // Construct a meaningful error message
        const errorMessage =
          result.error ||
          (result.status && result.statusText
            ? `${result.status} ${result.statusText}`
            : 'Unknown error');

        dispatch(
          addErrorTemporalToast(`${t('plugins.test_failed')}: ${errorMessage}`)
        );
        setIsTesting(false);
      }
    } catch (error) {
      dispatch(
        addErrorTemporalToast(
          `${t('plugins.test_failed')}: ${error instanceof Error ? error.message : 'Unknown error'}`
        )
      );
      setIsTesting(false);
    }
  };

  return (
    <DialogPrimitive.Root
      data-slot="dialog"
      open={true}
      onOpenChange={closeDialog}
    >
      <DialogPortal data-slot="dialog-portal">
        <DialogPrimitive.Overlay
          data-slot="dialog-overlay"
          className="data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 fixed inset-0 z-40 bg-black/80"
        />
        <DialogPrimitive.Content
          data-slot="dialog-content"
          className={cn(
            'bg-background data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 fixed top-[50%] left-[50%] z-50 w-full max-w-[calc(100%-2rem)] translate-x-[-50%] translate-y-[-50%] gap-4 rounded-lg border shadow-lg duration-200 sm:max-w-lg',
            'sm:max-w-[500px] p-0 overflow-hidden bg-white border-0 rounded-md'
          )}
        >
          <DialogDescription />
          <DialogHeader className="px-6 pt-6 mb-6">
            <DialogTitle className="text-xl font-semibold text-[22px] leading-[120%] tracking-normal">
              {isEditing
                ? t('plugins.update_plugin')
                : t('plugins.create_plugin')}
            </DialogTitle>
            {!isEditing && (
              <DialogDescription>
                {t('plugins.create_plugin_description')}
              </DialogDescription>
            )}
          </DialogHeader>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(handleSubmit)}
              className="space-y-6 px-6 pb-6"
            >
              <FormField
                control={form.control}
                name="eventName"
                rules={validationRules.eventName}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('common.name')}</FormLabel>
                    <FormControl>
                      <Input id="eventName" {...field} className="w-full" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="events"
                rules={validationRules.events}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('plugins.event')}</FormLabel>
                    <FormControl>
                      <DropdownSelect
                        options={EVENT_OPTIONS}
                        id="dropdownSelect"
                        className="flex flex-wrap items-center gap-1 p-2 border rounded-md cursor-text"
                        placeholder={t('plugins.search_event')}
                        selectedOptions={field.value || []}
                        onChange={(selectedIds): void =>
                          field.onChange(selectedIds)
                        }
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="eventUrl"
                rules={validationRules.eventUrl}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('actions.types.url')}</FormLabel>
                    <FormControl>
                      <Input id="eventUrl" {...field} className="w-full" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="eventSecret"
                rules={validationRules.eventSecret}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="mt-4">
                      {t('plugins.secret')}
                    </FormLabel>
                    <FormControl>
                      <Input
                        id="eventSecret"
                        {...field}
                        className="w-full"
                        type="password"
                      />
                    </FormControl>
                    <p className="foreground-muted line-height/leading-4 text-xs">
                      {t('plugins.secret_description')}
                    </p>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="verifySsl"
                render={({ field: { value, onChange, ...field } }) => (
                  <FormItem className="flex flex-row items-center gap-2">
                    <FormControl>
                      <FormSwitch
                        checked={value}
                        onCheckedChange={onChange}
                        label={t('plugins.verify_ssl')}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="flex gap-2 pt-2 flex-row-reverse">
                <Button
                  type="submit"
                  className="bg-[#0D6EFD] hover:bg-[#0D6EFD]/90 w-[76px] h-[40px]"
                  disabled={form.formState.isSubmitting}
                >
                  {isEditing ? t('common.update') : t('common.create')}
                </Button>

                <Button
                  type="button"
                  variant="outline"
                  onClick={testUrl}
                  className="text-[#0D6EFD] border-[#0D6EFD] hover:bg-[#0D6EFD]/10 w-[76px] h-[40px]"
                  disabled={isTesting}
                  isLoading={isTesting}
                >
                  {t('common.test')}
                </Button>
              </div>
            </form>
          </Form>
          <DialogPrimitive.Close className="ring-offset-background focus:ring-ring data-[state=open]:bg-accent data-[state=open]:text-muted-foreground absolute top-4 right-4 rounded-xs opacity-70 transition-opacity hover:opacity-100 focus:ring-2 focus:ring-offset-2 focus:outline-hidden disabled:pointer-events-none [&_svg]:pointer-events-none [&_svg]:shrink-0 [&_svg:not([class*='size-'])]:size-4">
            <XIcon />
            <span className="sr-only">{t('common.close')}</span>
          </DialogPrimitive.Close>
        </DialogPrimitive.Content>
      </DialogPortal>
    </DialogPrimitive.Root>
  );
};
