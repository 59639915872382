import { useCallback, useMemo, useState } from 'react';

import cn from 'classnames';
import { ChevronUpIcon } from 'lucide-react';

import { CategoryCheckbox } from '@/components/atoms/CategoryCheckbox/CategoryCheckbox';
import { CheckBoxTile } from '@/components/atoms/CheckBoxTile/CheckBoxTile';
import Loading from '@/components/atoms/Loading/Loading';
import { useTemplates } from '@/hooks/useTemplates';
import { isKeyEnter, preventClickThrough } from '@/util/util';

import { CommonProps, TemplateProps } from '../ModalBrainCreationNew';

import styles from '../ModalBrainCreationNew.module.scss';

const COLORS = 4;

export const Template = (props: TemplateProps & CommonProps) => {
  const { metadata } = useTemplates();
  const [selectedCategories, setSelectedCategories] = useState([]);

  // All the templates that are available for the selected language
  const allTemplates = useMemo(
    () =>
      metadata?.templates?.filter((t) => {
        const template = t.languages[props.language];
        return template?.brain_id && t?.integration_id;
      }),
    [props.language, metadata?.templates]
  );

  // Templates that match the selected categories
  const filteredTemplates = allTemplates?.filter(
    (template) =>
      selectedCategories.length === 0 ||
      selectedCategories.includes(template.category)
  );

  const templatesByCategory = useCallback(
    (category: string) => {
      return allTemplates?.filter((t) => t.category === category) || [];
    },
    [allTemplates]
  );

  const colorByCategory = useCallback(
    (category: string) => {
      const index = metadata?.categories.findIndex((c) => c === category);
      return `var(--color-support-${(index % COLORS) + 1})`;
    },
    [metadata]
  );

  const handleResetClick = useCallback(() => {
    setSelectedCategories([]);
  }, [setSelectedCategories]);

  const handleResetKeyDown = useCallback(
    (e) => {
      preventClickThrough(e);
      if (isKeyEnter(e)) {
        setSelectedCategories([]);
      }
    },
    [setSelectedCategories]
  );

  const handleCategoryChange = useCallback(
    ({ category, checked }) => {
      if (checked && !selectedCategories.includes(category)) {
        setSelectedCategories([...selectedCategories, category]);
      } else if (!checked && selectedCategories.includes(category)) {
        setSelectedCategories(selectedCategories.filter((c) => c !== category));
      }
    },
    [selectedCategories, setSelectedCategories]
  );

  const handleSelectTemplate = useCallback(
    (template) => {
      props.setTemplateId(template.id);
      props.setStep(3);
    },
    [props]
  );

  if (!filteredTemplates || !metadata?.categories) {
    return <Loading />;
  }

  return (
    <>
      <div className={styles.checkboxes}>
        <div className={styles.categories}>
          <span>{props.t('onboarding.categories_label')}</span>
          <span
            className={cn(styles.clear, {
              [styles.hasFilters]: selectedCategories.length > 0,
            })}
            role="button"
            tabIndex={-1}
            onClick={handleResetClick}
            onKeyDown={handleResetKeyDown}
          >
            {props.t('onboarding.clear_filters')}
          </span>
        </div>

        <div className={styles.checkboxesWrapper}>
          {metadata?.categories.map((c) => (
            <CategoryCheckbox
              key={c}
              category={c}
              label={props.t(`onboarding.categories.${c}`)}
              checked={selectedCategories.includes(c)}
              onChange={handleCategoryChange}
              badge={templatesByCategory(c)?.length}
            />
          ))}
        </div>
      </div>
      <div className={styles.main}>
        <div className={styles.scratch}>
          <CheckBoxTile
            title={props.t('onboarding.scratch')}
            text={props.t('onboarding.scratch_description')}
            onClick={props.onSubmit}
            submitLoading={props.createStatus === 'pending'}
            disabled={props.createStatus === 'pending'}
            iconRight={
              <span className={styles.svg}>
                <ChevronUpIcon color="var(--icon-default-blue)" />
              </span>
            }
          />
        </div>
        <div className={styles.titleWrapper}>
          <div className={styles.title}>{props.t('onboarding.jump_start')}</div>
          <div className={styles.info}>
            {selectedCategories.length === 0 &&
              props.t('onboarding.all_templates', {
                0: filteredTemplates.length,
              })}
            {selectedCategories.length > 0 &&
              props.t('onboarding.filtered_templates', {
                0: filteredTemplates.length,
              })}
          </div>
        </div>
        <div className={styles.templates}>
          {filteredTemplates.map((template) => {
            return (
              <CheckBoxTile
                key={template.id}
                size="small"
                title={props.t(`templates:${template.id}.title`)}
                text={props.t(`templates:${template.id}.text`)}
                onClick={() => handleSelectTemplate(template)}
                info={props.t(`onboarding.categories.${template.category}`)}
                infoColor={colorByCategory(template.category)}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};
