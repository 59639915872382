import { memo, useCallback, useMemo } from 'react';

import cn from 'classnames';
import { InfoIcon, MessageCircleIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/atoms/Button/Button/Button';
import ButtonCard from '@/components/atoms/Button/ButtonCard/ButtonCard';
import Bubble from '@/components/atoms/Icons/Bubble';

import styles from './LauncherAppearance.module.scss';

export type OptionItem =
  | {
      id: number;
      child: React.ReactNode;
      isButtonLike?: boolean;
      isTheme?: never;
      value: string;
    }
  | {
      id: number;
      child: React.ReactNode;
      isButtonLike?: never;
      isTheme?: boolean;
      value: string;
    };

type LauncherOptionsProps = {
  onChange: (value) => void;
  value: string;
  isDisabled?: boolean;
  disabled?: boolean;
};

export const launcherTypeOptions = {
  '0': 'bubble',
  '1': 'help',
  '2': 'feedback',
} as const;

const LauncherOptions = ({
  onChange,
  value,
  isDisabled,
  disabled = false,
}: LauncherOptionsProps) => {
  const { t } = useTranslation();

  const items: OptionItem[] = useMemo(
    () => [
      { id: 0, child: <Bubble />, value: 'bubble' },
      {
        id: 1,
        child: (
          <Button className={styles.button}>
            <InfoIcon size={18} color="var(--icon-default-blue)" />
            {t('integrations.web_launcher.types.help')}
          </Button>
        ),
        isButtonLike: true,
        value: 'help',
      },
      {
        id: 2,
        child: (
          <Button className={styles.button} isFullWidth={false}>
            <MessageCircleIcon
              color={'var(--surface-secondary-gray-light)'}
              size={18}
            />
            {t('integrations.web_launcher.types.feedback')}
          </Button>
        ),
        isButtonLike: true,
        value: 'feedback',
      },
    ],
    [t]
  );

  const handleCardClick = useCallback(
    (index) => {
      if (!disabled) {
        onChange(launcherTypeOptions[index]);
      }
    },
    [disabled, onChange]
  );

  return (
    <div>
      <div className={styles.launcherOptions__title}>
        {t('integrations.web_launcher.options')}
      </div>
      <div
        className={cn(styles.launcherOptions, {
          [styles.disabled]: disabled,
        })}
      >
        {items.map((item) => {
          return (
            <ButtonCard
              active={value === item.value}
              onClick={handleCardClick}
              key={item.id}
              index={item.id}
              isTheme={item.isTheme}
              isButtonLike={item.isButtonLike}
              isDisabled={isDisabled}
            >
              {item.child}
            </ButtonCard>
          );
        })}
      </div>
    </div>
  );
};

export default memo(LauncherOptions);
