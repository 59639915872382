import { useCallback, useEffect, useState } from 'react';

import Menu from '@mui/material/Menu';
import cn from 'classnames';
import { ArrowUpDownIcon, EllipsisIcon, TriangleAlertIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/atoms/Button/Button/Button';
import HelpTooltip from '@/components/atoms/HelpTooltip/HelpTooltip';
import IconButton from '@/components/atoms/IconButton/IconButton';
import ListItem from '@/components/atoms/ListItem/ListItem';

import styles from './CheckBoxTable.module.scss';

type Item = {
  text: string;
  info?: React.ReactNode;
  checked?: boolean;
  icon?: React.ReactNode;
  value?: string;
  hidden?: boolean;
};

export interface CheckBoxTableProps {
  title: string;
  subtitle?: string;
  tooltip?: string;
  onSaveClick?: (items: Item[]) => void;
  onRemoveClick?: (items: Item[]) => void;
  items?: Item[];
  onOptionsClick?: (i: number) => void;
  onItemsChecked?: (items: Item[]) => void;
  hasFooter?: boolean;
  isVersionPublish?: boolean;
  disableCollapse?: boolean;
  noGutters?: boolean;
  onlyIcon?: boolean;
  noBorder?: boolean;
  isRightColumnAuto?: boolean;
}

const CheckBoxTable = ({
  title,
  tooltip = '',
  onSaveClick = () => {},
  onRemoveClick = () => {},
  onOptionsClick,
  items,
  onItemsChecked,
  hasFooter = false,
  subtitle = '',
  isVersionPublish = false,
  disableCollapse = false,
  noGutters = false,
  noBorder = false,
  onlyIcon = false,
  isRightColumnAuto = false,
}: CheckBoxTableProps) => {
  const { t } = useTranslation();
  const getDefaultItems = useCallback(() => {
    if (items) {
      return Array(items?.length)
        .fill('')
        .map((_, i) => ({
          ...items[i],
          checked: items[i]?.checked ?? false,
        }));
    }
    return [];
  }, [items]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [activeItem, setActiveItem] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = useCallback((event, i) => {
    setAnchorEl(event.currentTarget);
    setActiveItem(i);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setActiveItem(null);
  }, []);

  const [checkedItems, setCheckedItems] = useState(getDefaultItems());
  const [collapsed, setCollapsed] = useState(false);
  const [checkedAll, setCheckedAll] = useState(false);

  useEffect(() => {
    const defaultItems = getDefaultItems();
    setCheckedItems(defaultItems);
    const checkedAll = defaultItems.every((item) => item.checked);
    setCheckedAll(checkedAll);
  }, [getDefaultItems, items]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { checked } = e.target;

    const newCheckedItems = checkedItems?.map((item, i) => {
      if (i === index) {
        return {
          ...checkedItems[i],
          checked,
        };
      }
      return item;
    });
    if (onItemsChecked) {
      onItemsChecked(newCheckedItems);
    }
    setCheckedItems(newCheckedItems);
  };
  const handleAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (checkedItems) {
      const newCheckedItems = checkedItems.map((item) => ({
        ...item,
        checked: event.target.checked,
      }));
      if (onItemsChecked) {
        onItemsChecked(newCheckedItems);
      }
      setCheckedItems(newCheckedItems);
      setCheckedAll((c) => !c);
    }
  };

  const handleCollapseToggle = () => {
    setCollapsed((c) => !c);
  };

  const handleOptionClick = (index: number) => {
    setAnchorEl(null);
    setActiveItem(null);
    onOptionsClick(index);
  };

  return (
    <div
      className={cn(styles.container, {
        [styles.publish_version]: isVersionPublish,
        [styles.no_gutters]: noGutters,
        [styles.no_border]: noBorder,
      })}
    >
      {title && (
        <div
          className={cn(styles.item, styles.titleItem, {
            [styles.collapsed]: collapsed,
          })}
        >
          <input
            type="checkbox"
            name={title}
            checked={checkedAll}
            onChange={handleAllChange}
          />
          <div
            className={cn(styles.titleContainer, {
              [styles.hasInfo]: subtitle,
            })}
          >
            <p className={styles.value}>
              {title.toUpperCase()}
              <span>({checkedItems?.length ?? 0})</span>
            </p>

            <p>{subtitle}</p>
            {tooltip && <HelpTooltip tooltip={tooltip} />}
          </div>
          {!disableCollapse && (
            <IconButton
              ariaLabel={t('common.collapse')}
              onClick={handleCollapseToggle}
            >
              <ArrowUpDownIcon size={14} color="var(--icon-default-gray)" />
            </IconButton>
          )}
        </div>
      )}
      {!collapsed && (
        <div className={styles.body}>
          {checkedItems ? (
            checkedItems.map((item, i) => {
              if (item?.hidden) {
                return null;
              }
              return (
                <div
                  className={cn(styles.item, {
                    [styles.right_column_auto]: isRightColumnAuto,
                  })}
                  key={item.text}
                >
                  <input
                    type="checkbox"
                    name={`${title}-${i}`}
                    aria-label={item.text}
                    checked={checkedItems ? checkedItems[i].checked : false}
                    onChange={(e) => handleChange(e, i)}
                  />
                  {item.icon && <div className={styles.icon}>{item.icon}</div>}
                  {!onlyIcon && (
                    <div
                      className={cn(styles.dataContainer, {
                        [styles.hasInfo]: item.info,
                      })}
                    >
                      <p className={styles.value}>{item.text}</p>
                      {item.info}
                    </div>
                  )}
                  {onOptionsClick && (
                    <IconButton
                      onClick={(e) => handleClick(e, i)}
                      ariaLabel={t('common.more')}
                      ariaHasPopUp
                    >
                      <EllipsisIcon
                        size={20}
                        color="var(--icon-default-gray)"
                      />
                    </IconButton>
                  )}
                </div>
              );
            })
          ) : (
            <div className={styles.empty}>
              <p>{t('recommendations.empty_table')}</p>
            </div>
          )}
        </div>
      )}
      {!collapsed && hasFooter && items && items?.length > 0 && (
        <div className={styles.footer}>
          <Button
            variant="secondary"
            onClick={() => onRemoveClick(checkedItems)}
          >
            {t('common.remove')}
          </Button>
          <Button onClick={() => onSaveClick(checkedItems)}>
            {t('common.add')}
          </Button>
        </div>
      )}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        MenuListProps={{
          disablePadding: true,
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: -25,
          horizontal: 'center',
        }}
      >
        <div>
          <ListItem
            text={t('recommendations.wrong')}
            onItemClick={() => handleOptionClick(activeItem)}
            mainIcon={
              <TriangleAlertIcon size={16} color="var(--icon-default-gray)" />
            }
          />
        </div>
      </Menu>
    </div>
  );
};

export default CheckBoxTable;
