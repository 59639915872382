import { useRef, useState } from 'react';

import Typography from '@mui/material/Typography';
import { CloudDownloadIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useClickAway } from 'react-use';

import HelpTooltip from '@/components/atoms/HelpTooltip/HelpTooltip';
import IconButton from '@/components/atoms/IconButton/IconButton';
import { delay } from '@/util/util';

import ExportDropdown from './ExportDropdown';
import { GraphType } from '../AnalyticsFieldset';

import styles from './AnalyticsTitle.module.scss';

type Props = {
  children?: React.ReactNode;
  title: string;
  tooltip?: string;
  data?: unknown;
  hasExport?: boolean;
  graphType?: GraphType;
};

const AnalyticsTitle = ({
  title,
  tooltip,
  data,
  hasExport,
  graphType,
}: Props) => {
  const { t } = useTranslation();

  const exportRef = useRef<HTMLDivElement>();
  const [showDropdown, setShowDropdown] = useState(false);

  useClickAway(exportRef, async () => {
    await delay(100);
    setShowDropdown(false);
  });

  return (
    <div className="fieldset__title">
      {title && (
        <>
          <Typography variant="subtitle1">{title}</Typography>
        </>
      )}
      {tooltip && <HelpTooltip tooltip={tooltip} />}
      {hasExport && (
        <div className={styles.exportButton} ref={exportRef}>
          <IconButton
            onClick={() => setShowDropdown((prev) => !prev)}
            tooltip={t('common.export')}
            disabled={!data}
            ariaLabel={t('common.export')}
          >
            <CloudDownloadIcon color="var(--icon-default-blue)" size={16} />
          </IconButton>
          {showDropdown && (
            <ExportDropdown
              title={title}
              setShow={setShowDropdown}
              data={data}
              graphType={graphType}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AnalyticsTitle;
