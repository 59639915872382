import { memo } from 'react';

import Typography from '@mui/material/Typography';

import { ItemsType } from '@/redux/dialogs/helper';

import ToolkitItem from '../ToolkitItem/ToolkitItem';

import styles from './Toolkit.module.scss';

type Props = {
  items: ItemsType[];
  t: (key: string) => string;
  title: string;
};

const ToolkitList = ({ items, t, title }: Props) => {
  return (
    <div>
      <Typography variant="label-caps-large" color="var(--text-default-gray)">
        {t(`dialog.${title}`)}
      </Typography>
      <div className={styles.list}>
        {items.map((item) => (
          <ToolkitItem
            key={item.id}
            id={item.id}
            title={item.title}
            type={item.type}
          />
        ))}
      </div>
    </div>
  );
};

export default memo(ToolkitList);
