import moment from 'moment';

import { BrainMessage, NodeType, TryItCollection } from '@/models/tryIt';
import { LogMessage } from '@/modules/analytics/models';

const isSameMinute = (a, b) => {
  return a.format('HH:mm') === b.format('HH:mm');
};

/**
 * Removes duplicate datasources based on unique combinations of document_id and datasource_id.
 *
 * @function
 * @param {Array.<Object>} datasources - An array of datasources to filter for uniqueness.
 * @param {string} datasources[].document_id - The ID of the document.
 * @param {string} datasources[].datasource_id - The ID of the datasource.
 * @returns {Array.<Object>} An array of unique datasources based on document_id and datasource_id combination.
 */
export const removeDuplicateDocuments = (
  fragments: TryItCollection['fragments']
): TryItCollection['fragments'] => {
  if (!fragments) return [];

  const seenPairs = new Set();
  const uniqueEntries = [];

  for (const entry of fragments) {
    const pair = `${entry.document_id}-${entry.datasource_id}`;
    if (!seenPairs.has(pair)) {
      seenPairs.add(pair);
      uniqueEntries.push(entry);
    }
  }
  return uniqueEntries;
};

/**
 * Groups an array of objects by a given key within each object.
 * If the key does not exist on an item, that item will not be included in the output.
 *
 * @template T - The type of the elements in the input array.
 *
 * @param {T[]} array - The array to group.
 * @param {string} key - The key of the property to group by.
 *
 * @returns {T[][]} - An array of arrays, where each subarray contains elements
 * that are grouped by the specified key.
 */
export const groupBy = <T>(array: T[], key: string): T[][] => {
  if (!array) return [];

  const grouped = array.reduce((acc, item) => {
    if (!acc[item[key]]) {
      acc[item[key]] = [];
    }
    acc[item[key]].push(item);
    return acc;
  }, {});

  return Object.values(grouped);
};

export const DEPRECATED_CUSTOM_INSTRUCTIONS = 'Custom Instructions';
export const DEPRECATED_LIVE_INSTRUCTIONS = 'Live Instructions';
export const CUSTOM_INSTRUCTIONS = 'Instructions';
export const LIVE_INSTRUCTIONS = 'User Info';

const isInstructions = (collection: TryItCollection) =>
  collection?.response_code === 0 &&
  !!collection?.external_urls?.find((url) =>
    [
      DEPRECATED_CUSTOM_INSTRUCTIONS,
      DEPRECATED_LIVE_INSTRUCTIONS,
      LIVE_INSTRUCTIONS,
      CUSTOM_INSTRUCTIONS,
    ].includes(url.name)
  );

/**
 *Check if the collection schema is sources from live/custom instructions
 * or has empty sources but went through LLM
 */
export const isMessageFromKnowledge = (collection: TryItCollection) => {
  return (
    isInstructions(collection) ||
    (collection?.response_code === 0 &&
      (!collection?.external_urls || collection?.external_urls?.length == 0) &&
      (!collection?.fragments || collection?.external_urls?.length == 0))
  );
};

/**
 * Checks if the provided schema has any external URLs
 * with at least one associated document ID. If there are no external_urls,
 * it checks if there are fragments present and the response code is 0.
 */

export const isMessageFromCollection = (collection: TryItCollection) => {
  const foundDocuments = collection?.external_urls?.filter(
    (url) => !!url.document_id
  );
  if (collection?.external_urls?.length > 0 && foundDocuments?.length > 0) {
    return true;
  }

  return collection?.fragments?.length > 0 && collection?.response_code === 0;
};

export const muiStyles = {
  accordionRoot: {
    boxSizing: 'border-box',
    backgroundColor: 'var(--surface-primary-white)',
    border: '1px solid transparent',
    boxShadow: 'none',
    transitionDuration: '200ms',
    scrollMarginTop: 'var(--space-16)',
    cursor: 'default',
    padding: 0,

    '&::before': {
      height: '0',
    },
  },

  detailsRoot: {
    padding: 0,
    marginTop: 'var(--space-8)',
  },
};

export const calculateSessionNodes = (messages?: LogMessage[]): NodeType[] => {
  if (!messages) return [];

  const newNodes: NodeType[] = messages.reduce((acc, message, idx) => {
    const time = message.time;

    switch (message.event) {
      case 'message:received': {
        const resp = messages.find(
          (m, index) =>
            m.request_id === message.request_id &&
            m.event === 'message:brain_send' &&
            index > idx
        ) as Extract<LogMessage, { event: 'message:brain_send' }> | null;
        // user -> live agent
        if (!resp) {
          // Check if message already exists
          for (let j = 0; j < acc.length; j++) {
            if (
              acc[j].messages.find((m) => m.request_id === message.request_id)
            ) {
              return acc;
            }
          }

          // Message doesn't exist, create new node
          const index = messages.findIndex(
            (m) => m.request_id === message.request_id
          );
          const userMessages = [
            {
              intents: null,
              request_id: message.request_id,
              text: message.message.text,
              type: 'user',
              isAudio: message.message.type === 'audio',
              attachments: message.message?.attachments,

              reminder: message.message?.reminder?.minutes
                ? {
                    minutes: message.message?.reminder?.minutes,
                    reminder_id: message.message?.reminder?.reminder_id,
                    trigger_node_id: message.message?.reminder?.trigger_node_id,
                  }
                : undefined,
            },
          ];
          for (let i = index + 1; i < messages.length; i++) {
            const nextMessage = messages[i];
            if (
              nextMessage.event === 'message:received' &&
              isSameMinute(moment(time), moment(messages[i].time))
            ) {
              userMessages.push({
                intents: null,
                request_id: messages[i].request_id,
                text: nextMessage.message.text,
                type: 'user',
                isAudio: nextMessage.message.type === 'audio',
                attachments: nextMessage.message?.attachments,
                reminder: message.message?.reminder?.minutes
                  ? {
                      minutes: message.message?.reminder?.minutes,
                      reminder_id: message.message?.reminder?.reminder_id,
                      trigger_node_id:
                        message.message?.reminder?.trigger_node_id,
                    }
                  : undefined,
              });
            } else {
              break;
            }
          }

          return [
            ...acc,
            {
              context: message.context,
              messages: userMessages,
              author_type: 'user',
              time,
            },
          ];
        }
        // user <-> brain
        const nodes_stack = resp?.message?.debug?.nodes_stack ?? [];
        const isDisambiguation =
          resp?.message?.responses?.[0]?.type === 'disambiguation';
        const standalone_question = resp?.message?.debug?.standalone_question;
        return [
          ...acc,
          {
            id: nodes_stack[nodes_stack.length - 1]?.node_id,
            context: resp.context,
            name: isDisambiguation
              ? null
              : nodes_stack[nodes_stack.length - 1]?.name,
            debugLogs: resp.message.debug.logs,
            steps: resp.message.debug.steps,
            time,
            request_id: resp.request_id,
            nodes_stack,
            standalone_question,
            collection: {
              collection_id: resp?.collection_id,
              request_code: resp?.collection_request_code,
              response_code: resp?.collection_response_code,
              fragments: resp?.collection_sources,
              external_urls: resp?.message.debug?.external_urls,
            },
            corrections: resp.corrections,
            reaction: resp.reaction,
            planner_intents: resp?.message?.planner_intents,
            messages: [
              {
                entities: resp.message.entities,
                intents: resp.message.intents,
                text: message.message.text,
                type: 'user',
                isAudio: message.message.type === 'audio',
                attachments: message.message?.attachments,
                reminder: resp.message?.reminder?.minutes
                  ? {
                      minutes: resp.message?.reminder?.minutes,
                      reminder_id: resp.message?.reminder?.reminder_id,
                      trigger_node_id: resp.message?.reminder?.trigger_node_id,
                    }
                  : undefined,
              },
              {
                responses: resp.message.responses,
                type: 'brain',
              },
            ],
            author_type: 'brain',
            author_id: resp?.brain_parent_id,
          },
        ];
      }
      case 'message:send': {
        for (let j = 0; j < acc.length; j++) {
          if (
            acc[j].messages.find((m) => m.request_id === message.request_id)
          ) {
            return acc;
          }
        }
        const index = messages.findIndex(
          (m) => m.request_id === message.request_id
        );
        const agentMessages = [
          {
            request_id: message.request_id,
            text: message.message.text,
            type: 'agent',
            template: message.message?.body?.template,
            body: message.message?.body,
          },
        ];
        for (let i = index + 1; i < messages.length; i++) {
          const nextMessage = messages[i];
          if (
            nextMessage.event === 'message:send' &&
            isSameMinute(moment(time), moment(nextMessage.time)) &&
            nextMessage.agent_id === message.agent_id
          ) {
            agentMessages.push({
              request_id: messages[i].request_id,
              text: nextMessage.message.text,
              type: 'agent',
              template: nextMessage.message?.body?.template,
              body: nextMessage.message?.body,
            });
          } else {
            break;
          }
        }
        // live agent -> user
        return [
          ...acc,
          {
            time,
            context: message.context,
            messages: agentMessages,
            author_type: 'agent',
            author_id: message.agent_id,
          },
        ];
      }
      case 'message:broadcast_send': {
        const messages = [
          {
            responses: message.message.responses,
            type: 'broadcast',
          },
        ];

        return [
          ...acc,
          {
            context: message.context,
            tags: message.tags,
            time,
            messages,
            broadcast_id: message.message.debug.broadcast.broadcast_id,
            author_type: 'broadcast',
          },
        ];
      }
      default:
        return acc;
    }
  }, []);
  return newNodes;
};

// Updates the nodes with new messages and information.
export const calculateNodesAfterTryResponse = ({
  nodes,
  nodes_stack,
  isDisambiguation,
  data,
  nodeKey,
  request_id,
  messageSentId,
  responseMessage,
}) => {
  // Determines the current node from the nodes stack.
  let realNode = nodes_stack ? nodes_stack[nodes_stack.length - 1] : undefined;

  // Special handling for disambiguation in the response.

  if (isDisambiguation) {
    realNode = {
      ...realNode,
      name: null,
    };
  }
  const newNodes = nodes.map((node) => {
    if (node.key !== nodeKey) {
      return node;
    }
    return {
      ...node,
      intents: data.output.intents,
      name: realNode?.name ?? null,
      id: realNode?.node_id ?? null,
      context: data.context,
      debugLogs: data.output.debug.logs,
      request_id,
      collection: data.output.debug.collection,
      standalone_question: data.output.debug.standalone_question,
      nodes_stack: data.output.debug.nodes_stack,
      steps: data.output.debug.steps,
      planner_intents: data.output.planner_intents,
      messages: [
        // Updates the previous message with intents and entities from the response.
        ...node.messages.map((msg: BrainMessage) =>
          msg.id === messageSentId
            ? {
                ...msg,
                intents: data.output.intents,
                entities: data.output.entities,
              }
            : msg
        ),
        // Adds the response message to the node.
        ...(responseMessage ? [responseMessage] : []),
      ],
    };
  });

  return newNodes;
};
