import { useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { TFunction } from 'i18next';
import { PencilLineIcon } from 'lucide-react';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import { sendCorrection } from '@/api/client';
import Button from '@/components/atoms/Button/Button/Button';
import MarkdownEditor from '@/components/atoms/MarkdownEditor/MarkdownEditor';
import { AmendPayload } from '@/models/tryIt';
import { addErrorTemporalToast } from '@/modules/notifications/redux/actions';
import { setNodeAmend } from '@/modules/TryIt/redux/actions';
import { muiStyles } from '@/modules/TryIt/utils/helper';
import { capitalizeFirstLetter, delay } from '@/util/util';

import styles from './AmendWrapper.module.scss';

interface Props {
  children?: React.ReactNode;
  isAmended: boolean;
  amendPayload: AmendPayload;
  position?: 'left' | 'right';
}

interface Form {
  correction: string;
}

const MAX_FORM_CHARACTERS = 1500;

const schema = (t: TFunction) =>
  yup
    .object({
      correction: yup
        .string()
        .max(
          MAX_FORM_CHARACTERS,
          t('validation.max_length', {
            0: MAX_FORM_CHARACTERS,
          })
        )
        .required(t('validation.required')),
    })
    .required();

export const AmendWrapper = ({
  children = <></>,
  amendPayload,
  position = 'right',
  isAmended,
}: Props) => {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { t } = useTranslation();
  const {
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<Form>({
    resolver: yupResolver(schema(t)) as Resolver<Form>,
  });

  const [show, setShow] = useState(false);
  const [amended, setAmended] = useState(isAmended);
  const [isAmending, setIsAmending] = useState(false);

  const onSubmit = async (data: Form) => {
    if (isAmending) {
      return null;
    }
    setIsAmending(true);
    const payload = {
      ...amendPayload,
      correction: data.correction,
    };

    try {
      const result = await sendCorrection(payload);
      setIsAmending(false);
      if (result.status == 'ok') {
        dispatch(setNodeAmend(payload));
        setShow(false);
        await delay(300);
        setAmended(true);
      }
    } catch (e) {
      dispatch(addErrorTemporalToast(e));
      setIsAmending(false);
    }
  };

  const handleShowClick = () => {
    if (!show) {
      setShow(true);
      if (inputRef.current) {
        setTimeout(() => {
          inputRef.current.focus();
        }, 300);
      }
    }
  };

  useEffect(() => {
    if (show && inputRef.current) {
      setTimeout(() => {
        inputRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 400);
    }
  }, [show]);

  if (!amendPayload) {
    return children;
  }

  if (amended) {
    return (
      <div>
        {children}
        <div
          className={cn(styles.amendButton, styles[position], styles.amended)}
        >
          <Typography
            color="var(--text-default-success)"
            variant="label-regular"
          >
            {t('try_it.amended')}
          </Typography>
          <PencilLineIcon size={16} color="var(--icon-default-success)" />
        </div>
      </div>
    );
  }

  return (
    <div>
      {children}
      <div className={styles.container}>
        <MuiAccordion sx={muiStyles.accordionRoot} expanded={show}>
          <div className={cn(styles.amendButton, styles[position])}>
            <Button
              variant="tertiary"
              size="small"
              onClick={handleShowClick}
              noGutters
            >
              <Typography variant="label-semi-bold">
                {t('try_it.amend')}
              </Typography>

              <div className="ml-4">
                <PencilLineIcon size={16} color="var(--cta-default-blue)" />
              </div>
            </Button>
          </div>
          <AccordionDetails sx={muiStyles.detailsRoot}>
            <MarkdownEditor<Form>
              name="correction"
              setValue={setValue}
              label={t('ai_agents.goal.label')}
              error={!!errors.correction}
              errorMessage={capitalizeFirstLetter(errors.correction?.message)}
              size="small"
              value={watch('correction')}
            />

            <Typography
              variant="label-regular"
              color="var(--text-default-gray-light)"
            >
              {t('try_it.upon_approval')}
            </Typography>
            <div className={styles.buttonsContainer}>
              <Button variant="tertiary" onClick={() => setShow(false)}>
                {t('common.cancel')}
              </Button>
              <Button variant="secondary" onClick={handleSubmit(onSubmit)}>
                {t('common.submit')}
              </Button>
            </div>
          </AccordionDetails>
        </MuiAccordion>
      </div>
    </div>
  );
};
