import { memo, useDeferredValue, useState } from 'react';

import List from '@mui/material/List';
import {
  TypographyVariant,
  TypographyVariants,
  useTheme,
} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ChevronLeftIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import IconButton from '@/components/atoms/IconButton/IconButton';
import Input from '@/components/atoms/Input/Input';

import { DrawerItem } from './DrawerItem/DrawerItem';
import { MenuItem } from '../../../models';

import styles from './DrawerList.module.scss';

type Props = {
  headerTitle: string;
  onBackClick: () => void;
  items: MenuItem[];
  pinnedItems: string[];
  variant?: keyof TypographyVariants;
  showNav?: boolean;
};

const SHOW_SEARCH_THRESHOLD = 10;

export const DrawerList = memo(
  ({
    headerTitle,
    onBackClick,
    items,
    pinnedItems,
    variant = 'body-regular',
    showNav = true,
  }: Props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const [hovered, setHovered] = useState<string>(null);

    const [query, setQuery] = useState('');

    // https://react.dev/reference/react/useDeferredValue
    const deferredQuery = useDeferredValue(query);

    const filteredItems = items?.filter((i) =>
      i.text?.toLowerCase().includes(deferredQuery.toLowerCase())
    );

    return (
      <>
        {showNav && (
          <header className={styles.drawerHeader}>
            <IconButton ariaLabel={t('common.back')} onClick={onBackClick}>
              <ChevronLeftIcon size={18} color="var(--icon-default-gray)" />
            </IconButton>
            <Typography
              color="var(--text-default-gray)"
              variant="label-caps-large"
            >
              {headerTitle}
            </Typography>
          </header>
        )}
        <div className={styles.itemsWrapper}>
          {items?.length > SHOW_SEARCH_THRESHOLD && showNav && (
            <div className={styles.search}>
              <Input
                placeholder={t('common.search')}
                onChange={(event) => setQuery(event.target.value)}
                value={query}
                size="small"
              />
            </div>
          )}
          <List
            disablePadding
            sx={theme.typography[variant as TypographyVariant]}
          >
            {filteredItems.map((item) => (
              <DrawerItem
                key={item.id}
                item={item}
                hovered={hovered}
                setHovered={setHovered}
                pinnedItems={pinnedItems}
              />
            ))}
          </List>
        </div>
      </>
    );
  }
);

DrawerList.displayName = 'DrawerList';
