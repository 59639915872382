import { useCallback } from 'react';

import Typography from '@mui/material/Typography';
import cloneDeep from 'lodash/cloneDeep';
import { XIcon } from 'lucide-react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Rating } from '@/components/atoms/Rating';
import {
  AccountAnalyticsFilterType,
  LogsFilterType,
} from '@/modules/analytics/models';
import useLanguageModels from '@/modules/developerTools/hooks/useLanguageModels';
import { preventClickThrough } from '@/util/util';

import { FilterOption } from '../../constants';

import styles from './FilterValue.module.scss';

interface Props {
  type: LogsFilterType | AccountAnalyticsFilterType;
  option: FilterOption;
  index: number;
}
const FilterValue = ({ type, index, option }: Props) => {
  const { watch, register, setValue } = useFormContext();
  const value = watch(`filters.${index}.${type}`);
  const { t } = useTranslation();
  const { languageModelNames } = useLanguageModels();

  const onRemove = useCallback(
    (valIndex) => (event) => {
      preventClickThrough(event);
      const valueCopy = cloneDeep(value);
      valueCopy.splice(valIndex, 1);
      setValue(`filters.${index}.${type}`, valueCopy);
    },
    [index, setValue, type, value]
  );

  return (
    <div className={styles.value}>
      <Typography component="p" variant="label-semi-bold">
        {option?.prefix ?? option?.label}
      </Typography>
      <div className={styles.container}>
        {option?.input ? (
          <input
            className={styles.chip}
            {...register(`filters.${index}.${type}`)}
          />
        ) : option?.valuePrefix ? (
          <>
            {value && (
              <Typography
                component="div"
                variant="label-regular"
                className={styles.chip}
              >
                {option?.valuePrefix} {`${value}${option?.valueSuffix ?? ''}`}
              </Typography>
            )}
          </>
        ) : (
          <>
            {option?.trueLabel && value !== null ? (
              <div className={styles.chip}>
                {value ? option?.trueLabel : option?.falseLabel}
              </div>
            ) : (
              value?.map((val, valIndex) => (
                <div
                  className={styles.chip}
                  key={`${type}-${val?.value ?? val}`}
                >
                  {option?.value === 'ratings' && (
                    <Rating stars={parseInt(val, 10)} />
                  )}
                  {option?.value === 'channels' && (
                    <Typography component="p" variant="label-semi-bold">
                      {t(`channels.${val}`)}
                    </Typography>
                  )}
                  {option?.value === 'languageModelIds' && (
                    <Typography component="p" variant="label-semi-bold">
                      {languageModelNames[val]}
                    </Typography>
                  )}

                  {option?.value !== 'ratings' &&
                    option?.value !== 'channels' &&
                    option?.value !== 'languageModelIds' && (
                      <Typography component="p" variant="label-semi-bold">
                        {val?.label ?? val}
                      </Typography>
                    )}
                  {value.length > 1 && (
                    <button
                      className={styles.close}
                      onClick={onRemove(valIndex)}
                    >
                      <XIcon size={8} color="var(--icon-default-black)" />
                    </button>
                  )}
                </div>
              ))
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default FilterValue;
