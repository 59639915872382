import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { TypographyVariant } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {
  ArrowLeftIcon,
  ChevronRightIcon,
  MousePointerClickIcon,
} from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import Button from '@/components/atoms/Button/Button/Button';
import ButtonTest from '@/components/atoms/Button/ButtonTest/ButtonTest';
import { EditableText } from '@/components/atoms/EditableText/EditableText';
import IconButton from '@/components/atoms/IconButton/IconButton';
import InAppHelp from '@/components/atoms/InAppHelp/InAppHelp';
import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';
import StatusDot from '@/components/atoms/StatusDot/StatusDot';
import useBrainHeaderHelper from '@/components/organisms/Headers/BrainHeader/useBrainHeaderHelper';
import {
  MODAL_CONFIRM_CHANGES,
  MODAL_TRYIT,
  MODAL_WHATSAPP_TEST,
} from '@/components/organisms/Modals/ModalConductor';
import { getRoleName } from '@/components/pages/ManageAccess/helper';
import { useVersionsTable } from '@/components/pages/Versions/useVersionsTable';
import useBrains from '@/hooks/useBrains';
import useEntities from '@/hooks/useEntities';
import { AccountUserPrefsEnum, INAPPHELP_KEYS } from '@/hooks/useHomeCheckList';
import useIntents from '@/hooks/useIntents';
import useMembers from '@/hooks/useMembers';
import { useRoles } from '@/hooks/useRoles';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { useBillingState } from '@/modules/billing/hooks/useBillingState';
import { openTryIt, updateIsReplay } from '@/modules/TryIt/redux/actions';
import { selectIntentDraft } from '@/redux/expressions/selectors';
import { hideHelper } from '@/redux/helpers/actions';
import { popModal, pushModal } from '@/redux/modals/actions';
import { saveDialog } from '@/redux/nodes/actions';
import { selectIsDirty } from '@/redux/nodes/selectors';
import { getPermissions } from '@/redux/permissions/selectors';
import {
  selectAccountSlug,
  selectBrainId,
  selectDialogId,
  selectVersion,
} from '@/redux/session/selectors';
import { getRestrictedNames, noop } from '@/util/util';
import { getEditableTextValidationSchema, LENGTH_XS } from '@/util/validator';

import styles from './Header.module.scss';

const Actions = () => {
  // Redux selectors
  const brainId = useSelector(selectBrainId);
  const dialogId = useSelector(selectDialogId);
  const intentName = useSelector(selectIntentDraft, shallowEqual);
  const isDirty = useSelector(selectIsDirty);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  // Custom hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { member } = useMembers();
  const { roles } = useRoles();
  const { brain } = useBrains(brainId);
  const billingState = useBillingState();
  const { entityName } = useParams();
  const { saveIntent } = useIntents(brainId, intentName);
  const { saveEntity } = useEntities(brainId, entityName);
  const { handleCreateVersion } = useVersionsTable();
  const {
    setDismissTestHelp,
    setDismissVersionHelp,
    showTestDialog,
    showVersionTooltip,
  } = useBrainHeaderHelper();

  // Local variables
  const role = getRoleName(member, roles);
  const hasPlanExpired = billingState?.has_expired;

  // Handlers
  const warningModal = async () => {
    return new Promise<void>((resolve, reject): void => {
      const onSave = async () => {
        try {
          if (dialogId) {
            saveDialog();
          } else if (intentName) {
            await saveIntent();
          } else if (entityName) {
            await saveEntity();
          }
          resolve();
        } catch (e) {
          reject();
        }
      };
      dispatch(
        pushModal(MODAL_CONFIRM_CHANGES, {
          onSave,
          onDiscard: resolve,
          secondaryText: t('dialog.warningModal.secondary_try_it'),
          primaryText: t('dialog.warningModal.primary_try_it'),
        })
      );
    });
  };

  const showTryItModal = async () => {
    if (isDirty) {
      try {
        await warningModal();
      } catch (e) {
        return;
      }
    }
    dispatch(updateIsReplay(false));
    dispatch(openTryIt());
    dispatch(pushModal(MODAL_TRYIT, { brainId }));
  };

  const showWhatsAppTestModal = async () => {
    if (isDirty) {
      try {
        await warningModal();
      } catch (e) {
        return;
      }
    }
    dispatch(pushModal(MODAL_WHATSAPP_TEST, { brainId }));
  };

  return (
    <div className={styles.actions}>
      <InAppHelp
        placement="bottom-end"
        theme="dark"
        defaultOpen={showVersionTooltip}
        onClose={() => {
          setDismissVersionHelp(true);
          dispatch(
            hideHelper(INAPPHELP_KEYS[AccountUserPrefsEnum.CREATE_VERSION])
          );
        }}
        steps={[
          {
            icon: <MousePointerClickIcon size={34} />,
            body: <Typography>{t('home.help.create_version')}</Typography>,
          },
        ]}
      >
        <Button
          variant="tertiary"
          disabled={brain?.status !== 'available' || !canWrite}
          onClick={handleCreateVersion}
        >
          {role === 'Owner' || role === 'Admin'
            ? t('version.publish')
            : t('version.save_version')}
        </Button>
      </InAppHelp>

      <InAppHelp
        placement="bottom-end"
        theme="dark"
        defaultOpen={showTestDialog}
        onClose={() => {
          setDismissTestHelp(true);

          dispatch(
            hideHelper(INAPPHELP_KEYS[AccountUserPrefsEnum.TEST_DIALOG])
          );
        }}
        steps={[
          {
            icon: <MousePointerClickIcon size={34} />,
            body: <Typography>{t('auto_brain.test')}</Typography>,
          },
        ]}
      >
        <Tooltip
          title={t('brain.expired_plan')}
          disableHoverListener={!hasPlanExpired}
        >
          <span>
            <ButtonTest
              disabled={!canWrite || hasPlanExpired}
              onClickMoveo={showTryItModal}
              onClickWhatsApp={showWhatsAppTestModal}
            />
          </span>
        </Tooltip>
      </InAppHelp>
    </div>
  );
};

const Breadcrumbs = () => {
  const { t } = useTranslation();
  const { brainId } = useParams();
  const slug = useSelector(selectAccountSlug);
  const { brain } = useBrains(brainId);

  const breadCrumbs = [
    {
      label: t('common.ai_agents'),
      link: `/${slug}/ai-agents`,
    },
    {
      label: brain?.name,
      link: `/${slug}/ai-agents/${brainId}`,
    },
  ];

  return (
    <div className={styles.breadcrumbs}>
      <Stack spacing={2}>
        <MuiBreadcrumbs
          separator={<ChevronRightIcon size={18} />}
          aria-label="breadcrumb"
          sx={{
            'li a span': {
              '&:hover': {
                textDecoration: 'underline',
                color: 'var(--text-hover-blue)',
              },
            },
          }}
        >
          {breadCrumbs.map((breadcrumb, index) => {
            const isLast = index === breadCrumbs.length - 1;
            return isLast || breadcrumb.link === '' ? (
              <Typography
                key={breadcrumb.link}
                color="var(--text-default-gray-light)"
              >
                {breadcrumb.label}
              </Typography>
            ) : (
              <Link key={breadcrumb.link} to={breadcrumb.link}>
                <Typography color="var(--text-default-blue)">
                  {breadcrumb.label}
                </Typography>
              </Link>
            );
          })}
        </MuiBreadcrumbs>
      </Stack>
    </div>
  );
};

const Title = () => {
  // Redux selectors
  const slug = useSelector(selectAccountSlug);
  const brainId = useSelector(selectBrainId);
  const version = useSelector(selectVersion);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  // Custom hooks
  const { t } = useTranslation();
  const { brain, updateBrain, getStatus } = useBrains(brainId);
  const dispatch = useDispatch();
  const { brains } = useBrains();

  // Handlers
  const handleSubmit = (text: string) => {
    updateBrain(
      { name: text, brain_id: brainId },
      {
        onSuccess: () => {
          dispatch(popModal());
        },
      }
    );
  };

  // Local variables
  const isLoading = getStatus === 'pending';
  const restrictedValues = getRestrictedNames(brains, brain?.name);
  const validationSchema = getEditableTextValidationSchema(
    LENGTH_XS,
    restrictedValues,
    t('brains.brain_name')
  );
  const editableTextProps = {
    defaultValue: brain?.name,
    onSubmit: handleSubmit,
    variant: 'h2-semi-bold' as TypographyVariant,
    canEdit: canWrite,
    validationSchema,
  };

  return (
    <>
      <div className={styles.titleWrapper}>
        <Link to={`/${slug}/ai-agents`}>
          <IconButton onClick={noop} ariaLabel={t('brains.back_to_brains')}>
            <ArrowLeftIcon color="var(--icon-default-blue)" />
          </IconButton>
        </Link>

        {isLoading ? (
          <Skeleton height={15} width={100} />
        ) : (
          <EditableText {...editableTextProps} />
        )}

        <StatusBadge
          withIcon={false}
          label={
            version === 0
              ? t('common.draft')
              : t('common.version_with_number', { 0: version })
          }
          variant="neutral"
        />

        <StatusDot status={brain?.status} />

        {brain?.status === 'training' && (
          <Typography
            variant="label-caps-large"
            color="var(--text-default-gray)"
            ml={1}
          >
            {t('status.training')}
          </Typography>
        )}
      </div>
    </>
  );
};

export const Header = () => {
  return (
    <div className={styles.header}>
      <Breadcrumbs />

      <div className={styles.content}>
        <Title />
        <Actions />
      </div>
    </div>
  );
};
