import { ComponentProps, useState } from 'react';

import TextField from '@mui/material/TextField';
import { SendHorizontalIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import IconButton from '@/components/atoms/IconButton/IconButton';
import { isKeyEnter } from '@/util/util';

type Props = {
  handleButtonClick?: (
    e: React.MouseEvent<HTMLButtonElement>,
    value: string
  ) => void;
} & ComponentProps<typeof TextField>;

export const SEND_ICON_COLORS = {
  DEFAULT: 'var(--icon-default-gray)',
  VALID: 'var(--icon-default-blue)',
};

export const SendMessageInput = ({
  handleButtonClick,
  onKeyUp,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [isFocused, setFocused] = useState(false);
  const isInputFocusedAndNotEmpty = isFocused && value !== '';

  return (
    <TextField
      value={value}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onKeyUp={(e) => {
        onKeyUp(e);
        if (isKeyEnter(e)) {
          setValue('');
        }
      }}
      variant="outlined"
      placeholder={t('try_it.modal.type_here')}
      fullWidth
      InputProps={{
        sx: {
          borderRadius: 'var(--space-8)',
          border: '1px solid var(--border-default-gray)',
          padding: '6px 12px 6px 0',

          '&:focus-within': {
            boxShadow: '0 0 0 2px var(--border-default-blue)',
            borderColor: 'transparent',
          },
          input: {
            padding: '0 var(--space-12)',
          },
        },
        endAdornment: (
          <IconButton
            ariaLabel={t('common.send')}
            onClick={(e) => {
              if (!value) return;

              handleButtonClick(e, value);
              setValue('');
            }}
          >
            <SendHorizontalIcon
              color={
                isInputFocusedAndNotEmpty
                  ? SEND_ICON_COLORS.VALID
                  : SEND_ICON_COLORS.DEFAULT
              }
            />
          </IconButton>
        ),
      }}
      {...rest}
    />
  );
};
