import { useState } from 'react';

import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { CopyIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/atoms/Button/Button/Button';
import Checkmark from '@/components/atoms/Icons/Checkmark';
import Input from '@/components/atoms/Input/Input';
import Modal from '@/components/organisms/Modals/Modal';

import styles from './ModalAPIKeyCreated.module.scss';

type ModalAPIKeyCreatedProps = {
  apiKey: string;
  name: string;
  type: string;
};

type CopyAPIKeyButtonProps = {
  apiKey: string;
};

const CopyAPIKeyButton = ({ apiKey }: CopyAPIKeyButtonProps) => {
  const { t } = useTranslation();
  const [showCopyButton, setShowCopyButton] = useState(true);

  const handleClick = async () => {
    try {
      // Copy text to clipboard
      await navigator.clipboard.writeText(apiKey);

      setShowCopyButton(false);

      // Revert back to original state
      setTimeout(() => {
        setShowCopyButton(true);
      }, 1500);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };
  return (
    <Button onClick={handleClick} isFullWidth className={styles.copyButton}>
      {showCopyButton ? (
        <>
          <CopyIcon size={16} color="var(--icon-default-white)" />
          {t('developer_tools.api_modal.copy_button')}
        </>
      ) : (
        <>
          <Checkmark color="var(--icon-default-white)" />
          {t('developer_tools.api_modal.copied_button')}
        </>
      )}
    </Button>
  );
};

export const ModalAPIKeyCreated = ({
  apiKey,
  name,
  type,
}: ModalAPIKeyCreatedProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('developer_tools.api_modal.title')}
      subtitle={t('developer_tools.api_modal.subtitle')}
      passiveModal
      className={styles.modal}
      preventClose
    >
      <TextareaAutosize
        className={styles.textarea}
        value={apiKey}
        minRows={2}
        draggable={false}
        placeholder={t('dialog.set_variables.set_value.placeholder')}
        disabled
      />

      <CopyAPIKeyButton apiKey={apiKey} />

      <fieldset>
        <Input
          disabled
          label={t('common.name')}
          name="name"
          value={name}
          size="large"
        />

        <Input
          disabled
          label={t('common.type')}
          name="type"
          value={type}
          size="large"
        />
      </fieldset>
    </Modal>
  );
};
