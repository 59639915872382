import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import {
  AlertTriangleIcon,
  CircleAlertIcon,
  CircleCheckIcon,
  InfoIcon,
} from 'lucide-react';

import typography from './typography';

// Create a theme instance.
let theme = createTheme({
  typography,
  components: {
    MuiTypography: {
      defaultProps: {
        variant: 'body-regular',
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '4px',
          margin: '0 2px',
          '&.Mui-checked': {
            color: 'var(--icon-default-blue)',
          },
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          lineHeight: 'var(--space-40)',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--surface-secondary-gray-light)',
          border: '1px solid var(--border-default-gray)',
          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
          borderRadius: 'var(--border-radius-base)',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '0px var(--space-8)',
          minHeight: 0,
          '&.Mui-expanded': {
            minHeight: 0,
          },
          '&.Mui-focusVisible': {
            backgroundColor: 'transparent',
          },
        },
        expandIconWrapper: {
          padding: '4px var(--space-8) 4px 4px',
          marginRight: '-4px',
          color: 'var(--icon-default-gray)',
        },
        content: {
          margin: 0,
          maxWidth: '90%',
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          display: 'block',
          padding: 'var(--space-8)',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'inset 0px -1px 0px #E8E8E8;',
        },
        colorPrimary: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 'auto',
          marginBottom: '0',
        },
        flexContainer: {
          margin: '2px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: 'var(--text-default-gray)',
          fontFamily: 'Geist',
          fontSize: 'var(--font-p)',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '120%',
          padding: 'var(--space-10)',
          minHeight: 'var(--space-40)',
          textTransform: 'none',

          '&:hover': {
            background: 'var(--surface-secondary-gray-light)',
          },

          '&.Mui-selected': {
            color: 'inherit',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: 'var(--text-default-black)',
          borderRadius: 'var(--border-radius-base)',
          backgroundColor: 'var(--surface-secondary-gray-light)',
          height: 'var(--space-32)',

          '&:focus-visible': {
            outline: '2px solid var(--border-default-blue)',
            outlineOffset: '-2px',
          },
        },
        sizeSmall: {
          height: '24px',
        },
        sizeMedium: {
          height: '28px',
        },
        colorPrimary: {
          backgroundColor: 'var(--surface-tertiary-blue-light)',
          color: 'var(--text-default-black)',
        },
        colorSecondary: {
          backgroundColor: 'var(--surface-secondary-gray-light)',
          color: 'var(--text-default-black)',
        },
        deleteIconColorPrimary: {
          color: 'var(--icon-default-blue)',
          '&:hover': {
            color: 'var(--icon-default-blue)',
          },
          width: 'var(--space-16)',
          height: 'var(--space-16)',
        },
        deleteIconColorSecondary: {
          color: 'var(--icon-default-blue)',
          '&:hover': {
            color: 'var(--icon-default-blue)',
          },
          width: 'var(--space-16)',
          height: 'var(--space-16)',
        },
        deletableColorPrimary: {
          '&:hover,&:focus': {
            backgroundColor: 'var(--surface-tertiary-blue-light)',
            cursor: 'default',
          },
        },
        deletableColorSecondary: {
          '&:hover,&:focus': {
            backgroundColor: 'var(--surface-tertiary-blue-light)',
            cursor: 'default',
          },
        },
        deleteIcon: {
          color: 'var(--icon-default-gray)',
          backgroundColor: 'inherit',
          width: 'var(--space-16)',
          height: 'var(--space-16)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          '&:focus-visible': {
            boxShadow: 'inset 0 0 0 2px var(--border-default-blue)',
            borderRadius: 'var(--border-radius-base)',
            outline: 'none',
          },

          '&:hover': {
            color: 'var(--text-hover-blue)',
            backgroundColor: 'var(--surface-secondary-gray-light)',
            borderRadius: '50%',
          },

          svg: {
            width: 'var(--space-16)',
            height: 'var(--space-16)',
          },
        },
        clickable: {
          '&:hover': {
            backgroundColor: 'var(--cta-default-blue)',
            color: 'var(--text-default-white)',
          },
        },

        label: {
          fontSize: 'var(--space-14)',
          overflow: 'visible',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          height: '40px',
          color: 'var(--text-default-gray)',
          fontFamily: 'var(--font-primary)',
          fontSize: 'var(--font-p)',
          borderRadius: 'var(--border-radius-base)',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          border: '1px solid var(--border-default-gray)',
          '&.Mui-disabled': {
            border: '1px solid var(--border-default-gray)',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'transparent',
          },
          '&:hover': {
            border: '1px solid var(--border-default-gray)',
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'transparent',
            },
          },
          '&$focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'var(--border-default-gray)',
              borderWidth: '1px',
            },
          },
        },
        multiline: {
          padding: 'var(--space-8)',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'var(--font-primary)',
          fontSize: 'var(--font-p)',
          color: 'var(--text-default-black)',
        },
        input: {
          '&[aria-label="naked"]': {
            padding: '0',
            height: 'auto',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottom: '1px solid var(--border-default-gray)',
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: '2px solid var(--border-default-gray)',
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&$selected': {
            background: 'transparent',
            position: 'relative',
            fontWeight: '600',
            '&:hover': {
              background: 'transparent',
            },
            '&:after': {
              position: 'absolute',
              bottom: '0',
              left: '0',
              transition: 'var(--transition)',
              borderTopLeftRadius: '20px',
              borderTopRightRadius: '20px',
              background: 'var(--cta-default-blue)',
              width: '100%',
              height: '4px',
              content: '""',
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'var(--text-default-gray-light)',
          fontFamily: 'var(--font-primary)',
          fontSize: 'var(--font-p)',
          textTransform: 'capitalize',
          '&$focused:not(.Mui-error)': {
            color: 'var(--text-default-gray)',
            textTransform: 'uppercase',
            fontWeight: '600',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--surface-primary-white)',
        },
        elevation8: {
          boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: 'var(--surface-primary-white)',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: 'var(--surface-secondary-blue-dark)',
        },
        tooltip: {
          backgroundColor: 'var(--surface-secondary-blue-dark)',
          borderRadius: 'var(--border-radius-base)',
          color: 'var(--text-default-white)',
          fontSize: 'var(--space-12)',
          fontWeight: 400,
          lineHeight: '1.2',
          padding: 'var(--space-8) var(--space-12)',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: 'var(--icon-default-gray)',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: 0,
          marginLeft: '-8px',
        },
        label: {
          fontSize: 'var(--space-12)',
          fontWeight: 500,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: '24px',
          height: '24px',
        },
      },
    },

    MuiBadge: {
      styleOverrides: {
        badge: {
          height: 'var(--space-16)',
          minWidth: 'var(--space-16)',
          padding: '0 2px',
          fontWeight: 700,
        },
        dot: {
          height: '6px',
          minWidth: '6px',
        },
        anchorOriginTopRightRectangular: {
          top: '3px',
          right: '-4px',
        },
        colorPrimary: {
          backgroundColor: 'var(--icon-default-blue)',
          color: 'var(--text-default-white)',
        },
        colorSecondary: {
          backgroundColor: 'var(--surface-secondary-blue-dark)',
          color: 'var(--text-default-white)',
        },
        colorError: {
          backgroundColor: 'var(--cta-default-error)',
          color: 'var(--text-default-white)',
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          '&.MuiOutlinedInput-root': {
            padding: '0 2px',
            maxHeight: '162px',
            overflowY: 'auto',
          },
          '&.MuiOutlinedInput-root.Mui-error': {
            boxShadow: '0 0 0 2px var(--border-default-error)',
            borderColor: 'transparent',
          },
          backgroundColor: 'var(--surface-primary-white)',
          border: '1px solid var(--border-default-gray)',
          fontSize: '14px',
          '&:hover': {
            border: '1px solid var(--border-default-gray)',
          },
          '&.Mui-focused': {
            boxShadow: '0 0 0 2px var(--border-default-blue)',
            borderColor: 'transparent',
            zIndex: 10,
          },
        },
        clearIndicator: {
          color: 'var(--cta-hover-gray)',
          '&:hover': {
            color: 'var(--cta-hover-gray)',
          },
        },
        tag: {
          '&.MuiChip-deletable': {
            borderWidth: '0px',
            height: '24px',
            fontSize: '14px',
            backgroundColor: 'var(--surface-tertiary-blue-light)',
            '&:hover': {
              cursor: 'pointer',
              borderWidth: '0px',
            },
          },
          '& .MuiChip-deleteIcon': {
            // backgroundColor: 'var(--icon-default-gray)',
            color: 'var(--icon-default-gray)',
            borderRadius: '50%',
            '&:hover': {
              backgroundColor: 'var(--surface-secondary-gray)',
              color: 'var(--cta-hover-dark-blue)',
            },
          },
        },
        groupUl: {
          '& .MuiAutocomplete-option': {
            paddingLeft: 'var(--space-8)',
          },
        },
        groupLabel: {
          fontSize: '12px',
        },
        option: {
          margin: '0 8px',
          borderRadius: 'var(--border-radius-base)',
          paddingLeft: 'var(--space-8)',
          fontSize: '14px',
          '&[aria-selected=true]:not(:hover)': {
            backgroundColor: 'transparent',
          },
        },
      },
    },

    MuiSlider: {
      styleOverrides: {
        thumb: {
          color: '#1B66D6',
          height: '24px',
          width: '24px',
          marginLeft: '-2px',
        },
        markLabel: {
          top: '-22px',
        },
        rail: {
          height: '3px',
          opacity: 0.7,
        },
        mark: {
          height: '12px',
          width: '12px',
          marginLeft: '-8px',
          borderRadius: '16px',
          opacity: 1,
        },
        root: {
          color: 'var(--text-default-gray-light)',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiIconButton-root:not(.Mui-disabled):hover svg.lucide-trash2': {
            color: 'var(--icon-default-error)',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.MuiButtonBase-root': {
            borderRadius: '50%',
            padding: 'var(--space-4)',
          },
          '&.Mui-disabled': {
            cursor: 'not-allowed',
            pointerEvents: 'auto',
            opacity: '0.3',
          },
          '&:not(.Mui-disabled):hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
          '&:not(.Mui-disabled):hover svg': {
            color: 'var(--text-hover-blue)',
          },
          '&:not(.Mui-disabled):hover svg.stroke': {
            stroke: 'var(--icon-hover-blue)',
          },
          '&:not(.Mui-disabled):hover svg.fill': {
            fill: 'var(--icon-hover-blue)',
          },
          '&:not(.Mui-disabled):hover svg.delete': {
            stroke: 'var(--icon-default-error)',
          },
          '&:not(.Mui-disabled):focus': {
            boxShadow: 'inset 0 0 0 2px var(--border-default-blue)',
          },
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          success: (
            <CircleCheckIcon size={14} color="var(--icon-default-success)" />
          ),
          info: <InfoIcon size={14} color="var(--icon-default-blue)" />,
          error: (
            <AlertTriangleIcon size={14} color="var(--icon-default-error)" />
          ),
          warning: (
            <CircleAlertIcon color="var(--icon-default-warning)" size={14} />
          ),
        },
        severity: undefined,
      },
      styleOverrides: {
        root: {
          position: 'relative',
          boxShadow: '0px 8px 20px -4px #1718181F, 0px 3px 6px -3px #17181814',
          display: 'flex',
          alignItems: 'center',
          '& .MuiIconButton-root:not(.Mui-disabled):hover svg': {
            color: 'currentColor',
          },
        },
        standardSuccess: {
          background: 'var(--surface-status-success)',
        },
        standardInfo: {
          background: 'var(--surface-status-info)',
        },
        standardWarning: {
          background: 'var(--surface-status-warning)',
        },
        standardError: {
          background: 'var(--surface-status-critical)',
        },
        icon: {
          alignSelf: 'flex-start',
          transform: 'translateY(12px)',
          padding: '0px',
        },
        action: {
          alignSelf: 'flex-start',
          transform: 'translateY(6px)',
          paddingTop: '0px',
          paddingLeft: 'var(--space-8)',
        },
      },
    },
  },
});
theme = responsiveFontSizes(theme);

export default theme;
