import { useEffect, useMemo } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { ArrowDownIcon, ArrowUpIcon } from 'lucide-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AnalyticsFieldset from '@/modules/analytics/components/fieldsets/AnalyticsFieldset/AnalyticsFieldset';
import useLineCard from '@/modules/analytics/hooks/useLineCard';
import { selectDeskId } from '@/redux/session/selectors';
import { getGrowthArrowColor } from '@/util/analytics';

import LineAnalytics from './LineAnalytics';

import styles from './HomeAnalytics.module.scss';

interface Props {
  setAnalyticsEmpty?: (empty: boolean) => void;
}

const HomeAnalytics = ({ setAnalyticsEmpty }: Props) => {
  const deskId = useSelector(selectDeskId);
  const deskIds = `{${deskId}}`;
  const { t } = useTranslation();

  const queryProps = useMemo(
    () => ({
      startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      deskIds,
    }),
    [deskIds]
  );
  const sessions = useLineCard({
    intervalProps: {
      type: 'sessions',
      ...queryProps,
    },
    skip: !deskId,
  });
  const messages = useLineCard({
    intervalProps: {
      type: 'dialogs',
      ...queryProps,
    },
    skip: !deskId,
  });

  const fieldsets = [messages, sessions];

  const isLoading = sessions?.isLoading || messages?.isLoading;
  const isAnalyticsEmpty = sessions?.data?.counts === 0;
  useEffect(() => {
    setAnalyticsEmpty(isAnalyticsEmpty);
  }, [isAnalyticsEmpty, setAnalyticsEmpty]);

  if (!sessions?.data?.lineData || !messages?.data?.lineData) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {fieldsets?.map(({ data }) => {
        const { growth, title, counts } = data ?? {};
        const arrowColor = getGrowthArrowColor(growth);
        const colors = [arrowColor, 'var(--icon-default-blue)'];
        return (
          <div className={styles.fieldsetWrapper} key={`${deskId}-${title}`}>
            <AnalyticsFieldset
              hasFooter={false}
              fullWidth
              title={title}
              hasExport={false}
              isLoading={isLoading}
              isEmpty={isAnalyticsEmpty}
              size="small"
            >
              {!isAnalyticsEmpty && (
                <div className={styles.content}>
                  <div className={styles.header}>
                    <Typography variant="h1-semi-bold">{counts}</Typography>
                    {typeof growth === 'number' && (
                      <p
                        className={cn(styles.percentage, {
                          [styles.success]: growth > 0,
                          [styles.error]: growth < 0,
                        })}
                      >
                        {growth < 0 ? (
                          <ArrowDownIcon size={12} color={arrowColor} />
                        ) : (
                          <ArrowUpIcon size={12} color={arrowColor} />
                        )}
                        <Typography variant="body-semi-bold">
                          <span
                            style={{
                              color: arrowColor,
                            }}
                          >
                            {growth} %
                          </span>
                          <span className={styles.desc}>
                            {t('home.vs_last_month')}
                          </span>
                        </Typography>
                      </p>
                    )}
                  </div>
                  <div className={styles.chartContainer}>
                    <LineAnalytics data={data?.lineData} colors={colors} />
                  </div>
                </div>
              )}
            </AnalyticsFieldset>
          </div>
        );
      })}
    </div>
  );
};

export default HomeAnalytics;
