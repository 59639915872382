import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import MarkdownEditor from '@/components/atoms/MarkdownEditor/MarkdownEditor';
import useBrains from '@/hooks/useBrains';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectBrainId } from '@/redux/session/selectors';
import { capitalizeFirstLetter } from '@/util/util';

import { fieldSchema } from '../../helper';
import { useForceRerender } from '../../hooks/useForceRerender';
import { useTrackFormState } from '../../hooks/useTrackFormState';
import { FormCard } from '../FormCard/FormCard';
import { NumberIcon } from '../NumberIcon/NumberIcon';

type Form = {
  custom_instructions: string;
};

const FORM_ID = 'ai-agents-custom-instructions';

export const CustomInstructions = () => {
  const brainId = useSelector(selectBrainId);
  const { brain, updateBrain } = useBrains(brainId);
  const { t } = useTranslation();
  const custom_instructions = brain?.guidelines?.custom_instructions || '';

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );
  const { editorKey, forceRerender } = useForceRerender();

  // RHF
  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, isDirty },
    watch,
  } = useForm<Form>({
    mode: 'onSubmit',
    resolver: yupResolver(
      fieldSchema<Form>('custom_instructions', 10_000, t)
    ) as Resolver<Form>,

    values: {
      custom_instructions: custom_instructions,
    },
  });

  const onSubmit = (data: Form) => {
    updateBrain(
      {
        brain_id: brainId,
        guidelines: {
          ...brain?.guidelines,
          custom_instructions: data.custom_instructions,
        },
      },
      {
        onSuccess: () => {
          forceRerender();
        },
      }
    );
  };

  useTrackFormState({ isDirty, formId: FORM_ID });

  return (
    <>
      <FormCard id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
        <FormCard.Header
          title={t('ai_agents.knowledge.custom_instructions.title')}
          subtitle={t('ai_agents.knowledge.custom_instructions.subtitle')}
          icon={
            <NumberIcon
              color="var(--icon-default-blue)"
              size="large"
              // We always display the custom instructions in the first position
              number={1}
            />
          }
        />

        <FormCard.Content>
          <MarkdownEditor
            name="custom_instructions"
            setValue={setValue}
            label={t('ai_agents.navigation.overview')}
            defaultValue={custom_instructions}
            error={!!errors.custom_instructions}
            errorMessage={capitalizeFirstLetter(
              errors.custom_instructions?.message
            )}
            disabled={!canWrite}
            value={watch('custom_instructions')}
            key={editorKey}
          />
        </FormCard.Content>

        <FormCard.Footer>
          <Button
            disabled={!isDirty}
            type="submit"
            variant="secondary"
            isLoading={isSubmitting}
          >
            {t('common.save')}
          </Button>
        </FormCard.Footer>
      </FormCard>
    </>
  );
};
