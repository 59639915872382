import { memo, useMemo } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import Scrollbars from 'react-custom-scrollbars-2';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

import useFeatureFlag from '@/hooks/useFeatureFlag';
import { RootState } from '@/models/state';
import {
  EXTENSIONS,
  OPERATIONS,
  RESPONSES,
  START_WITH,
} from '@/redux/dialogs/helper';

import ToolkitList from './ToolkitList';

import styles from './Toolkit.module.scss';

const ToolkitMenu = () => {
  const { inactivity_action } = useFeatureFlag();
  const { t } = useTranslation();
  const hasNodes = useSelector(
    (state: RootState) => state.nodes.nodes.length > 0,
    shallowEqual
  );

  const availableResponses = useMemo(
    () => RESPONSES.filter(({ id }) => id !== 'replay'), // removed features.replay check
    []
  );

  const starters = useMemo(
    () =>
      hasNodes ? START_WITH : START_WITH.filter(({ id }) => id !== 'unknown'),
    [hasNodes]
  );

  // Remove the last operation if inactivity_action is disabled
  const operationItems = inactivity_action
    ? OPERATIONS
    : OPERATIONS.slice(0, -1);

  return (
    <Scrollbars style={{ width: 360 }}>
      <div className={cn(styles.toolkit)}>
        <Typography
          variant="body-semi-bold"
          color="var(--text-default-gray)"
          className={styles.header}
        >
          {t('dialog.toolkit')}
        </Typography>
        <ToolkitList items={starters} t={t} title="trigger" />
        {hasNodes && (
          <>
            <ToolkitList
              items={availableResponses}
              t={t}
              title="respond_with"
            />
            <ToolkitList items={operationItems} t={t} title="operations" />
            <ToolkitList items={EXTENSIONS} t={t} title="extensions" />
          </>
        )}
      </div>
    </Scrollbars>
  );
};

export default memo(ToolkitMenu);
