import { ArrowLeftIcon } from 'lucide-react';

import styles from './BrainInfo.module.scss';

const BlueBox = ({ text }) => {
  return (
    <div className={styles.blueBox}>
      <span>
        <ArrowLeftIcon color="var(--icon-default-blue)" />
      </span>
      <span>{text}</span>
    </div>
  );
};

export default BlueBox;
