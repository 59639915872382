import { useCallback, useEffect, useMemo } from 'react';

import isEmpty from 'lodash/isEmpty';
import {
  BarChartIcon,
  ChartPieIcon,
  MousePointerClickIcon,
  RocketIcon,
  Share2Icon,
  WandSparklesIcon,
  CirclePlayIcon,
} from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Icon } from '@/models/icon';
import { EventName } from '@/models/segment';
import { addTemporalToast } from '@/modules/notifications/redux/actions';
import { trackEvent } from '@/segment/segment';
import { getRoles, resolveBrainsPath } from '@/util/util';

import { useAccount } from './useAccount';
import useFeatureFlag from './useFeatureFlag';
import useMembers from './useMembers';
import { usePreferences } from './usePreferences';

export enum StartEnum {
  ENVIRONMENT_SETUP = 'environment_setup',
  PRODUCT_TOUR = 'product_tour',
}
export enum ChatManagerStartEnum {
  ENVIRONMENT_SETUP = 'environment_setup',
  ANALYTICS_OVERVIEW = 'analytics_overview',
}

export enum BuildEnum {
  WHAT_IS_COLLECTION = 'what_is_collection',
  IMPORT_CONTENT = 'import_content',
  CONNECT_BRAIN = 'connect_brain',
  WHAT_IS_BRAIN = 'what_is_brain',
  CREATE_INTENT = 'create_intent',
  GENERATE_DIALOG = 'generate_dialog',
  TEST_DIALOG = 'test_dialog',
  CREATE_VERSION = 'create_version',
}

export enum ConnectEnum {
  UTILISE_CHANNELS = 'utilise_channels',
  ACTIVATE_CHANNELS = 'activate_channels',
  UTILISE_RULES = 'utilise_rules',
  UPDATE_RULE = 'update_rule',
}

export enum MeasureEnum {
  ANALYTICS_OVERVIEW = 'analytics_overview',
  RECEIVE_MESSAGES = 'receive_messages',
}

export const AccountUserPrefsEnum = {
  ...StartEnum,
  ...BuildEnum,
  ...ConnectEnum,
  ...MeasureEnum,
  COMPLETED: 'completed',
} as const;

type Keys = keyof typeof AccountUserPrefsEnum;
type ValuesTypes = (typeof AccountUserPrefsEnum)[Keys];

export const INAPPHELP_KEYS = {
  [AccountUserPrefsEnum.PRODUCT_TOUR]: 'inappHelp.productTour',
  [AccountUserPrefsEnum.WHAT_IS_BRAIN]: 'inappHelp.whatIsBrain',
  [AccountUserPrefsEnum.WHAT_IS_COLLECTION]: 'inappHelp.whatIsCollection',
  [AccountUserPrefsEnum.IMPORT_CONTENT]: 'inappHelp.importContent',
  [AccountUserPrefsEnum.CONNECT_BRAIN]: 'inappHelp.connectBrain',
  [AccountUserPrefsEnum.GENERATE_DIALOG]: 'inappHelp.generateDialog',
  [AccountUserPrefsEnum.TEST_DIALOG]: 'inappHelp.testBrain',
  [AccountUserPrefsEnum.CREATE_INTENT]: 'inappHelp.createIntent',
  [AccountUserPrefsEnum.CREATE_VERSION]: 'inappHelp.createVersion',
  [AccountUserPrefsEnum.ACTIVATE_CHANNELS]: 'inappHelp.activateChannels',
  [AccountUserPrefsEnum.UPDATE_RULE]: 'inappHelp.updateRules',
  [AccountUserPrefsEnum.ANALYTICS_OVERVIEW]: 'inappHelp.analyticsOverview',
};

export interface Step {
  label: string;
  completed: boolean;
  icon: React.ReactNode;
  video?: string;
  url?: string;
  buttonUrl?: string;
  checklistStep?: ValuesTypes;
  localStorageKey?: string;
}
interface Tile {
  title: string;
  Icon: ({ size, color }: Icon) => JSX.Element;
  steps: Step[];
}

export const getPreferencesList = (role_ids: string[], accountRole: string) => {
  if (!role_ids) {
    return null;
  }
  const { isBuilder, isChatManager, isAdminOrOwner } = getRoles(
    role_ids,
    accountRole
  );
  let list;
  if (isAdminOrOwner) {
    list = { ...AccountUserPrefsEnum };
  } else {
    if (isBuilder) {
      list = {
        ...StartEnum,
        ...BuildEnum,
      };
    }
    if (isChatManager) {
      list = {
        ...list,
        ...ChatManagerStartEnum,
      };
    }
  }
  return { ...list, COMPLETED: 'completed' };
};

const useHomeCheckList = () => {
  const { accountUserPrefs, updateAccountUserPreferences } = usePreferences();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { accountRole, accountStatus } = useAccount();
  const { ai_agents } = useFeatureFlag();

  const { member } = useMembers();
  const role_ids = member?.role_ids;

  const checklist = useMemo(
    () => (accountUserPrefs?.checklist ?? {}) as Record<string, boolean>,
    [accountUserPrefs?.checklist]
  );

  const markAsComplete = useCallback(
    (key: ValuesTypes, prevent = false) => {
      if (checklist && !checklist[key]) {
        updateAccountUserPreferences(
          {
            checklist: { ...checklist, [key]: true },
          },
          {
            onSuccess: () => {
              if (EventName[key]) {
                trackEvent(EventName[key]);
              }
              const formattedKey = key?.split('_')?.join(' ');
              if (key !== AccountUserPrefsEnum.COMPLETED && !prevent) {
                dispatch(
                  addTemporalToast(
                    'success',
                    t('home.checklist.success', { 0: formattedKey })
                  )
                );
              }
            },
          }
        );
      }
    },
    [checklist, dispatch, t, updateAccountUserPreferences]
  );

  useEffect(() => {
    if (
      !checklist[AccountUserPrefsEnum.COMPLETED] &&
      accountStatus === 'success'
    ) {
      let notCompleted = false;
      for (const key in getPreferencesList(role_ids, accountRole)) {
        if (
          !checklist[AccountUserPrefsEnum[key]] &&
          AccountUserPrefsEnum[key] !== AccountUserPrefsEnum.COMPLETED
        ) {
          notCompleted = true;
        }
      }

      if (!notCompleted && !isEmpty(checklist) && role_ids) {
        markAsComplete(AccountUserPrefsEnum.COMPLETED);
      }
    }
  }, [accountRole, accountStatus, checklist, markAsComplete, role_ids]);

  const getSteps = useCallback(
    (
      prefs: Record<string, boolean>,
      slug: string,
      brainId: string,
      deskId: string,
      integrationId?: string,
      ruleId?: string,
      collectionId?: string
    ) => {
      if (!prefs) {
        return [];
      }
      const tiles: Tile[] = [
        {
          title: 'home.steps.title1',
          Icon: () => <RocketIcon size={16} color="var(--icon-default-gray)" />,
          steps: [
            {
              label: 'home.steps.step1',
              completed: !!prefs[AccountUserPrefsEnum.ENVIRONMENT_SETUP],
              icon: (
                <MousePointerClickIcon
                  size={16}
                  color="var(--icon-default-blue)"
                />
              ),
            },
            {
              label: 'home.steps.step2',
              completed: !!prefs[AccountUserPrefsEnum.PRODUCT_TOUR],
              icon: (
                <CirclePlayIcon size={16} color="var(--icon-default-blue)" />
              ),
              video: 'https://youtu.be/nqPNv3kSPq0',
              checklistStep: AccountUserPrefsEnum.PRODUCT_TOUR,
              buttonUrl: resolveBrainsPath(
                brainId ? `/${slug}/brains/${brainId}` : `/${slug}/brains`,
                ai_agents
              ),
              localStorageKey:
                INAPPHELP_KEYS[AccountUserPrefsEnum.PRODUCT_TOUR],
            },
          ],
        },
        {
          title: 'home.steps.title2',
          Icon: () => (
            <WandSparklesIcon
              className="p-1.5"
              size={28}
              color="var(--icon-default-gray) "
            />
          ),
          steps: [
            {
              label: 'home.steps.new1',
              completed: !!prefs[AccountUserPrefsEnum.WHAT_IS_COLLECTION],
              icon: (
                <CirclePlayIcon size={16} color="var(--icon-default-blue)" />
              ),
              video: 'https://youtu.be/ETwfMZ99q1g',
              localStorageKey:
                INAPPHELP_KEYS[AccountUserPrefsEnum.WHAT_IS_COLLECTION],
              checklistStep: AccountUserPrefsEnum.WHAT_IS_COLLECTION,
              url: `/${slug}/collections`,
            },
            {
              label: 'home.steps.new2',
              completed: !!prefs[AccountUserPrefsEnum.IMPORT_CONTENT],
              icon: (
                <MousePointerClickIcon
                  size={16}
                  color="var(--icon-default-blue)"
                />
              ),
              url: collectionId
                ? `/${slug}/collections/${collectionId}`
                : `/${slug}/collections`,
              localStorageKey:
                INAPPHELP_KEYS[AccountUserPrefsEnum.IMPORT_CONTENT],
              checklistStep: AccountUserPrefsEnum.IMPORT_CONTENT,
            },
            {
              label: `home.steps.new3${ai_agents ? '_ai_agent' : ''}`,
              completed: !!prefs[AccountUserPrefsEnum.CONNECT_BRAIN],
              icon: (
                <MousePointerClickIcon
                  size={16}
                  color="var(--icon-default-blue)"
                />
              ),
              url: resolveBrainsPath(
                brainId
                  ? `/${slug}/brains/${brainId}/collections`
                  : `/${slug}/brains`,
                ai_agents
              ),
              localStorageKey:
                INAPPHELP_KEYS[AccountUserPrefsEnum.CONNECT_BRAIN],
            },
            {
              label: 'home.steps.step3',
              completed: !!prefs[AccountUserPrefsEnum.WHAT_IS_BRAIN],
              icon: (
                <CirclePlayIcon size={16} color="var(--icon-default-blue)" />
              ),
              video: 'https://youtu.be/URS8Ayt085k',
              url: resolveBrainsPath(
                brainId
                  ? `/${slug}/brains/${brainId}/dialogs`
                  : `/${slug}/brains`,
                ai_agents
              ),
              buttonUrl: resolveBrainsPath(
                brainId ? `/${slug}/brains/${brainId}` : `/${slug}/brains`,
                ai_agents
              ),
              checklistStep: AccountUserPrefsEnum.WHAT_IS_BRAIN,
              localStorageKey:
                INAPPHELP_KEYS[AccountUserPrefsEnum.WHAT_IS_BRAIN],
            },
            {
              label: 'home.steps.step4',
              completed: !!prefs[AccountUserPrefsEnum.GENERATE_DIALOG],
              icon: (
                <MousePointerClickIcon
                  size={16}
                  color="var(--icon-default-blue)"
                />
              ),
              url: resolveBrainsPath(
                brainId ? `/${slug}/brains/${brainId}` : `/${slug}/brains`,
                ai_agents
              ),
              localStorageKey:
                INAPPHELP_KEYS[AccountUserPrefsEnum.GENERATE_DIALOG],
            },
            {
              label: 'home.steps.step5',
              completed: !!prefs[AccountUserPrefsEnum.TEST_DIALOG],
              icon: (
                <MousePointerClickIcon
                  size={16}
                  color="var(--icon-default-blue)"
                />
              ),
              url: resolveBrainsPath(
                brainId
                  ? `/${slug}/brains/${brainId}/dialogs`
                  : `/${slug}/brains`,
                ai_agents
              ),
              localStorageKey: INAPPHELP_KEYS[AccountUserPrefsEnum.TEST_DIALOG],
            },
            {
              label: 'home.steps.step6',
              completed: !!prefs[AccountUserPrefsEnum.CREATE_INTENT],
              icon: (
                <MousePointerClickIcon
                  size={16}
                  color="var(--icon-default-blue)"
                />
              ),
              url: resolveBrainsPath(
                brainId
                  ? `/${slug}/brains/${brainId}/intents`
                  : `/${slug}/brains`,
                ai_agents
              ),
              localStorageKey:
                INAPPHELP_KEYS[AccountUserPrefsEnum.CREATE_INTENT],
            },
            {
              label: 'home.steps.step7',
              completed: !!prefs[AccountUserPrefsEnum.CREATE_VERSION],
              icon: (
                <MousePointerClickIcon
                  size={16}
                  color="var(--icon-default-blue)"
                />
              ),
              url: resolveBrainsPath(
                brainId
                  ? `/${slug}/brains/${brainId}/dialogs`
                  : `/${slug}/brains`,
                ai_agents
              ),
              localStorageKey:
                INAPPHELP_KEYS[AccountUserPrefsEnum.CREATE_VERSION],
            },
          ],
        },
        {
          title: 'home.steps.title3',
          Icon: () => <Share2Icon size={16} color="var(--icon-default-gray)" />,
          steps: [
            {
              label: 'home.steps.step9',
              completed: !!prefs[AccountUserPrefsEnum.ACTIVATE_CHANNELS],
              icon: (
                <MousePointerClickIcon
                  size={16}
                  color="var(--icon-default-blue)"
                />
              ),
              url: `/${slug}/environments/${deskId}/integrations/${integrationId}`,
              localStorageKey:
                INAPPHELP_KEYS[AccountUserPrefsEnum.ACTIVATE_CHANNELS],
            },

            {
              label: 'home.steps.step11',
              completed: !!prefs[AccountUserPrefsEnum.UPDATE_RULE],
              icon: (
                <MousePointerClickIcon
                  size={16}
                  color="var(--icon-default-blue)"
                />
              ),
              url: `/${slug}/environments/${deskId}/rules/${ruleId}`,
              localStorageKey: INAPPHELP_KEYS[AccountUserPrefsEnum.UPDATE_RULE],
            },
          ],
        },
        {
          title: 'home.steps.title4',
          Icon: () => (
            <ChartPieIcon size={16} color="var(--icon-default-gray)" />
          ),
          steps: [
            {
              label: 'home.steps.step13',
              completed: !!prefs[AccountUserPrefsEnum.ANALYTICS_OVERVIEW],
              icon: (
                <CirclePlayIcon size={16} color="var(--icon-default-blue)" />
              ),
              url: `/${slug}/analytics`,
              video: 'https://youtu.be/ErIFuSFpNPk',
              checklistStep: AccountUserPrefsEnum.ANALYTICS_OVERVIEW,
              localStorageKey:
                INAPPHELP_KEYS[AccountUserPrefsEnum.ANALYTICS_OVERVIEW],
            },
            {
              label: 'home.steps.step14',
              completed: !!prefs[AccountUserPrefsEnum.RECEIVE_MESSAGES],
              icon: <BarChartIcon size={16} color="var(--icon-default-blue)" />,
            },
          ],
        },
      ];
      const { isBuilder, isChatManager, isChatAgent, isAdminOrOwner } =
        getRoles(role_ids, accountRole);

      if (isAdminOrOwner) {
        return tiles;
      }
      if (isBuilder && isChatManager) {
        const analyticsOverviewStep = tiles[3].steps[0];
        return [
          ...tiles.slice(0, 2),
          {
            ...tiles[3],
            steps: [analyticsOverviewStep],
          },
        ];
      }
      if (isBuilder) {
        return tiles.slice(0, 2);
      }
      if (isChatManager) {
        const analyticsOverviewStep = tiles[3].steps[0];
        return [
          {
            ...tiles[0],
            steps: [{ ...tiles[0].steps[0] }, analyticsOverviewStep],
          },
        ];
      }
      if (isChatAgent) {
        return [];
      }
      return tiles;
    },
    [accountRole, role_ids, ai_agents]
  );

  return {
    accountUserChecklist: checklist,
    markAsComplete,
    getSteps,
  };
};
export default useHomeCheckList;
