import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import cn from 'classnames';
import { ArrowDownIcon, ArrowUpIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import styles from './TabsTable.module.scss';

interface Props {
  items: {
    isLoading?: boolean;
    isPreviousLoading?: boolean;
    data: {
      title: string;
      counts: number | string;
      exact_counts?: number | string;
      timeframe?: string;
      tooltip?: string;
      growth?: number;
      label?: string | number;
    };
  }[];
  invertArrow?: boolean;
}

const TabsTable = ({ items, invertArrow = false }: Props) => {
  const { t } = useTranslation();
  if (!items) {
    return null;
  }
  return (
    <div className={styles.container}>
      {items.map(({ data, isLoading, isPreviousLoading }) => {
        return (
          <div
            className={cn(styles.item, {
              [styles.hasLabel]: data?.label,
            })}
            key={data?.title}
          >
            <p className={styles.title}>{data.title}</p>
            <div className={styles.contentWrapper}>
              <span className={styles.content}>
                {' '}
                {!isLoading ? (
                  <>
                    <Tooltip
                      arrow
                      title={
                        data.counts !== data.exact_counts
                          ? data.exact_counts
                          : ''
                      }
                    >
                      <span className={styles.counts}>
                        {data.counts ?? t('common.na')}
                      </span>
                    </Tooltip>
                    <span className={styles.label}>{data?.label}</span>
                  </>
                ) : (
                  <Skeleton height={24} width={24} />
                )}
              </span>
              {isPreviousLoading ? (
                <span>
                  <Skeleton height={24} width={80} />
                </span>
              ) : (
                typeof data?.growth === 'number' && (
                  <>
                    {data.growth === 0 ? (
                      <span className={styles.emptyGrowth}>_</span>
                    ) : (
                      <span
                        className={cn(styles.percentage, {
                          [styles.success]: data.growth > 0,
                          [styles.error]: data.growth < 0,
                          [styles.invertArrow]: invertArrow,
                        })}
                      >
                        {data.growth < 0 ? (
                          <ArrowDownIcon
                            size={10}
                            color="var(--icon-default-black)"
                          />
                        ) : (
                          <ArrowUpIcon
                            size={10}
                            color="var(--icon-default-black)"
                          />
                        )}
                        {data.growth}%
                      </span>
                    )}
                    <span className={styles.timeframe}>{data.timeframe}</span>
                  </>
                )
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TabsTable;
