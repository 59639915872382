import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import {
  CirclePauseIcon,
  CircleStopIcon,
  DotIcon,
  MessageSquareTextIcon,
  MicIcon,
  PaperclipIcon,
  PlayIcon,
  SmileIcon,
  Trash2Icon,
  XIcon,
} from 'lucide-react';
import { AudioVisualizer, LiveAudioVisualizer } from 'react-audio-visualize';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import IconButton from '@/components/atoms/IconButton/IconButton';
import { EmojiPicker } from '@/components/organisms/EmojiPicker/EmojiPicker';
import { MODAL_CANNED_RESPONSES } from '@/components/organisms/Modals/ModalConductor';
import UploadCard from '@/modules/humanChat/components/Conversation/MessageArea/FileUploadChatBox/UploadCard/UploadCard';
import { useConversations } from '@/modules/humanChat/hooks/useConversations';
import { useTemplateMessage } from '@/modules/humanChat/hooks/useTemplateMessage';
import { pushModal } from '@/redux/modals/actions';
import { selectDeskId, selectSessionId } from '@/redux/session/selectors';
import { preventClickThrough } from '@/util/util';

import QuickResponses from './QuickResponses';
import { useAudioControls } from './useAudioControls';
import { useChatBox } from '../ChatBox/useChatBox';

import styles from '../ChatBox/ChatBox.module.scss';

const ICON_SIZE = 20;

const FileUploadChatBox = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const deskId = useSelector(selectDeskId);
  const sessionId = useSelector(selectSessionId);
  const { conversation } = useConversations({ deskId, sessionId });
  const [emojiPicker, setEmojiPicker] = useState(false);
  const { showTemplateMessage } = useTemplateMessage();
  const visualizerRef = useRef<HTMLCanvasElement>(null);
  const {
    setAudioRef,
    isPlaying,
    handleAudioEnded,
    handlePlayClick,
    currentTime,
    pausedTime,
    isPaused,
  } = useAudioControls();

  const {
    files,
    isClosed,
    isActive,
    inputRef,
    isLoading,
    filteredOptions,
    isButtonDisabled,
    showQuickResponses,
    handleClick,
    handleChange,
    changeInputTo,
    handleKeyPress,
    handleKeyDown,
    setShowQuickResponses,
    handleOnRemoveFile,
    handlePaste,
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    audioUrl,
    handleStartRecording,
    isRecording,
    recordingBlob,
    recordingTime,
    mediaRecorder,
    handleDeleteRecording,
    handleStopRecording,
    isTranscoding,
    totalRecordingTime,
    isChannelSupported,
  } = useChatBox();
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filesToRender = useMemo(
    () =>
      files.map((file, idx) => {
        const fileName = file.name;
        if (file.type?.includes('image')) {
          return (
            <div
              className={cn(styles.thumbsWrapper, {
                [styles.noHover]: isLoading,
              })}
              key={file.path}
            >
              <div className={styles.thumbs}>
                <img
                  src={file.preview}
                  alt={fileName}
                  onLoad={() => {
                    URL.revokeObjectURL(file.preview);
                  }}
                  onDragStart={(e) => preventClickThrough(e)}
                />

                {!isLoading && (
                  <XIcon
                    color="var(--icon-default-white)"
                    onClick={(e) => handleOnRemoveFile(e, idx, file.fileId)}
                    onKeyDown={(e) => handleOnRemoveFile(e, idx, file.fileId)}
                    className={styles.closeIcon}
                    size={16}
                  />
                )}
              </div>
            </div>
          );
        }
        return (
          <UploadCard
            fileName={file.name?.split('.')[0]}
            fileType={file.name?.split('.').pop()}
            handleClose={(e) => handleOnRemoveFile(e, idx, file.fileId)}
            key={file.path}
          />
        );
      }),
    [files, handleOnRemoveFile, isLoading]
  );

  const handleQuickResponseClick = useCallback(
    (text: string) => {
      setShowQuickResponses(false);
      changeInputTo(text);
    },
    [changeInputTo, setShowQuickResponses]
  );

  const shouldDisableButtons =
    showTemplateMessage || !isActive || !isChannelSupported;

  const timer = useMemo(() => {
    let time;
    if (isRecording) {
      time = recordingTime;
    } else {
      time = isPlaying
        ? currentTime
        : isPaused && pausedTime !== null
          ? pausedTime
          : totalRecordingTime;
    }

    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }, [
    isRecording,
    recordingTime,
    isPlaying,
    currentTime,
    isPaused,
    pausedTime,
    totalRecordingTime,
  ]);

  const handleDeleteRec = useCallback(() => {
    handleDeleteRecording();
    handleAudioEnded();
  }, [handleAudioEnded, handleDeleteRecording]);

  const isWhatsappOrWebIntegration =
    conversation?.channel === 'whatsapp' || conversation?.channel === 'web';
  return (
    <>
      {emojiPicker && (
        <EmojiPicker
          className={styles.emojiPicker}
          onClickAway={() => {
            setEmojiPicker(false);
          }}
          onEmojiClick={({ emoji }) => {
            inputRef.current.innerHTML += emoji;
            setEmojiPicker(false);
          }}
          width="100%"
          height={200}
        />
      )}

      <div
        {...getRootProps({
          className: cn({
            [styles.inputWrapper]: true,
            [styles.isDragActive]: isDragActive,
          }),
        })}
      >
        <div
          className={cn({
            [styles.hide]: isDragActive,
          })}
        >
          <div
            ref={inputRef}
            className={cn(styles.input, {
              [styles.isClosed]: isClosed,
            })}
            aria-label="input"
            role="textbox"
            contentEditable={!shouldDisableButtons}
            tabIndex={0}
            onKeyUp={handleKeyPress}
            onKeyDown={handleKeyDown}
            onInput={handleChange}
            onPaste={handlePaste}
            data-testid="file-upload-chat-box"
            data-placeholder={t('chatBox.open')}
          />
          {files &&
            !(audioUrl || recordingBlob || mediaRecorder || isTranscoding) && (
              <div className={styles.fileZone}>{filesToRender}</div>
            )}
          <div
            className={cn(styles.chatBoxFooter, {
              [styles.isClosed]: isClosed,
              [styles.isDragAccept]: isDragActive,
            })}
          >
            <ul
              className={cn(styles.icons, {
                [styles.hide]: audioUrl || isRecording || recordingBlob,
              })}
            >
              <IconButton
                onClick={() =>
                  dispatch(pushModal(MODAL_CANNED_RESPONSES, { changeInputTo }))
                }
                ariaLabel={t('chatBox.quick_responses')}
                tooltip={t('chatBox.quick_responses')}
                disabled={shouldDisableButtons}
              >
                <MessageSquareTextIcon
                  color="var(--icon-default-gray)"
                  size={ICON_SIZE}
                />
              </IconButton>

              <IconButton
                onClick={open}
                tooltip={t('chatBox.attach_file')}
                ariaLabel={t('chatBox.attach_file')}
                disabled={shouldDisableButtons}
              >
                <PaperclipIcon
                  color="var(--icon-default-gray)"
                  size={ICON_SIZE}
                />
              </IconButton>
              <IconButton
                onClick={() => setEmojiPicker(true)}
                tooltip={t('emojiPicker.emoji_panel')}
                ariaLabel={t('emojiPicker.emoji_panel')}
                disabled={shouldDisableButtons}
              >
                <SmileIcon size={ICON_SIZE} color="var(--icon-default-gray)" />
              </IconButton>
              <IconButton
                onClick={handleStartRecording}
                tooltip={t('chatbox.microphone')}
                ariaLabel={t('chatbox.microphone')}
                disabled={shouldDisableButtons || !isWhatsappOrWebIntegration}
              >
                <MicIcon color="var(--icon-default-gray)" size={18} />
              </IconButton>
            </ul>
            {(audioUrl || recordingBlob || mediaRecorder || isTranscoding) && (
              <>
                {isTranscoding ? (
                  <CircularProgress size={16} />
                ) : (
                  <Typography
                    variant="label-regular"
                    color="var(--text-default-blue)"
                  >
                    <span className={styles.recording}>
                      <span className={styles.recordingControls}>
                        {/* Delete button */}
                        {recordingBlob && (
                          <IconButton
                            onClick={handleDeleteRec}
                            tooltip={t('chatbox.delete_recording')}
                            ariaLabel={t('chatbox.delete_recording')}
                          >
                            <Trash2Icon
                              size={16}
                              color="var(--icon-hover-red)"
                            />
                          </IconButton>
                        )}

                        {/* Recording indicator */}
                        {isRecording && (
                          <DotIcon size={8} color="var(--cta-default-error)" />
                        )}
                      </span>
                      <span className={styles.recordingVisualizers}>
                        {/* Visualizers */}
                        {isRecording && (
                          <LiveAudioVisualizer
                            mediaRecorder={mediaRecorder}
                            width={160}
                            height={30}
                            barWidth={3}
                            gap={2}
                            barColor="#1B66D6"
                          />
                        )}

                        {recordingBlob && (
                          <AudioVisualizer
                            ref={visualizerRef}
                            blob={recordingBlob}
                            barWidth={3}
                            gap={2}
                            width={160}
                            height={30}
                            barColor="#1B66D6"
                          />
                        )}

                        {/* Timer */}
                        <Typography
                          variant="label-regular"
                          color={
                            isPlaying || isRecording
                              ? 'var(--text-default-blue)'
                              : 'var(--text-default-gray)'
                          }
                          className={styles.timer}
                        >
                          {timer}
                        </Typography>
                      </span>

                      {/* Stop recording */}
                      {!audioUrl && (
                        <IconButton
                          onClick={handleStopRecording}
                          tooltip={t('chatbox.stop')}
                          ariaLabel={t('chatbox.stop')}
                        >
                          <CircleStopIcon
                            size={16}
                            color="var(--icon-default-gray)"
                          />
                        </IconButton>
                      )}

                      {/* Audio playback */}
                      {audioUrl && (
                        <IconButton
                          onClick={handlePlayClick}
                          tooltip={isPlaying ? t('chat.pause') : t('chat.play')}
                          ariaLabel={
                            isPlaying ? t('chat.pause') : t('chat.play')
                          }
                        >
                          {isPlaying ? (
                            <CirclePauseIcon
                              color="var(--icon-default-gray)"
                              size={16}
                            />
                          ) : (
                            <PlayIcon
                              color="var(--icon-default-gray)"
                              size={16}
                            />
                          )}
                        </IconButton>
                      )}

                      <audio
                        ref={setAudioRef}
                        src={audioUrl}
                        onEnded={handleAudioEnded}
                        className={styles.hide}
                      />
                    </span>
                  </Typography>
                )}
              </>
            )}
            <input {...getInputProps()} />
            <Button
              isLoading={isLoading}
              disabled={isButtonDisabled}
              onClick={handleClick}
            >
              {t('chatBox.send')}
            </Button>
          </div>
        </div>
        {isDragActive && (
          <div className={styles.dropFile}>{t('chatBox.drop_files')}</div>
        )}
      </div>

      {showQuickResponses && (
        <QuickResponses
          handleClick={handleQuickResponseClick}
          filteredOptions={filteredOptions}
        />
      )}
    </>
  );
};

export default FileUploadChatBox;
