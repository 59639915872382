import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { InfoIcon } from 'lucide-react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useBrains from '@/hooks/useBrains';
import { selectBrainId } from '@/redux/session/selectors';

import {
  getMaximumCharactersByLanguage,
  sumCurrentCharacters,
} from '../../helper';

import styles from './Knowledge.module.scss';

const formatNumber = (number: number) => {
  if (number >= 1000) {
    const inK = (number / 1000).toFixed(1); // Convert to "k" format with one decimal
    // Remove the ".0" if the decimal part is zero
    return inK.endsWith('.0') ? inK.slice(0, -2) + 'k' : inK + 'k';
  }
  return number.toString(); // Return the number as is if less than 1000
};

export const CharactersLinearProgress = () => {
  const { t } = useTranslation();
  const brainId = useSelector(selectBrainId);
  const { brain } = useBrains(brainId);
  const { language, guidelines } = brain;
  const currentCharacters = sumCurrentCharacters(guidelines);
  const maxCharacters = getMaximumCharactersByLanguage(language);
  const progressValue =
    (Math.min(currentCharacters, maxCharacters) / maxCharacters) * 100;

  return (
    <Box component="section" m="var(--space-16)">
      <Typography
        variant="label-caps-small"
        color="var(--text-default-gray-light)"
      >
        {t('ai_agents.character_limit.title')}
      </Typography>
      <Box display={'flex'} alignItems={'center'} gap="var(--space-8)">
        <LinearProgress
          sx={{
            '.MuiLinearProgress-bar1': {
              backgroundColor: 'var(--icon-default-blue)',
            },
          }}
          className={styles.characterLimitBar}
          variant="determinate"
          value={progressValue}
        />

        <Typography
          variant="label-caps-small"
          color="var(--text-default-gray-light)"
          flexShrink={0}
        >
          {formatNumber(currentCharacters)} / {formatNumber(maxCharacters)}
        </Typography>

        <Tooltip
          arrow
          title={
            <Box padding="var(--space-8) var(--space-4)">
              <Typography
                component="h6"
                variant="label-semi-bold"
                mb="var(--space-4)"
              >
                {t('ai_agents.character_limit.tooltip.title')}
              </Typography>
              <Typography component="p" variant="label-regular">
                <Trans
                  i18nKey="ai_agents.character_limit.tooltip.body"
                  values={{
                    0: currentCharacters,
                    1: maxCharacters,
                  }}
                />
              </Typography>
            </Box>
          }
        >
          <InfoIcon size={16} color="var(--icon-default-gray)" />
        </Tooltip>
      </Box>
    </Box>
  );
};
