import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';

import { Size, getSize } from '../AutoComplete/styles';

export const autocompleteStyles = (size: Size, hasError) => ({
  '&': {
    position: 'relative',
  },
  '& .MuiAutocomplete-inputRoot': {
    minHeight: `${getSize(size)}px`,
    borderColor: hasError ? 'var(--border-default-error)' : '',

    '&:hover': {
      borderColor: hasError ? 'var(--border-default-error)' : '',
    },

    '&.Mui-focused': {
      boxShadow: hasError ? '0 0 0 1px var(--border-default-error)' : '',
    },
    '& .MuiAutocomplete-input': {
      padding: '2px 4px 2px 16px',

      '&:disabled:hover': {
        cursor: 'not-allowed',
      },
    },
  },
  '& .MuiAutocomplete-endAdornment': {
    top: 'calc(50%)',
    transform: 'translateY(-50%)',
    display: 'flex',

    '& .MuiButtonBase-root': {
      '&:hover': {
        svg: {
          stroke: 'var(--icon-default-gray)',
        },
      },
    },
  },
  '& .MuiInputAdornment-root': {
    marginLeft: '10px',
    marginRight: '0px',
  },
});

export const GroupHeader = styled('div')(({ theme }) => {
  return {
    position: 'sticky',
    top: '-8px',
    padding: '8px 12px',
    zIndex: 1,
    ...theme.typography['label-caps-big'],
    color: 'var(--text-default-gray-light)',
    backgroundColor: 'var(--surface-primary-white)',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  };
});

export const GroupItems = styled('ul')(({ theme }) => {
  return {
    padding: 0,
    ...theme.typography['body-regular'],
    color: 'var(--text-default-black)',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    '& .MuiAutocomplete-option': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'inline-flex',
      paddingLeft: '4px',
      '&.Mui-focused': {
        backgroundColor: 'var(--surface-secondary-gray)',
      },
      '&:hover': {
        backgroundColor: 'var(--surface-secondary-gray)',
      },
      '& > .frontIcon': {
        paddingRight: '4px',
      },
      '& > .backIcon': {
        paddingLeft: '4px',
        '& svg': {
          transform: 'translateY(3px)',
        },
      },
      '& > .name': {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      '& > .newLabel': {
        color: 'var(--text-default-blue)',
      },
      '& > .addLabel': {
        color: 'var(--text-default-blue)',
        marginLeft: 'auto',

        '& svg': {
          display: 'inline-block',
          marginRight: 'var(--space-8)',
        },
      },
      '&[aria-selected="true"]': {
        backgroundColor: 'var(--surface-secondary-gray)',
        color: 'var(--text-default-blue)',
        '&:not(:hover)': {
          backgroundColor: 'var(--surface-secondary-gray)',
        },
        '&.Mui-focused': {
          backgroundColor: 'var(--surface-secondary-gray)',
        },
      },
    },
  };
});

export const StyledPopper = (props) => {
  //TODO hide underline when freesolo
  return (
    <Popper
      {...props}
      placement="bottom-start"
      style={{
        width: props?.width ? props.width + 2 : '100%',
        boxShadow: props?.open ? '0px 4px 8px rgba(0, 0, 0, 0.08)' : 'none',
        borderRadius: '4px',
        border: props?.open ? '1px solid #dedede' : 'none',
      }}
      sx={{
        '& .MuiPaper-root': {
          boxShadow: 'none',
        },
      }}
    />
  );
};
