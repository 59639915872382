import { Dispatch, SetStateAction, useEffect } from 'react';

import { BoxIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import useDesks from '@/hooks/useDesks';

import ExternalFilter from './ExternalFilter/ExternalFilter';
import useExternalFilter from '../../../hooks/useExternalFilter';

interface Props {
  dateOpen: boolean;
  filterOpen: boolean;
  setDateOpen: Dispatch<SetStateAction<boolean>>;
  setFilterOpen: Dispatch<SetStateAction<boolean>>;
}

const DeskFilters = ({
  dateOpen,
  setDateOpen,
  setFilterOpen,
  filterOpen,
}: Props) => {
  const { desks, listStatus } = useDesks();
  const { t } = useTranslation();

  const updatedDesks = desks?.map(({ desk_id, name }) => ({
    name,
    desk_id,
  }));

  const filterActions = useExternalFilter({
    items: updatedDesks as { [key: string]: string }[],
    dateOpen,
    filterOpen,
    setDateOpen,
    key: 'desk_id',
    filterKey: 'deskIds',
  });
  const handleClickAway = () => {
    filterActions.setShowItems(false);
    filterActions.applyItems();
  };
  useEffect(() => {
    if (filterActions.showItems && (dateOpen || filterOpen)) {
      setDateOpen(false);
      setFilterOpen(false);
    }
  }, [
    dateOpen,
    filterOpen,
    setDateOpen,
    setFilterOpen,
    filterActions.showItems,
  ]);

  return (
    <ExternalFilter
      {...filterActions}
      itemsLength={desks?.length}
      listStatus={listStatus}
      onClickAway={handleClickAway}
      icon={<BoxIcon size={16} color="var(--icon-default-blue)" />}
      displayValue={filterActions.getItemsDisplayValue(
        t('environments.select_environments'),
        t('environments.all_environments')
      )}
      dropdownTitle={t('environments.all_environments')}
    />
  );
};

export default DeskFilters;
