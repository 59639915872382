import { useCallback } from 'react';

import {
  LayersIcon,
  SquarePenIcon,
  Trash2Icon,
  UploadIcon,
} from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  BUNDLE_DELETE,
  MODAL_EDIT_MULTIPLE,
} from '@/components/organisms/Modals/ModalConductor';
import Tile from '@/components/organisms/Tile/Tile';
import TileMenu from '@/components/organisms/Tile/TileMenu';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { useBundles } from '@/modules/bundles/hooks/useBundles';
import { Bundle as BundleModel } from '@/modules/bundles/models';
import { popModal, pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { bundleRules } from '@/util/validator';

import styles from './TileBundle.module.scss';

interface Props {
  bundle: Partial<BundleModel>;
  slug: string;
  deskId: string;
}

const TileBundle = ({ slug, bundle, deskId }: Props) => {
  const { t } = useTranslation();
  const { bundle_id, name, description } = bundle;
  const { exportBundle, updateBundle } = useBundles(deskId, bundle_id);
  const dispatch = useDispatch();
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'bundles', actions.WRITE)
  );

  const handleEdit = useCallback(() => {
    const renameProps = {
      title: t('bundles.edit'),
      onEdit: async (
        data: {
          new_name: string;
          new_description: string;
        },
        onError
      ) => {
        updateBundle(
          {
            ...bundle,
            name: data.new_name,
            description: data.new_description,
          },
          {
            onSuccess: () => {
              dispatch(popModal());
            },
            onError,
          }
        );
      },
      fields: [
        {
          fieldName: 'new_name',
          fieldValue: name,
          rules: bundleRules.name,
        },
        {
          fieldName: 'new_description',
          fieldValue: description,
          rules: bundleRules.description,
        },
      ],
    };
    dispatch(pushModal(MODAL_EDIT_MULTIPLE, renameProps));
  }, [description, dispatch, name, bundle, t, updateBundle]);

  const menuItems = [
    {
      name: t('common.rename'),
      icon: <SquarePenIcon size={16} color="var(--icon-default-gray)" />,
      onClick: handleEdit,
    },
    {
      name: t('common.export'),
      icon: <UploadIcon size={16} color="var(--icon-default-gray)" />,
      onClick: () => exportBundle(deskId, bundle_id),
    },
    {
      name: t('common.delete'),
      type: 'delete',
      icon: <Trash2Icon size={16} />,
      onClick: () => {
        const deleteProps = { deskId, bundleId: bundle_id, name };
        dispatch(pushModal(BUNDLE_DELETE, deleteProps));
      },
    },
  ];

  return (
    <Link
      aria-label={name}
      className={styles.link}
      to={`/${slug}/environments/${deskId}/context_bundles/${bundle_id}`}
    >
      <Tile className={styles.wrapper}>
        <div className={styles.dnd}>
          <LayersIcon color="var(--icon-default-gray)" />
        </div>
        <div className={styles.info}>
          <span>
            <span className={styles.name}>{name}</span>
          </span>
          <span className={styles.desc}>{description}</span>
        </div>
        <div className={styles.menu}>
          {canWrite && <TileMenu menuItems={menuItems} />}
        </div>
      </Tile>
    </Link>
  );
};

export default TileBundle;
