// Custom icon

import { Icon } from '@/models/icon';

export const MessageQuestionIcon = ({
  size = 16,
  color = 'var(--icon-default-gray)',
  ...rest
}: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10.06 7.667a2 2 0 0 1 3.887.666c0 1.334-2 2-2 2M12 13h.007M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v10Z"
      />
    </svg>
  );
};
