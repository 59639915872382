import { useCallback } from 'react';

import cn from 'classnames';
import { ArrowLeftIcon, XIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import IconButton from '@/components/atoms/IconButton/IconButton';
import { MODAL_PROMPT } from '@/components/organisms/Modals/ModalConductor';
import useBrains from '@/hooks/useBrains';
import { usePreferences } from '@/hooks/usePreferences';
import { EventName } from '@/models/segment';
import { useOnboarding } from '@/modules/onboarding/hooks/useOnboarding';
import { pushModal } from '@/redux/modals/actions';
import { setIntegrationId } from '@/redux/session/actions';
import { trackEvent } from '@/segment/segment';
import { languageByCountryCode } from '@/util/constants';

import { createLangOptions } from '../../helper';
import { setLanguage } from '../../redux/actions';
import { selectLanguage } from '../../redux/selectors';

import styles from './WizardNavbar.module.scss';

interface WizardNavbarProps {
  onBack?: () => void;
  onClose?: () => void;
}

export const WizardNavbar = (props: WizardNavbarProps) => {
  const { t } = useTranslation();
  const language = useSelector(selectLanguage);
  const { initializeBrainWithTemplate } = useOnboarding();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { brains } = useBrains();

  const { userPreferences, updateUserPreferencesAsync } = usePreferences();

  const getBrowserLanguage = useCallback((): string => {
    const browserLang = navigator.language;
    const langOptions = createLangOptions(
      Object.keys(languageByCountryCode),
      languageByCountryCode,
      t
    );
    let brainLang = langOptions.find((l) => l.value === browserLang);
    if (!brainLang) {
      brainLang = langOptions.find(
        (l) => l.value === browserLang.split('-')[0]
      );
      if (!brainLang) {
        brainLang = { value: 'en' };
      }
    }
    return brainLang.value;
  }, [t]);

  const handleConfirmClose = useCallback(async () => {
    await trackEvent(EventName.ClickCloseOnboarding);
    if (!language) {
      dispatch(setLanguage(getBrowserLanguage()));
    }
    if (!userPreferences?.onboarding_completed) {
      await updateUserPreferencesAsync({ onboarding_completed: false });
    }

    if (!brains.length) {
      await initializeBrainWithTemplate({ language: getBrowserLanguage() });
    }

    dispatch(setIntegrationId(null));
    navigate('/');
  }, [
    brains?.length,
    dispatch,
    initializeBrainWithTemplate,
    language,
    navigate,
    updateUserPreferencesAsync,
    userPreferences?.onboarding_completed,
    getBrowserLanguage,
  ]);

  const handleOnClose = useCallback(() => {
    const modalProps = {
      title: t('onboarding.prompt_title'),
      message: t('onboarding.prompt_subtitle'),
      onConfirm: handleConfirmClose,
      primaryButtonText: t('common.quit'),
    };
    dispatch(pushModal(MODAL_PROMPT, modalProps));
  }, [dispatch, handleConfirmClose, t]);

  return (
    <div className={styles.wrapper}>
      {props?.onBack && (
        <div className={cn(styles.back)}>
          <IconButton ariaLabel={t('common.back')} onClick={props.onBack}>
            <ArrowLeftIcon color="var(--icon-default-black)" />
          </IconButton>
          {t('common.back')}
        </div>
      )}

      <div className={styles.close}>
        <IconButton
          ariaLabel={t('common.back')}
          onClick={props.onClose || handleOnClose}
        >
          <XIcon color="var(--icon-default-black)" size={16} />
        </IconButton>
      </div>
    </div>
  );
};
