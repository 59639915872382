import { useEffect, useState } from 'react';

import { SearchIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useAsyncDebounce } from 'react-table';

import Input from '../Input/Input';

export const TableFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  searchPlaceholder,
}) => {
  const count = preGlobalFilteredRows?.length;
  const { t } = useTranslation();
  const [value, setValue] = useState(globalFilter);

  const onChange = useAsyncDebounce((val) => {
    setGlobalFilter(val || undefined);
  }, 200);

  const handleChange = (e) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  useEffect(() => {
    if (!globalFilter && value) {
      setGlobalFilter(value);
    }
  }, [globalFilter, setGlobalFilter, value]);

  return (
    <Input
      iconLeft={<SearchIcon size={16} color="var(--icon-default-gray)" />}
      size="medium"
      value={value || ''}
      onChange={handleChange}
      placeholder={searchPlaceholder ?? t('table.search', { 0: count })}
    />
  );
};
