/* eslint-disable @typescript-eslint/no-explicit-any */
import { SyntheticEvent, useMemo, useRef } from 'react';

import { AutocompleteValue } from '@mui/base/useAutocomplete';
import Autocomplete, {
  createFilterOptions,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  AutocompleteInputChangeReason,
} from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import { PopperProps } from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import merge from 'lodash/merge';
import { CirclePlusIcon, XIcon } from 'lucide-react';
import { Control, Controller, Path, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { OptionBase } from '@/models/common';

import {
  autocompleteStyles,
  GroupHeader,
  GroupItems,
  StyledPopper,
} from './Components';
import HelpTooltip from '../HelpTooltip/HelpTooltip';

import styles from './Autocomplete.module.scss';

/**
 * DEPRECATION WARNING:
 * This component is slated for replacement in a future release,
 * by the component in the path below:
 * src/client/components/atoms/AutocompleteNew/AutocompleteNew2.tsx
 * If you're considering making modifications or depending on it,
 * please ensure to check with the development team.
 */
export interface AutoCompleteNew<
  FormType,
  Option extends Record<string, unknown>,
> {
  control: Control<FormType>;
  checkDisabledAgainst?: string;
  groupByProp?: string;
  groupByLabelProp?: boolean;
  name: Path<FormType>;
  options: OptionBase<Option>[];
  placeholder: string;
  renderIconInFront?: (type: string) => React.ReactNode;
  renderIconInBack?: (type: string) => React.ReactNode;
  iconTypeKey?: string;
  shouldRenderGroup?: boolean;
  tooltip?: string;
  label?: string;
  id?: string;
  hasError?: boolean;
  required?: boolean;
  defaultValue?: null;
  errorMessage?: React.ReactNode | string;
  size?: 'xs' | 'small' | 'medium' | 'large' | 'xlarge';
  onChange?: (
    event: SyntheticEvent,
    value: AutocompleteValue<any, any, any, any>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>
  ) => void;
  onInputChange?: (
    event: React.SyntheticEvent,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => void;
  onClose?: (event) => void;
  onKeyUp?: (event) => void;
  onBlur?: () => void;
  freeSolo?: boolean;
  rules?: UseControllerProps<FormType>['rules'];
  openOnFocus?: boolean;
  inputAutoFocus?: boolean;
  searchIcon?: React.ReactNode;
  disabled?: boolean;
  enableNewEntry?: boolean;
  autoHighlight?: boolean;
  isOptionEqualToValue?: (option, value) => boolean;
  getOptionLabel?: (option) => string;
  inputStyles?: any;
  optionStyles?: any;
}

const defaultIsOptionEqualToValue = (option, value) => {
  if (typeof value === 'string') {
    return option.value === value;
  }
  return option.value === value.value;
};

const defaultGetOptionLabel = (option) => {
  return typeof option === 'string' ? option : option.label.toString();
};

const AutoCompleteNew = <
  FormType,
  CustomOption extends Record<string, unknown>,
>({
  control,
  checkDisabledAgainst,
  groupByProp,
  groupByLabelProp = true,
  name,
  options,
  placeholder,
  defaultValue,
  renderIconInFront,
  renderIconInBack,
  iconTypeKey = 'node_type',
  shouldRenderGroup = true,
  tooltip,
  label,
  id,
  freeSolo = false,
  hasError = false,
  errorMessage,
  rules,
  onChange,
  onInputChange,
  onKeyUp,
  onClose,
  onBlur,
  required,
  size = 'small',
  openOnFocus = false,
  inputAutoFocus = false,
  disabled = false,
  searchIcon,
  enableNewEntry = true,
  autoHighlight = true,
  isOptionEqualToValue = defaultIsOptionEqualToValue,
  getOptionLabel = defaultGetOptionLabel,
  inputStyles,
  optionStyles,
}: AutoCompleteNew<FormType, CustomOption>) => {
  const inputRef = useRef(null);
  const { t } = useTranslation();
  const unlistedEntry = groupByLabelProp
    ? 'unlisted_entry'
    : t('common.unlisted_entry');
  const getPopperWidth = () => {
    if (inputRef?.current) {
      return inputRef.current.offsetWidth;
    }
    return null;
  };
  const filter = createFilterOptions();
  const renderGroup = (params) => {
    return (
      <li key={params.key}>
        {shouldRenderGroup && <GroupHeader>{params.group}</GroupHeader>}
        <GroupItems style={optionStyles}>{params.children}</GroupItems>
      </li>
    );
  };

  const renderIcons = (position, isSelected, iconTypeKey) => {
    if (position === 'front') {
      return renderIconInFront && renderIconInFront(iconTypeKey);
    } else if (position === 'back') {
      return renderIconInBack && renderIconInBack(iconTypeKey);
    }
  };

  // This prevents the Popper from re-rendering and scrolling to the top
  const memoizedPopper = useMemo(
    () => (popperProps: PopperProps) => {
      return StyledPopper({ ...popperProps, width: getPopperWidth() });
    },
    []
  );

  return (
    <>
      {label && (
        <label className={cn(styles.label)} htmlFor={id}>
          {label}
          {required && '*'}
          <HelpTooltip tooltip={tooltip} />
        </label>
      )}
      <Controller<FormType>
        name={name as Path<FormType>}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({
          field: {
            onChange: onControllerChange,
            onBlur: onControllerBlur,
            value,
            ...rest
          },
        }) => {
          return (
            <Autocomplete
              {...rest}
              autoHighlight={autoHighlight}
              freeSolo={freeSolo}
              disabled={disabled}
              onClose={onClose}
              openOnFocus={openOnFocus}
              clearIcon={<XIcon color="var(--icon-default-gray)" size={12} />}
              forcePopupIcon={false}
              ref={inputRef}
              componentsProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, 4],
                      },
                    },
                  ],
                },
              }}
              // Add this
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (!enableNewEntry) {
                  return filtered;
                }

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option.value
                );
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    type: unlistedEntry,
                    label: inputValue,
                    value: inputValue,
                  });
                }

                return filtered;
              }}
              getOptionLabel={getOptionLabel}
              getOptionDisabled={(option) =>
                checkDisabledAgainst
                  ? option.value === checkDisabledAgainst
                  : undefined
              }
              groupBy={
                groupByProp
                  ? (option) =>
                      groupByLabelProp
                        ? t(`auto_complete.group_labels.${option[groupByProp]}`)
                        : option[groupByProp]
                  : undefined
              }
              id={id}
              onBlur={() => {
                onControllerBlur();

                if (onBlur) {
                  onBlur();
                }
              }}
              onChange={(event, value, reason, details) => {
                onControllerChange(value);

                if (onChange) {
                  onChange(event, value, reason, details);
                }
              }}
              onKeyUp={(event) => {
                if (onKeyUp) {
                  onKeyUp(event);
                }
              }}
              options={options}
              PopperComponent={memoizedPopper}
              renderGroup={renderGroup}
              onInputChange={(event, value, reason) => {
                onControllerChange(value);

                if (onInputChange) {
                  onInputChange(event, value, reason);
                }
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: searchIcon ? (
                        <InputAdornment position="start">
                          {searchIcon}
                        </InputAdornment>
                      ) : undefined,
                    }}
                    placeholder={placeholder}
                    autoFocus={inputAutoFocus}
                  />
                );
              }}
              renderOption={(props, option) => {
                return (
                  <span {...props} key={option.value ?? option}>
                    <span className="frontIcon">
                      {renderIcons(
                        'front',
                        props['aria-selected'],
                        option[iconTypeKey]
                      )}
                    </span>
                    <span className="name">{option.label}</span>
                    <span className="backIcon">
                      {renderIcons(
                        'back',
                        props['aria-selected'],
                        option[iconTypeKey]
                      )}
                    </span>
                    {option.type === unlistedEntry && (
                      <>
                        <span className="newLabel">
                          <Typography variant="body-regular">
                            ({t('dialog.tags.new')})
                          </Typography>
                        </span>
                        <span className="addLabel">
                          <CirclePlusIcon
                            size={16}
                            color="var(--icon-default-blue)"
                          />
                          <Typography variant="body-semi-bold">
                            {t('common.add')}
                          </Typography>
                        </span>
                      </>
                    )}
                  </span>
                );
              }}
              sx={() =>
                merge({}, autocompleteStyles(size, hasError), inputStyles)
              }
              value={value as any}
              isOptionEqualToValue={isOptionEqualToValue}
              disableClearable={!value}
              clearOnEscape
            />
          );
        }}
      />
      {errorMessage && <p className={styles.error}>{errorMessage}</p>}
    </>
  );
};

export default AutoCompleteNew;
