import { memo } from 'react';

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ConversationEvent, ConversationEventTag } from '@/models/chat';
import { useDepartments } from '@/modules/departments/hooks/useDepartments';
import { useConversations } from '@/modules/humanChat/hooks/useConversations';
import { selectDeskId, selectSessionId } from '@/redux/session/selectors';

import {
  isConversationEventAgent,
  isConversationEventChanges,
  isConversationEventDepartment,
  isConversationEventTag,
} from './EventsTypeGuards';
import { EventWrapper } from './EventWrapper';
import { NameAvatar } from './NameAvatar';

type Props = {
  event: ConversationEvent;
};

const translationMapping = {
  resolve: 'conversation.resolve_event',
  reopen: 'conversation.reopen_event',
  trash: 'conversation.trash_event',
  spam: 'conversation.spam_event',
};

export const Events = memo(({ event }: Props) => {
  const { t } = useTranslation();
  const deskId = useSelector(selectDeskId);
  const sessionId = useSelector(selectSessionId);

  const { departments } = useDepartments(deskId);
  const { conversation } = useConversations({ deskId, sessionId });
  const { type, target, created, source_id } = event;
  const date = new Date(created);
  const time = new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }).format(date);

  const renderTags = (target: ConversationEventTag) => {
    let tagType = null;

    if (target?.added_tags.length) {
      tagType = 'added';
    } else if (target?.removed_tags.length) {
      tagType = 'removed';
    }
    const translationKey = `conversation.context_tag_${tagType}`;
    const tagKey = tagType === 'added' ? 'added_tags' : 'removed_tags';

    return (
      <>
        <Typography variant="label-regular" color="var(--text-default-gray)">
          {t(translationKey)}
        </Typography>
        <Typography variant="label-semi-bold" color="var(--text-default-gray)">
          {target[tagKey][0]}
        </Typography>
      </>
    );
  };
  const name =
    (type === ConversationEvent.RESOLVE || type === ConversationEvent.REOPEN) &&
    source_id == null
      ? conversation?.context?.user?.display_name
      : null;

  const renderEvent = () => {
    switch (type) {
      case ConversationEvent.UNASSIGN_BRAIN:
        return (
          <EventWrapper
            time={time}
            name={t('common.virtual_assistant')}
            userId="brain"
          >
            <Typography
              variant="label-regular"
              color="var(--text-default-gray)"
            >
              {t('conversation.left_conversation')}
            </Typography>
          </EventWrapper>
        );
      case ConversationEvent.ASSIGN_AGENT:
        if (isConversationEventAgent(target)) {
          if (target.prev_agent_id) {
            return (
              <EventWrapper time={time} userId={source_id}>
                <Typography
                  variant="label-regular"
                  color="var(--text-default-gray)"
                >
                  {t('conversation.assigned')}
                </Typography>
                <NameAvatar id={target.agent_id} />
              </EventWrapper>
            );
          }
          return (
            <EventWrapper time={time} userId={target.agent_id}>
              <Typography
                variant="label-regular"
                color="var(--text-default-gray)"
              >
                {t('conversation.joined_conversation')}
              </Typography>
            </EventWrapper>
          );
        }
        break;
      case ConversationEvent.UNASSIGN_AGENT:
        if (isConversationEventAgent(target)) {
          return (
            <EventWrapper time={time} userId={target.prev_agent_id}>
              <Typography
                variant="label-regular"
                color="var(--text-default-gray)"
              >
                {t('conversation.left_conversation')}
              </Typography>
            </EventWrapper>
          );
        }
        break;
      case ConversationEvent.ASSIGN_DEPARTMENT:
      case ConversationEvent.UNASSIGN_DEPARTMENT:
        if (isConversationEventDepartment(target)) {
          const departmentId =
            type === ConversationEvent.ASSIGN_DEPARTMENT
              ? target.department_id
              : target.prev_department_id;

          const translationKey =
            type === ConversationEvent.ASSIGN_DEPARTMENT
              ? 'conversation.assigned'
              : 'conversation.unassigned_event';

          return (
            <EventWrapper
              time={time}
              userId={source_id}
              name={t('common.system')}
            >
              <Typography
                variant="label-regular"
                color="var(--text-default-gray)"
              >
                {t(translationKey)}
              </Typography>
              <Typography
                variant="label-semi-bold"
                color="var(--text-default-gray)"
              >
                {
                  departments?.find((d) => d.department_id === departmentId)
                    ?.name
                }
              </Typography>
              <Typography
                variant="label-regular"
                color="var(--text-default-gray)"
              >
                {t('conversation.department')}
              </Typography>
            </EventWrapper>
          );
        }
        break;
      case ConversationEvent.REOPEN:
      case ConversationEvent.TRASH:
      case ConversationEvent.SPAM:
      case ConversationEvent.RESOLVE:
        return (
          <EventWrapper time={time} userId={source_id} name={name}>
            <Typography
              variant="label-regular"
              color="var(--text-default-gray)"
            >
              {t(translationMapping[type])}
            </Typography>
          </EventWrapper>
        );

      case ConversationEvent.CONTEXT_USER:
        if (isConversationEventChanges(target)) {
          return (
            <EventWrapper time={time} userId={source_id}>
              <Typography
                variant="label-regular"
                color="var(--text-default-gray)"
              >
                {t('conversation.context_user', {
                  0: t(`common.${target.changes[0]}`).toLowerCase(),
                })}
              </Typography>
            </EventWrapper>
          );
        }
        break;
      case ConversationEvent.CONTEXT_TAG:
        if (isConversationEventTag(target)) {
          return (
            <EventWrapper time={time} userId={source_id}>
              {renderTags(target)}
            </EventWrapper>
          );
        }
        break;
    }
  };

  return <>{renderEvent()}</>;
});

Events.displayName = 'Events';
