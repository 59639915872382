import { useCallback } from 'react';

import { TriangleAlertIcon, XIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { BAD_EXECUTION_TIME, MODERATE_EXECUTION_TIME } from '@/util/constants';

import styles from './Webhook.module.scss';

const ErrorInfo = ({ info }) => {
  const { t } = useTranslation();
  const { error, duration } = info;

  const renderError = useCallback(() => {
    return (
      <>
        <div className={styles.error}>
          <div>
            <XIcon size={16} color="var(--icon-default-error)" />
          </div>
          <div>{error?.code}</div>
        </div>
        <div className={styles.details}>{error?.details}</div>
        <div className={styles.details}>{error?.message}</div>
      </>
    );
  }, [error?.code, error?.details, error?.message]);

  if (duration > BAD_EXECUTION_TIME) {
    return (
      <div className={styles.errorWrapper}>
        <div className={styles.error}>
          <div>
            <XIcon size={16} color="var(--icon-default-error)" />
          </div>
          <div>{t('webhook.test.poor_performance')}</div>
        </div>
        {error !== null && renderError()}
      </div>
    );
  }

  if (duration > MODERATE_EXECUTION_TIME) {
    return (
      <div className={styles.errorWrapper}>
        <div className={styles.error}>
          <div>
            <TriangleAlertIcon size={16} color="var(--icon-default-warning)" />
          </div>
          <div>{t('webhook.test.moderate_performance')}</div>
        </div>
        {error !== null && renderError()}
      </div>
    );
  }

  return <div className={styles.errorWrapper}>{renderError()}</div>;
};

export default ErrorInfo;
